const arr = [{
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 9,
  TimeNow: 145.63636363636363,
  S: 1.4545454545454546,
  N: 97.54545454545455,
  J: 0.0,
  St: 27.09090909090909,
  Nt: 1633.909090909091,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 18,
  TimeNow: 164.8125,
  S: 8.75,
  N: 90.25,
  J: 0.0,
  St: 159.6875,
  Nt: 1501.3125,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 21,
  TimeNow: 165.53846153846155,
  S: 8.76923076923077,
  N: 90.23076923076923,
  J: 0.0,
  St: 158.46153846153845,
  Nt: 1502.5384615384614,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 1,
  TimeNow: 150.96153846153845,
  S: 6.230769230769231,
  N: 92.76923076923077,
  J: 0.0,
  St: 113.0,
  Nt: 1548.0,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 0,
  TimeNow: 151.77777777777777,
  S: 2.3333333333333335,
  N: 96.66666666666667,
  J: 0.0,
  St: 42.833333333333336,
  Nt: 1618.1666666666667,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 15,
  TimeNow: 163.45,
  S: 11.45,
  N: 86.95,
  J: 0.5,
  St: 198.6,
  Nt: 1453.2,
  Jt: 9.2,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 17,
  TimeNow: 168.1875,
  S: 10.1875,
  N: 88.8125,
  J: 0.0,
  St: 178.90625,
  Nt: 1482.09375,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 7,
  TimeNow: 151.28571428571428,
  S: 5.142857142857143,
  N: 93.85714285714286,
  J: 0.0,
  St: 95.78571428571429,
  Nt: 1565.2142857142858,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 11,
  TimeNow: 166.9375,
  S: 14.125,
  N: 84.875,
  J: 0.0,
  St: 240.0,
  Nt: 1421.0,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 20,
  TimeNow: 172.21428571428572,
  S: 13.357142857142858,
  N: 85.64285714285714,
  J: 0.0,
  St: 230.71428571428572,
  Nt: 1430.2857142857142,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 8,
  TimeNow: 142.63636363636363,
  S: 0.7272727272727273,
  N: 98.27272727272727,
  J: 0.0,
  St: 12.454545454545455,
  Nt: 1648.5454545454545,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 22,
  TimeNow: 164.23076923076923,
  S: 8.23076923076923,
  N: 90.15384615384616,
  J: 0.6153846153846154,
  St: 144.6153846153846,
  Nt: 1504.923076923077,
  Jt: 11.461538461538462,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 19,
  TimeNow: 167.83783783783784,
  S: 12.675675675675675,
  N: 86.32432432432432,
  J: 0.0,
  St: 221.54054054054055,
  Nt: 1439.4594594594594,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 16,
  TimeNow: 165.3684210526316,
  S: 11.105263157894736,
  N: 87.89473684210526,
  J: 0.0,
  St: 195.78947368421052,
  Nt: 1465.2105263157894,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 14,
  TimeNow: 156.78947368421052,
  S: 2.789473684210526,
  N: 96.21052631578948,
  J: 0.0,
  St: 49.473684210526315,
  Nt: 1611.5263157894738,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 13,
  TimeNow: 163.92,
  S: 11.12,
  N: 87.64,
  J: 0.2,
  St: 195.04,
  Nt: 1462.28,
  Jt: 3.68,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 12,
  TimeNow: 164.2,
  S: 12.2,
  N: 86.8,
  J: 0.0,
  St: 213.25,
  Nt: 1447.75,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 2,
  TimeNow: 142.0,
  S: 0.6153846153846154,
  N: 98.38461538461539,
  J: 0.0,
  St: 10.538461538461538,
  Nt: 1650.4615384615386,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 6,
  TimeNow: 150.5,
  S: 4.0,
  N: 95.0,
  J: 0.0,
  St: 74.5,
  Nt: 1586.5,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 10,
  TimeNow: 157.86666666666667,
  S: 9.6,
  N: 87.8,
  J: 1.3333333333333333,
  St: 167.93333333333334,
  Nt: 1468.5333333333333,
  Jt: 24.533333333333335,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 23,
  TimeNow: 154.15384615384616,
  S: 7.384615384615385,
  N: 91.61538461538461,
  J: 0.0,
  St: 137.53846153846155,
  Nt: 1523.4615384615386,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 4,
  TimeNow: 142.0909090909091,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1661.0,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 3,
  TimeNow: 141.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1661.0,
  Jt: 0.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 9,
  TimeNow: 343.3636363636364,
  S: 37.45454545454545,
  N: 34.63636363636363,
  J: 26.454545454545453,
  St: 906.9090909090909,
  Nt: 842.9090909090909,
  Jt: 643.8181818181819,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 18,
  TimeNow: 356.9375,
  S: 14.09375,
  N: 59.90625,
  J: 24.59375,
  St: 348.625,
  Nt: 1446.625,
  Jt: 598.3125,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 21,
  TimeNow: 208.15384615384616,
  S: 8.846153846153847,
  N: 81.38461538461539,
  J: 8.692307692307692,
  St: 221.0,
  Nt: 1958.3846153846155,
  Jt: 214.46153846153845,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 1,
  TimeNow: 143.80769230769232,
  S: 0.38461538461538464,
  N: 98.61538461538461,
  J: 0.0,
  St: 14.26923076923077,
  Nt: 2378.5,
  Jt: 0.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 0,
  TimeNow: 177.0,
  S: 7.277777777777778,
  N: 89.11111111111111,
  J: 2.611111111111111,
  St: 187.77777777777777,
  Nt: 2143.0555555555557,
  Jt: 63.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 15,
  TimeNow: 318.2,
  S: 26.15,
  N: 41.95,
  J: 30.5,
  St: 637.8,
  Nt: 1016.7,
  Jt: 739.5,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 17,
  TimeNow: 349.59375,
  S: 19.59375,
  N: 41.0625,
  J: 37.8125,
  St: 481.78125,
  Nt: 995.5625,
  Jt: 916.25,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 7,
  TimeNow: 190.14285714285714,
  S: 23.642857142857142,
  N: 72.28571428571429,
  J: 2.857142857142857,
  St: 577.2142857142857,
  Nt: 1744.4285714285713,
  Jt: 71.5,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 11,
  TimeNow: 191.5625,
  S: 16.75,
  N: 78.25,
  J: 3.8125,
  St: 415.3125,
  Nt: 1881.875,
  Jt: 96.8125,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 20,
  TimeNow: 233.0,
  S: 17.642857142857142,
  N: 71.78571428571429,
  J: 9.142857142857142,
  St: 434.35714285714283,
  Nt: 1731.857142857143,
  Jt: 227.75,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 8,
  TimeNow: 248.1818181818182,
  S: 28.454545454545453,
  N: 62.45454545454545,
  J: 7.636363636363637,
  St: 696.0,
  Nt: 1505.2727272727273,
  Jt: 191.54545454545453,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 22,
  TimeNow: 153.92307692307693,
  S: 2.5384615384615383,
  N: 95.46153846153847,
  J: 1.0,
  St: 65.76923076923077,
  Nt: 2302.769230769231,
  Jt: 24.384615384615383,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 19,
  TimeNow: 236.75675675675674,
  S: 11.783783783783784,
  N: 78.21621621621621,
  J: 8.756756756756756,
  St: 292.72972972972974,
  Nt: 1883.7027027027027,
  Jt: 217.24324324324326,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 16,
  TimeNow: 275.6842105263158,
  S: 22.894736842105264,
  N: 50.78947368421053,
  J: 24.68421052631579,
  St: 560.7894736842105,
  Nt: 1228.3684210526317,
  Jt: 605.1578947368421,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 14,
  TimeNow: 238.6315789473684,
  S: 26.894736842105264,
  N: 63.73684210526316,
  J: 7.842105263157895,
  St: 658.6315789473684,
  Nt: 1540.3684210526317,
  Jt: 195.1578947368421,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 13,
  TimeNow: 223.88,
  S: 16.36,
  N: 75.12,
  J: 7.04,
  St: 406.28,
  Nt: 1810.48,
  Jt: 177.48,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 12,
  TimeNow: 173.4,
  S: 10.2,
  N: 87.4,
  J: 1.1,
  St: 258.0,
  Nt: 2104.9,
  Jt: 31.3,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 2,
  TimeNow: 140.07692307692307,
  S: 1.9230769230769231,
  N: 96.76923076923077,
  J: 0.3076923076923077,
  St: 49.92307692307692,
  Nt: 2335.0,
  Jt: 7.384615384615385,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 6,
  TimeNow: 146.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 2392.0,
  Jt: 0.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 10,
  TimeNow: 232.6,
  S: 38.0,
  N: 49.266666666666666,
  J: 11.333333333333334,
  St: 920.7333333333333,
  Nt: 1190.6666666666667,
  Jt: 283.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 23,
  TimeNow: 147.84615384615384,
  S: 1.6153846153846154,
  N: 97.38461538461539,
  J: 0.0,
  St: 40.46153846153846,
  Nt: 2351.923076923077,
  Jt: 0.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 4,
  TimeNow: 135.72727272727272,
  S: 0.36363636363636365,
  N: 98.63636363636364,
  J: 0.0,
  St: 9.454545454545455,
  Nt: 2382.5454545454545,
  Jt: 0.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 3,
  TimeNow: 136.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 2392.0,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 9,
  TimeNow: 245.0,
  S: 2.1818181818181817,
  N: 96.81818181818181,
  J: 0.0,
  St: 112.63636363636364,
  Nt: 4265.363636363636,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 18,
  TimeNow: 463.03125,
  S: 39.65625,
  N: 30.1875,
  J: 28.53125,
  St: 1761.4375,
  Nt: 1351.0625,
  Jt: 1265.5625,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 21,
  TimeNow: 340.38461538461536,
  S: 37.07692307692308,
  N: 46.53846153846154,
  J: 14.846153846153847,
  St: 1646.4615384615386,
  Nt: 2067.6153846153848,
  Jt: 663.9230769230769,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 1,
  TimeNow: 219.73076923076923,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 6.153846153846154,
  Nt: 4371.846153846154,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 0,
  TimeNow: 259.6111111111111,
  S: 1.7222222222222223,
  N: 95.5,
  J: 1.7777777777777777,
  St: 80.77777777777777,
  Nt: 4217.944444444444,
  Jt: 79.33333333333333,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 15,
  TimeNow: 549.95,
  S: 14.7,
  N: 27.65,
  J: 56.15,
  St: 664.3,
  Nt: 1231.5,
  Jt: 2482.35,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 17,
  TimeNow: 826.03125,
  S: 21.15625,
  N: 31.34375,
  J: 45.84375,
  St: 947.6875,
  Nt: 1399.90625,
  Jt: 2031.15625,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 7,
  TimeNow: 280.2142857142857,
  S: 34.0,
  N: 63.0,
  J: 1.7857142857142858,
  St: 1508.0714285714287,
  Nt: 2784.214285714286,
  Jt: 85.71428571428571,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 11,
  TimeNow: 268.5,
  S: 19.375,
  N: 78.375,
  J: 1.25,
  St: 863.5,
  Nt: 3454.5,
  Jt: 60.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 20,
  TimeNow: 321.10714285714283,
  S: 38.57142857142857,
  N: 58.82142857142857,
  J: 1.5,
  St: 1706.142857142857,
  Nt: 2601.75,
  Jt: 70.10714285714286,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 8,
  TimeNow: 245.1818181818182,
  S: 12.363636363636363,
  N: 85.0,
  J: 1.3636363636363635,
  St: 569.6363636363636,
  Nt: 3742.909090909091,
  Jt: 65.45454545454545,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 22,
  TimeNow: 302.84615384615387,
  S: 27.923076923076923,
  N: 65.92307692307692,
  J: 5.076923076923077,
  St: 1234.7692307692307,
  Nt: 2914.230769230769,
  Jt: 229.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 19,
  TimeNow: 298.13513513513516,
  S: 31.594594594594593,
  N: 63.83783783783784,
  J: 3.4324324324324325,
  St: 1402.4594594594594,
  Nt: 2818.7297297297296,
  Jt: 156.8108108108108,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 16,
  TimeNow: 707.3157894736842,
  S: 13.631578947368421,
  N: 30.68421052631579,
  J: 54.05263157894737,
  St: 614.578947368421,
  Nt: 1370.6315789473683,
  Jt: 2393.1052631578946,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 14,
  TimeNow: 493.42105263157896,
  S: 23.05263157894737,
  N: 25.57894736842105,
  J: 49.94736842105263,
  St: 1030.7368421052631,
  Nt: 1141.421052631579,
  Jt: 2205.842105263158,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 13,
  TimeNow: 483.32,
  S: 27.36,
  N: 34.16,
  J: 37.16,
  St: 1214.68,
  Nt: 1519.84,
  Jt: 1643.6,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 12,
  TimeNow: 445.45,
  S: 28.7,
  N: 41.95,
  J: 27.9,
  St: 1274.5,
  Nt: 1865.1,
  Jt: 1238.6,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 2,
  TimeNow: 213.53846153846155,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 8.615384615384615,
  Nt: 4369.384615384615,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 6,
  TimeNow: 227.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 4378.0,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 10,
  TimeNow: 263.3333333333333,
  S: 10.2,
  N: 88.8,
  J: 0.0,
  St: 470.26666666666665,
  Nt: 3907.866666666667,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 23,
  TimeNow: 246.76923076923077,
  S: 1.5384615384615385,
  N: 97.46153846153847,
  J: 0.0,
  St: 85.0,
  Nt: 4293.0,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 4,
  TimeNow: 211.1818181818182,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 11.636363636363637,
  Nt: 4366.363636363636,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 3,
  TimeNow: 213.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 4378.0,
  Jt: 0.0,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 9,
  TimeNow: 456.09090909090907,
  S: 55.54545454545455,
  N: 26.0,
  J: 16.727272727272727,
  St: 3133.909090909091,
  Nt: 1493.0,
  Jt: 960.0909090909091,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 18,
  TimeNow: 1473.5,
  S: 1.4375,
  N: 0.4375,
  J: 97.0625,
  St: 89.46875,
  Nt: 26.9375,
  Jt: 5469.78125,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 21,
  TimeNow: 430.6923076923077,
  S: 50.84615384615385,
  N: 29.46153846153846,
  J: 18.384615384615383,
  St: 2868.769230769231,
  Nt: 1669.2307692307693,
  Jt: 1048.5384615384614,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 1,
  TimeNow: 250.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5586.0,
  Jt: 0.0,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 0,
  TimeNow: 351.8333333333333,
  S: 1.1666666666666667,
  N: 92.33333333333333,
  J: 5.5,
  St: 71.94444444444444,
  Nt: 5203.722222222223,
  Jt: 310.3333333333333,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 15,
  TimeNow: 793.15,
  S: 35.65,
  N: 0.4,
  J: 62.95,
  St: 2018.75,
  Nt: 22.55,
  Jt: 3545.4,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 17,
  TimeNow: 1586.28125,
  S: 3.9375,
  N: 3.71875,
  J: 91.25,
  St: 235.5625,
  Nt: 213.59375,
  Jt: 5136.84375,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 7,
  TimeNow: 648.5,
  S: 29.214285714285715,
  N: 22.285714285714285,
  J: 46.785714285714285,
  St: 1661.857142857143,
  Nt: 1284.5,
  Jt: 2639.785714285714,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 11,
  TimeNow: 430.625,
  S: 50.25,
  N: 35.9375,
  J: 12.5625,
  St: 2838.0,
  Nt: 2032.1875,
  Jt: 716.75,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 20,
  TimeNow: 414.82142857142856,
  S: 50.82142857142857,
  N: 34.392857142857146,
  J: 13.214285714285714,
  St: 2870.8214285714284,
  Nt: 1953.607142857143,
  Jt: 762.3571428571429,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 8,
  TimeNow: 578.6363636363636,
  S: 52.27272727272727,
  N: 10.818181818181818,
  J: 35.63636363636363,
  St: 2944.181818181818,
  Nt: 625.1818181818181,
  Jt: 2016.8181818181818,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 22,
  TimeNow: 388.9230769230769,
  S: 46.69230769230769,
  N: 46.23076923076923,
  J: 5.846153846153846,
  St: 2636.230769230769,
  Nt: 2612.4615384615386,
  Jt: 338.0,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 19,
  TimeNow: 815.6486486486486,
  S: 21.54054054054054,
  N: 14.243243243243244,
  J: 62.648648648648646,
  St: 1228.1351351351352,
  Nt: 818.4864864864865,
  Jt: 3539.4324324324325,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 16,
  TimeNow: 986.1578947368421,
  S: 21.31578947368421,
  N: 1.736842105263158,
  J: 75.78947368421052,
  St: 1214.6315789473683,
  Nt: 103.05263157894737,
  Jt: 4268.8421052631575,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 14,
  TimeNow: 408.7368421052632,
  S: 49.10526315789474,
  N: 43.63157894736842,
  J: 6.2105263157894735,
  St: 2777.0,
  Nt: 2457.5263157894738,
  Jt: 352.2105263157895,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 13,
  TimeNow: 600.28,
  S: 50.48,
  N: 5.2,
  J: 43.24,
  St: 2853.44,
  Nt: 299.84,
  Jt: 2433.76,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 12,
  TimeNow: 610.05,
  S: 30.6,
  N: 24.85,
  J: 42.75,
  St: 1744.8,
  Nt: 1424.4,
  Jt: 2417.95,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 2,
  TimeNow: 240.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5586.0,
  Jt: 0.0,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 6,
  TimeNow: 364.0,
  S: 26.0,
  N: 61.5,
  J: 11.0,
  St: 1479.0,
  Nt: 3488.0,
  Jt: 619.5,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 10,
  TimeNow: 415.8,
  S: 38.333333333333336,
  N: 51.4,
  J: 8.933333333333334,
  St: 2164.866666666667,
  Nt: 2902.866666666667,
  Jt: 519.2666666666667,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 23,
  TimeNow: 331.46153846153845,
  S: 30.53846153846154,
  N: 67.6923076923077,
  J: 0.7692307692307693,
  St: 1731.6923076923076,
  Nt: 3811.076923076923,
  Jt: 43.38461538461539,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 4,
  TimeNow: 235.8,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5586.0,
  Jt: 0.0,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 3,
  TimeNow: 238.0,
  S: 7.5,
  N: 91.5,
  J: 0.0,
  St: 466.0,
  Nt: 5120.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 9,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 18,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 21,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 1,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 0,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 15,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 17,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 7,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 11,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 20,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 8,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 22,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 19,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 16,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 14,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 13,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 12,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 2,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 6,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 10,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 23,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 4,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 3,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 9,
  TimeNow: 581.5454545454545,
  S: 3.090909090909091,
  N: 95.9090909090909,
  J: 0.0,
  St: 414.1818181818182,
  Nt: 12094.818181818182,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 18,
  TimeNow: 640.34375,
  S: 10.84375,
  N: 87.25,
  J: 0.75,
  St: 1445.09375,
  Nt: 10961.34375,
  Jt: 102.5625,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 21,
  TimeNow: 776.0769230769231,
  S: 21.307692307692307,
  N: 70.07692307692308,
  J: 7.230769230769231,
  St: 2728.076923076923,
  Nt: 8823.076923076924,
  Jt: 957.8461538461538,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 1,
  TimeNow: 540.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 12509.0,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 0,
  TimeNow: 590.6666666666666,
  S: 3.4444444444444446,
  N: 94.22222222222223,
  J: 1.1666666666666667,
  St: 473.77777777777777,
  Nt: 11876.111111111111,
  Jt: 159.11111111111111,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 15,
  TimeNow: 751.9,
  S: 8.2,
  N: 82.6,
  J: 7.8,
  St: 1079.45,
  Nt: 10387.6,
  Jt: 1041.95,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 17,
  TimeNow: 702.59375,
  S: 19.53125,
  N: 75.34375,
  J: 3.71875,
  St: 2501.78125,
  Nt: 9484.9375,
  Jt: 522.28125,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 7,
  TimeNow: 649.0,
  S: 6.0,
  N: 88.78571428571429,
  J: 4.071428571428571,
  St: 794.7857142857143,
  Nt: 11168.714285714286,
  Jt: 545.5,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 11,
  TimeNow: 617.4375,
  S: 11.6875,
  N: 86.75,
  J: 0.5625,
  St: 1537.625,
  Nt: 10888.6875,
  Jt: 82.6875,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 20,
  TimeNow: 632.3571428571429,
  S: 5.142857142857143,
  N: 92.0,
  J: 1.6071428571428572,
  St: 703.1071428571429,
  Nt: 11587.07142857143,
  Jt: 218.82142857142858,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 8,
  TimeNow: 550.6363636363636,
  S: 2.3636363636363638,
  N: 95.81818181818181,
  J: 0.8181818181818182,
  St: 313.45454545454544,
  Nt: 12083.454545454546,
  Jt: 112.0909090909091,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 22,
  TimeNow: 690.3846153846154,
  S: 17.076923076923077,
  N: 78.84615384615384,
  J: 2.923076923076923,
  St: 2205.769230769231,
  Nt: 9921.153846153846,
  Jt: 382.0769230769231,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 19,
  TimeNow: 616.027027027027,
  S: 4.621621621621622,
  N: 93.13513513513513,
  J: 0.918918918918919,
  St: 655.0540540540541,
  Nt: 11721.918918918918,
  Jt: 132.02702702702703,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 16,
  TimeNow: 713.3684210526316,
  S: 13.31578947368421,
  N: 79.84210526315789,
  J: 5.526315789473684,
  St: 1722.421052631579,
  Nt: 10036.368421052632,
  Jt: 750.2105263157895,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 14,
  TimeNow: 759.5263157894736,
  S: 14.631578947368421,
  N: 75.52631578947368,
  J: 8.210526315789474,
  St: 1888.0,
  Nt: 9519.157894736842,
  Jt: 1101.842105263158,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 13,
  TimeNow: 850.28,
  S: 11.2,
  N: 72.8,
  J: 14.4,
  St: 1459.28,
  Nt: 9185.92,
  Jt: 1863.8,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 12,
  TimeNow: 892.4,
  S: 19.95,
  N: 65.95,
  J: 12.35,
  St: 2561.7,
  Nt: 8312.0,
  Jt: 1635.3,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 2,
  TimeNow: 539.0,
  S: 0.07692307692307693,
  N: 98.92307692307692,
  J: 0.0,
  St: 11.461538461538462,
  Nt: 12497.538461538461,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 6,
  TimeNow: 592.0,
  S: 6.5,
  N: 92.5,
  J: 0.0,
  St: 866.5,
  Nt: 11642.5,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 10,
  TimeNow: 584.1333333333333,
  S: 4.733333333333333,
  N: 93.6,
  J: 0.6,
  St: 651.4666666666667,
  Nt: 11776.733333333334,
  Jt: 80.8,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 23,
  TimeNow: 604.4615384615385,
  S: 7.0,
  N: 92.0,
  J: 0.0,
  St: 948.0,
  Nt: 11561.0,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 4,
  TimeNow: 565.0,
  S: 3.090909090909091,
  N: 95.36363636363636,
  J: 0.2727272727272727,
  St: 436.72727272727275,
  Nt: 12031.636363636364,
  Jt: 40.63636363636363,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 3,
  TimeNow: 533.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 12509.0,
  Jt: 0.0,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 9,
  TimeNow: 1400.0,
  S: 6.363636363636363,
  N: 90.36363636363636,
  J: 2.0,
  St: 1570.8181818181818,
  Nt: 21419.18181818182,
  Jt: 558.0,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 18,
  TimeNow: 2050.5625,
  S: 10.75,
  N: 73.28125,
  J: 14.5625,
  St: 2628.375,
  Nt: 17368.3125,
  Jt: 3551.3125,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 21,
  TimeNow: 1607.923076923077,
  S: 11.307692307692308,
  N: 84.6923076923077,
  J: 2.3846153846153846,
  St: 2768.6923076923076,
  Nt: 20112.53846153846,
  Jt: 666.7692307692307,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 1,
  TimeNow: 1225.8076923076924,
  S: 0.5,
  N: 98.46153846153847,
  J: 0.0,
  St: 210.46153846153845,
  Nt: 23320.576923076922,
  Jt: 16.96153846153846,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 0,
  TimeNow: 1310.6666666666667,
  S: 0.7777777777777778,
  N: 97.05555555555556,
  J: 1.1111111111111112,
  St: 283.0,
  Nt: 22978.944444444445,
  Jt: 286.05555555555554,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 15,
  TimeNow: 2457.85,
  S: 10.15,
  N: 65.45,
  J: 23.0,
  St: 2791.85,
  Nt: 16466.5,
  Jt: 5683.45,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 17,
  TimeNow: 2511.84375,
  S: 10.03125,
  N: 68.59375,
  J: 20.0,
  St: 2481.21875,
  Nt: 16256.59375,
  Jt: 4810.1875,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 7,
  TimeNow: 1495.857142857143,
  S: 5.5,
  N: 88.28571428571429,
  J: 4.714285714285714,
  St: 1419.9285714285713,
  Nt: 20923.35714285714,
  Jt: 1204.7142857142858,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 11,
  TimeNow: 2215.5625,
  S: 11.375,
  N: 71.6875,
  J: 15.5,
  St: 2817.8125,
  Nt: 16995.6875,
  Jt: 3734.5,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 20,
  TimeNow: 1559.607142857143,
  S: 14.678571428571429,
  N: 82.03571428571429,
  J: 1.8214285714285714,
  St: 3572.25,
  Nt: 19461.89285714286,
  Jt: 513.8571428571429,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 8,
  TimeNow: 1377.7272727272727,
  S: 5.545454545454546,
  N: 91.0909090909091,
  J: 1.5454545454545454,
  St: 1431.4545454545455,
  Nt: 21573.090909090908,
  Jt: 543.4545454545455,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 22,
  TimeNow: 1408.7692307692307,
  S: 4.615384615384615,
  N: 94.3076923076923,
  J: 0.0,
  St: 1186.4615384615386,
  Nt: 22320.384615384617,
  Jt: 41.15384615384615,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 19,
  TimeNow: 1788.3243243243244,
  S: 11.702702702702704,
  N: 78.0,
  J: 8.81081081081081,
  St: 2879.6486486486488,
  Nt: 18490.27027027027,
  Jt: 2178.0810810810813,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 16,
  TimeNow: 2592.2631578947367,
  S: 13.631578947368421,
  N: 67.26315789473684,
  J: 17.736842105263158,
  St: 3813.7894736842104,
  Nt: 18513.263157894737,
  Jt: 4708.0526315789475,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 14,
  TimeNow: 2148.3684210526317,
  S: 11.578947368421053,
  N: 68.84210526315789,
  J: 18.157894736842106,
  St: 2787.0,
  Nt: 16354.78947368421,
  Jt: 4406.210526315789,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 13,
  TimeNow: 2082.64,
  S: 14.84,
  N: 67.16,
  J: 16.6,
  St: 3603.04,
  Nt: 15943.44,
  Jt: 4001.52,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 12,
  TimeNow: 1914.45,
  S: 13.15,
  N: 72.75,
  J: 12.55,
  St: 3224.0,
  Nt: 17247.0,
  Jt: 3077.0,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 2,
  TimeNow: 1179.8461538461538,
  S: 0.15384615384615385,
  N: 98.84615384615384,
  J: 0.0,
  St: 120.53846153846153,
  Nt: 23427.46153846154,
  Jt: 0.0,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 6,
  TimeNow: 1352.5,
  S: 0.0,
  N: 95.5,
  J: 3.0,
  St: 148.5,
  Nt: 22667.0,
  Jt: 732.5,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 10,
  TimeNow: 1959.3333333333333,
  S: 6.733333333333333,
  N: 80.53333333333333,
  J: 11.333333333333334,
  St: 1683.7333333333333,
  Nt: 19074.333333333332,
  Jt: 2789.9333333333334,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 23,
  TimeNow: 1292.6923076923076,
  S: 0.38461538461538464,
  N: 98.53846153846153,
  J: 0.0,
  St: 237.0,
  Nt: 23294.46153846154,
  Jt: 16.53846153846154,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 4,
  TimeNow: 1166.0,
  S: 0.36363636363636365,
  N: 98.63636363636364,
  J: 0.0,
  St: 155.27272727272728,
  Nt: 23369.81818181818,
  Jt: 22.90909090909091,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 3,
  TimeNow: 1155.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 99.0,
  Nt: 23449.0,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 9,
  TimeNow: 198.36363636363637,
  S: 26.545454545454547,
  N: 65.45454545454545,
  J: 6.454545454545454,
  St: 503.90909090909093,
  Nt: 1223.1818181818182,
  Jt: 130.54545454545453,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 18,
  TimeNow: 126.125,
  S: 13.4375,
  N: 83.40625,
  J: 2.125,
  St: 259.375,
  Nt: 1556.0625,
  Jt: 41.5625,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 21,
  TimeNow: 124.53846153846153,
  S: 6.3076923076923075,
  N: 92.6923076923077,
  J: 0.0,
  St: 120.46153846153847,
  Nt: 1736.6923076923076,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 1,
  TimeNow: 113.92307692307692,
  S: 0.8461538461538461,
  N: 97.61538461538461,
  J: 0.5384615384615384,
  St: 18.192307692307693,
  Nt: 1827.7692307692307,
  Jt: 11.576923076923077,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 0,
  TimeNow: 122.05555555555556,
  S: 1.6111111111111112,
  N: 96.0,
  J: 1.3333333333333333,
  St: 33.5,
  Nt: 1796.611111111111,
  Jt: 27.77777777777778,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 15,
  TimeNow: 228.25,
  S: 16.9,
  N: 56.3,
  J: 25.1,
  St: 326.5,
  Nt: 1050.4,
  Jt: 480.6,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 17,
  TimeNow: 142.125,
  S: 18.9375,
  N: 73.3125,
  J: 6.5,
  St: 360.90625,
  Nt: 1367.875,
  Jt: 128.46875,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 7,
  TimeNow: 106.07142857142857,
  S: 2.2857142857142856,
  N: 96.0,
  J: 0.7142857142857143,
  St: 45.285714285714285,
  Nt: 1797.642857142857,
  Jt: 14.142857142857142,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 11,
  TimeNow: 258.6875,
  S: 16.9375,
  N: 52.5,
  J: 28.6875,
  St: 327.4375,
  Nt: 982.9375,
  Jt: 547.0625,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 20,
  TimeNow: 128.5,
  S: 17.642857142857142,
  N: 79.03571428571429,
  J: 2.2857142857142856,
  St: 337.25,
  Nt: 1474.392857142857,
  Jt: 45.42857142857143,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 8,
  TimeNow: 157.72727272727272,
  S: 12.818181818181818,
  N: 81.54545454545455,
  J: 4.363636363636363,
  St: 246.0,
  Nt: 1523.909090909091,
  Jt: 87.18181818181819,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 22,
  TimeNow: 115.6923076923077,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1857.0,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 19,
  TimeNow: 132.94594594594594,
  S: 12.864864864864865,
  N: 77.72972972972973,
  J: 8.378378378378379,
  St: 245.3783783783784,
  Nt: 1448.918918918919,
  Jt: 162.75675675675674,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 16,
  TimeNow: 230.94736842105263,
  S: 15.68421052631579,
  N: 55.73684210526316,
  J: 27.0,
  St: 300.7368421052632,
  Nt: 1041.421052631579,
  Jt: 515.5263157894736,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 14,
  TimeNow: 267.1578947368421,
  S: 11.210526315789474,
  N: 56.0,
  J: 30.94736842105263,
  St: 219.3684210526316,
  Nt: 1047.421052631579,
  Jt: 590.9473684210526,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 13,
  TimeNow: 238.88,
  S: 19.72,
  N: 50.64,
  J: 27.8,
  St: 376.4,
  Nt: 950.28,
  Jt: 530.64,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 12,
  TimeNow: 236.8,
  S: 18.55,
  N: 52.15,
  J: 27.4,
  St: 352.35,
  Nt: 981.9,
  Jt: 523.3,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 2,
  TimeNow: 111.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1857.0,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 6,
  TimeNow: 113.5,
  S: 4.0,
  N: 95.0,
  J: 0.0,
  St: 81.0,
  Nt: 1776.5,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 10,
  TimeNow: 247.2,
  S: 24.066666666666666,
  N: 59.13333333333333,
  J: 15.466666666666667,
  St: 455.8,
  Nt: 1103.4666666666667,
  Jt: 298.2,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 23,
  TimeNow: 113.53846153846153,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1857.0,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 4,
  TimeNow: 120.27272727272727,
  S: 4.909090909090909,
  N: 94.0909090909091,
  J: 0.0,
  St: 97.36363636363636,
  Nt: 1759.6363636363637,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 3,
  TimeNow: 111.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1857.0,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 9,
  TimeNow: 456.27272727272725,
  S: 23.727272727272727,
  N: 74.81818181818181,
  J: 0.36363636363636365,
  St: 1177.4545454545455,
  Nt: 3698.2727272727275,
  Jt: 30.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 18,
  TimeNow: 440.40625,
  S: 7.34375,
  N: 91.5,
  J: 0.0,
  St: 387.25,
  Nt: 4512.34375,
  Jt: 5.40625,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 21,
  TimeNow: 441.7692307692308,
  S: 13.76923076923077,
  N: 83.76923076923077,
  J: 1.1538461538461537,
  St: 699.1538461538462,
  Nt: 4135.923076923077,
  Jt: 69.61538461538461,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 1,
  TimeNow: 420.38461538461536,
  S: 4.0,
  N: 95.0,
  J: 0.0,
  St: 229.46153846153845,
  Nt: 4675.0,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 0,
  TimeNow: 416.77777777777777,
  S: 5.277777777777778,
  N: 93.72222222222223,
  J: 0.0,
  St: 280.1666666666667,
  Nt: 4623.5,
  Jt: 0.7222222222222222,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 15,
  TimeNow: 492.8,
  S: 21.85,
  N: 72.7,
  J: 4.0,
  St: 1094.85,
  Nt: 3584.85,
  Jt: 225.2,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 17,
  TimeNow: 458.25,
  S: 19.25,
  N: 79.71875,
  J: 0.0,
  St: 967.375,
  Nt: 3932.46875,
  Jt: 5.25,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 7,
  TimeNow: 451.7857142857143,
  S: 17.071428571428573,
  N: 81.0,
  J: 0.8571428571428571,
  St: 861.2857142857143,
  Nt: 3993.9285714285716,
  Jt: 50.214285714285715,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 11,
  TimeNow: 457.875,
  S: 24.5625,
  N: 72.75,
  J: 1.5,
  St: 1227.5,
  Nt: 3594.25,
  Jt: 83.625,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 20,
  TimeNow: 437.39285714285717,
  S: 10.535714285714286,
  N: 87.89285714285714,
  J: 0.42857142857142855,
  St: 544.5357142857143,
  Nt: 4334.464285714285,
  Jt: 25.857142857142858,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 8,
  TimeNow: 446.54545454545456,
  S: 4.909090909090909,
  N: 90.36363636363636,
  J: 3.6363636363636362,
  St: 261.1818181818182,
  Nt: 4448.272727272727,
  Jt: 195.0909090909091,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 22,
  TimeNow: 438.15384615384613,
  S: 16.53846153846154,
  N: 82.0,
  J: 0.46153846153846156,
  St: 837.4615384615385,
  Nt: 4039.6153846153848,
  Jt: 27.846153846153847,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 19,
  TimeNow: 442.05405405405406,
  S: 8.378378378378379,
  N: 88.67567567567568,
  J: 1.5405405405405406,
  St: 443.5135135135135,
  Nt: 4372.108108108108,
  Jt: 89.35135135135135,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 16,
  TimeNow: 469.7368421052632,
  S: 20.473684210526315,
  N: 75.84210526315789,
  J: 2.4210526315789473,
  St: 1025.3684210526317,
  Nt: 3745.842105263158,
  Jt: 133.73684210526315,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 14,
  TimeNow: 527.2631578947369,
  S: 38.578947368421055,
  N: 54.94736842105263,
  J: 5.052631578947368,
  St: 1915.6842105263158,
  Nt: 2718.2631578947367,
  Jt: 270.4736842105263,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 13,
  TimeNow: 500.08,
  S: 31.8,
  N: 63.4,
  J: 3.44,
  St: 1581.52,
  Nt: 3134.76,
  Jt: 188.56,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 12,
  TimeNow: 491.9,
  S: 28.05,
  N: 63.7,
  J: 6.7,
  St: 1398.55,
  Nt: 3155.4,
  Jt: 350.95,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 2,
  TimeNow: 413.84615384615387,
  S: 4.230769230769231,
  N: 94.76923076923077,
  J: 0.0,
  St: 240.69230769230768,
  Nt: 4664.307692307692,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 6,
  TimeNow: 429.5,
  S: 13.5,
  N: 85.5,
  J: 0.0,
  St: 687.0,
  Nt: 4219.0,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 10,
  TimeNow: 473.26666666666665,
  S: 20.8,
  N: 74.2,
  J: 4.0,
  St: 1033.0,
  Nt: 3649.4666666666667,
  Jt: 223.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 23,
  TimeNow: 434.3076923076923,
  S: 9.076923076923077,
  N: 88.76923076923077,
  J: 0.9230769230769231,
  St: 467.6923076923077,
  Nt: 4381.076923076923,
  Jt: 55.69230769230769,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 4,
  TimeNow: 426.1818181818182,
  S: 4.0,
  N: 95.0,
  J: 0.0,
  St: 237.0,
  Nt: 4668.0,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 3,
  TimeNow: 429.0,
  S: 4.0,
  N: 95.0,
  J: 0.0,
  St: 237.0,
  Nt: 4668.0,
  Jt: 0.0,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 9,
  TimeNow: 799.2727272727273,
  S: 3.6363636363636362,
  N: 91.36363636363636,
  J: 3.6363636363636362,
  St: 386.1818181818182,
  Nt: 8547.636363636364,
  Jt: 366.1818181818182,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 18,
  TimeNow: 1018.875,
  S: 5.96875,
  N: 88.125,
  J: 4.46875,
  St: 615.875,
  Nt: 8227.84375,
  Jt: 456.28125,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 21,
  TimeNow: 908.4615384615385,
  S: 5.153846153846154,
  N: 89.84615384615384,
  J: 3.3846153846153846,
  St: 521.1538461538462,
  Nt: 8413.461538461539,
  Jt: 365.38461538461536,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 1,
  TimeNow: 715.8076923076923,
  S: 3.576923076923077,
  N: 95.0,
  J: 0.38461538461538464,
  St: 384.84615384615387,
  Nt: 8873.538461538461,
  Jt: 41.61538461538461,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 0,
  TimeNow: 749.6111111111111,
  S: 2.4444444444444446,
  N: 95.27777777777777,
  J: 1.0555555555555556,
  St: 276.8333333333333,
  Nt: 8897.722222222223,
  Jt: 125.44444444444444,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 15,
  TimeNow: 1041.65,
  S: 5.9,
  N: 86.6,
  J: 5.95,
  St: 602.85,
  Nt: 8099.7,
  Jt: 597.45,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 17,
  TimeNow: 1066.0625,
  S: 5.84375,
  N: 87.90625,
  J: 4.96875,
  St: 599.0625,
  Nt: 8205.9375,
  Jt: 495.0,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 7,
  TimeNow: 811.0714285714286,
  S: 3.0714285714285716,
  N: 92.85714285714286,
  J: 2.357142857142857,
  St: 354.2142857142857,
  Nt: 8682.92857142857,
  Jt: 262.85714285714283,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 11,
  TimeNow: 854.9375,
  S: 3.125,
  N: 92.0,
  J: 3.4375,
  St: 325.875,
  Nt: 8613.9375,
  Jt: 360.1875,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 20,
  TimeNow: 937.75,
  S: 6.928571428571429,
  N: 87.89285714285714,
  J: 3.5357142857142856,
  St: 700.9285714285714,
  Nt: 8216.892857142857,
  Jt: 382.17857142857144,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 8,
  TimeNow: 772.5454545454545,
  S: 2.1818181818181817,
  N: 93.36363636363636,
  J: 2.8181818181818183,
  St: 252.27272727272728,
  Nt: 8739.363636363636,
  Jt: 308.3636363636364,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 22,
  TimeNow: 822.7692307692307,
  S: 3.3076923076923075,
  N: 94.3076923076923,
  J: 0.9230769230769231,
  St: 351.84615384615387,
  Nt: 8822.153846153846,
  Jt: 126.0,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 19,
  TimeNow: 930.4444444444445,
  S: 4.861111111111111,
  N: 90.13888888888889,
  J: 3.3333333333333335,
  St: 502.0,
  Nt: 8433.861111111111,
  Jt: 364.1388888888889,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 16,
  TimeNow: 1048.421052631579,
  S: 5.052631578947368,
  N: 88.73684210526316,
  J: 4.7368421052631575,
  St: 512.0,
  Nt: 8298.052631578947,
  Jt: 489.94736842105266,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 14,
  TimeNow: 952.0,
  S: 4.368421052631579,
  N: 90.26315789473684,
  J: 3.789473684210526,
  St: 463.1578947368421,
  Nt: 8424.78947368421,
  Jt: 412.05263157894734,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 13,
  TimeNow: 917.92,
  S: 3.48,
  N: 91.16,
  J: 3.76,
  St: 374.56,
  Nt: 8518.2,
  Jt: 407.24,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 12,
  TimeNow: 942.05,
  S: 4.8,
  N: 90.05,
  J: 3.65,
  St: 487.7,
  Nt: 8423.2,
  Jt: 389.1,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 2,
  TimeNow: 721.7692307692307,
  S: 15.461538461538462,
  N: 83.38461538461539,
  J: 0.07692307692307693,
  St: 1501.1538461538462,
  Nt: 7790.615384615385,
  Jt: 8.23076923076923,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 6,
  TimeNow: 753.0,
  S: 2.5,
  N: 95.5,
  J: 0.5,
  St: 268.5,
  Nt: 8947.5,
  Jt: 84.0,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 10,
  TimeNow: 795.6,
  S: 2.933333333333333,
  N: 92.8,
  J: 2.6666666666666665,
  St: 309.93333333333334,
  Nt: 8680.466666666667,
  Jt: 309.6,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 23,
  TimeNow: 775.2307692307693,
  S: 2.230769230769231,
  N: 95.3076923076923,
  J: 1.0769230769230769,
  St: 265.0,
  Nt: 8894.076923076924,
  Jt: 140.92307692307693,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 4,
  TimeNow: 688.4545454545455,
  S: 1.1818181818181819,
  N: 96.72727272727273,
  J: 0.7272727272727273,
  St: 161.8181818181818,
  Nt: 9060.0,
  Jt: 78.18181818181819,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 3,
  TimeNow: 685.0,
  S: 2.5,
  N: 96.0,
  J: 0.5,
  St: 309.5,
  Nt: 8935.0,
  Jt: 55.5,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 9,
  TimeNow: 2152.6363636363635,
  S: 8.727272727272727,
  N: 77.81818181818181,
  J: 12.0,
  St: 2566.3636363636365,
  Nt: 21559.727272727272,
  Jt: 3422.181818181818,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 18,
  TimeNow: 2074.125,
  S: 10.375,
  N: 78.3125,
  J: 9.8125,
  St: 3007.03125,
  Nt: 21695.5,
  Jt: 2846.875,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 21,
  TimeNow: 1643.923076923077,
  S: 7.3076923076923075,
  N: 90.23076923076923,
  J: 1.0769230769230769,
  St: 2157.230769230769,
  Nt: 25018.923076923078,
  Jt: 372.38461538461536,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 1,
  TimeNow: 1427.64,
  S: 5.76,
  N: 93.04,
  J: 0.0,
  St: 1683.8,
  Nt: 25817.28,
  Jt: 47.0,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 0,
  TimeNow: 1521.5555555555557,
  S: 5.333333333333333,
  N: 92.33333333333333,
  J: 1.1111111111111112,
  St: 1593.2222222222222,
  Nt: 25603.38888888889,
  Jt: 351.8333333333333,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 15,
  TimeNow: 2042.1,
  S: 8.7,
  N: 82.85,
  J: 6.9,
  St: 2551.2,
  Nt: 22960.55,
  Jt: 2036.4,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 17,
  TimeNow: 2718.5,
  S: 16.0,
  N: 64.65625,
  J: 17.90625,
  St: 4642.46875,
  Nt: 18234.0,
  Jt: 5195.46875,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 7,
  TimeNow: 2491.0714285714284,
  S: 13.142857142857142,
  N: 71.28571428571429,
  J: 14.142857142857142,
  St: 3756.714285714286,
  Nt: 19787.785714285714,
  Jt: 4004.214285714286,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 11,
  TimeNow: 1638.5625,
  S: 7.1875,
  N: 88.625,
  J: 2.8125,
  St: 2142.75,
  Nt: 24513.6875,
  Jt: 892.875,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 20,
  TimeNow: 1907.9642857142858,
  S: 10.428571428571429,
  N: 81.60714285714286,
  J: 6.428571428571429,
  St: 3113.785714285714,
  Nt: 23088.214285714286,
  Jt: 1944.5357142857142,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 8,
  TimeNow: 2205.909090909091,
  S: 12.090909090909092,
  N: 69.18181818181819,
  J: 17.0,
  St: 3446.7272727272725,
  Nt: 19235.636363636364,
  Jt: 4866.181818181818,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 22,
  TimeNow: 1536.2307692307693,
  S: 5.846153846153846,
  N: 93.07692307692308,
  J: 0.0,
  St: 1790.8461538461538,
  Nt: 25755.846153846152,
  Jt: 1.6153846153846154,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 19,
  TimeNow: 2148.3783783783783,
  S: 11.35135135135135,
  N: 77.83783783783784,
  J: 9.35135135135135,
  St: 3417.7297297297296,
  Nt: 22507.486486486487,
  Jt: 2754.0810810810813,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 16,
  TimeNow: 2555.9473684210525,
  S: 10.68421052631579,
  N: 72.6842105263158,
  J: 14.947368421052632,
  St: 3103.157894736842,
  Nt: 20172.78947368421,
  Jt: 4273.105263157895,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 14,
  TimeNow: 2282.9473684210525,
  S: 7.894736842105263,
  N: 83.57894736842105,
  J: 7.0,
  St: 2339.8947368421054,
  Nt: 23156.736842105263,
  Jt: 2052.2631578947367,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 13,
  TimeNow: 1887.2,
  S: 9.56,
  N: 85.68,
  J: 3.28,
  St: 2793.64,
  Nt: 23728.68,
  Jt: 1026.76,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 12,
  TimeNow: 1728.45,
  S: 8.1,
  N: 86.35,
  J: 3.95,
  St: 2401.05,
  Nt: 23914.75,
  Jt: 1233.2,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 2,
  TimeNow: 1412.8461538461538,
  S: 4.461538461538462,
  N: 94.53846153846153,
  J: 0.0,
  St: 1361.1538461538462,
  Nt: 26186.846153846152,
  Jt: 0.0,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 6,
  TimeNow: 1898.5,
  S: 10.5,
  N: 85.5,
  J: 2.5,
  St: 3013.0,
  Nt: 23717.5,
  Jt: 818.0,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 10,
  TimeNow: 1648.1333333333334,
  S: 9.333333333333334,
  N: 86.6,
  J: 2.6666666666666665,
  St: 2698.2,
  Nt: 24008.6,
  Jt: 841.5333333333333,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 23,
  TimeNow: 1461.5384615384614,
  S: 1.9230769230769231,
  N: 97.07692307692308,
  J: 0.0,
  St: 639.0,
  Nt: 26909.153846153848,
  Jt: 0.0,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 4,
  TimeNow: 1481.4545454545455,
  S: 9.909090909090908,
  N: 88.18181818181819,
  J: 0.5454545454545454,
  St: 2885.7272727272725,
  Nt: 24407.727272727272,
  Jt: 254.54545454545453,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 3,
  TimeNow: 1411.5,
  S: 10.0,
  N: 89.0,
  J: 0.0,
  St: 2811.0,
  Nt: 24737.0,
  Jt: 0.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 9,
  TimeNow: 977.7272727272727,
  S: 7.909090909090909,
  N: 90.18181818181819,
  J: 0.5454545454545454,
  St: 1879.909090909091,
  Nt: 20177.909090909092,
  Jt: 223.1818181818182,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 18,
  TimeNow: 1678.75,
  S: 20.34375,
  N: 55.875,
  J: 22.1875,
  St: 4652.75,
  Nt: 12527.375,
  Jt: 5100.625,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 21,
  TimeNow: 1153.2307692307693,
  S: 15.0,
  N: 75.15384615384616,
  J: 8.384615384615385,
  St: 3447.6153846153848,
  Nt: 16863.76923076923,
  Jt: 1969.076923076923,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 1,
  TimeNow: 861.32,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 25.92,
  Nt: 22255.08,
  Jt: 0.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 0,
  TimeNow: 948.2777777777778,
  S: 1.3888888888888888,
  N: 96.16666666666667,
  J: 1.3888888888888888,
  St: 332.5,
  Nt: 21631.277777777777,
  Jt: 317.22222222222223,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 15,
  TimeNow: 1652.7,
  S: 20.3,
  N: 56.95,
  J: 21.3,
  St: 4635.4,
  Nt: 12772.05,
  Jt: 4873.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 17,
  TimeNow: 2095.4375,
  S: 19.25,
  N: 53.3125,
  J: 26.09375,
  St: 4383.78125,
  Nt: 11986.75,
  Jt: 5910.46875,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 7,
  TimeNow: 992.0,
  S: 14.214285714285714,
  N: 82.57142857142857,
  J: 1.7142857142857142,
  St: 3285.785714285714,
  Nt: 18519.14285714286,
  Jt: 476.07142857142856,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 11,
  TimeNow: 1072.75,
  S: 15.125,
  N: 80.0,
  J: 3.4375,
  St: 3495.8125,
  Nt: 17908.125,
  Jt: 876.8125,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 20,
  TimeNow: 1105.857142857143,
  S: 15.142857142857142,
  N: 79.39285714285714,
  J: 3.7857142857142856,
  St: 3500.25,
  Nt: 17828.25,
  Jt: 952.2142857142857,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 8,
  TimeNow: 919.7272727272727,
  S: 9.090909090909092,
  N: 88.81818181818181,
  J: 0.7272727272727273,
  St: 2166.818181818182,
  Nt: 19912.81818181818,
  Jt: 201.36363636363637,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 22,
  TimeNow: 1075.076923076923,
  S: 16.076923076923077,
  N: 78.61538461538461,
  J: 4.0,
  St: 3699.076923076923,
  Nt: 17610.615384615383,
  Jt: 971.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 19,
  TimeNow: 1256.6756756756756,
  S: 14.297297297297296,
  N: 70.70270270270271,
  J: 13.486486486486486,
  St: 3306.5945945945946,
  Nt: 15865.891891891892,
  Jt: 3108.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 16,
  TimeNow: 1755.3684210526317,
  S: 15.947368421052632,
  N: 58.0,
  J: 24.57894736842105,
  St: 3680.6315789473683,
  Nt: 13028.368421052632,
  Jt: 5572.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 14,
  TimeNow: 1410.3157894736842,
  S: 15.947368421052632,
  N: 66.0,
  J: 16.63157894736842,
  St: 3632.4210526315787,
  Nt: 14797.421052631578,
  Jt: 3850.842105263158,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 13,
  TimeNow: 1429.12,
  S: 5.32,
  N: 73.08,
  J: 19.96,
  St: 1306.12,
  Nt: 16407.36,
  Jt: 4567.4,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 12,
  TimeNow: 1208.25,
  S: 9.85,
  N: 77.4,
  J: 11.25,
  St: 2286.8,
  Nt: 17346.35,
  Jt: 2647.35,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 2,
  TimeNow: 836.3846153846154,
  S: 0.07692307692307693,
  N: 98.92307692307692,
  J: 0.0,
  St: 30.923076923076923,
  Nt: 22250.076923076922,
  Jt: 0.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 6,
  TimeNow: 886.0,
  S: 2.0,
  N: 97.0,
  J: 0.0,
  St: 474.5,
  Nt: 21777.0,
  Jt: 29.5,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 10,
  TimeNow: 1023.4,
  S: 10.0,
  N: 84.06666666666666,
  J: 4.666666666666667,
  St: 2378.866666666667,
  Nt: 18777.666666666668,
  Jt: 1124.2666666666667,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 23,
  TimeNow: 955.3076923076923,
  S: 4.615384615384615,
  N: 94.15384615384616,
  J: 0.15384615384615385,
  St: 1079.8461538461538,
  Nt: 21150.30769230769,
  Jt: 50.84615384615385,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 4,
  TimeNow: 816.0,
  S: 0.45454545454545453,
  N: 98.54545454545455,
  J: 0.0,
  St: 125.45454545454545,
  Nt: 22155.545454545456,
  Jt: 0.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 3,
  TimeNow: 827.0,
  S: 1.5,
  N: 97.5,
  J: 0.0,
  St: 443.5,
  Nt: 21837.5,
  Jt: 0.0,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 9,
  TimeNow: 1131.2727272727273,
  S: 7.0,
  N: 91.36363636363636,
  J: 0.6363636363636364,
  St: 1723.6363636363637,
  Nt: 20820.545454545456,
  Jt: 159.8181818181818,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 18,
  TimeNow: 2391.96875,
  S: 20.65625,
  N: 40.09375,
  J: 37.46875,
  St: 4827.75,
  Nt: 9248.0,
  Jt: 8628.25,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 21,
  TimeNow: 1201.3076923076924,
  S: 11.538461538461538,
  N: 87.0,
  J: 0.46153846153846156,
  St: 2735.923076923077,
  Nt: 19841.153846153848,
  Jt: 126.92307692307692,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 1,
  TimeNow: 1236.7692307692307,
  S: 8.73076923076923,
  N: 86.53846153846153,
  J: 3.0384615384615383,
  St: 2110.576923076923,
  Nt: 19777.73076923077,
  Jt: 815.6923076923077,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 0,
  TimeNow: 1145.2222222222222,
  S: 1.6666666666666667,
  N: 94.88888888888889,
  J: 2.388888888888889,
  St: 384.5,
  Nt: 21765.444444444445,
  Jt: 554.0555555555555,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 15,
  TimeNow: 2401.8,
  S: 19.65,
  N: 37.85,
  J: 40.95,
  St: 4566.35,
  Nt: 8714.85,
  Jt: 9422.8,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 17,
  TimeNow: 3027.625,
  S: 18.09375,
  N: 39.84375,
  J: 40.6875,
  St: 4206.875,
  Nt: 9147.75,
  Jt: 9349.375,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 7,
  TimeNow: 1319.857142857143,
  S: 10.214285714285714,
  N: 82.57142857142857,
  J: 5.928571428571429,
  St: 2424.714285714286,
  Nt: 18840.571428571428,
  Jt: 1438.7142857142858,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 11,
  TimeNow: 1116.6875,
  S: 3.4375,
  N: 95.5625,
  J: 0.0,
  St: 883.5625,
  Nt: 21820.4375,
  Jt: 0.0,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 20,
  TimeNow: 1294.9642857142858,
  S: 14.678571428571429,
  N: 79.28571428571429,
  J: 4.678571428571429,
  St: 3453.1785714285716,
  Nt: 18101.571428571428,
  Jt: 1149.25,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 8,
  TimeNow: 1230.6363636363637,
  S: 6.909090909090909,
  N: 87.54545454545455,
  J: 3.909090909090909,
  St: 1707.3636363636363,
  Nt: 19987.727272727272,
  Jt: 1008.9090909090909,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 22,
  TimeNow: 1117.7692307692307,
  S: 0.5384615384615384,
  N: 98.38461538461539,
  J: 0.07692307692307693,
  St: 193.53846153846155,
  Nt: 22489.30769230769,
  Jt: 21.153846153846153,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 19,
  TimeNow: 1675.027027027027,
  S: 13.72972972972973,
  N: 67.05405405405405,
  J: 17.91891891891892,
  St: 3213.4054054054054,
  Nt: 15322.324324324325,
  Jt: 4168.27027027027,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 16,
  TimeNow: 2524.5263157894738,
  S: 24.42105263157895,
  N: 40.78947368421053,
  J: 33.26315789473684,
  St: 5653.789473684211,
  Nt: 9372.684210526315,
  Jt: 7677.526315789473,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 14,
  TimeNow: 1830.7368421052631,
  S: 21.894736842105264,
  N: 53.78947368421053,
  J: 22.63157894736842,
  St: 5102.631578947368,
  Nt: 12344.473684210527,
  Jt: 5256.894736842105,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 13,
  TimeNow: 1513.12,
  S: 11.44,
  N: 75.48,
  J: 11.64,
  St: 2731.52,
  Nt: 17237.96,
  Jt: 2734.52,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 12,
  TimeNow: 1445.25,
  S: 11.7,
  N: 78.1,
  J: 8.85,
  St: 2747.75,
  Nt: 17841.65,
  Jt: 2114.6,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 2,
  TimeNow: 1109.6923076923076,
  S: 2.4615384615384617,
  N: 96.07692307692308,
  J: 0.07692307692307693,
  St: 657.6153846153846,
  Nt: 21942.923076923078,
  Jt: 103.46153846153847,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 6,
  TimeNow: 1089.0,
  S: 2.0,
  N: 97.0,
  J: 0.0,
  St: 541.0,
  Nt: 22163.0,
  Jt: 0.0,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 10,
  TimeNow: 1123.1333333333334,
  S: 1.7333333333333334,
  N: 96.33333333333333,
  J: 0.7333333333333333,
  St: 453.6,
  Nt: 22049.266666666666,
  Jt: 201.13333333333333,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 23,
  TimeNow: 1066.3076923076924,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 18.384615384615383,
  Nt: 22685.615384615383,
  Jt: 0.0,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 4,
  TimeNow: 1066.909090909091,
  S: 0.5454545454545454,
  N: 98.36363636363636,
  J: 0.0,
  St: 205.1818181818182,
  Nt: 22498.81818181818,
  Jt: 0.0,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 3,
  TimeNow: 1203.5,
  S: 4.5,
  N: 92.0,
  J: 2.0,
  St: 1136.0,
  Nt: 21063.0,
  Jt: 505.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 9,
  TimeNow: 102.27272727272727,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 18,
  TimeNow: 117.0625,
  S: 3.875,
  N: 95.125,
  J: 0.0,
  St: 75.84375,
  Nt: 1854.15625,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 21,
  TimeNow: 115.53846153846153,
  S: 2.3846153846153846,
  N: 96.61538461538461,
  J: 0.0,
  St: 46.84615384615385,
  Nt: 1883.1538461538462,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 1,
  TimeNow: 109.42307692307692,
  S: 0.2692307692307692,
  N: 98.73076923076923,
  J: 0.0,
  St: 5.5,
  Nt: 1924.5,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 0,
  TimeNow: 109.22222222222223,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 15,
  TimeNow: 102.45,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 17,
  TimeNow: 108.1875,
  S: 0.9375,
  N: 98.0625,
  J: 0.0,
  St: 19.03125,
  Nt: 1910.96875,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 7,
  TimeNow: 106.78571428571429,
  S: 0.42857142857142855,
  N: 98.57142857142857,
  J: 0.0,
  St: 8.928571428571429,
  Nt: 1921.0714285714287,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 11,
  TimeNow: 108.3125,
  S: 3.0,
  N: 96.0,
  J: 0.0,
  St: 58.25,
  Nt: 1871.75,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 20,
  TimeNow: 114.57142857142857,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.5357142857142857,
  Nt: 1929.4642857142858,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 8,
  TimeNow: 103.18181818181819,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 22,
  TimeNow: 112.76923076923077,
  S: 1.3076923076923077,
  N: 97.6923076923077,
  J: 0.0,
  St: 25.76923076923077,
  Nt: 1904.2307692307693,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 19,
  TimeNow: 114.8108108108108,
  S: 1.1891891891891893,
  N: 97.0,
  J: 0.8108108108108109,
  St: 23.62162162162162,
  Nt: 1890.6486486486488,
  Jt: 15.72972972972973,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 16,
  TimeNow: 105.63157894736842,
  S: 0.5263157894736842,
  N: 98.47368421052632,
  J: 0.0,
  St: 10.631578947368421,
  Nt: 1919.3684210526317,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 14,
  TimeNow: 105.26315789473684,
  S: 1.263157894736842,
  N: 97.73684210526316,
  J: 0.0,
  St: 24.526315789473685,
  Nt: 1905.4736842105262,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 13,
  TimeNow: 106.72,
  S: 0.96,
  N: 98.04,
  J: 0.0,
  St: 18.64,
  Nt: 1911.36,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 12,
  TimeNow: 108.1,
  S: 1.2,
  N: 97.8,
  J: 0.0,
  St: 24.65,
  Nt: 1905.35,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 2,
  TimeNow: 105.23076923076923,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 6,
  TimeNow: 109.0,
  S: 1.5,
  N: 97.5,
  J: 0.0,
  St: 35.5,
  Nt: 1894.5,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 10,
  TimeNow: 108.8,
  S: 2.1333333333333333,
  N: 96.86666666666666,
  J: 0.0,
  St: 42.666666666666664,
  Nt: 1887.3333333333333,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 23,
  TimeNow: 110.92307692307692,
  S: 0.5384615384615384,
  N: 98.46153846153847,
  J: 0.0,
  St: 11.0,
  Nt: 1919.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 4,
  TimeNow: 106.45454545454545,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 3,
  TimeNow: 108.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 9,
  TimeNow: 119.63636363636364,
  S: 8.636363636363637,
  N: 90.0909090909091,
  J: 0.09090909090909091,
  St: 97.63636363636364,
  Nt: 962.4545454545455,
  Jt: 1.9090909090909092,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 18,
  TimeNow: 120.21875,
  S: 9.125,
  N: 88.5,
  J: 1.15625,
  St: 100.5,
  Nt: 947.9375,
  Jt: 13.5625,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 21,
  TimeNow: 110.6923076923077,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1062.0,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 1,
  TimeNow: 112.03846153846153,
  S: 0.9230769230769231,
  N: 97.8076923076923,
  J: 0.0,
  St: 14.538461538461538,
  Nt: 1047.4615384615386,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 0,
  TimeNow: 115.94444444444444,
  S: 4.666666666666667,
  N: 94.27777777777777,
  J: 0.0,
  St: 55.94444444444444,
  Nt: 1006.0555555555555,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 15,
  TimeNow: 108.9,
  S: 1.45,
  N: 97.55,
  J: 0.0,
  St: 17.15,
  Nt: 1044.85,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 17,
  TimeNow: 126.5,
  S: 12.21875,
  N: 85.59375,
  J: 1.0,
  St: 134.90625,
  Nt: 915.21875,
  Jt: 11.875,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 7,
  TimeNow: 114.71428571428571,
  S: 3.9285714285714284,
  N: 95.0,
  J: 0.0,
  St: 46.0,
  Nt: 1016.0,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 11,
  TimeNow: 115.5,
  S: 2.75,
  N: 96.25,
  J: 0.0,
  St: 33.6875,
  Nt: 1028.3125,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 20,
  TimeNow: 112.82142857142857,
  S: 3.892857142857143,
  N: 94.78571428571429,
  J: 0.32142857142857145,
  St: 43.5,
  Nt: 1015.0,
  Jt: 3.5,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 8,
  TimeNow: 114.63636363636364,
  S: 2.727272727272727,
  N: 96.18181818181819,
  J: 0.0,
  St: 32.72727272727273,
  Nt: 1029.2727272727273,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 22,
  TimeNow: 113.3076923076923,
  S: 1.4615384615384615,
  N: 97.53846153846153,
  J: 0.0,
  St: 18.076923076923077,
  Nt: 1043.923076923077,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 19,
  TimeNow: 135.05405405405406,
  S: 4.405405405405405,
  N: 89.78378378378379,
  J: 4.486486486486487,
  St: 50.5945945945946,
  Nt: 961.3513513513514,
  Jt: 50.054054054054056,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 16,
  TimeNow: 112.73684210526316,
  S: 1.4736842105263157,
  N: 97.52631578947368,
  J: 0.0,
  St: 17.263157894736842,
  Nt: 1044.7368421052631,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 14,
  TimeNow: 128.05263157894737,
  S: 8.31578947368421,
  N: 86.47368421052632,
  J: 4.0,
  St: 93.10526315789474,
  Nt: 923.4736842105264,
  Jt: 45.421052631578945,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 13,
  TimeNow: 113.28,
  S: 1.48,
  N: 97.52,
  J: 0.0,
  St: 17.64,
  Nt: 1044.36,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 12,
  TimeNow: 114.55,
  S: 3.15,
  N: 95.85,
  J: 0.0,
  St: 36.35,
  Nt: 1025.65,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 2,
  TimeNow: 111.07692307692308,
  S: 3.6923076923076925,
  N: 95.3076923076923,
  J: 0.0,
  St: 42.92307692307692,
  Nt: 1019.0769230769231,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 6,
  TimeNow: 112.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1062.0,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 10,
  TimeNow: 113.2,
  S: 0.8,
  N: 98.2,
  J: 0.0,
  St: 9.733333333333333,
  Nt: 1052.2666666666667,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 23,
  TimeNow: 112.38461538461539,
  S: 2.3076923076923075,
  N: 96.53846153846153,
  J: 0.0,
  St: 29.615384615384617,
  Nt: 1032.3846153846155,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 4,
  TimeNow: 112.36363636363636,
  S: 0.36363636363636365,
  N: 98.63636363636364,
  J: 0.0,
  St: 4.545454545454546,
  Nt: 1057.4545454545455,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 3,
  TimeNow: 115.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1062.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 9,
  TimeNow: 488.72727272727275,
  S: 21.90909090909091,
  N: 1.0909090909090908,
  J: 75.9090909090909,
  St: 393.72727272727275,
  Nt: 19.363636363636363,
  Jt: 1347.909090909091,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 18,
  TimeNow: 102.15625,
  S: 12.15625,
  N: 84.25,
  J: 2.53125,
  St: 223.71875,
  Nt: 1490.03125,
  Jt: 47.25,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 21,
  TimeNow: 82.23076923076923,
  S: 1.6923076923076923,
  N: 97.3076923076923,
  J: 0.0,
  St: 30.846153846153847,
  Nt: 1730.1538461538462,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 1,
  TimeNow: 72.61538461538461,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1761.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 0,
  TimeNow: 76.77777777777777,
  S: 2.4444444444444446,
  N: 95.94444444444444,
  J: 0.5555555555555556,
  St: 43.833333333333336,
  Nt: 1706.6666666666667,
  Jt: 10.5,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 15,
  TimeNow: 731.95,
  S: 0.0,
  N: 0.0,
  J: 99.0,
  St: 0.0,
  Nt: 0.0,
  Jt: 1761.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 17,
  TimeNow: 226.21875,
  S: 32.0,
  N: 42.0,
  J: 24.625,
  St: 572.0,
  Nt: 747.71875,
  Jt: 441.28125,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 7,
  TimeNow: 84.07142857142857,
  S: 4.428571428571429,
  N: 93.5,
  J: 1.0,
  St: 81.07142857142857,
  Nt: 1660.5,
  Jt: 19.428571428571427,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 11,
  TimeNow: 780.0,
  S: 0.0,
  N: 0.0,
  J: 99.0,
  St: 0.0,
  Nt: 0.0,
  Jt: 1761.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 20,
  TimeNow: 91.0,
  S: 9.0,
  N: 88.64285714285714,
  J: 1.3571428571428572,
  St: 168.42857142857142,
  Nt: 1567.392857142857,
  Jt: 25.178571428571427,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 8,
  TimeNow: 240.1818181818182,
  S: 36.81818181818182,
  N: 39.72727272727273,
  J: 21.818181818181817,
  St: 656.4545454545455,
  Nt: 708.7272727272727,
  Jt: 395.8181818181818,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 22,
  TimeNow: 78.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1761.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 19,
  TimeNow: 118.64864864864865,
  S: 13.405405405405405,
  N: 76.83783783783784,
  J: 8.54054054054054,
  St: 241.05405405405406,
  Nt: 1358.7297297297298,
  Jt: 161.21621621621622,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 16,
  TimeNow: 734.9473684210526,
  S: 5.157894736842105,
  N: 0.0,
  J: 93.84210526315789,
  St: 93.47368421052632,
  Nt: 0.0,
  Jt: 1667.5263157894738,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 14,
  TimeNow: 955.9473684210526,
  S: 0.0,
  N: 0.0,
  J: 99.0,
  St: 0.0,
  Nt: 0.0,
  Jt: 1761.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 13,
  TimeNow: 693.6,
  S: 0.0,
  N: 0.0,
  J: 99.0,
  St: 0.0,
  Nt: 0.0,
  Jt: 1761.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 12,
  TimeNow: 712.3,
  S: 0.0,
  N: 0.0,
  J: 99.0,
  St: 0.0,
  Nt: 0.0,
  Jt: 1761.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 2,
  TimeNow: 80.33333333333333,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1761.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 6,
  TimeNow: 73.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1761.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 10,
  TimeNow: 684.7333333333333,
  S: 0.06666666666666667,
  N: 0.0,
  J: 98.93333333333334,
  St: 1.5333333333333334,
  Nt: 0.0,
  Jt: 1759.4666666666667,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 23,
  TimeNow: 74.3076923076923,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1761.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 4,
  TimeNow: 68.2,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1761.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 3,
  TimeNow: 69.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1761.0,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 9,
  TimeNow: 188.63636363636363,
  S: 35.36363636363637,
  N: 54.54545454545455,
  J: 8.272727272727273,
  St: 527.7272727272727,
  Nt: 809.2727272727273,
  Jt: 130.54545454545453,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 18,
  TimeNow: 129.1875,
  S: 22.71875,
  N: 72.6875,
  J: 2.90625,
  St: 342.0625,
  Nt: 1077.8125,
  Jt: 47.21875,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 21,
  TimeNow: 123.92307692307692,
  S: 7.153846153846154,
  N: 91.84615384615384,
  J: 0.0,
  St: 110.46153846153847,
  Nt: 1356.5384615384614,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 1,
  TimeNow: 114.11538461538461,
  S: 1.6923076923076923,
  N: 97.3076923076923,
  J: 0.0,
  St: 34.15384615384615,
  Nt: 1433.1538461538462,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 0,
  TimeNow: 114.94444444444444,
  S: 0.9444444444444444,
  N: 97.5,
  J: 0.5555555555555556,
  St: 21.055555555555557,
  Nt: 1437.3333333333333,
  Jt: 8.666666666666666,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 15,
  TimeNow: 217.9,
  S: 23.4,
  N: 42.8,
  J: 31.9,
  St: 350.05,
  Nt: 636.45,
  Jt: 480.6,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 17,
  TimeNow: 139.40625,
  S: 25.71875,
  N: 64.375,
  J: 8.21875,
  St: 386.59375,
  Nt: 952.34375,
  Jt: 128.46875,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 7,
  TimeNow: 104.57142857142857,
  S: 2.7857142857142856,
  N: 95.28571428571429,
  J: 0.8571428571428571,
  St: 44.214285714285715,
  Nt: 1408.642857142857,
  Jt: 14.142857142857142,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 11,
  TimeNow: 239.5625,
  S: 19.875,
  N: 41.625,
  J: 36.625,
  St: 298.25,
  Nt: 619.1875,
  Jt: 549.6875,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 20,
  TimeNow: 130.07142857142858,
  S: 27.178571428571427,
  N: 68.71428571428571,
  J: 2.9285714285714284,
  St: 406.07142857142856,
  Nt: 1015.7857142857143,
  Jt: 45.42857142857143,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 8,
  TimeNow: 151.0,
  S: 16.636363636363637,
  N: 76.63636363636364,
  J: 5.2727272727272725,
  St: 249.1818181818182,
  Nt: 1130.8181818181818,
  Jt: 87.18181818181819,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 22,
  TimeNow: 117.92307692307692,
  S: 0.38461538461538464,
  N: 98.61538461538461,
  J: 0.0,
  St: 9.384615384615385,
  Nt: 1457.7692307692307,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 19,
  TimeNow: 131.86486486486487,
  S: 27.64864864864865,
  N: 60.21621621621622,
  J: 10.513513513513514,
  St: 413.5135135135135,
  Nt: 890.4864864864865,
  Jt: 163.16216216216216,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 16,
  TimeNow: 222.78947368421052,
  S: 24.894736842105264,
  N: 38.8421052631579,
  J: 34.31578947368421,
  St: 373.2631578947368,
  Nt: 578.421052631579,
  Jt: 515.5263157894736,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 14,
  TimeNow: 254.42105263157896,
  S: 14.789473684210526,
  N: 43.94736842105263,
  J: 39.36842105263158,
  St: 224.78947368421052,
  Nt: 651.421052631579,
  Jt: 590.9473684210526,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 13,
  TimeNow: 221.36,
  S: 23.76,
  N: 39.0,
  J: 35.32,
  St: 357.84,
  Nt: 578.6,
  Jt: 530.64,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 12,
  TimeNow: 218.5,
  S: 17.15,
  N: 46.0,
  J: 34.95,
  St: 259.0,
  Nt: 682.85,
  Jt: 525.4,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 2,
  TimeNow: 111.91666666666667,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1467.0,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 6,
  TimeNow: 109.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1467.0,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 10,
  TimeNow: 231.2,
  S: 31.133333333333333,
  N: 47.333333333333336,
  J: 19.6,
  St: 465.6,
  Nt: 703.5333333333333,
  Jt: 298.2,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 23,
  TimeNow: 116.6923076923077,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1467.0,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 4,
  TimeNow: 114.54545454545455,
  S: 1.0909090909090908,
  N: 97.9090909090909,
  J: 0.0,
  St: 17.454545454545453,
  Nt: 1449.909090909091,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 3,
  TimeNow: 109.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1467.0,
  Jt: 0.0,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 9,
  TimeNow: 327.8181818181818,
  S: 1.1818181818181819,
  N: 95.18181818181819,
  J: 2.0,
  St: 65.72727272727273,
  Nt: 4138.272727272727,
  Jt: 127.0,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 18,
  TimeNow: 427.4375,
  S: 2.21875,
  N: 88.0625,
  J: 8.3125,
  St: 110.4375,
  Nt: 3836.9375,
  Jt: 383.625,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 21,
  TimeNow: 301.0,
  S: 2.1538461538461537,
  N: 93.84615384615384,
  J: 2.3846153846153846,
  St: 111.53846153846153,
  Nt: 4079.076923076923,
  Jt: 140.3846153846154,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 1,
  TimeNow: 248.8846153846154,
  S: 1.9615384615384615,
  N: 96.8076923076923,
  J: 0.23076923076923078,
  St: 115.03846153846153,
  Nt: 4201.307692307692,
  Jt: 14.653846153846153,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 0,
  TimeNow: 276.0,
  S: 1.3333333333333333,
  N: 96.05555555555556,
  J: 1.5555555555555556,
  St: 85.38888888888889,
  Nt: 4164.5,
  Jt: 81.11111111111111,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 15,
  TimeNow: 447.05,
  S: 10.65,
  N: 72.05,
  J: 15.95,
  St: 478.3,
  Nt: 3144.8,
  Jt: 707.9,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 17,
  TimeNow: 688.53125,
  S: 9.75,
  N: 62.59375,
  J: 26.0625,
  St: 439.9375,
  Nt: 2735.21875,
  Jt: 1155.84375,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 7,
  TimeNow: 371.14285714285717,
  S: 4.928571428571429,
  N: 83.92857142857143,
  J: 9.571428571428571,
  St: 227.85714285714286,
  Nt: 3657.0714285714284,
  Jt: 446.07142857142856,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 11,
  TimeNow: 280.4375,
  S: 0.375,
  N: 95.9375,
  J: 2.25,
  St: 30.1875,
  Nt: 4165.4375,
  Jt: 135.375,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 20,
  TimeNow: 308.92857142857144,
  S: 2.0357142857142856,
  N: 93.39285714285714,
  J: 2.9642857142857144,
  St: 109.60714285714286,
  Nt: 4063.8928571428573,
  Jt: 157.5,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 8,
  TimeNow: 378.90909090909093,
  S: 5.636363636363637,
  N: 81.81818181818181,
  J: 11.181818181818182,
  St: 252.9090909090909,
  Nt: 3564.181818181818,
  Jt: 513.9090909090909,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 22,
  TimeNow: 272.84615384615387,
  S: 0.8461538461538461,
  N: 96.76923076923077,
  J: 1.3076923076923077,
  St: 51.30769230769231,
  Nt: 4197.461538461538,
  Jt: 82.23076923076923,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 19,
  TimeNow: 301.2972972972973,
  S: 1.4054054054054055,
  N: 94.27027027027027,
  J: 2.810810810810811,
  St: 78.83783783783784,
  Nt: 4095.0810810810813,
  Jt: 157.0810810810811,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 16,
  TimeNow: 562.4736842105264,
  S: 6.631578947368421,
  N: 66.78947368421052,
  J: 25.05263157894737,
  St: 306.94736842105266,
  Nt: 2910.315789473684,
  Jt: 1113.7368421052631,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 14,
  TimeNow: 483.36842105263156,
  S: 11.31578947368421,
  N: 72.6842105263158,
  J: 14.31578947368421,
  St: 509.6842105263158,
  Nt: 3171.1052631578946,
  Jt: 650.2105263157895,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 13,
  TimeNow: 364.36,
  S: 2.68,
  N: 91.48,
  J: 4.28,
  St: 133.0,
  Nt: 3980.28,
  Jt: 217.72,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 12,
  TimeNow: 293.15,
  S: 0.4,
  N: 96.25,
  J: 2.05,
  St: 27.6,
  Nt: 4175.25,
  Jt: 128.15,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 2,
  TimeNow: 234.46153846153845,
  S: 2.1538461538461537,
  N: 96.84615384615384,
  J: 0.0,
  St: 121.07692307692308,
  Nt: 4209.923076923077,
  Jt: 0.0,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 6,
  TimeNow: 275.0,
  S: 0.0,
  N: 97.0,
  J: 2.0,
  St: 0.0,
  Nt: 4204.0,
  Jt: 127.0,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 10,
  TimeNow: 302.2,
  S: 0.5333333333333333,
  N: 96.0,
  J: 2.2,
  St: 30.066666666666666,
  Nt: 4167.933333333333,
  Jt: 133.0,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 23,
  TimeNow: 254.92307692307693,
  S: 1.5384615384615385,
  N: 97.0,
  J: 0.46153846153846156,
  St: 97.6923076923077,
  Nt: 4204.0,
  Jt: 29.307692307692307,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 4,
  TimeNow: 236.0,
  S: 1.3636363636363635,
  N: 97.63636363636364,
  J: 0.0,
  St: 81.18181818181819,
  Nt: 4249.818181818182,
  Jt: 0.0,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 3,
  TimeNow: 225.5,
  S: 2.0,
  N: 97.0,
  J: 0.0,
  St: 127.0,
  Nt: 4204.0,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 9,
  TimeNow: 323.8181818181818,
  S: 3.0,
  N: 96.0,
  J: 0.0,
  St: 238.1818181818182,
  Nt: 7145.818181818182,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 18,
  TimeNow: 1156.5,
  S: 7.0625,
  N: 5.15625,
  J: 86.53125,
  St: 548.53125,
  Nt: 415.59375,
  Jt: 6419.875,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 21,
  TimeNow: 406.46153846153845,
  S: 28.692307692307693,
  N: 60.46153846153846,
  J: 9.384615384615385,
  St: 2157.6923076923076,
  Nt: 4505.7692307692305,
  Jt: 720.5384615384615,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 1,
  TimeNow: 291.0769230769231,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7384.0,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 0,
  TimeNow: 363.6666666666667,
  S: 2.1666666666666665,
  N: 92.0,
  J: 4.777777777777778,
  St: 171.27777777777777,
  Nt: 6856.5,
  Jt: 356.22222222222223,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 15,
  TimeNow: 687.85,
  S: 40.05,
  N: 27.65,
  J: 30.9,
  St: 2990.3,
  Nt: 2074.25,
  Jt: 2319.45,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 17,
  TimeNow: 1436.375,
  S: 6.0,
  N: 4.1875,
  J: 88.28125,
  St: 477.84375,
  Nt: 348.09375,
  Jt: 6558.0625,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 7,
  TimeNow: 1397.0714285714287,
  S: 12.357142857142858,
  N: 19.928571428571427,
  J: 66.5,
  St: 957.2142857142857,
  Nt: 1498.7857142857142,
  Jt: 4928.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 11,
  TimeNow: 336.8125,
  S: 1.4375,
  N: 97.5625,
  J: 0.0,
  St: 115.625,
  Nt: 7268.375,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 20,
  TimeNow: 501.82142857142856,
  S: 61.42857142857143,
  N: 14.0,
  J: 23.178571428571427,
  St: 4568.607142857143,
  Nt: 1066.7142857142858,
  Jt: 1748.6785714285713,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 8,
  TimeNow: 580.2727272727273,
  S: 18.272727272727273,
  N: 49.63636363636363,
  J: 30.636363636363637,
  St: 1381.6363636363637,
  Nt: 3713.2727272727275,
  Jt: 2289.090909090909,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 22,
  TimeNow: 324.0769230769231,
  S: 1.6923076923076923,
  N: 97.3076923076923,
  J: 0.0,
  St: 130.92307692307693,
  Nt: 7253.076923076923,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 19,
  TimeNow: 739.7297297297297,
  S: 35.7027027027027,
  N: 9.837837837837839,
  J: 52.891891891891895,
  St: 2674.7297297297296,
  Nt: 768.0,
  Jt: 3941.2702702702704,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 16,
  TimeNow: 1319.2631578947369,
  S: 16.842105263157894,
  N: 1.736842105263158,
  J: 80.0,
  St: 1276.421052631579,
  Nt: 165.6315789473684,
  Jt: 5941.9473684210525,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 14,
  TimeNow: 644.8947368421053,
  S: 32.421052631578945,
  N: 35.10526315789474,
  J: 31.210526315789473,
  St: 2434.4210526315787,
  Nt: 2622.2105263157896,
  Jt: 2327.3684210526317,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 13,
  TimeNow: 392.48,
  S: 26.72,
  N: 69.12,
  J: 2.92,
  St: 2011.8,
  Nt: 5146.08,
  Jt: 226.12,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 12,
  TimeNow: 345.9,
  S: 13.7,
  N: 85.3,
  J: 0.0,
  St: 1039.9,
  Nt: 6344.1,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 2,
  TimeNow: 279.38461538461536,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7384.0,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 6,
  TimeNow: 718.0,
  S: 2.5,
  N: 62.5,
  J: 34.0,
  St: 187.5,
  Nt: 4667.0,
  Jt: 2529.5,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 10,
  TimeNow: 326.0,
  S: 2.466666666666667,
  N: 96.53333333333333,
  J: 0.0,
  St: 197.93333333333334,
  Nt: 7186.066666666667,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 23,
  TimeNow: 314.3076923076923,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7384.0,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 4,
  TimeNow: 269.1818181818182,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7384.0,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 3,
  TimeNow: 278.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7384.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 9,
  TimeNow: 206.36363636363637,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 18,
  TimeNow: 226.90625,
  S: 2.96875,
  N: 96.0,
  J: 0.0,
  St: 112.4375,
  Nt: 3515.78125,
  Jt: 0.78125,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 21,
  TimeNow: 234.07692307692307,
  S: 1.1538461538461537,
  N: 95.07692307692308,
  J: 2.6923076923076925,
  St: 47.15384615384615,
  Nt: 3481.846153846154,
  Jt: 100.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 1,
  TimeNow: 391.84615384615387,
  S: 11.384615384615385,
  N: 70.96153846153847,
  J: 16.346153846153847,
  St: 433.2307692307692,
  Nt: 2592.0,
  Jt: 603.7692307692307,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 0,
  TimeNow: 267.3888888888889,
  S: 4.888888888888889,
  N: 89.72222222222223,
  J: 4.111111111111111,
  St: 187.05555555555554,
  Nt: 3286.722222222222,
  Jt: 155.22222222222223,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 15,
  TimeNow: 214.75,
  S: 0.15,
  N: 98.85,
  J: 0.0,
  St: 8.2,
  Nt: 3620.8,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 17,
  TimeNow: 254.8125,
  S: 13.5625,
  N: 84.90625,
  J: 0.40625,
  St: 505.0625,
  Nt: 3105.4375,
  Jt: 18.5,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 7,
  TimeNow: 200.14285714285714,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 11,
  TimeNow: 213.375,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 4.0,
  Nt: 3625.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 20,
  TimeNow: 225.07142857142858,
  S: 0.14285714285714285,
  N: 98.85714285714286,
  J: 0.0,
  St: 7.571428571428571,
  Nt: 3621.4285714285716,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 8,
  TimeNow: 203.36363636363637,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 22,
  TimeNow: 332.61538461538464,
  S: 6.6923076923076925,
  N: 82.3076923076923,
  J: 9.76923076923077,
  St: 253.3846153846154,
  Nt: 3013.769230769231,
  Jt: 361.84615384615387,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 19,
  TimeNow: 222.9189189189189,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.6756756756756757,
  Nt: 3628.324324324324,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 16,
  TimeNow: 223.1578947368421,
  S: 0.15789473684210525,
  N: 98.84210526315789,
  J: 0.0,
  St: 10.947368421052632,
  Nt: 3618.0526315789475,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 14,
  TimeNow: 216.3684210526316,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 13,
  TimeNow: 213.8,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 12,
  TimeNow: 216.7,
  S: 0.5,
  N: 98.5,
  J: 0.0,
  St: 18.65,
  Nt: 3610.35,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 2,
  TimeNow: 226.53846153846155,
  S: 5.769230769230769,
  N: 93.23076923076923,
  J: 0.0,
  St: 222.46153846153845,
  Nt: 3406.5384615384614,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 6,
  TimeNow: 203.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 10,
  TimeNow: 210.66666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 23,
  TimeNow: 226.15384615384616,
  S: 0.6923076923076923,
  N: 98.3076923076923,
  J: 0.0,
  St: 30.076923076923077,
  Nt: 3598.923076923077,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 4,
  TimeNow: 212.0,
  S: 0.2727272727272727,
  N: 98.63636363636364,
  J: 0.0,
  St: 14.727272727272727,
  Nt: 3614.2727272727275,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 3,
  TimeNow: 207.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 9,
  TimeNow: 774.9090909090909,
  S: 14.181818181818182,
  N: 0.45454545454545453,
  J: 84.27272727272727,
  St: 523.0909090909091,
  Nt: 19.09090909090909,
  Jt: 3049.818181818182,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 18,
  TimeNow: 182.875,
  S: 6.84375,
  N: 90.84375,
  J: 1.09375,
  St: 256.71875,
  Nt: 3289.09375,
  Jt: 46.1875,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 21,
  TimeNow: 162.15384615384616,
  S: 0.6153846153846154,
  N: 98.38461538461539,
  J: 0.0,
  St: 29.923076923076923,
  Nt: 3562.076923076923,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 1,
  TimeNow: 146.46153846153845,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3592.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 0,
  TimeNow: 152.72222222222223,
  S: 1.8888888888888888,
  N: 96.66666666666667,
  J: 0.3888888888888889,
  St: 70.5,
  Nt: 3506.0,
  Jt: 15.5,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 15,
  TimeNow: 1045.7,
  S: 4.55,
  N: 2.5,
  J: 91.9,
  St: 173.0,
  Nt: 94.3,
  Jt: 3324.7,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 17,
  TimeNow: 343.03125,
  S: 27.625,
  N: 49.625,
  J: 21.28125,
  St: 1008.90625,
  Nt: 1803.25,
  Jt: 779.84375,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 7,
  TimeNow: 166.07142857142858,
  S: 3.0714285714285716,
  N: 95.42857142857143,
  J: 0.5,
  St: 121.57142857142857,
  Nt: 3451.285714285714,
  Jt: 19.142857142857142,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 11,
  TimeNow: 1305.9375,
  S: 2.1875,
  N: 1.8125,
  J: 94.9375,
  St: 84.8125,
  Nt: 67.875,
  Jt: 3439.3125,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 20,
  TimeNow: 171.07142857142858,
  S: 4.321428571428571,
  N: 93.96428571428571,
  J: 0.4642857142857143,
  St: 165.71428571428572,
  Nt: 3402.25,
  Jt: 24.035714285714285,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 8,
  TimeNow: 470.3636363636364,
  S: 20.818181818181817,
  N: 28.363636363636363,
  J: 49.27272727272727,
  St: 768.0,
  Nt: 1041.2727272727273,
  Jt: 1782.7272727272727,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 22,
  TimeNow: 155.76923076923077,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3592.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 19,
  TimeNow: 195.6216216216216,
  S: 6.945945945945946,
  N: 87.78378378378379,
  J: 4.243243243243243,
  St: 258.1621621621622,
  Nt: 3176.0810810810813,
  Jt: 157.75675675675674,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 16,
  TimeNow: 986.7368421052631,
  S: 16.473684210526315,
  N: 9.105263157894736,
  J: 73.10526315789474,
  St: 611.2105263157895,
  Nt: 340.4736842105263,
  Jt: 2640.315789473684,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 14,
  TimeNow: 1380.2631578947369,
  S: 0.47368421052631576,
  N: 0.0,
  J: 98.52631578947368,
  St: 19.263157894736842,
  Nt: 0.0,
  Jt: 3572.7368421052633,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 13,
  TimeNow: 1094.0,
  S: 2.88,
  N: 0.2,
  J: 95.72,
  St: 113.52,
  Nt: 10.8,
  Jt: 3467.68,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 12,
  TimeNow: 1097.4,
  S: 8.15,
  N: 0.9,
  J: 89.85,
  St: 306.65,
  Nt: 36.0,
  Jt: 3249.35,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 2,
  TimeNow: 151.6153846153846,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3592.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 6,
  TimeNow: 148.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3592.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 10,
  TimeNow: 1126.0666666666666,
  S: 0.0,
  N: 0.0,
  J: 99.0,
  St: 1.3333333333333333,
  Nt: 0.0,
  Jt: 3590.6666666666665,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 23,
  TimeNow: 149.6153846153846,
  S: 0.07692307692307693,
  N: 98.92307692307692,
  J: 0.0,
  St: 4.153846153846154,
  Nt: 3587.846153846154,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 4,
  TimeNow: 140.1,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3592.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 3,
  TimeNow: 141.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3592.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 9,
  TimeNow: 167.8181818181818,
  S: 2.8181818181818183,
  N: 96.18181818181819,
  J: 0.0,
  St: 111.0909090909091,
  Nt: 3573.909090909091,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 18,
  TimeNow: 415.3125,
  S: 4.78125,
  N: 48.875,
  J: 44.8125,
  St: 194.40625,
  Nt: 1816.53125,
  Jt: 1673.59375,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 21,
  TimeNow: 178.0,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 37.84615384615385,
  Nt: 3647.153846153846,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 1,
  TimeNow: 166.34615384615384,
  S: 0.038461538461538464,
  N: 98.96153846153847,
  J: 0.0,
  St: 2.769230769230769,
  Nt: 3682.230769230769,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 0,
  TimeNow: 189.83333333333334,
  S: 0.2222222222222222,
  N: 95.88888888888889,
  J: 2.8333333333333335,
  St: 9.61111111111111,
  Nt: 3569.0555555555557,
  Jt: 106.38888888888889,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 15,
  TimeNow: 325.2,
  S: 6.1,
  N: 55.5,
  J: 36.85,
  St: 244.55,
  Nt: 2060.8,
  Jt: 1379.65,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 17,
  TimeNow: 577.5625,
  S: 2.5,
  N: 45.75,
  J: 50.3125,
  St: 107.375,
  Nt: 1697.15625,
  Jt: 1880.625,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 7,
  TimeNow: 177.21428571428572,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 11,
  TimeNow: 183.125,
  S: 9.625,
  N: 88.875,
  J: 0.5,
  St: 368.5,
  Nt: 3297.25,
  Jt: 19.25,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 20,
  TimeNow: 184.75,
  S: 1.7857142857142858,
  N: 97.21428571428571,
  J: 0.0,
  St: 68.03571428571429,
  Nt: 3617.035714285714,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 8,
  TimeNow: 163.9090909090909,
  S: 3.0,
  N: 96.0,
  J: 0.0,
  St: 117.63636363636364,
  Nt: 3567.3636363636365,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 22,
  TimeNow: 173.46153846153845,
  S: 0.23076923076923078,
  N: 98.76923076923077,
  J: 0.0,
  St: 11.076923076923077,
  Nt: 3673.923076923077,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 19,
  TimeNow: 284.5405405405405,
  S: 8.64864864864865,
  N: 62.2972972972973,
  J: 27.594594594594593,
  St: 334.8378378378378,
  Nt: 2313.7297297297296,
  Jt: 1036.1081081081081,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 16,
  TimeNow: 449.0,
  S: 7.894736842105263,
  N: 52.94736842105263,
  J: 37.68421052631579,
  St: 308.42105263157896,
  Nt: 1966.1052631578948,
  Jt: 1410.7894736842106,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 14,
  TimeNow: 339.05263157894734,
  S: 9.0,
  N: 62.89473684210526,
  J: 26.526315789473685,
  St: 353.2105263157895,
  Nt: 2336.0526315789475,
  Jt: 995.3684210526316,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 13,
  TimeNow: 189.84,
  S: 15.2,
  N: 83.8,
  J: 0.0,
  St: 580.6,
  Nt: 3104.56,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 12,
  TimeNow: 184.8,
  S: 12.45,
  N: 86.55,
  J: 0.0,
  St: 479.15,
  Nt: 3205.95,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 2,
  TimeNow: 163.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 6,
  TimeNow: 168.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 10,
  TimeNow: 176.2,
  S: 6.8,
  N: 92.2,
  J: 0.0,
  St: 263.06666666666666,
  Nt: 3421.9333333333334,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 23,
  TimeNow: 169.84615384615384,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 4,
  TimeNow: 163.9090909090909,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 3,
  TimeNow: 164.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 9,
  TimeNow: 146.0,
  S: 6.090909090909091,
  N: 92.81818181818181,
  J: 0.09090909090909091,
  St: 123.45454545454545,
  Nt: 1676.5454545454545,
  Jt: 3.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 18,
  TimeNow: 182.125,
  S: 4.0625,
  N: 90.25,
  J: 4.5,
  St: 79.90625,
  Nt: 1630.53125,
  Jt: 92.53125,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 21,
  TimeNow: 191.76923076923077,
  S: 4.384615384615385,
  N: 89.76923076923077,
  J: 4.615384615384615,
  St: 86.07692307692308,
  Nt: 1622.7692307692307,
  Jt: 94.15384615384616,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 1,
  TimeNow: 149.07692307692307,
  S: 6.153846153846154,
  N: 90.03846153846153,
  J: 2.3846153846153846,
  St: 121.65384615384616,
  Nt: 1631.5,
  Jt: 49.84615384615385,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 0,
  TimeNow: 155.33333333333334,
  S: 11.222222222222221,
  N: 85.94444444444444,
  J: 1.7222222222222223,
  St: 207.22222222222223,
  Nt: 1559.9444444444443,
  Jt: 35.833333333333336,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 15,
  TimeNow: 169.95,
  S: 3.7,
  N: 91.0,
  J: 4.15,
  St: 73.75,
  Nt: 1644.2,
  Jt: 85.05,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 17,
  TimeNow: 178.53125,
  S: 3.375,
  N: 90.25,
  J: 4.84375,
  St: 70.46875,
  Nt: 1633.71875,
  Jt: 98.8125,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 7,
  TimeNow: 168.42857142857142,
  S: 7.5,
  N: 87.71428571428571,
  J: 3.142857142857143,
  St: 144.85714285714286,
  Nt: 1592.357142857143,
  Jt: 65.78571428571429,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 11,
  TimeNow: 272.3125,
  S: 2.0625,
  N: 89.3125,
  J: 7.5625,
  St: 43.4375,
  Nt: 1616.875,
  Jt: 142.6875,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 20,
  TimeNow: 203.75,
  S: 6.5,
  N: 86.60714285714286,
  J: 5.214285714285714,
  St: 128.03571428571428,
  Nt: 1568.892857142857,
  Jt: 106.07142857142857,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 8,
  TimeNow: 146.8181818181818,
  S: 5.7272727272727275,
  N: 93.0,
  J: 0.2727272727272727,
  St: 117.0,
  Nt: 1679.7272727272727,
  Jt: 6.2727272727272725,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 22,
  TimeNow: 165.23076923076923,
  S: 4.6923076923076925,
  N: 92.38461538461539,
  J: 1.7692307692307692,
  St: 99.0,
  Nt: 1667.3076923076924,
  Jt: 36.69230769230769,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 19,
  TimeNow: 182.72972972972974,
  S: 4.45945945945946,
  N: 90.05405405405405,
  J: 4.054054054054054,
  St: 90.54054054054055,
  Nt: 1628.7027027027027,
  Jt: 83.75675675675676,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 16,
  TimeNow: 185.42105263157896,
  S: 2.8421052631578947,
  N: 90.52631578947368,
  J: 5.315789473684211,
  St: 57.63157894736842,
  Nt: 1637.3684210526317,
  Jt: 108.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 14,
  TimeNow: 163.21052631578948,
  S: 2.263157894736842,
  N: 92.26315789473684,
  J: 4.2631578947368425,
  St: 48.31578947368421,
  Nt: 1666.8947368421052,
  Jt: 87.78947368421052,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 13,
  TimeNow: 175.84,
  S: 2.12,
  N: 91.44,
  J: 5.28,
  St: 43.68,
  Nt: 1656.0,
  Jt: 103.32,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 12,
  TimeNow: 223.95,
  S: 2.4,
  N: 89.15,
  J: 7.25,
  St: 50.4,
  Nt: 1613.85,
  Jt: 138.75,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 2,
  TimeNow: 139.30769230769232,
  S: 6.615384615384615,
  N: 92.38461538461539,
  J: 0.0,
  St: 128.15384615384616,
  Nt: 1674.8461538461538,
  Jt: 0.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 6,
  TimeNow: 148.0,
  S: 2.5,
  N: 91.0,
  J: 5.0,
  St: 49.5,
  Nt: 1655.0,
  Jt: 98.5,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 10,
  TimeNow: 153.0,
  S: 6.666666666666667,
  N: 90.53333333333333,
  J: 1.6,
  St: 133.8,
  Nt: 1635.0,
  Jt: 34.2,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 23,
  TimeNow: 162.6153846153846,
  S: 3.769230769230769,
  N: 92.46153846153847,
  J: 2.769230769230769,
  St: 76.61538461538461,
  Nt: 1668.6923076923076,
  Jt: 57.69230769230769,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 4,
  TimeNow: 126.72727272727273,
  S: 0.18181818181818182,
  N: 98.81818181818181,
  J: 0.0,
  St: 3.8181818181818183,
  Nt: 1799.1818181818182,
  Jt: 0.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 3,
  TimeNow: 147.0,
  S: 9.0,
  N: 90.0,
  J: 0.0,
  St: 169.0,
  Nt: 1634.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 9,
  TimeNow: 996.4545454545455,
  S: 25.272727272727273,
  N: 5.545454545454546,
  J: 68.0909090909091,
  St: 1529.7272727272727,
  Nt: 336.90909090909093,
  Jt: 4059.3636363636365,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 18,
  TimeNow: 286.0,
  S: 4.125,
  N: 94.09375,
  J: 0.5,
  St: 265.0625,
  Nt: 5620.25,
  Jt: 39.96875,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 21,
  TimeNow: 265.61538461538464,
  S: 0.15384615384615385,
  N: 98.84615384615384,
  J: 0.0,
  St: 21.307692307692307,
  Nt: 5904.692307692308,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 1,
  TimeNow: 226.6,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5926.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 0,
  TimeNow: 233.88888888888889,
  S: 1.1111111111111112,
  N: 97.61111111111111,
  J: 0.2222222222222222,
  St: 70.55555555555556,
  Nt: 5839.833333333333,
  Jt: 15.666666666666666,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 15,
  TimeNow: 1119.15,
  S: 4.85,
  N: 36.6,
  J: 57.35,
  St: 312.2,
  Nt: 2184.2,
  Jt: 3429.6,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 7,
  TimeNow: 279.14285714285717,
  S: 3.357142857142857,
  N: 95.28571428571429,
  J: 0.2857142857142857,
  St: 210.35714285714286,
  Nt: 5695.857142857143,
  Jt: 19.571428571428573,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 11,
  TimeNow: 1338.875,
  S: 3.6875,
  N: 35.125,
  J: 60.0625,
  St: 237.125,
  Nt: 2098.75,
  Jt: 3590.125,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 20,
  TimeNow: 275.64285714285717,
  S: 2.5,
  N: 96.0,
  J: 0.25,
  St: 166.0,
  Nt: 5733.892857142857,
  Jt: 25.25,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 8,
  TimeNow: 665.9090909090909,
  S: 24.636363636363637,
  N: 35.09090909090909,
  J: 38.90909090909091,
  St: 1489.0,
  Nt: 2108.2727272727275,
  Jt: 2328.5454545454545,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 17,
  TimeNow: 447.258064516129,
  S: 17.387096774193548,
  N: 67.90322580645162,
  J: 13.258064516129032,
  St: 1062.032258064516,
  Nt: 4053.2580645161293,
  Jt: 810.6451612903226,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 22,
  TimeNow: 253.23076923076923,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5926.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 19,
  TimeNow: 299.27027027027026,
  S: 4.351351351351352,
  N: 92.05405405405405,
  J: 2.5135135135135136,
  St: 270.2972972972973,
  Nt: 5497.324324324324,
  Jt: 157.56756756756758,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 16,
  TimeNow: 1034.9473684210527,
  S: 12.789473684210526,
  N: 41.63157894736842,
  J: 43.8421052631579,
  St: 787.8421052631579,
  Nt: 2495.2105263157896,
  Jt: 2642.7894736842104,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 14,
  TimeNow: 1487.6842105263158,
  S: 7.421052631578948,
  N: 22.42105263157895,
  J: 68.78947368421052,
  St: 460.7894736842105,
  Nt: 1356.4736842105262,
  Jt: 4108.736842105263,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 13,
  TimeNow: 1209.72,
  S: 9.76,
  N: 26.48,
  J: 62.44,
  St: 608.84,
  Nt: 1593.0,
  Jt: 3724.16,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 12,
  TimeNow: 1131.05,
  S: 8.0,
  N: 36.4,
  J: 54.4,
  St: 500.45,
  Nt: 2173.85,
  Jt: 3251.7,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 2,
  TimeNow: 230.23076923076923,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5926.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 6,
  TimeNow: 234.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5926.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 10,
  TimeNow: 1354.8666666666666,
  S: 9.0,
  N: 12.2,
  J: 77.8,
  St: 551.1333333333333,
  Nt: 735.6,
  Jt: 4639.266666666666,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 23,
  TimeNow: 237.0,
  S: 0.38461538461538464,
  N: 98.61538461538461,
  J: 0.0,
  St: 23.846153846153847,
  Nt: 5902.153846153846,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 4,
  TimeNow: 215.45454545454547,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5926.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 3,
  TimeNow: 217.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5926.0,
  Jt: 0.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 9,
  TimeNow: 88.27272727272727,
  S: 32.45454545454545,
  N: 0.7272727272727273,
  J: 65.81818181818181,
  St: 121.9090909090909,
  Nt: 2.909090909090909,
  Jt: 246.1818181818182,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 18,
  TimeNow: 66.0625,
  S: 73.75,
  N: 0.0,
  J: 25.125,
  St: 276.4375,
  Nt: 0.0,
  Jt: 94.5625,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 21,
  TimeNow: 44.15384615384615,
  S: 47.46153846153846,
  N: 51.53846153846154,
  J: 0.0,
  St: 178.53846153846155,
  Nt: 192.46153846153845,
  Jt: 0.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 1,
  TimeNow: 38.19230769230769,
  S: 0.6923076923076923,
  N: 98.1923076923077,
  J: 0.11538461538461539,
  St: 3.0,
  Nt: 367.5,
  Jt: 0.5,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 0,
  TimeNow: 36.72222222222222,
  S: 5.666666666666667,
  N: 90.66666666666667,
  J: 2.6666666666666665,
  St: 21.72222222222222,
  Nt: 339.1666666666667,
  Jt: 10.11111111111111,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 15,
  TimeNow: 204.1,
  S: 1.2,
  N: 0.0,
  J: 97.8,
  St: 5.2,
  Nt: 0.0,
  Jt: 365.8,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 17,
  TimeNow: 79.5625,
  S: 45.71875,
  N: 3.09375,
  J: 50.09375,
  St: 171.71875,
  Nt: 11.59375,
  Jt: 187.6875,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 7,
  TimeNow: 66.64285714285714,
  S: 34.357142857142854,
  N: 37.07142857142857,
  J: 27.571428571428573,
  St: 129.57142857142858,
  Nt: 138.0,
  Jt: 103.42857142857143,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 11,
  TimeNow: 136.4375,
  S: 9.1875,
  N: 0.0,
  J: 89.8125,
  St: 35.8125,
  Nt: 0.0,
  Jt: 335.1875,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 20,
  TimeNow: 110.82142857142857,
  S: 46.42857142857143,
  N: 2.6785714285714284,
  J: 49.857142857142854,
  St: 173.67857142857142,
  Nt: 9.964285714285714,
  Jt: 187.35714285714286,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 8,
  TimeNow: 78.9090909090909,
  S: 22.272727272727273,
  N: 22.727272727272727,
  J: 53.81818181818182,
  St: 84.36363636363636,
  Nt: 85.0909090909091,
  Jt: 201.54545454545453,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 22,
  TimeNow: 39.38461538461539,
  S: 6.230769230769231,
  N: 92.3076923076923,
  J: 0.46153846153846156,
  St: 24.53846153846154,
  Nt: 344.46153846153845,
  Jt: 2.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 19,
  TimeNow: 145.7027027027027,
  S: 14.162162162162161,
  N: 0.0,
  J: 84.83783783783784,
  St: 53.351351351351354,
  Nt: 0.0,
  Jt: 317.64864864864865,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 16,
  TimeNow: 190.89473684210526,
  S: 2.3157894736842106,
  N: 0.0,
  J: 96.6842105263158,
  St: 9.894736842105264,
  Nt: 0.0,
  Jt: 361.10526315789474,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 14,
  TimeNow: 259.10526315789474,
  S: 1.5789473684210527,
  N: 0.0,
  J: 97.42105263157895,
  St: 6.842105263157895,
  Nt: 0.0,
  Jt: 364.1578947368421,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 13,
  TimeNow: 153.04,
  S: 6.0,
  N: 0.0,
  J: 93.0,
  St: 23.44,
  Nt: 0.0,
  Jt: 347.56,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 12,
  TimeNow: 132.65,
  S: 2.7,
  N: 0.0,
  J: 96.3,
  St: 11.7,
  Nt: 0.0,
  Jt: 359.3,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 2,
  TimeNow: 54.84615384615385,
  S: 5.3076923076923075,
  N: 68.3076923076923,
  J: 25.384615384615383,
  St: 20.53846153846154,
  Nt: 255.23076923076923,
  Jt: 95.23076923076923,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 6,
  TimeNow: 47.0,
  S: 27.0,
  N: 70.5,
  J: 1.5,
  St: 101.0,
  Nt: 263.5,
  Jt: 6.5,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 10,
  TimeNow: 124.53333333333333,
  S: 6.8,
  N: 0.0,
  J: 92.2,
  St: 26.866666666666667,
  Nt: 0.0,
  Jt: 344.1333333333333,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 23,
  TimeNow: 34.69230769230769,
  S: 0.6923076923076923,
  N: 98.3076923076923,
  J: 0.0,
  St: 3.0,
  Nt: 368.0,
  Jt: 0.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 4,
  TimeNow: 33.90909090909091,
  S: 0.8181818181818182,
  N: 98.18181818181819,
  J: 0.0,
  St: 3.5454545454545454,
  Nt: 367.45454545454544,
  Jt: 0.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 3,
  TimeNow: 36.5,
  S: 3.0,
  N: 96.0,
  J: 0.0,
  St: 13.0,
  Nt: 358.0,
  Jt: 0.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 9,
  TimeNow: 493.54545454545456,
  S: 11.636363636363637,
  N: 81.63636363636364,
  J: 4.818181818181818,
  St: 910.9090909090909,
  Nt: 6166.727272727273,
  Jt: 414.3636363636364,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 18,
  TimeNow: 584.40625,
  S: 16.71875,
  N: 71.71875,
  J: 10.21875,
  St: 1294.09375,
  Nt: 5404.875,
  Jt: 793.03125,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 21,
  TimeNow: 581.2307692307693,
  S: 24.76923076923077,
  N: 67.61538461538461,
  J: 6.153846153846154,
  St: 1890.8461538461538,
  Nt: 5106.307692307692,
  Jt: 494.84615384615387,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 1,
  TimeNow: 391.0769230769231,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 2.269230769230769,
  Nt: 7489.7307692307695,
  Jt: 0.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 0,
  TimeNow: 418.1111111111111,
  S: 0.5555555555555556,
  N: 97.83333333333333,
  J: 0.5555555555555556,
  St: 51.44444444444444,
  Nt: 7397.777777777777,
  Jt: 42.77777777777778,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 15,
  TimeNow: 1212.7,
  S: 8.55,
  N: 43.4,
  J: 46.35,
  St: 683.5,
  Nt: 3289.6,
  Jt: 3518.9,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 17,
  TimeNow: 626.40625,
  S: 15.21875,
  N: 72.3125,
  J: 10.875,
  St: 1182.1875,
  Nt: 5459.375,
  Jt: 850.4375,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 7,
  TimeNow: 679.8571428571429,
  S: 12.214285714285714,
  N: 75.0,
  J: 11.214285714285714,
  St: 952.0,
  Nt: 5660.071428571428,
  Jt: 879.9285714285714,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 11,
  TimeNow: 1617.8125,
  S: 21.25,
  N: 30.0625,
  J: 47.3125,
  St: 1635.0,
  Nt: 2283.125,
  Jt: 3573.875,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 20,
  TimeNow: 554.5714285714286,
  S: 33.57142857142857,
  N: 60.67857142857143,
  J: 4.464285714285714,
  St: 2548.9285714285716,
  Nt: 4580.607142857143,
  Jt: 362.4642857142857,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 8,
  TimeNow: 549.8181818181819,
  S: 13.090909090909092,
  N: 80.63636363636364,
  J: 5.0,
  St: 1017.5454545454545,
  Nt: 6071.272727272727,
  Jt: 403.1818181818182,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 22,
  TimeNow: 467.0769230769231,
  S: 9.615384615384615,
  N: 89.38461538461539,
  J: 0.0,
  St: 759.9230769230769,
  Nt: 6732.076923076923,
  Jt: 0.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 19,
  TimeNow: 562.4324324324324,
  S: 20.783783783783782,
  N: 67.54054054054055,
  J: 10.216216216216216,
  St: 1595.2432432432433,
  Nt: 5103.189189189189,
  Jt: 793.5675675675676,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 16,
  TimeNow: 983.4736842105264,
  S: 17.0,
  N: 51.473684210526315,
  J: 30.0,
  St: 1316.9473684210527,
  Nt: 3891.0,
  Jt: 2284.0526315789475,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 14,
  TimeNow: 743.1578947368421,
  S: 19.94736842105263,
  N: 56.31578947368421,
  J: 22.36842105263158,
  St: 1534.3684210526317,
  Nt: 4255.894736842105,
  Jt: 1701.7368421052631,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 13,
  TimeNow: 1010.72,
  S: 25.44,
  N: 41.16,
  J: 31.92,
  St: 1950.48,
  Nt: 3122.72,
  Jt: 2418.8,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 12,
  TimeNow: 1134.15,
  S: 25.65,
  N: 37.25,
  J: 35.45,
  St: 1955.15,
  Nt: 2839.75,
  Jt: 2697.1,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 2,
  TimeNow: 370.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7492.0,
  Jt: 0.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 6,
  TimeNow: 538.5,
  S: 0.0,
  N: 89.5,
  J: 9.0,
  St: 29.0,
  Nt: 6780.0,
  Jt: 683.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 10,
  TimeNow: 1357.3333333333333,
  S: 8.733333333333333,
  N: 55.666666666666664,
  J: 34.2,
  St: 687.7333333333333,
  Nt: 4200.866666666667,
  Jt: 2603.4,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 23,
  TimeNow: 425.46153846153845,
  S: 0.5384615384615384,
  N: 98.46153846153847,
  J: 0.0,
  St: 64.0,
  Nt: 7428.0,
  Jt: 0.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 4,
  TimeNow: 363.72727272727275,
  S: 0.36363636363636365,
  N: 98.63636363636364,
  J: 0.0,
  St: 38.72727272727273,
  Nt: 7453.272727272727,
  Jt: 0.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 3,
  TimeNow: 364.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 16.5,
  Nt: 7475.5,
  Jt: 0.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 9,
  TimeNow: 473.27272727272725,
  S: 42.72727272727273,
  N: 21.363636363636363,
  J: 34.45454545454545,
  St: 2088.0,
  Nt: 1049.3636363636363,
  Jt: 1697.3636363636363,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 18,
  TimeNow: 315.625,
  S: 24.84375,
  N: 60.5,
  J: 13.0625,
  St: 1230.09375,
  Nt: 2950.46875,
  Jt: 652.21875,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 21,
  TimeNow: 366.0769230769231,
  S: 54.92307692307692,
  N: 28.0,
  J: 15.76923076923077,
  St: 2673.5384615384614,
  Nt: 1377.6153846153845,
  Jt: 781.2307692307693,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 1,
  TimeNow: 192.52,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 4832.0,
  Jt: 0.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 0,
  TimeNow: 214.22222222222223,
  S: 2.2222222222222223,
  N: 95.05555555555556,
  J: 1.6666666666666667,
  St: 109.77777777777777,
  Nt: 4639.5,
  Jt: 83.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 15,
  TimeNow: 616.1,
  S: 22.8,
  N: 11.15,
  J: 64.35,
  St: 1128.2,
  Nt: 565.75,
  Jt: 3143.05,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 17,
  TimeNow: 493.96875,
  S: 33.96875,
  N: 21.21875,
  J: 43.1875,
  St: 1671.3125,
  Nt: 1052.40625,
  Jt: 2113.125,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 7,
  TimeNow: 714.9285714285714,
  S: 15.5,
  N: 7.142857142857143,
  J: 76.21428571428571,
  St: 766.0714285714286,
  Nt: 353.64285714285717,
  Jt: 3714.0714285714284,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 11,
  TimeNow: 358.625,
  S: 32.4375,
  N: 52.5625,
  J: 13.875,
  St: 1587.0625,
  Nt: 2555.3125,
  Jt: 689.625,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 20,
  TimeNow: 326.39285714285717,
  S: 44.785714285714285,
  N: 46.785714285714285,
  J: 7.071428571428571,
  St: 2191.8928571428573,
  Nt: 2287.285714285714,
  Jt: 352.82142857142856,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 8,
  TimeNow: 587.7272727272727,
  S: 24.636363636363637,
  N: 23.90909090909091,
  J: 50.18181818181818,
  St: 1207.2727272727273,
  Nt: 1169.909090909091,
  Jt: 2458.909090909091,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 22,
  TimeNow: 266.9230769230769,
  S: 27.76923076923077,
  N: 70.23076923076923,
  J: 0.9230769230769231,
  St: 1354.1538461538462,
  Nt: 3431.846153846154,
  Jt: 46.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 19,
  TimeNow: 303.64864864864865,
  S: 26.7027027027027,
  N: 63.945945945945944,
  J: 8.162162162162161,
  St: 1316.2432432432433,
  Nt: 3107.891891891892,
  Jt: 407.86486486486484,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 16,
  TimeNow: 525.0,
  S: 28.63157894736842,
  N: 16.842105263157894,
  J: 53.05263157894737,
  St: 1401.6842105263158,
  Nt: 839.7368421052631,
  Jt: 2595.5789473684213,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 14,
  TimeNow: 661.6315789473684,
  S: 22.31578947368421,
  N: 9.263157894736842,
  J: 67.0,
  St: 1101.2631578947369,
  Nt: 466.1578947368421,
  Jt: 3269.5789473684213,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 13,
  TimeNow: 622.2,
  S: 24.32,
  N: 11.16,
  J: 63.0,
  St: 1204.52,
  Nt: 555.28,
  Jt: 3076.8,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 12,
  TimeNow: 467.35,
  S: 54.05,
  N: 10.7,
  J: 34.0,
  St: 2631.5,
  Nt: 526.8,
  Jt: 1674.45,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 2,
  TimeNow: 186.92307692307693,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 4832.0,
  Jt: 0.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 6,
  TimeNow: 324.0,
  S: 26.0,
  N: 49.5,
  J: 23.0,
  St: 1280.0,
  Nt: 2416.5,
  Jt: 1135.5,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 10,
  TimeNow: 350.6,
  S: 32.86666666666667,
  N: 50.46666666666667,
  J: 15.333333333333334,
  St: 1609.7333333333333,
  Nt: 2460.4,
  Jt: 761.8666666666667,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 23,
  TimeNow: 208.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 4832.0,
  Jt: 0.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 4,
  TimeNow: 192.0909090909091,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 4832.0,
  Jt: 0.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 3,
  TimeNow: 190.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 4832.0,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 9,
  TimeNow: 240.54545454545453,
  S: 11.727272727272727,
  N: 86.18181818181819,
  J: 0.8181818181818182,
  St: 270.3636363636364,
  Nt: 1942.909090909091,
  Jt: 24.727272727272727,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 18,
  TimeNow: 253.53125,
  S: 17.40625,
  N: 80.5,
  J: 0.625,
  St: 403.125,
  Nt: 1814.8125,
  Jt: 20.5,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 21,
  TimeNow: 254.0,
  S: 19.153846153846153,
  N: 79.53846153846153,
  J: 0.23076923076923078,
  St: 442.0,
  Nt: 1790.3846153846155,
  Jt: 5.3076923076923075,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 1,
  TimeNow: 251.07692307692307,
  S: 24.423076923076923,
  N: 74.57692307692308,
  J: 0.0,
  St: 554.6923076923077,
  Nt: 1683.6923076923076,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 0,
  TimeNow: 264.0,
  S: 38.611111111111114,
  N: 60.388888888888886,
  J: 0.0,
  St: 880.2777777777778,
  Nt: 1358.2777777777778,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 15,
  TimeNow: 243.8,
  S: 9.5,
  N: 87.5,
  J: 1.6,
  St: 222.55,
  Nt: 1970.65,
  Jt: 44.85,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 17,
  TimeNow: 270.1875,
  S: 17.75,
  N: 77.875,
  J: 3.03125,
  St: 410.125,
  Nt: 1753.84375,
  Jt: 74.375,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 7,
  TimeNow: 237.21428571428572,
  S: 11.571428571428571,
  N: 86.57142857142857,
  J: 0.5,
  St: 269.92857142857144,
  Nt: 1951.0714285714287,
  Jt: 17.071428571428573,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 11,
  TimeNow: 232.75,
  S: 6.125,
  N: 92.25,
  J: 0.25,
  St: 148.25,
  Nt: 2077.5,
  Jt: 12.3125,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 20,
  TimeNow: 256.2857142857143,
  S: 17.071428571428573,
  N: 80.35714285714286,
  J: 1.3214285714285714,
  St: 394.9642857142857,
  Nt: 1809.357142857143,
  Jt: 33.892857142857146,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 8,
  TimeNow: 237.54545454545453,
  S: 6.090909090909091,
  N: 90.54545454545455,
  J: 1.7272727272727273,
  St: 148.8181818181818,
  Nt: 2041.2727272727273,
  Jt: 47.90909090909091,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 22,
  TimeNow: 237.6153846153846,
  S: 9.461538461538462,
  N: 89.46153846153847,
  J: 0.0,
  St: 219.30769230769232,
  Nt: 2018.7692307692307,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 19,
  TimeNow: 271.7837837837838,
  S: 14.135135135135135,
  N: 82.51351351351352,
  J: 2.108108108108108,
  St: 325.4864864864865,
  Nt: 1860.7567567567567,
  Jt: 52.45945945945946,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 16,
  TimeNow: 268.63157894736844,
  S: 12.368421052631579,
  N: 81.52631578947368,
  J: 4.894736842105263,
  St: 285.42105263157896,
  Nt: 1839.3684210526317,
  Jt: 113.52631578947368,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 14,
  TimeNow: 259.10526315789474,
  S: 12.157894736842104,
  N: 83.78947368421052,
  J: 2.6842105263157894,
  St: 281.89473684210526,
  Nt: 1889.6315789473683,
  Jt: 66.84210526315789,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 13,
  TimeNow: 241.12,
  S: 10.12,
  N: 88.64,
  J: 0.08,
  St: 233.76,
  Nt: 1997.72,
  Jt: 6.8,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 12,
  TimeNow: 245.2,
  S: 10.85,
  N: 87.1,
  J: 0.8,
  St: 253.5,
  Nt: 1962.05,
  Jt: 22.55,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 2,
  TimeNow: 227.46153846153845,
  S: 3.3076923076923075,
  N: 95.6923076923077,
  J: 0.0,
  St: 85.46153846153847,
  Nt: 2152.5384615384614,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 6,
  TimeNow: 236.0,
  S: 11.0,
  N: 88.0,
  J: 0.0,
  St: 259.0,
  Nt: 1979.0,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 10,
  TimeNow: 241.46666666666667,
  S: 6.333333333333333,
  N: 89.26666666666667,
  J: 2.933333333333333,
  St: 156.13333333333333,
  Nt: 2009.2,
  Jt: 72.73333333333333,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 23,
  TimeNow: 244.69230769230768,
  S: 13.153846153846153,
  N: 85.76923076923077,
  J: 0.0,
  St: 304.15384615384613,
  Nt: 1933.923076923077,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 4,
  TimeNow: 234.36363636363637,
  S: 1.1818181818181819,
  N: 97.81818181818181,
  J: 0.0,
  St: 38.54545454545455,
  Nt: 2199.4545454545455,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 3,
  TimeNow: 231.5,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 34.0,
  Nt: 2204.0,
  Jt: 0.0,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 9,
  TimeNow: 229.0,
  S: 3.3636363636363638,
  N: 93.27272727272727,
  J: 2.272727272727273,
  St: 93.45454545454545,
  Nt: 2337.0,
  Jt: 61.54545454545455,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 18,
  TimeNow: 557.25,
  S: 12.90625,
  N: 57.15625,
  J: 28.375,
  St: 334.21875,
  Nt: 1440.65625,
  Jt: 718.3125,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 21,
  TimeNow: 291.38461538461536,
  S: 14.692307692307692,
  N: 80.53846153846153,
  J: 3.6153846153846154,
  St: 379.7692307692308,
  Nt: 2017.076923076923,
  Jt: 96.0,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 1,
  TimeNow: 188.03846153846155,
  S: 0.6923076923076923,
  N: 98.3076923076923,
  J: 0.0,
  St: 24.23076923076923,
  Nt: 2467.769230769231,
  Jt: 0.0,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 0,
  TimeNow: 210.55555555555554,
  S: 0.7222222222222222,
  N: 93.38888888888889,
  J: 4.833333333333333,
  St: 25.0,
  Nt: 2345.222222222222,
  Jt: 121.83333333333333,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 15,
  TimeNow: 269.4,
  S: 29.45,
  N: 67.45,
  J: 1.9,
  St: 749.2,
  Nt: 1692.6,
  Jt: 50.9,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 17,
  TimeNow: 582.375,
  S: 22.34375,
  N: 21.625,
  J: 54.1875,
  St: 571.53125,
  Nt: 555.1875,
  Jt: 1366.6875,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 7,
  TimeNow: 204.85714285714286,
  S: 1.2142857142857142,
  N: 96.0,
  J: 1.7142857142857142,
  St: 34.92857142857143,
  Nt: 2411.214285714286,
  Jt: 45.857142857142854,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 11,
  TimeNow: 240.0,
  S: 7.625,
  N: 89.9375,
  J: 1.25,
  St: 202.6875,
  Nt: 2255.9375,
  Jt: 33.4375,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 20,
  TimeNow: 295.92857142857144,
  S: 12.0,
  N: 81.07142857142857,
  J: 5.428571428571429,
  St: 314.89285714285717,
  Nt: 2035.0357142857142,
  Jt: 142.96428571428572,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 8,
  TimeNow: 216.1818181818182,
  S: 1.6363636363636365,
  N: 95.54545454545455,
  J: 1.8181818181818181,
  St: 48.45454545454545,
  Nt: 2394.909090909091,
  Jt: 48.63636363636363,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 22,
  TimeNow: 235.07692307692307,
  S: 9.692307692307692,
  N: 88.0,
  J: 1.1538461538461537,
  St: 256.46153846153845,
  Nt: 2204.153846153846,
  Jt: 31.615384615384617,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 19,
  TimeNow: 319.05405405405406,
  S: 12.837837837837839,
  N: 79.78378378378379,
  J: 5.72972972972973,
  St: 337.02702702702703,
  Nt: 2005.4864864864865,
  Jt: 150.24324324324326,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 16,
  TimeNow: 577.5263157894736,
  S: 20.68421052631579,
  N: 29.57894736842105,
  J: 48.05263157894737,
  St: 529.5263157894736,
  Nt: 752.6315789473684,
  Jt: 1211.1052631578948,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 14,
  TimeNow: 250.26315789473685,
  S: 9.526315789473685,
  N: 87.10526315789474,
  J: 2.1578947368421053,
  St: 247.52631578947367,
  Nt: 2186.2631578947367,
  Jt: 58.473684210526315,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 13,
  TimeNow: 276.08,
  S: 9.72,
  N: 85.84,
  J: 3.2,
  St: 257.6,
  Nt: 2149.8,
  Jt: 84.92,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 12,
  TimeNow: 240.65,
  S: 10.9,
  N: 86.7,
  J: 1.25,
  St: 286.45,
  Nt: 2171.5,
  Jt: 34.05,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 2,
  TimeNow: 182.92307692307693,
  S: 0.15384615384615385,
  N: 98.3076923076923,
  J: 0.5384615384615384,
  St: 5.384615384615385,
  Nt: 2467.769230769231,
  Jt: 18.846153846153847,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 6,
  TimeNow: 184.0,
  S: 3.0,
  N: 96.0,
  J: 0.0,
  St: 85.0,
  Nt: 2407.0,
  Jt: 0.0,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 10,
  TimeNow: 237.66666666666666,
  S: 5.2,
  N: 93.06666666666666,
  J: 0.7333333333333333,
  St: 140.13333333333333,
  Nt: 2330.6,
  Jt: 21.266666666666666,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 23,
  TimeNow: 211.0,
  S: 6.076923076923077,
  N: 92.53846153846153,
  J: 0.38461538461538464,
  St: 164.23076923076923,
  Nt: 2317.076923076923,
  Jt: 10.923076923076923,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 4,
  TimeNow: 177.63636363636363,
  S: 0.0,
  N: 98.0,
  J: 1.0,
  St: 1.0,
  Nt: 2456.0,
  Jt: 35.0,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 3,
  TimeNow: 192.5,
  S: 3.5,
  N: 95.0,
  J: 0.5,
  St: 90.5,
  Nt: 2384.0,
  Jt: 17.5,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 9,
  TimeNow: 1298.5454545454545,
  S: 41.72727272727273,
  N: 15.909090909090908,
  J: 40.72727272727273,
  St: 5548.454545454545,
  Nt: 2174.2727272727275,
  Jt: 5437.272727272727,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 18,
  TimeNow: 752.6875,
  S: 17.59375,
  N: 74.5625,
  J: 6.4375,
  St: 2386.84375,
  Nt: 9868.78125,
  Jt: 904.375,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 21,
  TimeNow: 775.6923076923077,
  S: 35.46153846153846,
  N: 58.53846153846154,
  J: 4.615384615384615,
  St: 4729.307692307692,
  Nt: 7768.923076923077,
  Jt: 661.7692307692307,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 1,
  TimeNow: 504.7692307692308,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 13160.0,
  Jt: 0.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 0,
  TimeNow: 543.2222222222222,
  S: 1.9444444444444444,
  N: 95.61111111111111,
  J: 1.3888888888888888,
  St: 261.6111111111111,
  Nt: 12710.055555555555,
  Jt: 188.33333333333334,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 15,
  TimeNow: 1490.45,
  S: 17.35,
  N: 28.1,
  J: 52.9,
  St: 2364.8,
  Nt: 3762.3,
  Jt: 7032.9,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 17,
  TimeNow: 1152.46875,
  S: 24.03125,
  N: 39.21875,
  J: 35.21875,
  St: 3236.875,
  Nt: 5224.15625,
  Jt: 4698.96875,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 7,
  TimeNow: 1211.7857142857142,
  S: 17.071428571428573,
  N: 44.42857142857143,
  J: 37.142857142857146,
  St: 2320.785714285714,
  Nt: 5900.857142857143,
  Jt: 4938.357142857143,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 11,
  TimeNow: 1144.8125,
  S: 17.375,
  N: 63.375,
  J: 17.4375,
  St: 2380.25,
  Nt: 8407.125,
  Jt: 2372.625,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 20,
  TimeNow: 710.2857142857143,
  S: 23.821428571428573,
  N: 74.17857142857143,
  J: 0.8571428571428571,
  St: 3216.8571428571427,
  Nt: 9814.142857142857,
  Jt: 129.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 8,
  TimeNow: 1335.6363636363637,
  S: 31.818181818181817,
  N: 23.0,
  J: 43.90909090909091,
  St: 4237.818181818182,
  Nt: 3104.181818181818,
  Jt: 5818.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 22,
  TimeNow: 626.1538461538462,
  S: 13.76923076923077,
  N: 85.23076923076923,
  J: 0.0,
  St: 1889.923076923077,
  Nt: 11270.076923076924,
  Jt: 0.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 19,
  TimeNow: 683.7297297297297,
  S: 14.567567567567568,
  N: 83.16216216216216,
  J: 1.1891891891891893,
  St: 1982.4594594594594,
  Nt: 11001.216216216217,
  Jt: 176.32432432432432,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 16,
  TimeNow: 1363.2105263157894,
  S: 26.105263157894736,
  N: 30.789473684210527,
  J: 41.473684210526315,
  St: 3509.2631578947367,
  Nt: 4108.789473684211,
  Jt: 5541.9473684210525,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 14,
  TimeNow: 1727.7894736842106,
  S: 14.842105263157896,
  N: 20.05263157894737,
  J: 63.63157894736842,
  St: 2032.5263157894738,
  Nt: 2701.5789473684213,
  Jt: 8425.894736842105,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 13,
  TimeNow: 1659.56,
  S: 19.52,
  N: 20.28,
  J: 58.8,
  St: 2624.84,
  Nt: 2747.88,
  Jt: 7787.28,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 12,
  TimeNow: 1247.7,
  S: 44.5,
  N: 29.3,
  J: 24.85,
  St: 5923.6,
  Nt: 3915.95,
  Jt: 3320.45,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 2,
  TimeNow: 494.53846153846155,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 13160.0,
  Jt: 0.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 6,
  TimeNow: 682.0,
  S: 18.0,
  N: 73.5,
  J: 7.0,
  St: 2370.0,
  Nt: 9803.5,
  Jt: 986.5,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 10,
  TimeNow: 1243.4666666666667,
  S: 23.466666666666665,
  N: 49.06666666666667,
  J: 26.0,
  St: 3141.9333333333334,
  Nt: 6525.466666666666,
  Jt: 3492.6,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 23,
  TimeNow: 541.3076923076923,
  S: 0.15384615384615385,
  N: 98.84615384615384,
  J: 0.0,
  St: 23.846153846153847,
  Nt: 13136.153846153846,
  Jt: 0.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 4,
  TimeNow: 498.27272727272725,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 13160.0,
  Jt: 0.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 3,
  TimeNow: 499.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 13160.0,
  Jt: 0.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 9,
  TimeNow: 708.7272727272727,
  S: 13.818181818181818,
  N: 80.81818181818181,
  J: 3.8181818181818183,
  St: 1253.0,
  Nt: 7130.636363636364,
  Jt: 385.3636363636364,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 18,
  TimeNow: 859.15625,
  S: 19.375,
  N: 62.96875,
  J: 16.21875,
  St: 1739.5625,
  Nt: 5563.0,
  Jt: 1466.4375,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 21,
  TimeNow: 913.0,
  S: 15.153846153846153,
  N: 66.15384615384616,
  J: 17.23076923076923,
  St: 1376.7692307692307,
  Nt: 5839.692307692308,
  Jt: 1552.5384615384614,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 1,
  TimeNow: 512.3461538461538,
  S: 0.7307692307692307,
  N: 98.26923076923077,
  J: 0.0,
  St: 100.38461538461539,
  Nt: 8668.615384615385,
  Jt: 0.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 0,
  TimeNow: 549.7777777777778,
  S: 3.2222222222222223,
  N: 93.38888888888889,
  J: 2.2222222222222223,
  St: 325.05555555555554,
  Nt: 8222.944444444445,
  Jt: 221.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 15,
  TimeNow: 759.3,
  S: 15.65,
  N: 76.25,
  J: 6.35,
  St: 1416.4,
  Nt: 6736.15,
  Jt: 616.45,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 17,
  TimeNow: 943.25,
  S: 30.15625,
  N: 44.28125,
  J: 24.125,
  St: 2689.8125,
  Nt: 3928.3125,
  Jt: 2150.875,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 7,
  TimeNow: 664.4285714285714,
  S: 13.5,
  N: 80.28571428571429,
  J: 4.642857142857143,
  St: 1231.0714285714287,
  Nt: 7088.642857142857,
  Jt: 449.2857142857143,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 11,
  TimeNow: 851.25,
  S: 14.0625,
  N: 73.3125,
  J: 11.3125,
  St: 1264.9375,
  Nt: 6472.5625,
  Jt: 1031.5,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 20,
  TimeNow: 820.5714285714286,
  S: 18.357142857142858,
  N: 68.25,
  J: 11.785714285714286,
  St: 1659.4642857142858,
  Nt: 6026.214285714285,
  Jt: 1083.3214285714287,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 8,
  TimeNow: 582.9090909090909,
  S: 4.090909090909091,
  N: 92.9090909090909,
  J: 1.8181818181818181,
  St: 403.54545454545456,
  Nt: 8179.272727272727,
  Jt: 186.1818181818182,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 22,
  TimeNow: 620.7692307692307,
  S: 8.307692307692308,
  N: 87.6923076923077,
  J: 2.5384615384615383,
  St: 784.4615384615385,
  Nt: 7730.7692307692305,
  Jt: 253.76923076923077,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 19,
  TimeNow: 715.972972972973,
  S: 18.945945945945947,
  N: 72.91891891891892,
  J: 6.54054054054054,
  St: 1708.945945945946,
  Nt: 6440.135135135135,
  Jt: 619.918918918919,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 16,
  TimeNow: 872.8947368421053,
  S: 29.42105263157895,
  N: 54.421052631578945,
  J: 14.789473684210526,
  St: 2617.4736842105262,
  Nt: 4813.1578947368425,
  Jt: 1338.3684210526317,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 14,
  TimeNow: 786.6315789473684,
  S: 23.789473684210527,
  N: 66.05263157894737,
  J: 8.68421052631579,
  St: 2121.0,
  Nt: 5830.0,
  Jt: 818.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 13,
  TimeNow: 917.12,
  S: 32.12,
  N: 52.16,
  J: 14.04,
  St: 2871.0,
  Nt: 4615.36,
  Jt: 1282.64,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 12,
  TimeNow: 1022.95,
  S: 38.9,
  N: 37.0,
  J: 22.55,
  St: 3463.55,
  Nt: 3282.05,
  Jt: 2023.4,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 2,
  TimeNow: 472.53846153846155,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 32.46153846153846,
  Nt: 8736.538461538461,
  Jt: 0.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 6,
  TimeNow: 588.5,
  S: 11.0,
  N: 85.5,
  J: 2.0,
  St: 992.5,
  Nt: 7574.0,
  Jt: 202.5,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 10,
  TimeNow: 640.0666666666667,
  S: 3.1333333333333333,
  N: 92.0,
  J: 3.0,
  St: 320.53333333333336,
  Nt: 8118.066666666667,
  Jt: 330.4,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 23,
  TimeNow: 523.9230769230769,
  S: 0.38461538461538464,
  N: 98.46153846153847,
  J: 0.07692307692307693,
  St: 61.30769230769231,
  Nt: 8700.538461538461,
  Jt: 7.153846153846154,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 4,
  TimeNow: 469.45454545454544,
  S: 0.5454545454545454,
  N: 98.45454545454545,
  J: 0.0,
  St: 76.0909090909091,
  Nt: 8692.90909090909,
  Jt: 0.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 3,
  TimeNow: 466.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 8769.0,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 9,
  TimeNow: 201.63636363636363,
  S: 6.2727272727272725,
  N: 92.45454545454545,
  J: 0.0,
  St: 144.63636363636363,
  Nt: 1928.3636363636363,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 18,
  TimeNow: 258.61290322580646,
  S: 6.774193548387097,
  N: 88.16129032258064,
  J: 3.967741935483871,
  St: 148.16129032258064,
  Nt: 1838.6451612903227,
  Jt: 86.19354838709677,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 21,
  TimeNow: 213.30769230769232,
  S: 7.769230769230769,
  N: 91.23076923076923,
  J: 0.0,
  St: 176.15384615384616,
  Nt: 1896.8461538461538,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 1,
  TimeNow: 206.1153846153846,
  S: 0.9230769230769231,
  N: 98.07692307692308,
  J: 0.0,
  St: 20.53846153846154,
  Nt: 2053.230769230769,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 0,
  TimeNow: 204.61111111111111,
  S: 0.2222222222222222,
  N: 98.16666666666667,
  J: 0.6111111111111112,
  St: 4.666666666666667,
  Nt: 2055.8333333333335,
  Jt: 13.444444444444445,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 15,
  TimeNow: 219.3,
  S: 14.9,
  N: 83.65,
  J: 0.1,
  St: 322.25,
  Nt: 1748.45,
  Jt: 2.6,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 17,
  TimeNow: 304.375,
  S: 6.375,
  N: 84.8125,
  J: 7.40625,
  St: 143.1875,
  Nt: 1767.78125,
  Jt: 162.03125,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 7,
  TimeNow: 215.85714285714286,
  S: 15.857142857142858,
  N: 83.14285714285714,
  J: 0.0,
  St: 332.64285714285717,
  Nt: 1740.857142857143,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 11,
  TimeNow: 194.75,
  S: 0.9375,
  N: 98.0,
  J: 0.0625,
  St: 23.6875,
  Nt: 2048.5,
  Jt: 1.5,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 20,
  TimeNow: 229.39285714285714,
  S: 7.142857142857143,
  N: 89.42857142857143,
  J: 1.8214285714285714,
  St: 164.32142857142858,
  Nt: 1869.4642857142858,
  Jt: 39.214285714285715,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 8,
  TimeNow: 198.63636363636363,
  S: 3.0,
  N: 96.0,
  J: 0.0,
  St: 68.9090909090909,
  Nt: 2004.3636363636363,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 22,
  TimeNow: 208.92307692307693,
  S: 5.384615384615385,
  N: 93.53846153846153,
  J: 0.0,
  St: 121.84615384615384,
  Nt: 1951.4615384615386,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 19,
  TimeNow: 296.97297297297297,
  S: 8.08108108108108,
  N: 82.29729729729729,
  J: 8.27027027027027,
  St: 176.21621621621622,
  Nt: 1715.2972972972973,
  Jt: 181.48648648648648,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 16,
  TimeNow: 210.26315789473685,
  S: 6.526315789473684,
  N: 91.94736842105263,
  J: 0.47368421052631576,
  St: 142.73684210526315,
  Nt: 1919.5263157894738,
  Jt: 10.894736842105264,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 14,
  TimeNow: 257.3157894736842,
  S: 49.8421052631579,
  N: 48.473684210526315,
  J: 0.631578947368421,
  St: 1040.6315789473683,
  Nt: 1017.578947368421,
  Jt: 14.894736842105264,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 13,
  TimeNow: 237.36,
  S: 37.16,
  N: 61.8,
  J: 0.0,
  St: 778.96,
  Nt: 1294.2,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 12,
  TimeNow: 225.9,
  S: 26.65,
  N: 72.2,
  J: 0.15,
  St: 560.45,
  Nt: 1508.85,
  Jt: 3.9,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 2,
  TimeNow: 191.84615384615384,
  S: 0.23076923076923078,
  N: 98.76923076923077,
  J: 0.0,
  St: 5.538461538461538,
  Nt: 2068.230769230769,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 6,
  TimeNow: 197.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 2074.0,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 10,
  TimeNow: 201.73333333333332,
  S: 4.333333333333333,
  N: 94.26666666666667,
  J: 0.0,
  St: 105.0,
  Nt: 1968.1333333333334,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 23,
  TimeNow: 206.3846153846154,
  S: 3.769230769230769,
  N: 95.23076923076923,
  J: 0.0,
  St: 91.0,
  Nt: 1982.1538461538462,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 4,
  TimeNow: 211.45454545454547,
  S: 0.7272727272727273,
  N: 98.27272727272727,
  J: 0.0,
  St: 18.181818181818183,
  Nt: 2055.4545454545455,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 3,
  TimeNow: 197.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 2074.0,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 9,
  TimeNow: 136.27272727272728,
  S: 19.363636363636363,
  N: 71.0909090909091,
  J: 8.454545454545455,
  St: 389.6363636363636,
  Nt: 1393.2727272727273,
  Jt: 166.0909090909091,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 18,
  TimeNow: 239.96875,
  S: 33.53125,
  N: 25.125,
  J: 40.125,
  St: 661.8125,
  Nt: 497.25,
  Jt: 790.15625,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 21,
  TimeNow: 167.92307692307693,
  S: 41.23076923076923,
  N: 44.61538461538461,
  J: 12.846153846153847,
  St: 815.3076923076923,
  Nt: 878.7692307692307,
  Jt: 255.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 1,
  TimeNow: 94.42307692307692,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1949.0,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 0,
  TimeNow: 110.27777777777777,
  S: 0.8333333333333334,
  N: 96.0,
  J: 2.1666666666666665,
  St: 16.333333333333332,
  Nt: 1890.111111111111,
  Jt: 42.611111111111114,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 15,
  TimeNow: 461.25,
  S: 8.7,
  N: 0.6,
  J: 89.7,
  St: 177.7,
  Nt: 12.7,
  Jt: 1758.65,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 17,
  TimeNow: 290.625,
  S: 37.59375,
  N: 17.90625,
  J: 43.125,
  St: 742.65625,
  Nt: 359.15625,
  Jt: 847.53125,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 7,
  TimeNow: 119.0,
  S: 11.357142857142858,
  N: 84.07142857142857,
  J: 3.2857142857142856,
  St: 229.71428571428572,
  Nt: 1646.7857142857142,
  Jt: 72.5,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 11,
  TimeNow: 544.1875,
  S: 34.6875,
  N: 15.9375,
  J: 47.5,
  St: 689.25,
  Nt: 320.1875,
  Jt: 939.5625,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 20,
  TimeNow: 176.32142857142858,
  S: 68.96428571428571,
  N: 12.142857142857142,
  J: 17.75,
  St: 1354.857142857143,
  Nt: 243.85714285714286,
  Jt: 350.42857142857144,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 8,
  TimeNow: 122.45454545454545,
  S: 18.545454545454547,
  N: 80.45454545454545,
  J: 0.0,
  St: 370.09090909090907,
  Nt: 1578.909090909091,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 22,
  TimeNow: 119.46153846153847,
  S: 15.0,
  N: 84.0,
  J: 0.0,
  St: 299.9230769230769,
  Nt: 1649.1538461538462,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 19,
  TimeNow: 212.78378378378378,
  S: 45.75675675675676,
  N: 13.027027027027026,
  J: 40.08108108108108,
  St: 900.6216216216217,
  Nt: 259.8378378378378,
  Jt: 788.7027027027027,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 16,
  TimeNow: 285.57894736842104,
  S: 35.68421052631579,
  N: 12.842105263157896,
  J: 49.94736842105263,
  St: 707.0526315789474,
  Nt: 259.3157894736842,
  Jt: 982.8947368421053,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 14,
  TimeNow: 311.2631578947368,
  S: 32.10526315789474,
  N: 10.473684210526315,
  J: 56.21052631578947,
  St: 635.6315789473684,
  Nt: 209.78947368421052,
  Jt: 1103.6842105263158,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 13,
  TimeNow: 207.84,
  S: 52.16,
  N: 8.52,
  J: 37.8,
  St: 1025.92,
  Nt: 176.24,
  Jt: 746.88,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 12,
  TimeNow: 313.2,
  S: 51.45,
  N: 19.1,
  J: 27.75,
  St: 1012.95,
  Nt: 383.25,
  Jt: 552.95,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 2,
  TimeNow: 90.46153846153847,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1949.0,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 6,
  TimeNow: 89.0,
  S: 1.0,
  N: 97.5,
  J: 0.0,
  St: 29.0,
  Nt: 1920.0,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 10,
  TimeNow: 460.8666666666667,
  S: 17.4,
  N: 39.6,
  J: 41.4,
  St: 347.8666666666667,
  Nt: 782.6,
  Jt: 818.7333333333333,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 23,
  TimeNow: 104.6923076923077,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 2.6923076923076925,
  Nt: 1946.3076923076924,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 4,
  TimeNow: 89.0909090909091,
  S: 1.8181818181818181,
  N: 97.18181818181819,
  J: 0.0,
  St: 38.72727272727273,
  Nt: 1910.2727272727273,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 3,
  TimeNow: 87.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1949.0,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 9,
  TimeNow: 409.90909090909093,
  S: 43.45454545454545,
  N: 23.636363636363637,
  J: 31.181818181818183,
  St: 2247.2727272727275,
  Nt: 1231.5454545454545,
  Jt: 1620.1818181818182,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 18,
  TimeNow: 719.21875,
  S: 14.875,
  N: 0.0,
  J: 84.125,
  St: 782.5625,
  Nt: 0.0,
  Jt: 4316.4375,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 21,
  TimeNow: 493.2307692307692,
  S: 25.846153846153847,
  N: 8.23076923076923,
  J: 64.15384615384616,
  St: 1350.7692307692307,
  Nt: 450.7692307692308,
  Jt: 3297.4615384615386,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 1,
  TimeNow: 202.96153846153845,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5099.0,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 0,
  TimeNow: 268.3333333333333,
  S: 20.333333333333332,
  N: 67.0,
  J: 11.555555555555555,
  St: 1052.6666666666667,
  Nt: 3448.722222222222,
  Jt: 597.6111111111111,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 15,
  TimeNow: 735.05,
  S: 21.45,
  N: 4.1,
  J: 73.25,
  St: 1117.2,
  Nt: 219.0,
  Jt: 3765.6,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 17,
  TimeNow: 653.34375,
  S: 18.875,
  N: 3.75,
  J: 76.28125,
  St: 985.71875,
  Nt: 195.625,
  Jt: 3917.65625,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 7,
  TimeNow: 295.57142857142856,
  S: 26.071428571428573,
  N: 69.14285714285714,
  J: 3.642857142857143,
  St: 1344.5,
  Nt: 3560.3571428571427,
  Jt: 194.14285714285714,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 11,
  TimeNow: 476.9375,
  S: 36.6875,
  N: 13.8125,
  J: 48.0,
  St: 1901.25,
  Nt: 727.0,
  Jt: 2470.75,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 20,
  TimeNow: 549.3214285714286,
  S: 21.857142857142858,
  N: 4.5,
  J: 72.21428571428571,
  St: 1140.392857142857,
  Nt: 248.78571428571428,
  Jt: 3709.8214285714284,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 8,
  TimeNow: 534.8181818181819,
  S: 23.90909090909091,
  N: 16.09090909090909,
  J: 58.36363636363637,
  St: 1248.909090909091,
  Nt: 843.8181818181819,
  Jt: 3006.2727272727275,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 22,
  TimeNow: 314.3076923076923,
  S: 32.0,
  N: 54.53846153846154,
  J: 12.153846153846153,
  St: 1644.8461538461538,
  Nt: 2823.3846153846152,
  Jt: 630.7692307692307,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 19,
  TimeNow: 585.3783783783783,
  S: 21.7027027027027,
  N: 4.945945945945946,
  J: 71.89189189189189,
  St: 1137.4054054054054,
  Nt: 268.81081081081084,
  Jt: 3692.7837837837837,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 16,
  TimeNow: 806.6842105263158,
  S: 18.36842105263158,
  N: 2.9473684210526314,
  J: 77.42105263157895,
  St: 962.2631578947369,
  Nt: 164.52631578947367,
  Jt: 3975.157894736842,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 14,
  TimeNow: 625.1578947368421,
  S: 28.473684210526315,
  N: 6.315789473684211,
  J: 64.0,
  St: 1484.6842105263158,
  Nt: 337.8421052631579,
  Jt: 3279.0526315789475,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 13,
  TimeNow: 474.44,
  S: 39.0,
  N: 14.44,
  J: 45.2,
  St: 2012.08,
  Nt: 762.76,
  Jt: 2324.16,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 12,
  TimeNow: 445.05,
  S: 34.9,
  N: 22.7,
  J: 41.05,
  St: 1805.55,
  Nt: 1176.1,
  Jt: 2117.35,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 2,
  TimeNow: 195.92307692307693,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5099.0,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 6,
  TimeNow: 215.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5099.0,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 10,
  TimeNow: 403.4,
  S: 46.53333333333333,
  N: 28.0,
  J: 23.866666666666667,
  St: 2396.9333333333334,
  Nt: 1457.4,
  Jt: 1244.6666666666667,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 23,
  TimeNow: 230.0,
  S: 2.923076923076923,
  N: 96.07692307692308,
  J: 0.0,
  St: 155.07692307692307,
  Nt: 4943.923076923077,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 4,
  TimeNow: 191.36363636363637,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5099.0,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 3,
  TimeNow: 202.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5099.0,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 9,
  TimeNow: 297.54545454545456,
  S: 25.818181818181817,
  N: 67.27272727272727,
  J: 5.090909090909091,
  St: 682.1818181818181,
  Nt: 1744.5454545454545,
  Jt: 147.45454545454547,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 18,
  TimeNow: 247.59375,
  S: 23.875,
  N: 73.125,
  J: 1.65625,
  St: 626.5,
  Nt: 1901.09375,
  Jt: 48.15625,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 21,
  TimeNow: 254.76923076923077,
  S: 20.384615384615383,
  N: 78.38461538461539,
  J: 0.15384615384615385,
  St: 536.3846153846154,
  Nt: 2033.1538461538462,
  Jt: 5.3076923076923075,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 1,
  TimeNow: 245.0,
  S: 22.92,
  N: 76.08,
  J: 0.0,
  St: 599.52,
  Nt: 1975.76,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 0,
  TimeNow: 255.0,
  S: 32.166666666666664,
  N: 66.5,
  J: 0.3333333333333333,
  St: 841.8888888888889,
  Nt: 1724.3333333333333,
  Jt: 8.777777777777779,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 15,
  TimeNow: 341.2,
  S: 20.25,
  N: 58.3,
  J: 19.7,
  St: 532.9,
  Nt: 1517.25,
  Jt: 524.15,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 17,
  TimeNow: 269.15625,
  S: 24.3125,
  N: 67.28125,
  J: 6.75,
  St: 640.625,
  Nt: 1747.25,
  Jt: 187.09375,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 7,
  TimeNow: 214.64285714285714,
  S: 9.142857142857142,
  N: 88.71428571428571,
  J: 1.0,
  St: 248.35714285714286,
  Nt: 2297.9285714285716,
  Jt: 28.785714285714285,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 11,
  TimeNow: 345.8125,
  S: 14.75,
  N: 62.375,
  J: 21.375,
  St: 390.9375,
  Nt: 1620.5625,
  Jt: 562.625,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 20,
  TimeNow: 261.2142857142857,
  S: 27.821428571428573,
  N: 68.03571428571429,
  J: 2.7142857142857144,
  St: 729.2142857142857,
  Nt: 1768.392857142857,
  Jt: 77.53571428571429,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 8,
  TimeNow: 261.09090909090907,
  S: 12.545454545454545,
  N: 80.81818181818181,
  J: 4.7272727272727275,
  St: 340.0,
  Nt: 2095.909090909091,
  Jt: 138.9090909090909,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 22,
  TimeNow: 232.3846153846154,
  S: 7.461538461538462,
  N: 91.15384615384616,
  J: 0.0,
  St: 209.3846153846154,
  Nt: 2365.846153846154,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 19,
  TimeNow: 258.05405405405406,
  S: 25.16216216216216,
  N: 67.35135135135135,
  J: 6.216216216216216,
  St: 662.5675675675676,
  Nt: 1746.6216216216217,
  Jt: 166.1891891891892,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 16,
  TimeNow: 366.63157894736844,
  S: 23.57894736842105,
  N: 50.89473684210526,
  J: 23.736842105263158,
  St: 624.8421052631579,
  Nt: 1325.421052631579,
  Jt: 624.2631578947369,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 14,
  TimeNow: 374.36842105263156,
  S: 14.947368421052632,
  N: 60.26315789473684,
  J: 23.157894736842106,
  St: 396.7368421052632,
  Nt: 1565.9473684210527,
  Jt: 612.0526315789474,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 13,
  TimeNow: 340.0,
  S: 21.68,
  N: 56.44,
  J: 20.36,
  St: 570.68,
  Nt: 1467.88,
  Jt: 536.24,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 12,
  TimeNow: 339.3,
  S: 17.05,
  N: 60.45,
  J: 20.8,
  St: 452.95,
  Nt: 1571.45,
  Jt: 549.85,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 2,
  TimeNow: 216.6153846153846,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 33.0,
  Nt: 2542.0,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 6,
  TimeNow: 222.5,
  S: 10.0,
  N: 89.0,
  J: 0.0,
  St: 262.0,
  Nt: 2313.0,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 10,
  TimeNow: 350.53333333333336,
  S: 22.333333333333332,
  N: 61.86666666666667,
  J: 14.066666666666666,
  St: 589.3333333333334,
  Nt: 1610.3333333333333,
  Jt: 374.6666666666667,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 23,
  TimeNow: 239.6153846153846,
  S: 10.461538461538462,
  N: 88.3076923076923,
  J: 0.0,
  St: 288.0,
  Nt: 2287.0,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 4,
  TimeNow: 225.63636363636363,
  S: 1.7272727272727273,
  N: 97.27272727272727,
  J: 0.0,
  St: 50.45454545454545,
  Nt: 2524.909090909091,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 3,
  TimeNow: 214.5,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 33.0,
  Nt: 2542.0,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 9,
  TimeNow: 648.7272727272727,
  S: 22.0,
  N: 33.18181818181818,
  J: 43.36363636363637,
  St: 1330.1818181818182,
  Nt: 1992.2727272727273,
  Jt: 2601.5454545454545,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 18,
  TimeNow: 386.25,
  S: 29.21875,
  N: 67.46875,
  J: 2.28125,
  St: 1759.6875,
  Nt: 4026.25,
  Jt: 138.0625,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 21,
  TimeNow: 515.0,
  S: 36.76923076923077,
  N: 36.92307692307692,
  J: 24.846153846153847,
  St: 2207.769230769231,
  Nt: 2220.153846153846,
  Jt: 1496.076923076923,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 1,
  TimeNow: 290.24,
  S: 0.96,
  N: 98.04,
  J: 0.0,
  St: 64.08,
  Nt: 5859.92,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 0,
  TimeNow: 367.8333333333333,
  S: 29.944444444444443,
  N: 64.33333333333333,
  J: 4.5,
  St: 1794.5,
  Nt: 3849.777777777778,
  Jt: 279.72222222222223,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 15,
  TimeNow: 477.75,
  S: 48.2,
  N: 40.25,
  J: 10.15,
  St: 2879.7,
  Nt: 2418.55,
  Jt: 625.75,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 17,
  TimeNow: 536.625,
  S: 38.59375,
  N: 34.6875,
  J: 25.5,
  St: 2303.90625,
  Nt: 2091.875,
  Jt: 1528.21875,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 7,
  TimeNow: 784.0714285714286,
  S: 21.285714285714285,
  N: 27.214285714285715,
  J: 50.07142857142857,
  St: 1296.357142857143,
  Nt: 1631.142857142857,
  Jt: 2996.5,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 11,
  TimeNow: 329.875,
  S: 13.5,
  N: 85.5,
  J: 0.0,
  St: 821.0,
  Nt: 5103.0,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 20,
  TimeNow: 520.8928571428571,
  S: 36.464285714285715,
  N: 34.67857142857143,
  J: 27.392857142857142,
  St: 2192.3214285714284,
  Nt: 2082.785714285714,
  Jt: 1648.892857142857,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 8,
  TimeNow: 700.8181818181819,
  S: 23.0,
  N: 29.636363636363637,
  J: 45.90909090909091,
  St: 1395.4545454545455,
  Nt: 1788.5454545454545,
  Jt: 2740.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 22,
  TimeNow: 385.61538461538464,
  S: 24.307692307692307,
  N: 67.92307692307692,
  J: 6.538461538461538,
  St: 1463.8461538461538,
  Nt: 4065.769230769231,
  Jt: 394.38461538461536,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 19,
  TimeNow: 390.7567567567568,
  S: 36.270270270270274,
  N: 62.108108108108105,
  J: 0.5135135135135135,
  St: 2176.054054054054,
  Nt: 3713.7297297297296,
  Jt: 34.21621621621622,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 16,
  TimeNow: 567.0,
  S: 40.0,
  N: 28.210526315789473,
  J: 30.36842105263158,
  St: 2395.0526315789475,
  Nt: 1704.7894736842106,
  Jt: 1824.157894736842,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 14,
  TimeNow: 400.3157894736842,
  S: 39.36842105263158,
  N: 58.526315789473685,
  J: 0.9473684210526315,
  St: 2365.4210526315787,
  Nt: 3498.2631578947367,
  Jt: 60.31578947368421,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 13,
  TimeNow: 444.0,
  S: 32.48,
  N: 53.28,
  J: 13.04,
  St: 1946.68,
  Nt: 3192.16,
  Jt: 785.16,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 12,
  TimeNow: 393.65,
  S: 36.35,
  N: 59.5,
  J: 3.1,
  St: 2184.6,
  Nt: 3551.1,
  Jt: 188.3,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 2,
  TimeNow: 292.38461538461536,
  S: 0.7692307692307693,
  N: 98.23076923076923,
  J: 0.0,
  St: 63.38461538461539,
  Nt: 5860.615384615385,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 6,
  TimeNow: 434.0,
  S: 13.0,
  N: 67.5,
  J: 18.0,
  St: 774.5,
  Nt: 4057.5,
  Jt: 1092.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 10,
  TimeNow: 394.42857142857144,
  S: 21.214285714285715,
  N: 67.64285714285714,
  J: 10.0,
  St: 1290.5,
  Nt: 4032.6428571428573,
  Jt: 600.8571428571429,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 23,
  TimeNow: 307.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 8.923076923076923,
  Nt: 5915.076923076923,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 4,
  TimeNow: 282.3,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5924.0,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 3,
  TimeNow: 355.5,
  S: 35.5,
  N: 50.0,
  J: 13.0,
  St: 2125.5,
  Nt: 3007.5,
  Jt: 791.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 9,
  TimeNow: 283.54545454545456,
  S: 21.636363636363637,
  N: 65.81818181818181,
  J: 10.818181818181818,
  St: 813.9090909090909,
  Nt: 2433.5454545454545,
  Jt: 413.54545454545456,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 18,
  TimeNow: 350.875,
  S: 27.09375,
  N: 50.21875,
  J: 21.375,
  St: 1007.34375,
  Nt: 1861.625,
  Jt: 792.03125,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 21,
  TimeNow: 336.6923076923077,
  S: 40.53846153846154,
  N: 45.07692307692308,
  J: 13.153846153846153,
  St: 1500.4615384615386,
  Nt: 1667.0,
  Jt: 493.53846153846155,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 1,
  TimeNow: 198.03846153846155,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3661.0,
  Jt: 0.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 0,
  TimeNow: 217.94444444444446,
  S: 0.6666666666666666,
  N: 97.16666666666667,
  J: 1.1666666666666667,
  St: 26.555555555555557,
  Nt: 3591.722222222222,
  Jt: 42.72222222222222,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 15,
  TimeNow: 941.0,
  S: 9.9,
  N: 4.4,
  J: 84.55,
  St: 379.25,
  Nt: 167.5,
  Jt: 3114.25,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 17,
  TimeNow: 390.5,
  S: 25.59375,
  N: 50.28125,
  J: 22.71875,
  St: 953.34375,
  Nt: 1858.1875,
  Jt: 849.46875,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 7,
  TimeNow: 274.14285714285717,
  S: 10.642857142857142,
  N: 80.92857142857143,
  J: 6.714285714285714,
  St: 411.2142857142857,
  Nt: 2982.9285714285716,
  Jt: 266.85714285714283,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 11,
  TimeNow: 990.0,
  S: 28.4375,
  N: 17.3125,
  J: 52.6875,
  St: 1059.75,
  Nt: 653.3125,
  Jt: 1947.9375,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 20,
  TimeNow: 314.9642857142857,
  S: 56.75,
  N: 32.357142857142854,
  J: 9.535714285714286,
  St: 2096.8928571428573,
  Nt: 1201.9642857142858,
  Jt: 362.14285714285717,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 8,
  TimeNow: 322.3636363636364,
  S: 20.90909090909091,
  N: 70.36363636363636,
  J: 7.636363636363637,
  St: 778.1818181818181,
  Nt: 2594.909090909091,
  Jt: 287.90909090909093,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 22,
  TimeNow: 247.84615384615384,
  S: 16.076923076923077,
  N: 82.92307692307692,
  J: 0.0,
  St: 603.4615384615385,
  Nt: 3057.5384615384614,
  Jt: 0.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 19,
  TimeNow: 333.97297297297297,
  S: 36.91891891891892,
  N: 40.45945945945946,
  J: 21.45945945945946,
  St: 1368.6756756756756,
  Nt: 1500.4864864864865,
  Jt: 791.8378378378378,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 16,
  TimeNow: 597.0526315789474,
  S: 24.63157894736842,
  N: 28.42105263157895,
  J: 45.578947368421055,
  St: 923.9473684210526,
  Nt: 1053.9473684210527,
  Jt: 1683.1052631578948,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 14,
  TimeNow: 456.2631578947368,
  S: 31.894736842105264,
  N: 29.263157894736842,
  J: 37.36842105263158,
  St: 1184.4736842105262,
  Nt: 1088.5263157894738,
  Jt: 1388.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 13,
  TimeNow: 619.56,
  S: 39.48,
  N: 10.2,
  J: 48.64,
  St: 1464.32,
  Nt: 393.96,
  Jt: 1802.72,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 12,
  TimeNow: 616.55,
  S: 40.75,
  N: 21.45,
  J: 36.35,
  St: 1508.1,
  Nt: 800.45,
  Jt: 1352.45,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 2,
  TimeNow: 186.46153846153845,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3661.0,
  Jt: 0.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 6,
  TimeNow: 215.0,
  S: 0.5,
  N: 95.0,
  J: 3.5,
  St: 29.5,
  Nt: 3499.5,
  Jt: 132.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 10,
  TimeNow: 1078.2666666666667,
  S: 12.133333333333333,
  N: 22.733333333333334,
  J: 63.666666666666664,
  St: 460.0,
  Nt: 851.7333333333333,
  Jt: 2349.266666666667,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 23,
  TimeNow: 220.23076923076923,
  S: 1.1538461538461537,
  N: 97.76923076923077,
  J: 0.0,
  St: 49.30769230769231,
  Nt: 3611.6923076923076,
  Jt: 0.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 4,
  TimeNow: 181.9090909090909,
  S: 0.9090909090909091,
  N: 98.0909090909091,
  J: 0.0,
  St: 38.72727272727273,
  Nt: 3622.2727272727275,
  Jt: 0.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 3,
  TimeNow: 182.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3661.0,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 9,
  TimeNow: 932.2727272727273,
  S: 3.727272727272727,
  N: 82.18181818181819,
  J: 12.727272727272727,
  St: 448.1818181818182,
  Nt: 8816.272727272728,
  Jt: 1401.7272727272727,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 18,
  TimeNow: 740.96875,
  S: 6.0625,
  N: 84.03125,
  J: 8.34375,
  St: 698.96875,
  Nt: 9014.3125,
  Jt: 952.71875,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 21,
  TimeNow: 570.8461538461538,
  S: 2.230769230769231,
  N: 96.76923076923077,
  J: 0.0,
  St: 291.38461538461536,
  Nt: 10374.76923076923,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 1,
  TimeNow: 526.7307692307693,
  S: 0.4230769230769231,
  N: 98.57692307692308,
  J: 0.0,
  St: 59.84615384615385,
  Nt: 10606.153846153846,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 0,
  TimeNow: 557.6111111111111,
  S: 3.111111111111111,
  N: 95.0,
  J: 0.8333333333333334,
  St: 342.55555555555554,
  Nt: 10230.055555555555,
  Jt: 93.44444444444444,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 15,
  TimeNow: 701.7,
  S: 5.9,
  N: 86.8,
  J: 5.4,
  St: 688.75,
  Nt: 9325.5,
  Jt: 652.1,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 17,
  TimeNow: 1050.75,
  S: 8.84375,
  N: 75.8125,
  J: 13.875,
  St: 990.125,
  Nt: 8135.0,
  Jt: 1540.875,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 7,
  TimeNow: 773.1428571428571,
  S: 15.642857142857142,
  N: 72.5,
  J: 10.357142857142858,
  St: 1717.0714285714287,
  Nt: 7784.928571428572,
  Jt: 1164.357142857143,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 11,
  TimeNow: 780.375,
  S: 9.1875,
  N: 81.75,
  J: 7.25,
  St: 1041.125,
  Nt: 8783.9375,
  Jt: 841.125,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 20,
  TimeNow: 712.1071428571429,
  S: 5.571428571428571,
  N: 85.17857142857143,
  J: 7.892857142857143,
  St: 651.0357142857143,
  Nt: 9126.75,
  Jt: 888.2142857142857,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 8,
  TimeNow: 870.2727272727273,
  S: 7.818181818181818,
  N: 76.27272727272727,
  J: 14.545454545454545,
  St: 874.4545454545455,
  Nt: 8193.272727272728,
  Jt: 1598.4545454545455,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 22,
  TimeNow: 553.7692307692307,
  S: 0.5384615384615384,
  N: 98.46153846153847,
  J: 0.0,
  St: 79.92307692307692,
  Nt: 10586.076923076924,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 19,
  TimeNow: 851.3513513513514,
  S: 5.756756756756757,
  N: 80.62162162162163,
  J: 12.0,
  St: 663.6486486486486,
  Nt: 8660.351351351352,
  Jt: 1342.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 16,
  TimeNow: 876.8421052631579,
  S: 7.578947368421052,
  N: 80.3157894736842,
  J: 10.578947368421053,
  St: 872.3684210526316,
  Nt: 8608.105263157895,
  Jt: 1185.6315789473683,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 14,
  TimeNow: 1029.7368421052631,
  S: 4.842105263157895,
  N: 82.10526315789474,
  J: 11.631578947368421,
  St: 571.8947368421053,
  Nt: 8799.684210526315,
  Jt: 1294.578947368421,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 13,
  TimeNow: 840.08,
  S: 5.56,
  N: 84.84,
  J: 7.96,
  St: 641.6,
  Nt: 9104.68,
  Jt: 919.88,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 12,
  TimeNow: 796.85,
  S: 7.65,
  N: 83.9,
  J: 6.85,
  St: 864.5,
  Nt: 9001.2,
  Jt: 800.45,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 2,
  TimeNow: 518.3846153846154,
  S: 0.6923076923076923,
  N: 98.3076923076923,
  J: 0.0,
  St: 89.92307692307692,
  Nt: 10576.076923076924,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 6,
  TimeNow: 610.0,
  S: 7.5,
  N: 89.0,
  J: 2.5,
  St: 832.0,
  Nt: 9553.0,
  Jt: 281.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 10,
  TimeNow: 690.9285714285714,
  S: 8.0,
  N: 85.92857142857143,
  J: 4.428571428571429,
  St: 889.0714285714286,
  Nt: 9237.142857142857,
  Jt: 539.9285714285714,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 23,
  TimeNow: 542.3846153846154,
  S: 0.3076923076923077,
  N: 98.6923076923077,
  J: 0.0,
  St: 47.15384615384615,
  Nt: 10618.923076923076,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 4,
  TimeNow: 537.3636363636364,
  S: 1.4545454545454546,
  N: 97.54545454545455,
  J: 0.0,
  St: 184.63636363636363,
  Nt: 10481.363636363636,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 3,
  TimeNow: 510.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 10666.0,
  Jt: 0.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 9,
  TimeNow: 429.09090909090907,
  S: 24.727272727272727,
  N: 73.36363636363636,
  J: 0.5454545454545454,
  St: 1225.0,
  Nt: 3586.909090909091,
  Jt: 45.18181818181818,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 18,
  TimeNow: 414.7741935483871,
  S: 9.935483870967742,
  N: 88.96774193548387,
  J: 0.0,
  St: 503.96774193548384,
  Nt: 4347.741935483871,
  Jt: 6.516129032258065,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 21,
  TimeNow: 417.84615384615387,
  S: 15.615384615384615,
  N: 81.84615384615384,
  J: 1.3846153846153846,
  St: 779.3076923076923,
  Nt: 4001.3076923076924,
  Jt: 76.92307692307692,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 1,
  TimeNow: 401.46153846153845,
  S: 7.884615384615385,
  N: 90.8076923076923,
  J: 0.07692307692307693,
  St: 412.0769230769231,
  Nt: 4434.7307692307695,
  Jt: 10.192307692307692,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 0,
  TimeNow: 391.05882352941177,
  S: 6.764705882352941,
  N: 92.17647058823529,
  J: 0.0,
  St: 359.70588235294116,
  Nt: 4496.882352941177,
  Jt: 0.8235294117647058,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 15,
  TimeNow: 463.25,
  S: 23.8,
  N: 70.6,
  J: 4.2,
  St: 1177.6,
  Nt: 3448.4,
  Jt: 231.7,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 17,
  TimeNow: 433.1875,
  S: 21.125,
  N: 77.6875,
  J: 0.0,
  St: 1053.5625,
  Nt: 3795.125,
  Jt: 9.28125,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 7,
  TimeNow: 423.57142857142856,
  S: 19.857142857142858,
  N: 78.0,
  J: 0.8571428571428571,
  St: 992.8571428571429,
  Nt: 3814.3571428571427,
  Jt: 50.214285714285715,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 11,
  TimeNow: 430.625,
  S: 29.5,
  N: 67.625,
  J: 1.5625,
  St: 1458.5625,
  Nt: 3308.3125,
  Jt: 90.875,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 20,
  TimeNow: 408.4642857142857,
  S: 13.214285714285714,
  N: 85.14285714285714,
  J: 0.5,
  St: 666.5,
  Nt: 4161.535714285715,
  Jt: 29.428571428571427,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 8,
  TimeNow: 412.90909090909093,
  S: 6.454545454545454,
  N: 88.0909090909091,
  J: 3.8181818181818183,
  St: 342.09090909090907,
  Nt: 4309.909090909091,
  Jt: 206.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 22,
  TimeNow: 407.46153846153845,
  S: 19.23076923076923,
  N: 79.23076923076923,
  J: 0.46153846153846156,
  St: 953.8461538461538,
  Nt: 3876.846153846154,
  Jt: 27.076923076923077,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 19,
  TimeNow: 421.4594594594595,
  S: 14.27027027027027,
  N: 82.94594594594595,
  J: 1.4594594594594594,
  St: 715.4054054054054,
  Nt: 4055.9189189189187,
  Jt: 86.91891891891892,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 16,
  TimeNow: 441.94736842105266,
  S: 22.36842105263158,
  N: 73.84210526315789,
  J: 2.4210526315789473,
  St: 1109.6842105263158,
  Nt: 3612.0526315789475,
  Jt: 136.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 14,
  TimeNow: 501.2105263157895,
  S: 41.10526315789474,
  N: 52.26315789473684,
  J: 5.157894736842105,
  St: 2019.6315789473683,
  Nt: 2565.6315789473683,
  Jt: 272.6842105263158,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 13,
  TimeNow: 481.96,
  S: 33.88,
  N: 61.08,
  J: 3.6,
  St: 1671.12,
  Nt: 2987.16,
  Jt: 198.92,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 12,
  TimeNow: 478.35,
  S: 30.75,
  N: 61.25,
  J: 6.8,
  St: 1509.15,
  Nt: 2996.15,
  Jt: 351.8,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 2,
  TimeNow: 392.2307692307692,
  S: 8.538461538461538,
  N: 90.15384615384616,
  J: 0.3076923076923077,
  St: 440.6923076923077,
  Nt: 4398.076923076923,
  Jt: 18.46153846153846,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 6,
  TimeNow: 397.5,
  S: 17.0,
  N: 81.5,
  J: 0.5,
  St: 852.0,
  Nt: 3970.0,
  Jt: 35.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 10,
  TimeNow: 448.46666666666664,
  S: 25.4,
  N: 68.93333333333334,
  J: 4.266666666666667,
  St: 1254.5333333333333,
  Nt: 3374.3333333333335,
  Jt: 228.86666666666667,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 23,
  TimeNow: 403.2307692307692,
  S: 10.538461538461538,
  N: 87.07692307692308,
  J: 1.1538461538461537,
  St: 542.7692307692307,
  Nt: 4251.0,
  Jt: 63.38461538461539,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 4,
  TimeNow: 422.3636363636364,
  S: 10.0,
  N: 88.18181818181819,
  J: 0.8181818181818182,
  St: 517.6363636363636,
  Nt: 4286.272727272727,
  Jt: 53.09090909090909,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 3,
  TimeNow: 421.0,
  S: 13.0,
  N: 86.0,
  J: 0.0,
  St: 670.0,
  Nt: 4187.0,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 9,
  TimeNow: 147.1818181818182,
  S: 9.272727272727273,
  N: 88.81818181818181,
  J: 0.7272727272727273,
  St: 123.45454545454545,
  Nt: 1118.1818181818182,
  Jt: 11.363636363636363,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 18,
  TimeNow: 146.3125,
  S: 10.15625,
  N: 87.125,
  J: 1.34375,
  St: 134.3125,
  Nt: 1098.75,
  Jt: 19.9375,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 21,
  TimeNow: 129.46153846153845,
  S: 1.0,
  N: 97.84615384615384,
  J: 0.0,
  St: 19.384615384615383,
  Nt: 1233.6153846153845,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 1,
  TimeNow: 129.34615384615384,
  S: 0.9230769230769231,
  N: 98.07692307692308,
  J: 0.0,
  St: 14.538461538461538,
  Nt: 1238.4615384615386,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 0,
  TimeNow: 133.5,
  S: 4.166666666666667,
  N: 94.83333333333333,
  J: 0.0,
  St: 62.611111111111114,
  Nt: 1190.388888888889,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 15,
  TimeNow: 135.65,
  S: 2.65,
  N: 96.15,
  J: 0.2,
  St: 39.95,
  Nt: 1210.05,
  Jt: 3.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 17,
  TimeNow: 154.125,
  S: 12.09375,
  N: 85.59375,
  J: 1.0625,
  St: 160.1875,
  Nt: 1075.5,
  Jt: 17.3125,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 7,
  TimeNow: 142.78571428571428,
  S: 5.357142857142857,
  N: 93.21428571428571,
  J: 0.2857142857142857,
  St: 74.71428571428571,
  Nt: 1173.7142857142858,
  Jt: 4.571428571428571,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 11,
  TimeNow: 139.6875,
  S: 4.1875,
  N: 94.6875,
  J: 0.125,
  St: 59.9375,
  Nt: 1190.3125,
  Jt: 2.75,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 20,
  TimeNow: 132.10714285714286,
  S: 5.821428571428571,
  N: 92.71428571428571,
  J: 0.25,
  St: 81.57142857142857,
  Nt: 1167.9285714285713,
  Jt: 3.5,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 8,
  TimeNow: 142.72727272727272,
  S: 4.545454545454546,
  N: 94.36363636363636,
  J: 0.09090909090909091,
  St: 63.45454545454545,
  Nt: 1188.090909090909,
  Jt: 1.4545454545454546,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 22,
  TimeNow: 131.30769230769232,
  S: 2.6923076923076925,
  N: 96.3076923076923,
  J: 0.0,
  St: 38.38461538461539,
  Nt: 1214.6153846153845,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 19,
  TimeNow: 160.16216216216216,
  S: 5.972972972972973,
  N: 88.97297297297297,
  J: 3.6216216216216215,
  St: 82.16216216216216,
  Nt: 1119.4864864864865,
  Jt: 51.351351351351354,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 16,
  TimeNow: 140.31578947368422,
  S: 4.526315789473684,
  N: 94.26315789473684,
  J: 0.10526315789473684,
  St: 64.3157894736842,
  Nt: 1186.3684210526317,
  Jt: 2.3157894736842106,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 14,
  TimeNow: 153.8421052631579,
  S: 8.736842105263158,
  N: 86.21052631578948,
  J: 3.8421052631578947,
  St: 116.63157894736842,
  Nt: 1086.1052631578948,
  Jt: 50.26315789473684,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 13,
  TimeNow: 139.36,
  S: 1.76,
  N: 96.84,
  J: 0.24,
  St: 27.04,
  Nt: 1221.88,
  Jt: 4.08,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 12,
  TimeNow: 138.4,
  S: 4.0,
  N: 94.85,
  J: 0.1,
  St: 57.15,
  Nt: 1193.95,
  Jt: 1.9,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 2,
  TimeNow: 131.6153846153846,
  S: 6.230769230769231,
  N: 92.76923076923077,
  J: 0.0,
  St: 81.92307692307692,
  Nt: 1171.076923076923,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 6,
  TimeNow: 134.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1253.0,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 10,
  TimeNow: 135.46666666666667,
  S: 2.2666666666666666,
  N: 96.46666666666667,
  J: 0.26666666666666666,
  St: 31.866666666666667,
  Nt: 1215.2666666666667,
  Jt: 5.866666666666666,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 23,
  TimeNow: 131.23076923076923,
  S: 1.7692307692307692,
  N: 97.23076923076923,
  J: 0.0,
  St: 29.615384615384617,
  Nt: 1223.3846153846155,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 4,
  TimeNow: 132.1818181818182,
  S: 0.2727272727272727,
  N: 98.63636363636364,
  J: 0.0,
  St: 4.545454545454546,
  Nt: 1248.4545454545455,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 3,
  TimeNow: 133.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1253.0,
  Jt: 0.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 9,
  TimeNow: 320.72727272727275,
  S: 2.5454545454545454,
  N: 91.63636363636364,
  J: 4.454545454545454,
  St: 94.0,
  Nt: 2904.3636363636365,
  Jt: 149.63636363636363,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 18,
  TimeNow: 314.4375,
  S: 5.34375,
  N: 91.75,
  J: 1.65625,
  St: 181.8125,
  Nt: 2905.1875,
  Jt: 61.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 21,
  TimeNow: 339.46153846153845,
  S: 8.153846153846153,
  N: 88.6923076923077,
  J: 1.9230769230769231,
  St: 268.61538461538464,
  Nt: 2810.3846153846152,
  Jt: 69.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 1,
  TimeNow: 276.34615384615387,
  S: 5.8076923076923075,
  N: 93.1923076923077,
  J: 0.0,
  St: 198.84615384615384,
  Nt: 2949.153846153846,
  Jt: 0.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 0,
  TimeNow: 278.8888888888889,
  S: 3.3333333333333335,
  N: 95.38888888888889,
  J: 0.2222222222222222,
  St: 121.72222222222223,
  Nt: 3018.277777777778,
  Jt: 8.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 15,
  TimeNow: 306.2,
  S: 3.45,
  N: 93.65,
  J: 1.7,
  St: 123.5,
  Nt: 2962.35,
  Jt: 62.15,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 17,
  TimeNow: 317.875,
  S: 6.3125,
  N: 90.40625,
  J: 2.0625,
  St: 210.4375,
  Nt: 2862.65625,
  Jt: 74.90625,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 7,
  TimeNow: 439.85714285714283,
  S: 16.071428571428573,
  N: 70.85714285714286,
  J: 11.642857142857142,
  St: 522.4285714285714,
  Nt: 2246.214285714286,
  Jt: 379.35714285714283,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 11,
  TimeNow: 300.8125,
  S: 3.0,
  N: 94.5,
  J: 1.3125,
  St: 105.0625,
  Nt: 2992.9375,
  Jt: 50.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 20,
  TimeNow: 344.0,
  S: 9.071428571428571,
  N: 87.53571428571429,
  J: 2.142857142857143,
  St: 301.5357142857143,
  Nt: 2768.75,
  Jt: 77.71428571428571,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 8,
  TimeNow: 400.54545454545456,
  S: 10.545454545454545,
  N: 80.54545454545455,
  J: 7.363636363636363,
  St: 345.27272727272725,
  Nt: 2550.4545454545455,
  Jt: 252.27272727272728,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 22,
  TimeNow: 307.2307692307692,
  S: 4.0,
  N: 93.23076923076923,
  J: 1.6923076923076923,
  St: 138.30769230769232,
  Nt: 2951.3846153846152,
  Jt: 58.30769230769231,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 19,
  TimeNow: 314.2432432432432,
  S: 6.378378378378378,
  N: 91.1891891891892,
  J: 1.3513513513513513,
  St: 210.67567567567568,
  Nt: 2888.891891891892,
  Jt: 48.432432432432435,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 16,
  TimeNow: 298.4736842105263,
  S: 2.263157894736842,
  N: 95.15789473684211,
  J: 1.4736842105263157,
  St: 80.84210526315789,
  Nt: 3013.684210526316,
  Jt: 53.473684210526315,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 14,
  TimeNow: 321.7368421052632,
  S: 4.842105263157895,
  N: 92.15789473684211,
  J: 1.631578947368421,
  St: 170.8421052631579,
  Nt: 2914.684210526316,
  Jt: 62.473684210526315,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 13,
  TimeNow: 315.04,
  S: 7.36,
  N: 89.12,
  J: 2.12,
  St: 252.24,
  Nt: 2819.6,
  Jt: 76.16,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 12,
  TimeNow: 306.5,
  S: 5.55,
  N: 91.8,
  J: 1.4,
  St: 189.85,
  Nt: 2905.35,
  Jt: 52.8,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 2,
  TimeNow: 249.6153846153846,
  S: 0.9230769230769231,
  N: 98.07692307692308,
  J: 0.0,
  St: 42.53846153846154,
  Nt: 3105.4615384615386,
  Jt: 0.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 6,
  TimeNow: 318.0,
  S: 7.0,
  N: 87.5,
  J: 4.0,
  St: 229.0,
  Nt: 2780.5,
  Jt: 138.5,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 10,
  TimeNow: 306.4,
  S: 2.6666666666666665,
  N: 94.26666666666667,
  J: 1.9333333333333333,
  St: 100.26666666666667,
  Nt: 2979.733333333333,
  Jt: 68.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 23,
  TimeNow: 289.6923076923077,
  S: 3.5384615384615383,
  N: 94.46153846153847,
  J: 0.9230769230769231,
  St: 129.76923076923077,
  Nt: 2985.0,
  Jt: 33.23076923076923,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 4,
  TimeNow: 243.63636363636363,
  S: 1.3636363636363635,
  N: 97.63636363636364,
  J: 0.0,
  St: 50.36363636363637,
  Nt: 3097.6363636363635,
  Jt: 0.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 3,
  TimeNow: 229.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 19.0,
  Nt: 3129.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 9,
  TimeNow: 801.1818181818181,
  S: 6.909090909090909,
  N: 68.18181818181819,
  J: 23.90909090909091,
  St: 694.5454545454545,
  Nt: 6340.272727272727,
  Jt: 2263.181818181818,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 18,
  TimeNow: 364.4375,
  S: 1.125,
  N: 97.875,
  J: 0.0,
  St: 117.90625,
  Nt: 9180.09375,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 21,
  TimeNow: 355.0769230769231,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 1,
  TimeNow: 340.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 0,
  TimeNow: 343.44444444444446,
  S: 0.6111111111111112,
  N: 98.33333333333333,
  J: 0.05555555555555555,
  St: 61.333333333333336,
  Nt: 9231.444444444445,
  Jt: 5.222222222222222,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 15,
  TimeNow: 899.15,
  S: 2.4,
  N: 65.55,
  J: 30.9,
  St: 274.6,
  Nt: 6146.15,
  Jt: 2916.8,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 17,
  TimeNow: 434.84375,
  S: 9.5,
  N: 82.3125,
  J: 6.90625,
  St: 916.125,
  Nt: 7718.84375,
  Jt: 663.03125,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 7,
  TimeNow: 344.7857142857143,
  S: 0.42857142857142855,
  N: 98.57142857142857,
  J: 0.0,
  St: 44.857142857142854,
  Nt: 9253.142857142857,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 11,
  TimeNow: 1282.5,
  S: 3.9375,
  N: 66.0625,
  J: 29.0,
  St: 421.3125,
  Nt: 6147.6875,
  Jt: 2729.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 20,
  TimeNow: 356.85714285714283,
  S: 0.21428571428571427,
  N: 98.78571428571429,
  J: 0.0,
  St: 20.25,
  Nt: 9277.75,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 8,
  TimeNow: 560.3636363636364,
  S: 7.7272727272727275,
  N: 78.72727272727273,
  J: 12.0,
  St: 759.5454545454545,
  Nt: 7386.818181818182,
  Jt: 1172.1818181818182,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 22,
  TimeNow: 351.2307692307692,
  S: 0.15384615384615385,
  N: 98.84615384615384,
  J: 0.0,
  St: 17.692307692307693,
  Nt: 9280.307692307691,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 19,
  TimeNow: 368.6216216216216,
  S: 2.72972972972973,
  N: 96.27027027027027,
  J: 0.0,
  St: 264.1621621621622,
  Nt: 9033.837837837838,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 16,
  TimeNow: 842.3157894736842,
  S: 5.2631578947368425,
  N: 66.57894736842105,
  J: 26.842105263157894,
  St: 537.1578947368421,
  Nt: 6249.631578947368,
  Jt: 2546.8947368421054,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 14,
  TimeNow: 1085.1052631578948,
  S: 2.210526315789474,
  N: 66.05263157894737,
  J: 30.63157894736842,
  St: 256.63157894736844,
  Nt: 6179.368421052632,
  Jt: 2891.7368421052633,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 13,
  TimeNow: 935.76,
  S: 3.08,
  N: 62.48,
  J: 32.88,
  St: 339.0,
  Nt: 5910.84,
  Jt: 3129.52,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 12,
  TimeNow: 1035.7,
  S: 2.0,
  N: 66.8,
  J: 30.0,
  St: 241.8,
  Nt: 6242.2,
  Jt: 2836.6,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 2,
  TimeNow: 339.53846153846155,
  S: 0.5384615384615384,
  N: 98.46153846153847,
  J: 0.0,
  St: 54.76923076923077,
  Nt: 9243.23076923077,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 6,
  TimeNow: 333.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 10,
  TimeNow: 1104.2,
  S: 2.0,
  N: 68.0,
  J: 29.0,
  St: 246.0,
  Nt: 6323.0,
  Jt: 2729.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 23,
  TimeNow: 347.46153846153845,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 4,
  TimeNow: 327.54545454545456,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 3,
  TimeNow: 335.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 9,
  TimeNow: 325.0,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 14.0,
  Nt: 1210.0,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 18,
  TimeNow: 325.0,
  S: 0.8125,
  N: 98.0,
  J: 0.1875,
  St: 11.375,
  Nt: 1210.0,
  Jt: 2.625,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 21,
  TimeNow: 325.0,
  S: 0.6923076923076923,
  N: 98.3076923076923,
  J: 0.0,
  St: 9.692307692307692,
  Nt: 1214.3076923076924,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 1,
  TimeNow: 325.0,
  S: 0.23076923076923078,
  N: 98.73076923076923,
  J: 0.038461538461538464,
  St: 3.230769230769231,
  Nt: 1220.2307692307693,
  Jt: 0.5384615384615384,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 0,
  TimeNow: 325.0,
  S: 0.2777777777777778,
  N: 98.61111111111111,
  J: 0.1111111111111111,
  St: 3.888888888888889,
  Nt: 1218.5555555555557,
  Jt: 1.5555555555555556,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 15,
  TimeNow: 325.0,
  S: 0.4,
  N: 98.0,
  J: 0.6,
  St: 5.6,
  Nt: 1210.0,
  Jt: 8.4,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 17,
  TimeNow: 325.0,
  S: 0.5625,
  N: 98.03125,
  J: 0.40625,
  St: 7.875,
  Nt: 1210.4375,
  Jt: 5.6875,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 7,
  TimeNow: 325.0,
  S: 0.7142857142857143,
  N: 98.07142857142857,
  J: 0.21428571428571427,
  St: 10.0,
  Nt: 1211.0,
  Jt: 3.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 11,
  TimeNow: 325.0,
  S: 0.8125,
  N: 98.0,
  J: 0.1875,
  St: 11.375,
  Nt: 1210.0,
  Jt: 2.625,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 20,
  TimeNow: 325.0,
  S: 0.32142857142857145,
  N: 98.0,
  J: 0.6785714285714286,
  St: 4.5,
  Nt: 1210.0,
  Jt: 9.5,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 8,
  TimeNow: 325.0,
  S: 0.6363636363636364,
  N: 98.0,
  J: 0.36363636363636365,
  St: 8.909090909090908,
  Nt: 1210.0,
  Jt: 5.090909090909091,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 22,
  TimeNow: 325.0,
  S: 0.6923076923076923,
  N: 98.15384615384616,
  J: 0.15384615384615385,
  St: 9.692307692307692,
  Nt: 1212.1538461538462,
  Jt: 2.1538461538461537,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 19,
  TimeNow: 325.0,
  S: 0.2972972972972973,
  N: 98.0,
  J: 0.7027027027027027,
  St: 4.162162162162162,
  Nt: 1210.0,
  Jt: 9.837837837837839,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 16,
  TimeNow: 325.0,
  S: 0.631578947368421,
  N: 98.0,
  J: 0.3684210526315789,
  St: 8.842105263157896,
  Nt: 1210.0,
  Jt: 5.157894736842105,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 14,
  TimeNow: 325.0,
  S: 0.5263157894736842,
  N: 98.0,
  J: 0.47368421052631576,
  St: 7.368421052631579,
  Nt: 1210.0,
  Jt: 6.631578947368421,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 13,
  TimeNow: 325.0,
  S: 0.56,
  N: 98.0,
  J: 0.44,
  St: 7.84,
  Nt: 1210.0,
  Jt: 6.16,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 12,
  TimeNow: 325.0,
  S: 0.9,
  N: 98.0,
  J: 0.1,
  St: 12.6,
  Nt: 1210.0,
  Jt: 1.4,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 2,
  TimeNow: 325.0,
  S: 0.45454545454545453,
  N: 98.36363636363636,
  J: 0.18181818181818182,
  St: 6.363636363636363,
  Nt: 1215.090909090909,
  Jt: 2.5454545454545454,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 6,
  TimeNow: 325.0,
  S: 0.0,
  N: 98.5,
  J: 0.5,
  St: 0.0,
  Nt: 1217.0,
  Jt: 7.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 10,
  TimeNow: 325.0,
  S: 0.8666666666666667,
  N: 98.0,
  J: 0.13333333333333333,
  St: 12.133333333333333,
  Nt: 1210.0,
  Jt: 1.8666666666666667,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 23,
  TimeNow: 325.0,
  S: 0.23076923076923078,
  N: 98.76923076923077,
  J: 0.0,
  St: 3.230769230769231,
  Nt: 1220.7692307692307,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 4,
  TimeNow: 325.0,
  S: 0.2727272727272727,
  N: 98.72727272727273,
  J: 0.0,
  St: 3.8181818181818183,
  Nt: 1220.1818181818182,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 3,
  TimeNow: 325.0,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 14.0,
  Nt: 1210.0,
  Jt: 0.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 9,
  TimeNow: 422.3636363636364,
  S: 13.181818181818182,
  N: 79.0,
  J: 6.181818181818182,
  St: 849.4545454545455,
  Nt: 4911.181818181818,
  Jt: 414.3636363636364,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 18,
  TimeNow: 506.375,
  S: 19.0,
  N: 67.3125,
  J: 12.28125,
  St: 1201.25,
  Nt: 4188.0625,
  Jt: 785.6875,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 21,
  TimeNow: 502.2307692307692,
  S: 29.0,
  N: 61.84615384615385,
  J: 7.6923076923076925,
  St: 1828.1538461538462,
  Nt: 3852.0,
  Jt: 494.84615384615387,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 1,
  TimeNow: 324.03846153846155,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 2.3076923076923075,
  Nt: 6172.692307692308,
  Jt: 0.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 0,
  TimeNow: 349.94444444444446,
  S: 0.6666666666666666,
  N: 97.66666666666667,
  J: 0.6666666666666666,
  St: 44.666666666666664,
  Nt: 6087.611111111111,
  Jt: 42.72222222222222,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 15,
  TimeNow: 1137.35,
  S: 8.45,
  N: 34.25,
  J: 56.0,
  St: 549.55,
  Nt: 2138.9,
  Jt: 3486.55,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 17,
  TimeNow: 548.875,
  S: 17.875,
  N: 67.40625,
  J: 13.40625,
  St: 1136.875,
  Nt: 4188.65625,
  Jt: 849.46875,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 7,
  TimeNow: 455.92857142857144,
  S: 8.928571428571429,
  N: 83.42857142857143,
  J: 6.214285714285714,
  St: 587.0,
  Nt: 5177.857142857143,
  Jt: 410.14285714285717,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 11,
  TimeNow: 1548.1875,
  S: 20.125,
  N: 22.0,
  J: 56.375,
  St: 1278.125,
  Nt: 1376.625,
  Jt: 3520.25,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 20,
  TimeNow: 473.25,
  S: 39.642857142857146,
  N: 53.535714285714285,
  J: 5.571428571428571,
  St: 2479.4285714285716,
  Nt: 3333.214285714286,
  Jt: 362.35714285714283,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 8,
  TimeNow: 457.6363636363636,
  S: 14.090909090909092,
  N: 80.0,
  J: 4.7272727272727275,
  St: 900.7272727272727,
  Nt: 4970.727272727273,
  Jt: 303.54545454545456,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 22,
  TimeNow: 391.2307692307692,
  S: 11.23076923076923,
  N: 87.76923076923077,
  J: 0.0,
  St: 733.0769230769231,
  Nt: 5441.923076923077,
  Jt: 0.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 19,
  TimeNow: 486.7027027027027,
  S: 24.62162162162162,
  N: 61.567567567567565,
  J: 12.405405405405405,
  St: 1548.2702702702702,
  Nt: 3834.4594594594596,
  Jt: 792.2702702702703,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 16,
  TimeNow: 838.0,
  S: 17.68421052631579,
  N: 44.68421052631579,
  J: 36.05263157894737,
  St: 1126.842105263158,
  Nt: 2794.0,
  Jt: 2254.157894736842,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 14,
  TimeNow: 647.578947368421,
  S: 23.63157894736842,
  N: 48.0,
  J: 26.842105263157894,
  St: 1487.9473684210527,
  Nt: 2999.157894736842,
  Jt: 1687.8947368421052,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 13,
  TimeNow: 934.52,
  S: 29.48,
  N: 30.48,
  J: 38.36,
  St: 1847.8,
  Nt: 1924.92,
  Jt: 2402.28,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 12,
  TimeNow: 1049.5,
  S: 28.6,
  N: 27.55,
  J: 42.35,
  St: 1797.05,
  Nt: 1731.5,
  Jt: 2646.45,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 2,
  TimeNow: 307.2307692307692,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 6175.0,
  Jt: 0.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 6,
  TimeNow: 359.5,
  S: 0.0,
  N: 94.0,
  J: 4.5,
  St: 29.0,
  Nt: 5862.5,
  Jt: 283.5,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 10,
  TimeNow: 1291.9333333333334,
  S: 9.066666666666666,
  N: 48.2,
  J: 41.266666666666666,
  St: 598.0,
  Nt: 3000.8,
  Jt: 2576.2,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 23,
  TimeNow: 354.46153846153845,
  S: 0.6923076923076923,
  N: 98.3076923076923,
  J: 0.0,
  St: 56.61538461538461,
  Nt: 6118.384615384615,
  Jt: 0.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 4,
  TimeNow: 299.90909090909093,
  S: 0.5454545454545454,
  N: 98.45454545454545,
  J: 0.0,
  St: 38.72727272727273,
  Nt: 6136.272727272727,
  Jt: 0.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 3,
  TimeNow: 304.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 6175.0,
  Jt: 0.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 9,
  TimeNow: 213.9090909090909,
  S: 32.54545454545455,
  N: 27.727272727272727,
  J: 38.54545454545455,
  St: 216.1818181818182,
  Nt: 184.63636363636363,
  Jt: 255.1818181818182,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 18,
  TimeNow: 125.375,
  S: 80.71875,
  N: 0.0,
  J: 18.25,
  St: 534.65625,
  Nt: 0.0,
  Jt: 121.4375,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 21,
  TimeNow: 92.92307692307692,
  S: 39.15384615384615,
  N: 59.84615384615385,
  J: 0.0,
  St: 259.0,
  Nt: 397.0,
  Jt: 0.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 1,
  TimeNow: 85.36,
  S: 13.4,
  N: 85.48,
  J: 0.08,
  St: 90.2,
  Nt: 565.4,
  Jt: 0.56,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 0,
  TimeNow: 85.0,
  S: 29.38888888888889,
  N: 68.11111111111111,
  J: 1.5,
  St: 195.55555555555554,
  Nt: 450.5,
  Jt: 10.055555555555555,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 15,
  TimeNow: 396.25,
  S: 37.55,
  N: 4.05,
  J: 57.4,
  St: 250.85,
  Nt: 27.45,
  Jt: 377.7,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 17,
  TimeNow: 169.53125,
  S: 65.21875,
  N: 3.65625,
  J: 30.03125,
  St: 432.46875,
  Nt: 24.3125,
  Jt: 199.21875,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 7,
  TimeNow: 117.07142857142857,
  S: 54.357142857142854,
  N: 26.071428571428573,
  J: 18.357142857142858,
  St: 360.7857142857143,
  Nt: 173.21428571428572,
  Jt: 122.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 11,
  TimeNow: 308.9375,
  S: 30.75,
  N: 16.4375,
  J: 51.8125,
  St: 205.125,
  Nt: 109.8125,
  Jt: 341.0625,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 20,
  TimeNow: 152.89285714285714,
  S: 63.392857142857146,
  N: 2.5357142857142856,
  J: 33.035714285714285,
  St: 419.7142857142857,
  Nt: 16.892857142857142,
  Jt: 219.5,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 8,
  TimeNow: 152.8181818181818,
  S: 47.63636363636363,
  N: 15.272727272727273,
  J: 35.72727272727273,
  St: 315.72727272727275,
  Nt: 102.9090909090909,
  Jt: 237.36363636363637,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 22,
  TimeNow: 88.3076923076923,
  S: 11.0,
  N: 87.38461538461539,
  J: 0.3076923076923077,
  St: 75.0,
  Nt: 578.8461538461538,
  Jt: 2.1538461538461537,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 19,
  TimeNow: 186.02702702702703,
  S: 47.189189189189186,
  N: 0.0,
  J: 51.810810810810814,
  St: 314.64864864864865,
  Nt: 0.0,
  Jt: 341.35135135135135,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 16,
  TimeNow: 411.89473684210526,
  S: 33.89473684210526,
  N: 9.789473684210526,
  J: 55.31578947368421,
  St: 226.52631578947367,
  Nt: 65.47368421052632,
  Jt: 364.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 14,
  TimeNow: 475.10526315789474,
  S: 41.05263157894737,
  N: 0.0,
  J: 57.94736842105263,
  St: 274.4736842105263,
  Nt: 0.0,
  Jt: 381.5263157894737,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 13,
  TimeNow: 322.72,
  S: 42.28,
  N: 2.4,
  J: 54.32,
  St: 282.52,
  Nt: 16.0,
  Jt: 357.48,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 12,
  TimeNow: 329.85,
  S: 43.45,
  N: 0.0,
  J: 55.55,
  St: 290.45,
  Nt: 0.0,
  Jt: 365.55,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 2,
  TimeNow: 95.0,
  S: 5.076923076923077,
  N: 79.61538461538461,
  J: 14.153846153846153,
  St: 34.92307692307692,
  Nt: 525.6923076923077,
  Jt: 95.38461538461539,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 6,
  TimeNow: 99.5,
  S: 35.0,
  N: 61.0,
  J: 3.0,
  St: 231.5,
  Nt: 404.5,
  Jt: 20.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 10,
  TimeNow: 287.8666666666667,
  S: 37.733333333333334,
  N: 1.2,
  J: 60.06666666666667,
  St: 251.06666666666666,
  Nt: 8.133333333333333,
  Jt: 396.8,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 23,
  TimeNow: 87.15384615384616,
  S: 27.846153846153847,
  N: 71.15384615384616,
  J: 0.0,
  St: 185.84615384615384,
  Nt: 470.15384615384613,
  Jt: 0.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 4,
  TimeNow: 92.81818181818181,
  S: 31.272727272727273,
  N: 67.72727272727273,
  J: 0.0,
  St: 208.54545454545453,
  Nt: 447.6363636363636,
  Jt: 0.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 3,
  TimeNow: 83.5,
  S: 34.0,
  N: 65.0,
  J: 0.0,
  St: 227.0,
  Nt: 430.0,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 9,
  TimeNow: 363.09090909090907,
  S: 33.63636363636363,
  N: 62.45454545454545,
  J: 2.727272727272727,
  St: 1877.7272727272727,
  Nt: 3464.4545454545455,
  Jt: 159.8181818181818,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 18,
  TimeNow: 907.375,
  S: 6.59375,
  N: 37.09375,
  J: 55.03125,
  St: 380.5625,
  Nt: 2060.5,
  Jt: 3057.9375,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 21,
  TimeNow: 291.53846153846155,
  S: 3.076923076923077,
  N: 95.92307692307692,
  J: 0.0,
  St: 199.30769230769232,
  Nt: 5302.692307692308,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 1,
  TimeNow: 391.3076923076923,
  S: 11.846153846153847,
  N: 78.03846153846153,
  J: 8.153846153846153,
  St: 683.1153846153846,
  Nt: 4335.7307692307695,
  Jt: 483.15384615384613,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 0,
  TimeNow: 309.3888888888889,
  S: 0.4444444444444444,
  N: 95.27777777777777,
  J: 3.2777777777777777,
  St: 27.055555555555557,
  Nt: 5292.166666666667,
  Jt: 182.44444444444446,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 15,
  TimeNow: 665.8,
  S: 7.75,
  N: 44.65,
  J: 46.3,
  St: 450.2,
  Nt: 2483.6,
  Jt: 2568.2,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 17,
  TimeNow: 1213.65625,
  S: 3.84375,
  N: 37.8125,
  J: 56.96875,
  St: 229.4375,
  Nt: 2097.46875,
  Jt: 3170.59375,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 7,
  TimeNow: 503.07142857142856,
  S: 33.92857142857143,
  N: 41.785714285714285,
  J: 22.928571428571427,
  St: 1897.7857142857142,
  Nt: 2319.4285714285716,
  Jt: 1284.357142857143,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 11,
  TimeNow: 294.125,
  S: 4.5,
  N: 94.5,
  J: 0.0,
  St: 254.875,
  Nt: 5247.125,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 20,
  TimeNow: 295.0,
  S: 6.571428571428571,
  N: 92.42857142857143,
  J: 0.0,
  St: 377.07142857142856,
  Nt: 5124.928571428572,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 8,
  TimeNow: 436.09090909090907,
  S: 25.09090909090909,
  N: 57.45454545454545,
  J: 16.181818181818183,
  St: 1405.4545454545455,
  Nt: 3180.090909090909,
  Jt: 916.4545454545455,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 22,
  TimeNow: 276.9230769230769,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5502.0,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 19,
  TimeNow: 475.35135135135135,
  S: 8.702702702702704,
  N: 66.75675675675676,
  J: 23.35135135135135,
  St: 504.56756756756755,
  Nt: 3700.4054054054054,
  Jt: 1296.5405405405406,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 16,
  TimeNow: 899.6315789473684,
  S: 9.210526315789474,
  N: 34.0,
  J: 55.21052631578947,
  St: 531.578947368421,
  Nt: 1900.7368421052631,
  Jt: 3068.1052631578946,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 14,
  TimeNow: 364.57894736842104,
  S: 24.31578947368421,
  N: 65.36842105263158,
  J: 9.105263157894736,
  St: 1364.578947368421,
  Nt: 3628.157894736842,
  Jt: 509.2631578947368,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 13,
  TimeNow: 303.8,
  S: 15.96,
  N: 83.04,
  J: 0.0,
  St: 902.04,
  Nt: 4599.96,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 12,
  TimeNow: 307.55,
  S: 15.05,
  N: 83.45,
  J: 0.4,
  St: 859.8,
  Nt: 4617.5,
  Jt: 24.7,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 2,
  TimeNow: 305.7692307692308,
  S: 4.076923076923077,
  N: 93.0,
  J: 1.5384615384615385,
  St: 245.0,
  Nt: 5153.538461538462,
  Jt: 103.46153846153847,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 6,
  TimeNow: 295.5,
  S: 2.0,
  N: 94.5,
  J: 2.5,
  St: 116.5,
  Nt: 5231.0,
  Jt: 154.5,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 10,
  TimeNow: 351.2,
  S: 9.866666666666667,
  N: 85.53333333333333,
  J: 3.533333333333333,
  St: 559.8,
  Nt: 4740.866666666667,
  Jt: 201.33333333333334,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 23,
  TimeNow: 269.0769230769231,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5502.0,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 4,
  TimeNow: 282.27272727272725,
  S: 2.0,
  N: 97.0,
  J: 0.0,
  St: 122.63636363636364,
  Nt: 5379.363636363636,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 3,
  TimeNow: 310.0,
  S: 9.5,
  N: 89.0,
  J: 0.0,
  St: 539.0,
  Nt: 4923.0,
  Jt: 40.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 9,
  TimeNow: 664.3636363636364,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 18,
  TimeNow: 772.28125,
  S: 2.03125,
  N: 95.625,
  J: 1.15625,
  St: 440.1875,
  Nt: 17709.84375,
  Jt: 235.96875,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 21,
  TimeNow: 752.2307692307693,
  S: 2.4615384615384617,
  N: 95.61538461538461,
  J: 0.7692307692307693,
  St: 528.9230769230769,
  Nt: 17671.30769230769,
  Jt: 185.76923076923077,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 1,
  TimeNow: 698.1153846153846,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 0,
  TimeNow: 707.1111111111111,
  S: 0.3333333333333333,
  N: 98.16666666666667,
  J: 0.4444444444444444,
  St: 68.0,
  Nt: 18230.0,
  Jt: 88.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 15,
  TimeNow: 840.1,
  S: 5.9,
  N: 87.15,
  J: 5.55,
  St: 1174.75,
  Nt: 16099.7,
  Jt: 1111.55,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 17,
  TimeNow: 960.09375,
  S: 3.59375,
  N: 86.90625,
  J: 7.75,
  St: 760.28125,
  Nt: 16089.71875,
  Jt: 1536.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 7,
  TimeNow: 665.2857142857143,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 11,
  TimeNow: 697.1875,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 20,
  TimeNow: 728.8571428571429,
  S: 1.0357142857142858,
  N: 97.96428571428571,
  J: 0.0,
  St: 248.60714285714286,
  Nt: 18137.39285714286,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 8,
  TimeNow: 657.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 22,
  TimeNow: 726.4615384615385,
  S: 1.0769230769230769,
  N: 97.92307692307692,
  J: 0.0,
  St: 270.84615384615387,
  Nt: 18115.153846153848,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 19,
  TimeNow: 728.7027027027027,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.8648648648648649,
  Nt: 18385.135135135137,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 16,
  TimeNow: 779.6842105263158,
  S: 5.2105263157894735,
  N: 91.89473684210526,
  J: 1.4210526315789473,
  St: 1072.2631578947369,
  Nt: 16993.736842105263,
  Jt: 320.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 14,
  TimeNow: 706.8947368421053,
  S: 1.368421052631579,
  N: 97.52631578947368,
  J: 0.05263157894736842,
  St: 281.0,
  Nt: 18086.36842105263,
  Jt: 18.63157894736842,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 13,
  TimeNow: 738.92,
  S: 3.56,
  N: 94.56,
  J: 0.68,
  St: 742.0,
  Nt: 17488.8,
  Jt: 155.2,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 12,
  TimeNow: 709.65,
  S: 0.6,
  N: 98.4,
  J: 0.0,
  St: 164.55,
  Nt: 18221.45,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 2,
  TimeNow: 693.3846153846154,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 6,
  TimeNow: 678.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 21.5,
  Nt: 18364.5,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 10,
  TimeNow: 687.1333333333333,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 23,
  TimeNow: 719.1538461538462,
  S: 0.3076923076923077,
  N: 98.6923076923077,
  J: 0.0,
  St: 68.6923076923077,
  Nt: 18317.30769230769,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 4,
  TimeNow: 687.7272727272727,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 12.727272727272727,
  Nt: 18373.272727272728,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 3,
  TimeNow: 708.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 87.0,
  Nt: 18299.0,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 18,
  TimeNow: 1178.65625,
  S: 8.3125,
  N: 67.0,
  J: 23.15625,
  St: 957.90625,
  Nt: 7294.65625,
  Jt: 2547.40625,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 21,
  TimeNow: 575.7692307692307,
  S: 5.769230769230769,
  N: 93.23076923076923,
  J: 0.0,
  St: 687.4615384615385,
  Nt: 10112.23076923077,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 1,
  TimeNow: 152.28,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3879.0,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 0,
  TimeNow: 485.27777777777777,
  S: 0.9444444444444444,
  N: 96.27777777777777,
  J: 1.7222222222222223,
  St: 104.66666666666667,
  Nt: 8967.055555555555,
  Jt: 190.27777777777777,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 15,
  TimeNow: 1040.35,
  S: 21.55,
  N: 54.05,
  J: 22.9,
  St: 2388.2,
  Nt: 5885.65,
  Jt: 2526.15,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 20,
  TimeNow: 668.0357142857143,
  S: 22.142857142857142,
  N: 72.78571428571429,
  J: 3.642857142857143,
  St: 2450.785714285714,
  Nt: 7916.857142857143,
  Jt: 432.35714285714283,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 17,
  TimeNow: 1606.1290322580646,
  S: 16.225806451612904,
  N: 47.516129032258064,
  J: 34.903225806451616,
  St: 1802.032258064516,
  Nt: 5168.290322580645,
  Jt: 3829.6774193548385,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 22,
  TimeNow: 553.6153846153846,
  S: 0.3076923076923077,
  N: 98.6923076923077,
  J: 0.0,
  St: 53.23076923076923,
  Nt: 10746.76923076923,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 19,
  TimeNow: 766.9459459459459,
  S: 20.89189189189189,
  N: 70.72972972972973,
  J: 6.8108108108108105,
  St: 2312.7297297297296,
  Nt: 7688.972972972973,
  Jt: 798.2432432432432,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 16,
  TimeNow: 1048.842105263158,
  S: 26.31578947368421,
  N: 44.94736842105263,
  J: 27.157894736842106,
  St: 2904.6315789473683,
  Nt: 4916.473684210527,
  Jt: 2978.8947368421054,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 14,
  TimeNow: 1081.157894736842,
  S: 26.94736842105263,
  N: 43.10526315789474,
  J: 28.473684210526315,
  St: 2959.2105263157896,
  Nt: 4709.210526315789,
  Jt: 3131.5789473684213,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 13,
  TimeNow: 1027.56,
  S: 25.2,
  N: 49.84,
  J: 23.52,
  St: 2774.8,
  Nt: 5429.44,
  Jt: 2595.76,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 2,
  TimeNow: 148.53846153846155,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3879.0,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 23,
  TimeNow: 524.8461538461538,
  S: 0.15384615384615385,
  N: 98.84615384615384,
  J: 0.0,
  St: 26.0,
  Nt: 10774.0,
  Jt: 0.0,
}]

const groupByCategory = arr.reduce((group, product) => {
  const { Id } = product
  group[Id] = group[Id] ?? []
  group[Id].push(product)
  return group
}, {})
export default groupByCategory
