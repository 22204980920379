/* eslint-disable eqeqeq */
import data from '../AVg'
import data6 from '../AVg6'
import data7 from '../AVg7'
import data8 from '../AVg8'
import data9 from '../AVg9'

let generatedData

export const getVerticalBarChartData = (themes, id) => {
  var findObject = data.filter(x => {
    if (Object.keys(x).includes(id)) {
      return x
    }
  })
  var n, j, s
  if (findObject.length > 0) {
    n = (findObject[0][id]?.n + 1).toFixed(2)
    j = (findObject[0][id]?.J).toFixed(2)
    s = (findObject[0][id]?.s).toFixed(2)
  } else {
    n = 0
    s = 0
    j = 0
  }
  console.log(findObject)
  if (generatedData) {
    generatedData.datasets[0].data = [n]
    generatedData.datasets[1].data = [s]
    generatedData.datasets[2].data = [j]
  } else {
    generatedData = {
      labels: [''],
      datasets: [
        {
          label: 'اعتيادي',
          backgroundColor: '#29c99d',
          borderColor: 'transparent',
          data: [n],
        },
        {
          label: 'بطئ',
          backgroundColor: '#febc33',
          borderColor: 'transparent',
          data: [s],
        },
        {
          label: 'مزدخم',
          backgroundColor: '#f25727',
          borderColor: 'transparent',
          data: [j],
        },
      ],
      options: {
        legend: {
          position: 'bottom',
          labels: {
            fontColor: '#34495e',
            fontFamily: 'sans-serif',
            fontSize: 14,
            padding: 20,
            usePointStyle: true,
          },
        },
        tooltips: {
          bodyFontSize: 14,
          bodyFontFamily: 'sans-serif',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              max: 100,
              reverse: false,
              beginAtZero: true,
            },
            title: {
              display: true,
              text: 'Month',
            },
          }],
          xAxes: [{
            ticks: {
              fontSize: 8,
            },
          }],
        },
      },
    }
  }
  console.log(generatedData)
  return generatedData
}
let generatedData2
export const getVerticalBarChartData2 = (themes, ids, isTime = false) => {
  var findObject6 = data6.filter(x => {
    if (Object.keys(x).some(r => ids.includes(r))) {
      return x
    }
  })
  var findObject7 = data7.filter(x => {
    if (Object.keys(x).some(r => ids.includes(r))) {
      return x
    }
  })
  var n = []
  var j = []
  var s = []
  var labels = []
  if (findObject6.length > 0) {
    findObject6.forEach((o, index) => {
      const key = Object.keys(o)[0]
      const i = routs.findIndex(s => s.id == key)
      labels.push(routs[i].Name)
      if (!isTime) {
        var fd = ((o[key]?.J) + (o[key]?.s)).toFixed(2)
        var ld = ((returnObhectByKey(findObject7, key)?.J) + (returnObhectByKey(findObject7, key)?.s)).toFixed(2)
        n.push(fd)
        j.push(ld)
        s.push((fd - ld))
      } else {
        var fdt = ((o[key]?.Jt / 1000) + (o[key]?.St / 1000)).toFixed(2)
        var ldt = ((returnObhectByKey(findObject7, key)?.Jt / 1000) + (returnObhectByKey(findObject7, key)?.St / 1000)).toFixed(2)
        n.push(fdt)
        j.push(ldt)
        s.push((fdt - ldt).toFixed(2))
      }
    })
  } else {
    n.push(0)
    s.push(0)
    j.push(0)
  }
  if (generatedData2) {
    generatedData2.labels = labels
    generatedData2.datasets[0].data = n
    generatedData2.datasets[1].data = j
    generatedData2.datasets[2].data = s
  } else {
    generatedData2 = {
      labels: labels,
      datasets: [
        {
          label: 'اليوم الأول',
          backgroundColor: '#29c99d',
          borderColor: 'transparent',
          data: n,
        },
        {
          label: 'اليوم الثانى',
          backgroundColor: '#febc33',
          borderColor: 'transparent',
          data: j,
        },
        {
          label: 'الفرق',
          backgroundColor: '#f25727',
          borderColor: 'transparent',
          data: s,
        },
      ],
      options: {
        legend: {
          position: 'bottom',
          labels: {
            fontColor: '#34495e',
            fontFamily: 'sans-serif',
            fontSize: 14,
            padding: 20,
            usePointStyle: true,
          },
        },
        tooltips: {
          bodyFontSize: 14,
          bodyFontFamily: 'sans-serif',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              min: -1,
              max: 100,
              reverse: false,
              beginAtZero: true,
            },
            title: {
              display: true,
              text: 'النسبة',
            },
          }],
        },
      },
    }
  }
  console.log(generatedData2)
  return generatedData2
}

let generatedData3
export const getVerticalBarChartData3 = (themes, ids, isTime = false) => {
  var findObject7 = data7.filter(x => {
    if (Object.keys(x).some(r => ids.includes(r))) {
      return x
    }
  })
  var findObject8 = data8.filter(x => {
    if (Object.keys(x).some(r => ids.includes(r))) {
      return x
    }
  })
  var n = []
  var j = []
  var s = []
  var labels = []
  if (findObject7.length > 0) {
    findObject7.forEach((o, index) => {
      const key = Object.keys(o)[0]
      const i = routs.findIndex(s => s.id == key)
      labels.push(routs[i].Name)
      if (!isTime) {
        var fd = ((o[key]?.J) + (o[key]?.s)).toFixed(2)
        var ld = ((returnObhectByKey(findObject8, key)?.J) + (returnObhectByKey(findObject8, key)?.s)).toFixed(2)
        n.push(fd)
        j.push(ld)
        s.push((fd - ld).toFixed(2))
      } else {
        var fdt = ((o[key]?.Jt / 1000) + (o[key]?.St / 1000)).toFixed(2)
        var ldt = ((returnObhectByKey(findObject8, key)?.Jt / 1000) + (returnObhectByKey(findObject8, key)?.St / 1000)).toFixed(2)
        n.push(fdt)
        j.push(ldt)
        s.push((fdt - ldt).toFixed(2))
      }
    })
  } else {
    n.push(0)
    s.push(0)
    j.push(0)
  }
  if (generatedData3) {
    generatedData3.labels = labels
    generatedData3.datasets[0].data = n
    generatedData3.datasets[1].data = j
    generatedData3.datasets[2].data = s
  } else {
    generatedData3 = {
      labels: labels,
      datasets: [
        {
          label: 'اليوم الثاني',
          backgroundColor: '#29c99d',
          borderColor: 'transparent',
          data: n,
        },
        {
          label: 'اليوم الثالت',
          backgroundColor: '#febc33',
          borderColor: 'transparent',
          data: j,
        },
        {
          label: 'الفرق',
          backgroundColor: '#f25727',
          borderColor: 'transparent',
          data: s,
        },
      ],
      options: {
        legend: {
          position: 'bottom',
          labels: {
            fontColor: '#34495e',
            fontFamily: 'sans-serif',
            fontSize: 14,
            padding: 20,
            usePointStyle: true,
          },
        },
        tooltips: {
          bodyFontSize: 14,
          bodyFontFamily: 'sans-serif',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              min: -1,
              max: 100,
              reverse: false,
              beginAtZero: true,
            },
            title: {
              display: true,
              text: 'النسبة',
            },
          }],
        },
      },
    }
  }
  console.log(generatedData3)
  return generatedData3
}

let generatedData4
export const getVerticalBarChartData4 = (themes, ids, isTime = false) => {
  var findObject8 = data8.filter(x => {
    if (Object.keys(x).some(r => ids.includes(r))) {
      return x
    }
  })
  var findObject9 = data9.filter(x => {
    if (Object.keys(x).some(r => ids.includes(r))) {
      return x
    }
  })
  var n = []
  var j = []
  var s = []
  var labels = []
  if (findObject8.length > 0) {
    findObject8.forEach((o, index) => {
      const key = Object.keys(o)[0]
      const i = routs.findIndex(s => s.id == key)
      labels.push(routs[i].Name)
      if (!isTime) {
        var fd = ((o[key]?.J) + (o[key]?.s)).toFixed(2)
        var ld = ((returnObhectByKey(findObject9, key)?.J) + (returnObhectByKey(findObject9, key)?.s)).toFixed(2)
        n.push(fd)
        j.push(ld)
        s.push((fd - ld).toFixed(2))
      } else {
        var fdt = ((o[key]?.Jt / 1000) + (o[key]?.St / 1000)).toFixed(2)
        var ldt = ((returnObhectByKey(findObject9, key)?.Jt / 1000) + (returnObhectByKey(findObject9, key)?.St / 1000)).toFixed(2)
        n.push(fdt)
        j.push(ldt)
        s.push((fdt - ldt).toFixed(2))
      }
    })
  } else {
    n.push(0)
    s.push(0)
    j.push(0)
  }
  if (generatedData4) {
    generatedData4.labels = labels
    generatedData4.datasets[0].data = n
    generatedData4.datasets[1].data = j
    generatedData4.datasets[2].data = s
  } else {
    generatedData4 = {
      labels: labels,
      datasets: [
        {
          label: 'اليوم الثالت',
          backgroundColor: '#29c99d',
          borderColor: 'transparent',
          data: n,
        },
        {
          label: 'اليوم الرابع',
          backgroundColor: '#febc33',
          borderColor: 'transparent',
          data: j,
        },
        {
          label: 'الفرق',
          backgroundColor: '#f25727',
          borderColor: 'transparent',
          data: s,
        },
      ],
      options: {
        legend: {
          position: 'bottom',
          labels: {
            fontColor: '#34495e',
            fontFamily: 'sans-serif',
            fontSize: 14,
            padding: 20,
            usePointStyle: true,
          },
        },
        tooltips: {
          bodyFontSize: 14,
          bodyFontFamily: 'sans-serif',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              min: -1,
              max: 100,
              reverse: false,
              beginAtZero: true,
            },
            title: {
              display: true,
              text: 'النسبة',
            },
          }],
        },
      },
    }
  }
  console.log(generatedData4)
  return generatedData4
}

function returnObhectByKey (list, id) {
  let obj
  for (let i = 0; i < list.length; i++) {
    const element = list[i][id]
    if (typeof element === 'object') {
      obj = element
    }
  }
  return obj
}
const routs = [
  {
    id: '24fBwLf4cYSZpeKnJh7z',
    Name: 'Al Cheikh Hasan Ibn Hussein Ibn Ali',
  },
  {
    id: 'A6noUNQaeqBvu9nw2W9l',
    Name: 'Al Imam Saud Bin Abdulaziz Bin Mohammed Rd',
  },
  {
    id: 'AK045s1PEwVSnG7u00jZ',
    Name: 'King Fahd Road',
  },
  {
    id: 'D4QgJsuHSWponcYfbvR0',
    Name: 'King Fahd Road',
  },
  {
    id: 'EpkvexvRz5okcvZNB9Uz',
    Name: 'Leap Blue Parking 2',
  },
  {
    id: 'FYCoCtE3WUyaAPEE64Ng',
    Name: 'Al Janadriyah Rd',
  },
  {
    id: 'FzRd6op64jHeWdsfQBoL',
    Name: 'Al Thoumamah Road 2',
  },
  {
    id: 'GAbS3czb5upPGpUZCaYv',
    Name: 'Airport Road to Roundabout',
  },
  {
    id: 'GnSJS1Tf4aZHLFVixC1c',
    Name: 'Red Parking to AirportRoad  Exit',
  },
  {
    id: 'GuUxAcTrgDuZq36ona8p',
    Name: 'Prince Saud Bin Mohammed Bin Muqrin Rd',
  },
  {
    id: 'JJ0MT1LnOLOzJAa1uWEv',
    Name: 'Al Thoumamah Road',
  },
  {
    id: 'JZiIa8J7JXL9yIWQtDKC',
    Name: 'Northern Ring Road (طريق الدمام الفرعي)',
  },
  {
    id: 'KK6CpiSlPPG6ChUKmN5g',
    Name: 'King Abdullah Road',
  },
  {
    id: 'KTabvSUbDkLllhZ9M0fz',
    Name: 'Al Sheikh Jaber Alahmad Alsabah ',
  },
  {
    id: 'Lh8UanQXJltQacha7VGq',
    Name: 'Thumamah to 2nd roundabout',
  },
  {
    id: 'MvAnpzEUy1wRjUknb2Wq',
    Name: 'Airport Leap Live 1',
  },
  {
    id: 'NkQgYfbxHPHM9xFmIzWK',
    Name: 'Airport to Mosque ',
  },
  {
    id: 'NlrvbOqaEZ95gJLZQJHK',
    Name: 'Prince Turki Bin Abdulaziz Al Awal Rd',
  },
  {
    id: 'PElsdRZHXvD54ZwV4qsi',
    Name: 'Airport Road 2',
  },
  {
    id: 'Q5nsyZclh4X6utoxdCBr',
    Name: 'Al Takhassousi Rd',
  },
  {
    id: 'QTnF9muZIf3AKWIzWJqq',
    Name: 'Airport Leap Live 2',
  },
  {
    id: 'Qz3oMEwGZDIqB05vTeD5',
    Name: 'Thumamah East  Live from Imam U',
  },
  {
    id: 'Spo0PQkpY5nEurTi1JZV',
    Name: 'Al Sahaba Road',
  },
  {
    id: 'SvsubPFf0KJzL2nE7zav',
    Name: 'Airport Leap Live 3',
  },
  {
    id: 'TJx7jVaVTtCaRhj8chu4',
    Name: 'King Khaled Airport Road',
  },
  {
    id: 'U8EjK5bVsRkjAZ9HEc0G',
    Name: 'Airport Entrance to Blue Parking',
  },
  {
    id: 'VHifwHAt6iuP9XKJQdK6',
    Name: 'Thumamah East Live to 4th Uturn',
  },
  {
    id: 'ZMDmlhS8v6rgDCJZPWo0',
    Name: 'Airport Rd  2',
  },
  {
    id: 'ZTXeIZP0br2MbYiKaX1G',
    Name: 'Thumamah Entrance to Red Parking',
  },
  {
    id: 'bkIY4932t5SVR7hf8fRX',
    Name: 'Al Imam Saud Bin Abdulaziz Bin Mohammed Rd 2',
  },
  {
    id: 'cRyuvsyxZFjaFD1hqjfl',
    Name: 'Airport Road',
  },
  {
    id: 'fdNNCQ6kPiglUszPfuR2',
    Name: 'Al Sheikh Jaber Alahmad Alsabah ',
  },
  {
    id: 'hZrY5SFfLR6WbPUf2bMJ',
    Name: 'Red Parking to Airport Road Exit 3',
  },
  {
    id: 'jIIiFSIkF4FGpc3d8FGX',
    Name: 'Thumamah East Live to 1st Uturn',
  },
  {
    id: 'jovVzfQYAGFJa3xfBccZ',
    Name: 'Airport Rd 1',
  },
  {
    id: 'kuGkReF6XUPgehdO2Obd',
    Name: 'Airport to Red Entrance 2',
  },
  {
    id: 'lD4aGchJfjRI2LTzcXE0',
    Name: 'King Fahd Road',
  },
  {
    id: 'lF2ALyrRClB2hYH5aUKY',
    Name: 'Thumamah East Live to 2nd Uturn',
  },
  {
    id: 'm7jwb0WGHCZ86rI2Mlgs',
    Name: 'Thumamah West to Airport',
  },
  {
    id: 'qJjMaaYZxNoHTnmI8FGY',
    Name: 'Red Parking to Airport Road Exit 2',
  },
  {
    id: 'qpGOFcJPWgOaCilvlspC',
    Name: 'Thumamah Entrance to Mosque Parking',
  },
  {
    id: 'qrfVyESuKWArso9aUxGe',
    Name: 'Said Ibn Zayd Rd',
  },
  {
    id: 'qwET8nsY5FqeF3tcphSJ',
    Name: 'Airport Road 1',
  },
  {
    id: 'sCRXgLM8YvJVvw0h8R2d',
    Name: 'Leap Blue Parking',
  },
  {
    id: 'u5qDYGjH2IVqp1qUHfRb',
    Name: 'Thumamah East Live to 3rd Uturn',
  },
  {
    id: 'vLYYPYJhRtmdqMvfqqdl',
    Name: 'Airport Entrance to Red Parking',
  },
  {
    id: 'yaXl7CiWAjijl2XPONca',
    Name: 'Northern Ring Road (طريق الدمام الفرعي)',
  },
  {
    id: 'zo7i6MU1XWSiVHPOcuho',
    Name: 'King Salman Bin Abdulaziz Road',
  },
]
// ({
//   labels: ['Al Sahaba Road', 'Al Imam Saud Bin Abdulaziz Bin Mohammed Rd',
//     'Airport Rd 2', 'Northern Ring Road (طريق الدمام الفرعي)',
//     'Al Cheikh Hasan Ibn Hussein Ibn Ali',
//     'Said Ibn Zayd Rd', 'King Fahd Road', 'Al Takhassousi Rd',
//     'Airport Road', 'King Fahd Road',
//     'Al Imam Saud Bin Abdulaziz Bin Mohammed Rd 2'],
//   datasets: [
//     {
//       label: 'اعتيادي',
//       backgroundColor: '#29c99d',
//       borderColor: 'transparent',
//       data: [32, 6, 15, 92, 31, 59, 9, 15, 100, 58, 83],
//     },
//     {
//       label: 'بطئ',
//       backgroundColor: '#febc33',
//       borderColor: 'transparent',
//       data: [33, 27, 35, 16, 97, 3, 56, 39, 66, 4, 0],
//     },
//     {
//       label: 'مزدحم',
//       backgroundColor: '#f25727',
//       borderColor: 'transparent',
//       data: [26, 1, 8, 96, 10, 88, 79, 46, 51, 84, 38],
//     },
//   ],
// })
