const arr = [{
  '24fBwLf4cYSZpeKnJh7z': {
    s: 8.45609756097561,
    n: 90.4219512195122,
    J: 0.1048780487804878,
    St: 149.08048780487806,
    Nt: 1509.9853658536585,
    Jt: 1.9341463414634146,
  },
}, {
  A6noUNQaeqBvu9nw2W9l: {
    s: 15.421951219512195,
    n: 71.50975609756098,
    J: 11.770731707317074,
    St: 379.8414634146341,
    Nt: 1725.3121951219512,
    Jt: 288.43658536585366,
  },
}, {
  AK045s1PEwVSnG7u00jZ: {
    s: 20.582926829268292,
    n: 59.890243902439025,
    J: 18.258536585365853,
    St: 918.6878048780488,
    Nt: 2649.5073170731707,
    Jt: 809.9146341463414,
  },
}, {
  D4QgJsuHSWponcYfbvR0: {
    s: 27.00244498777506,
    n: 33.90220048899756,
    J: 37.84596577017115,
    St: 1530.4180929095355,
    Nt: 1919.1002444987776,
    Jt: 2136.924205378973,
  },
}, {
  EpkvexvRz5okcvZNB9Uz: {
    s: 0.0,
    n: 99.0,
    J: 0.0,
    St: 0.0,
    Nt: 337.0,
    Jt: 0.0,
  },
}, {
  FYCoCtE3WUyaAPEE64Ng: {
    s: 9.178048780487805,
    n: 85.93170731707318,
    J: 3.624390243902439,
    St: 1203.1951219512196,
    Nt: 10821.729268292684,
    Jt: 484.07560975609755,
  },
}, {
  FzRd6op64jHeWdsfQBoL: {
    s: 8.685365853658537,
    n: 80.51951219512195,
    J: 9.441463414634146,
    St: 2190.1560975609755,
    Nt: 19250.18536585366,
    Jt: 2337.2463414634144,
  },
}, {
  GAbS3czb5upPGpUZCaYv: {
    s: 12.221951219512196,
    n: 75.86585365853658,
    J: 10.61951219512195,
    St: 234.32682926829267,
    Nt: 1418.7756097560975,
    Jt: 204.2,
  },
}, {
  GnSJS1Tf4aZHLFVixC1c: {
    s: 15.792682926829269,
    n: 81.34390243902439,
    J: 1.6682926829268292,
    St: 799.8975609756097,
    Nt: 4011.958536585366,
    Jt: 93.05853658536586,
  },
}, {
  GuUxAcTrgDuZq36ona8p: {
    s: 4.713936430317848,
    n: 90.70171149144254,
    J: 3.1246943765281174,
    St: 489.87286063569684,
    Nt: 8478.552567237164,
    Jt: 331.5745721271394,
  },
}, {
  JJ0MT1LnOLOzJAa1uWEv: {
    s: 9.28117359413203,
    n: 82.51344743276283,
    J: 6.787286063569682,
    St: 2727.911980440098,
    Nt: 23010.04400977995,
    Jt: 1994.8459657701712,
  },
}, {
  JZiIa8J7JXL9yIWQtDKC: {
    s: 11.838630806845966,
    n: 75.93398533007336,
    J: 10.841075794621027,
    St: 2735.677261613692,
    Nt: 17040.185819070906,
    Jt: 2504.9339853300735,
  },
}, {
  KK6CpiSlPPG6ChUKmN5g: {
    s: 11.692682926829269,
    n: 72.49756097560976,
    J: 14.44390243902439,
    St: 2757.417073170732,
    Nt: 16585.534146341462,
    Jt: 3361.048780487805,
  },
}, {
  KTabvSUbDkLllhZ9M0fz: {
    s: 1.051219512195122,
    n: 97.87560975609756,
    J: 0.07317073170731707,
    St: 20.853658536585368,
    Nt: 1907.7268292682927,
    Jt: 1.4195121951219511,
  },
}, {
  Lh8UanQXJltQacha7VGq: {
    s: 4.182926829268292,
    n: 93.92926829268292,
    J: 0.7829268292682927,
    St: 47.821951219512194,
    Nt: 1005.280487804878,
    Jt: 8.897560975609757,
  },
}, {
  MvAnpzEUy1wRjUknb2Wq: {
    s: 7.436274509803922,
    n: 53.52205882352941,
    J: 37.963235294117645,
    St: 134.25,
    Nt: 949.9852941176471,
    Jt: 676.7647058823529,
  },
}, {
  NkQgYfbxHPHM9xFmIzWK: {
    s: 17.26161369193154,
    n: 67.78484107579462,
    J: 13.442542787286063,
    St: 260.1833740831296,
    Nt: 1003.1955990220049,
    Jt: 203.80684596577018,
  },
}, {
  NlrvbOqaEZ95gJLZQJHK: {
    s: 3.569682151589242,
    n: 87.85574572127139,
    J: 7.188264058679707,
    St: 173.15892420537898,
    Nt: 3820.7677261613694,
    Jt: 337.07334963325184,
  },
}, {
  PElsdRZHXvD54ZwV4qsi: {
    s: 17.180487804878048,
    n: 50.97317073170732,
    J: 30.60487804878049,
    St: 1293.6170731707316,
    Nt: 3811.7170731707315,
    Jt: 2278.6658536585364,
  },
}, {
  Q5nsyZclh4X6utoxdCBr: {
    s: 2.7365853658536587,
    n: 94.56341463414634,
    J: 1.6439024390243901,
    St: 104.36341463414634,
    Nt: 3463.3853658536586,
    Jt: 61.25121951219512,
  },
}, {
  QTnF9muZIf3AKWIzWJqq: {
    s: 6.442542787286063,
    n: 56.57946210268948,
    J: 35.84841075794621,
    St: 239.84107579462102,
    Nt: 2052.0171149144253,
    Jt: 1300.1418092909535,
  },
}, {
  Qz3oMEwGZDIqB05vTeD5: {
    s: 4.917073170731707,
    n: 79.05609756097562,
    J: 14.831707317073171,
    St: 192.0731707317073,
    Nt: 2937.7609756097563,
    Jt: 555.1317073170732,
  },
}, {
  Spo0PQkpY5nEurTi1JZV: {
    s: 4.548780487804878,
    n: 90.39512195121951,
    J: 3.7853658536585364,
    St: 90.60975609756098,
    Nt: 1635.8536585365853,
    Jt: 76.53414634146341,
  },
}, {
  SvsubPFf0KJzL2nE7zav: {
    s: 6.382352941176471,
    n: 68.46323529411765,
    J: 23.96078431372549,
    St: 395.39460784313724,
    Nt: 4094.5882352941176,
    Jt: 1435.8063725490197,
  },
}, {
  U8EjK5bVsRkjAZ9HEc0G: {
    s: 20.11219512195122,
    n: 25.817073170731707,
    J: 53.046341463414635,
    St: 76.00243902439024,
    Nt: 96.52926829268293,
    Jt: 198.46829268292683,
  },
}, {
  VHifwHAt6iuP9XKJQdK6: {
    s: 15.0,
    n: 68.34718826405867,
    J: 15.28117359413203,
    St: 1156.8606356968214,
    Nt: 5164.635696821516,
    Jt: 1170.5036674816627,
  },
}, {
  ZMDmlhS8v6rgDCJZPWo0: {
    s: 25.364303178484107,
    n: 46.75305623471883,
    J: 26.56723716381418,
    St: 1244.963325183374,
    Nt: 2285.921760391198,
    Jt: 1302.8508557457212,
  },
}, {
  ZTXeIZP0br2MbYiKaX1G: {
    s: 14.039024390243902,
    n: 83.46585365853659,
    J: 1.248780487804878,
    St: 325.15365853658534,
    Nt: 1880.7365853658537,
    Jt: 32.35609756097561,
  },
}, {
  bkIY4932t5SVR7hf8fRX: {
    s: 10.43170731707317,
    n: 77.59512195121951,
    J: 10.673170731707318,
    St: 271.95365853658535,
    Nt: 1948.370731707317,
    Jt: 272.1780487804878,
  },
}, {
  cRyuvsyxZFjaFD1hqjfl: {
    s: 18.12682926829268,
    n: 59.4780487804878,
    J: 21.078048780487805,
    St: 2442.0170731707317,
    Nt: 7903.731707317073,
    Jt: 2814.2512195121953,
  },
}, {
  fdNNCQ6kPiglUszPfuR2: {
    s: 16.53170731707317,
    n: 72.54146341463415,
    J: 9.495121951219513,
    St: 1492.5439024390244,
    Nt: 6406.082926829268,
    Jt: 870.3731707317073,
  },
}, {
  hZrY5SFfLR6WbPUf2bMJ: {
    s: 10.775061124694377,
    n: 86.21760391198045,
    J: 1.8459657701711492,
    St: 231.82151589242054,
    Nt: 1800.9584352078239,
    Jt: 40.479217603911984,
  },
}, {
  jIIiFSIkF4FGpc3d8FGX: {
    s: 28.914634146341463,
    n: 41.34634146341463,
    J: 28.490243902439026,
    St: 571.4731707317073,
    Nt: 816.2073170731708,
    Jt: 561.4317073170731,
  },
}, {
  jovVzfQYAGFJa3xfBccZ: {
    s: 22.034146341463416,
    n: 29.670731707317074,
    J: 47.02439024390244,
    St: 1144.9048780487806,
    Nt: 1536.860975609756,
    Jt: 2417.6268292682926,
  },
}, {
  kuGkReF6XUPgehdO2Obd: {
    s: 19.797066014669927,
    n: 70.30317848410758,
    J: 8.47921760391198,
    St: 522.8508557457212,
    Nt: 1825.6088019559902,
    Jt: 226.46943765281173,
  },
}, {
  lD4aGchJfjRI2LTzcXE0: {
    s: 27.798525798525798,
    n: 58.13513513513514,
    J: 12.862407862407862,
    St: 1670.8697788697789,
    Nt: 3479.253071253071,
    Jt: 773.8771498771499,
  },
}, {
  lF2ALyrRClB2hYH5aUKY: {
    s: 23.76341463414634,
    n: 50.604878048780485,
    J: 24.33170731707317,
    St: 884.3707317073171,
    Nt: 1874.1268292682928,
    Jt: 902.5024390243902,
  },
}, {
  m7jwb0WGHCZ86rI2Mlgs: {
    s: 5.486552567237164,
    n: 86.04889975550122,
    J: 7.053789731051345,
    St: 628.6063569682152,
    Nt: 9240.283618581907,
    Jt: 797.200488997555,
  },
}, {
  qJjMaaYZxNoHTnmI8FGY: {
    s: 18.850490196078432,
    n: 78.11764705882354,
    J: 1.7794117647058822,
    St: 940.2328431372549,
    Nt: 3817.752450980392,
    Jt: 99.62990196078431,
  },
}, {
  qpGOFcJPWgOaCilvlspC: {
    s: 5.139024390243902,
    n: 92.92439024390244,
    J: 0.7902439024390244,
    St: 70.97317073170731,
    Nt: 1170.5,
    Jt: 11.526829268292683,
  },
}, {
  qrfVyESuKWArso9aUxGe: {
    s: 5.548780487804878,
    n: 91.2609756097561,
    J: 1.9926829268292683,
    St: 188.8219512195122,
    Nt: 2888.9756097560976,
    Jt: 70.20243902439024,
  },
}, {
  qwET8nsY5FqeF3tcphSJ: {
    s: 2.534313725490196,
    n: 84.9656862745098,
    J: 11.392156862745098,
    St: 260.82107843137254,
    Nt: 7969.549019607844,
    Jt: 1079.262254901961,
  },
}, {
  sCRXgLM8YvJVvw0h8R2d: {
    s: 0.5441176470588235,
    n: 98.14950980392157,
    J: 0.30637254901960786,
    St: 7.617647058823529,
    Nt: 1212.0931372549019,
    Jt: 4.28921568627451,
  },
}, {
  u5qDYGjH2IVqp1qUHfRb: {
    s: 16.821951219512194,
    n: 63.78048780487805,
    J: 18.065853658536586,
    St: 1064.8975609756098,
    Nt: 3974.0634146341463,
    Jt: 1136.0390243902439,
  },
}, {
  vLYYPYJhRtmdqMvfqqdl: {
    s: 42.58924205378973,
    n: 24.12713936430318,
    J: 32.23227383863081,
    St: 283.36919315403424,
    Nt: 159.85330073349633,
    Jt: 212.8166259168704,
  },
}, {
  yaXl7CiWAjijl2XPONca: {
    s: 10.065853658536586,
    n: 70.45853658536585,
    J: 18.24878048780488,
    St: 574.0682926829269,
    Nt: 3908.826829268293,
    Jt: 1018.3731707317073,
  },
}, {
  zo7i6MU1XWSiVHPOcuho: {
    s: 1.4853658536585366,
    n: 96.25853658536586,
    J: 1.1195121951219513,
    St: 315.4317073170732,
    Nt: 17843.136585365854,
    Jt: 227.43170731707318,
  },
}, {
  TJx7jVaVTtCaRhj8chu4: {
    s: 14.160130718954248,
    n: 70.41503267973856,
    J: 14.088235294117647,
    St: 1572.2450980392157,
    Nt: 6719.558823529412,
    Jt: 1558.2320261437908,
  },
}]
arr.sort(function (a, b) {
  var x = Object.keys(a)[0]; var y = Object.keys(b)[0]
  return ((x < y) ? -1 : ((x > y) ? 1 : 0))
})
export default arr
