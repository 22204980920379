const arr = [{
  A6noUNQaeqBvu9nw2W9l: {
    s: 14.87,
    n: 71.57333333333334,
    J: 12.23,
    St: 366.5733333333333,
    Nt: 1727.4266666666667,
    Jt: 299.3333333333333,
  },
}, {
  AK045s1PEwVSnG7u00jZ: {
    s: 22.593333333333334,
    n: 64.72333333333333,
    J: 11.466666666666667,
    St: 1006.74,
    Nt: 2861.98,
    Jt: 509.4033333333333,
  },
}, {
  D4QgJsuHSWponcYfbvR0: {
    s: 32.02333333333333,
    n: 28.09,
    J: 38.50666666666667,
    St: 1814.2266666666667,
    Nt: 1598.07,
    Jt: 2174.346666666667,
  },
}, {
  EpkvexvRz5okcvZNB9Uz: {
    s: 0.0,
    n: 99.0,
    J: 0.0,
    St: 0.0,
    Nt: 337.0,
    Jt: 0.0,
  },
}, {
  FYCoCtE3WUyaAPEE64Ng: {
    s: 9.332225913621263,
    n: 84.23255813953489,
    J: 5.1262458471760795,
    St: 1216.7541528239203,
    Nt: 10613.97342192691,
    Jt: 678.2724252491695,
  },
}, {
  FzRd6op64jHeWdsfQBoL: {
    s: 9.08970099667774,
    n: 78.88039867109634,
    J: 10.601328903654485,
    St: 2323.65780730897,
    Nt: 19238.129568106313,
    Jt: 2633.378737541528,
  },
}, {
  GAbS3czb5upPGpUZCaYv: {
    s: 17.79,
    n: 74.85,
    J: 6.1433333333333335,
    St: 337.31,
    Nt: 1399.88,
    Jt: 120.02333333333333,
  },
}, {
  GnSJS1Tf4aZHLFVixC1c: {
    s: 10.956521739130435,
    n: 86.4648829431438,
    J: 1.3444816053511706,
    St: 564.0468227424749,
    Nt: 4264.836120401338,
    Jt: 75.91973244147157,
  },
}, {
  GuUxAcTrgDuZq36ona8p: {
    s: 5.6843853820598005,
    n: 89.48172757475083,
    J: 3.4053156146179404,
    St: 577.3820598006645,
    Nt: 8368.495016611296,
    Jt: 354.12292358803984,
  },
}, {
  JJ0MT1LnOLOzJAa1uWEv: {
    s: 7.259136212624584,
    n: 85.11960132890366,
    J: 6.136212624584718,
    St: 2143.156146179402,
    Nt: 23611.49833887043,
    Jt: 1821.2292358803986,
  },
}, {
  JZiIa8J7JXL9yIWQtDKC: {
    s: 11.887043189368772,
    n: 73.16279069767442,
    J: 13.548172757475083,
    St: 2742.6843853820596,
    Nt: 16425.1926910299,
    Jt: 3112.9302325581393,
  },
}, {
  KK6CpiSlPPG6ChUKmN5g: {
    s: 12.50498338870432,
    n: 70.31893687707641,
    J: 15.877076411960132,
    St: 2940.2890365448507,
    Nt: 16090.44850498339,
    Jt: 3682.87707641196,
  },
}, {
  KTabvSUbDkLllhZ9M0fz: {
    s: 0.6366666666666667,
    n: 98.36333333333333,
    J: 0.0,
    St: 12.623333333333333,
    Nt: 1917.3766666666668,
    Jt: 0.0,
  },
}, {
  Lh8UanQXJltQacha7VGq: {
    s: 3.71,
    n: 94.38666666666667,
    J: 0.8366666666666667,
    St: 41.376666666666665,
    Nt: 1011.1533333333333,
    Jt: 9.47,
  },
}, {
  MvAnpzEUy1wRjUknb2Wq: {
    s: 17.18936877076412,
    n: 51.116279069767444,
    J: 30.46843853820598,
    St: 308.0897009966777,
    Nt: 908.1162790697674,
    Jt: 544.7940199335549,
  },
}, {
  NkQgYfbxHPHM9xFmIzWK: {
    s: 24.06354515050167,
    n: 66.61872909698997,
    J: 7.88628762541806,
    St: 358.7792642140468,
    Nt: 985.6588628762541,
    Jt: 122.65886287625418,
  },
}, {
  NlrvbOqaEZ95gJLZQJHK: {
    s: 3.823920265780731,
    n: 85.42524916943522,
    J: 9.368770764119601,
    St: 185.3156146179402,
    Nt: 3715.8372093023254,
    Jt: 429.843853820598,
  },
}, {
  PElsdRZHXvD54ZwV4qsi: {
    s: 18.726666666666667,
    n: 54.70333333333333,
    J: 25.363333333333333,
    St: 1408.5766666666666,
    Nt: 4084.8566666666666,
    Jt: 1890.5666666666666,
  },
}, {
  Q5nsyZclh4X6utoxdCBr: {
    s: 2.4651162790697674,
    n: 96.53488372093024,
    J: 0.0,
    St: 100.5514950166113,
    Nt: 3734.2425249169437,
    Jt: 0.0,
  },
}, {
  QTnF9muZIf3AKWIzWJqq: {
    s: 15.475083056478406,
    n: 73.69102990033223,
    J: 9.691029900332225,
    St: 316.96013289036546,
    Nt: 1857.4418604651162,
    Jt: 198.00996677740864,
  },
}, {
  Qz3oMEwGZDIqB05vTeD5: {
    s: 5.006688963210703,
    n: 77.79598662207358,
    J: 16.006688963210703,
    St: 194.4314381270903,
    Nt: 2890.9966555183946,
    Jt: 599.4615384615385,
  },
}, {
  Spo0PQkpY5nEurTi1JZV: {
    s: 4.401993355481728,
    n: 90.8970099667774,
    J: 3.3654485049833887,
    St: 89.59468438538207,
    Nt: 1645.362126245847,
    Jt: 68.04318936877077,
  },
}, {
  SvsubPFf0KJzL2nE7zav: {
    s: 6.033333333333333,
    n: 92.82333333333334,
    J: 0.10666666666666667,
    St: 144.33333333333334,
    Nt: 2961.096666666667,
    Jt: 3.2733333333333334,
  },
}, {
  TJx7jVaVTtCaRhj8chu4: {
    s: 10.61,
    n: 83.51,
    J: 4.68,
    St: 422.29333333333335,
    Nt: 3268.7166666666667,
    Jt: 188.00333333333333,
  },
}, {
  U8EjK5bVsRkjAZ9HEc0G: {
    s: 20.760797342192692,
    n: 20.205980066445182,
    J: 58.006644518272424,
    St: 78.69767441860465,
    Nt: 75.64119601328903,
    Jt: 216.6611295681063,
  },
}, {
  VHifwHAt6iuP9XKJQdK6: {
    s: 16.259136212624586,
    n: 62.91029900332226,
    J: 19.411960132890364,
    St: 1248.9036544850499,
    Nt: 4758.215946843854,
    Jt: 1484.8803986710964,
  },
}, {
  ZMDmlhS8v6rgDCJZPWo0: {
    s: 29.362126245847175,
    n: 32.096345514950166,
    J: 37.222591362126245,
    St: 1438.4983388704318,
    Nt: 1574.0897009966777,
    Jt: 1821.7707641196014,
  },
}, {
  ZTXeIZP0br2MbYiKaX1G: {
    s: 9.246666666666666,
    n: 88.39,
    J: 0.9866666666666667,
    St: 218.2,
    Nt: 1990.9366666666667,
    Jt: 29.053333333333335,
  },
}, {
  bkIY4932t5SVR7hf8fRX: {
    s: 12.286666666666667,
    n: 76.11,
    J: 10.39,
    St: 316.45666666666665,
    Nt: 1910.25,
    Jt: 265.6433333333333,
  },
}, {
  cRyuvsyxZFjaFD1hqjfl: {
    s: 22.13621262458472,
    n: 54.029900332225914,
    J: 22.40531561461794,
    St: 2972.906976744186,
    Nt: 7182.538205980067,
    Jt: 3004.5548172757476,
  },
}, {
  fdNNCQ6kPiglUszPfuR2: {
    s: 16.664451827242527,
    n: 69.82059800664452,
    J: 12.109634551495017,
    St: 1500.421926910299,
    Nt: 6169.538205980067,
    Jt: 1099.0398671096345,
  },
}, {
  hZrY5SFfLR6WbPUf2bMJ: {
    s: 6.179401993355482,
    n: 91.01328903654485,
    J: 1.6744186046511629,
    St: 134.72757475083057,
    Nt: 1902.189368770764,
    Jt: 36.38538205980066,
  },
}, {
  jIIiFSIkF4FGpc3d8FGX: {
    s: 28.327759197324415,
    n: 32.66220735785953,
    J: 37.62876254180602,
    St: 560.2642140468228,
    Nt: 648.113712374582,
    Jt: 740.809364548495,
  },
}, {
  jovVzfQYAGFJa3xfBccZ: {
    s: 27.953333333333333,
    n: 29.066666666666666,
    J: 41.76,
    St: 1449.1133333333332,
    Nt: 1503.2066666666667,
    Jt: 2146.68,
  },
}, {
  kuGkReF6XUPgehdO2Obd: {
    s: 19.694352159468437,
    n: 73.91694352159469,
    J: 5.06312292358804,
    St: 519.5647840531561,
    Nt: 1917.2292358803986,
    Jt: 138.08305647840533,
  },
}, {
  lD4aGchJfjRI2LTzcXE0: {
    s: 28.256666666666668,
    n: 48.00666666666667,
    J: 22.446666666666665,
    St: 1701.05,
    Nt: 2875.6866666666665,
    Jt: 1347.2633333333333,
  },
}, {
  lF2ALyrRClB2hYH5aUKY: {
    s: 25.475083056478404,
    n: 38.51162790697674,
    J: 34.62458471760797,
    St: 948.0531561461794,
    Nt: 1429.983388704319,
    Jt: 1282.9634551495017,
  },
}, {
  m7jwb0WGHCZ86rI2Mlgs: {
    s: 4.730897009966777,
    n: 88.21926910299004,
    J: 5.644518272425249,
    St: 550.9867109634552,
    Nt: 9471.598006644519,
    Jt: 643.5581395348837,
  },
}, {
  qJjMaaYZxNoHTnmI8FGY: {
    s: 14.338870431893687,
    n: 82.76079734219269,
    J: 1.564784053156146,
    St: 723.1528239202657,
    Nt: 4045.1461794019933,
    Jt: 89.48504983388705,
  },
}, {
  qpGOFcJPWgOaCilvlspC: {
    s: 4.441471571906354,
    n: 93.48160535117057,
    J: 0.8963210702341137,
    St: 61.01003344481605,
    Nt: 1177.9096989966556,
    Jt: 14.080267558528428,
  },
}, {
  qrfVyESuKWArso9aUxGe: {
    s: 4.9397993311036785,
    n: 92.27759197324414,
    J: 1.5585284280936456,
    St: 169.02341137123747,
    Nt: 2923.8361204013377,
    Jt: 55.1438127090301,
  },
}, {
  qwET8nsY5FqeF3tcphSJ: {
    s: 5.840531561461794,
    n: 86.85714285714286,
    J: 6.176079734219269,
    St: 574.0,
    Nt: 8134.677740863787,
    Jt: 590.0730897009967,
  },
}, {
  sCRXgLM8YvJVvw0h8R2d: {
    s: 0.6733333333333333,
    n: 98.15666666666667,
    J: 0.17,
    St: 9.426666666666666,
    Nt: 1212.1933333333334,
    Jt: 2.38,
  },
}, {
  u5qDYGjH2IVqp1qUHfRb: {
    s: 18.345514950166113,
    n: 57.245847176079735,
    J: 23.003322259136212,
    St: 1159.7076411960134,
    Nt: 3569.813953488372,
    Jt: 1445.4784053156145,
  },
}, {
  vLYYPYJhRtmdqMvfqqdl: {
    s: 45.80730897009967,
    n: 19.976744186046513,
    J: 33.12624584717608,
    St: 304.51495016611295,
    Nt: 132.49501661129568,
    Jt: 219.02325581395348,
  },
}, {
  yaXl7CiWAjijl2XPONca: {
    s: 13.233333333333333,
    n: 62.39666666666667,
    J: 23.036666666666665,
    St: 753.0233333333333,
    Nt: 3463.0133333333333,
    Jt: 1284.8633333333332,
  },
}, {
  zo7i6MU1XWSiVHPOcuho: {
    s: 2.3533333333333335,
    n: 93.43,
    J: 3.0633333333333335,
    St: 477.45,
    Nt: 17313.7,
    Jt: 594.85,
  },
}, {
  '24fBwLf4cYSZpeKnJh7z': {
    s: 8.230240549828178,
    n: 90.55326460481099,
    J: 0.18213058419243985,
    St: 145.00343642611685,
    Nt: 1512.5910652920961,
    Jt: 3.4054982817869415,
  },
}]
arr.sort(function (a, b) {
  var x = Object.keys(a)[0]; var y = Object.keys(b)[0]
  return ((x < y) ? -1 : ((x > y) ? 1 : 0))
})
export default arr
