const arr = [{
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 15,
  TimeNow: 371.4,
  S: 23.25,
  N: 43.05,
  J: 32.2,
  St: 569.7,
  Nt: 1043.05,
  Jt: 780.5,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 12,
  TimeNow: 168.06451612903226,
  S: 10.483870967741936,
  N: 86.90322580645162,
  J: 1.5161290322580645,
  St: 263.03225806451616,
  Nt: 2092.3548387096776,
  Jt: 38.38709677419355,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 13,
  TimeNow: 206.13333333333333,
  S: 13.833333333333334,
  N: 79.13333333333334,
  J: 5.7,
  St: 343.26666666666665,
  Nt: 1907.2333333333333,
  Jt: 144.1,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 4,
  TimeNow: 137.14285714285714,
  S: 0.42857142857142855,
  N: 98.57142857142857,
  J: 0.0,
  St: 12.642857142857142,
  Nt: 2379.3571428571427,
  Jt: 0.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 17,
  TimeNow: 483.5882352941176,
  S: 19.176470588235293,
  N: 41.0,
  J: 38.11764705882353,
  St: 472.52941176470586,
  Nt: 995.0,
  Jt: 925.6470588235294,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 9,
  TimeNow: 294.3333333333333,
  S: 32.916666666666664,
  N: 41.25,
  J: 24.583333333333332,
  St: 797.1666666666666,
  Nt: 999.25,
  Jt: 597.3333333333334,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 18,
  TimeNow: 356.0625,
  S: 8.0,
  N: 69.9375,
  J: 20.625,
  St: 203.6875,
  Nt: 1687.0,
  Jt: 502.375,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 14,
  TimeNow: 282.53333333333336,
  S: 19.266666666666666,
  N: 65.93333333333334,
  J: 13.333333333333334,
  St: 475.1333333333333,
  Nt: 1592.5333333333333,
  Jt: 326.6,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 19,
  TimeNow: 310.42857142857144,
  S: 8.571428571428571,
  N: 73.71428571428571,
  J: 16.333333333333332,
  St: 215.0952380952381,
  Nt: 1777.047619047619,
  Jt: 401.23809523809524,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 5,
  TimeNow: 137.0,
  S: 0.5,
  N: 98.5,
  J: 0.0,
  St: 14.75,
  Nt: 2377.25,
  Jt: 0.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 20,
  TimeNow: 350.75,
  S: 22.083333333333332,
  N: 55.416666666666664,
  J: 20.75,
  St: 542.0,
  Nt: 1341.0,
  Jt: 510.5833333333333,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 7,
  TimeNow: 176.58333333333334,
  S: 24.333333333333332,
  N: 72.5,
  J: 2.0,
  St: 594.4166666666666,
  Nt: 1749.6666666666667,
  Jt: 48.916666666666664,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 16,
  TimeNow: 413.25,
  S: 24.05,
  N: 45.0,
  J: 29.3,
  St: 588.05,
  Nt: 1090.35,
  Jt: 714.9,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 10,
  TimeNow: 209.0,
  S: 29.727272727272727,
  N: 64.45454545454545,
  J: 4.545454545454546,
  St: 726.0,
  Nt: 1555.0,
  Jt: 113.0909090909091,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 6,
  TimeNow: 149.75,
  S: 1.8333333333333333,
  N: 96.66666666666667,
  J: 0.5,
  St: 47.583333333333336,
  Nt: 2331.25,
  Jt: 13.333333333333334,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 11,
  TimeNow: 162.57894736842104,
  S: 7.105263157894737,
  N: 90.15789473684211,
  J: 1.4736842105263157,
  St: 183.73684210526315,
  Nt: 2171.2631578947367,
  Jt: 38.73684210526316,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 3,
  TimeNow: 133.85714285714286,
  S: 0.42857142857142855,
  N: 98.57142857142857,
  J: 0.0,
  St: 10.571428571428571,
  Nt: 2381.4285714285716,
  Jt: 0.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 8,
  TimeNow: 235.41666666666666,
  S: 33.166666666666664,
  N: 61.333333333333336,
  J: 4.0,
  St: 806.3333333333334,
  Nt: 1481.3333333333333,
  Jt: 104.83333333333333,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 15,
  TimeNow: 464.8,
  S: 34.55,
  N: 38.8,
  J: 25.3,
  St: 1529.3,
  Nt: 1723.85,
  Jt: 1125.2,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 12,
  TimeNow: 376.38709677419354,
  S: 26.451612903225808,
  N: 53.67741935483871,
  J: 18.548387096774192,
  St: 1173.4516129032259,
  Nt: 2383.7419354838707,
  Jt: 821.2258064516129,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 13,
  TimeNow: 281.6,
  S: 24.033333333333335,
  N: 70.36666666666666,
  J: 4.466666666666667,
  St: 1076.5,
  Nt: 3100.266666666667,
  Jt: 201.3,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 4,
  TimeNow: 207.92857142857142,
  S: 0.14285714285714285,
  N: 98.85714285714286,
  J: 0.0,
  St: 14.428571428571429,
  Nt: 4363.571428571428,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 17,
  TimeNow: 524.9411764705883,
  S: 33.35294117647059,
  N: 24.941176470588236,
  J: 40.0,
  St: 1482.5882352941176,
  Nt: 1123.764705882353,
  Jt: 1772.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 9,
  TimeNow: 243.0,
  S: 1.0833333333333333,
  N: 97.91666666666667,
  J: 0.0,
  St: 64.83333333333333,
  Nt: 4313.166666666667,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 18,
  TimeNow: 507.25,
  S: 32.875,
  N: 25.0,
  J: 40.5,
  St: 1463.5,
  Nt: 1119.8125,
  Jt: 1795.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 14,
  TimeNow: 292.73333333333335,
  S: 40.53333333333333,
  N: 53.266666666666666,
  J: 5.066666666666666,
  St: 1794.9333333333334,
  Nt: 2353.6666666666665,
  Jt: 229.4,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 19,
  TimeNow: 341.76190476190476,
  S: 48.57142857142857,
  N: 41.76190476190476,
  J: 8.476190476190476,
  St: 2147.6666666666665,
  Nt: 1851.2380952380952,
  Jt: 379.0952380952381,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 5,
  TimeNow: 214.25,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 2.6666666666666665,
  Nt: 4375.333333333333,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 20,
  TimeNow: 322.3333333333333,
  S: 51.083333333333336,
  N: 41.0,
  J: 6.416666666666667,
  St: 2258.5,
  Nt: 1824.3333333333333,
  Jt: 295.1666666666667,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 7,
  TimeNow: 246.5,
  S: 2.1666666666666665,
  N: 96.83333333333333,
  J: 0.0,
  St: 113.75,
  Nt: 4264.333333333333,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 16,
  TimeNow: 441.25,
  S: 47.0,
  N: 23.8,
  J: 27.8,
  St: 2075.8,
  Nt: 1074.65,
  Jt: 1227.6,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 10,
  TimeNow: 244.45454545454547,
  S: 4.2727272727272725,
  N: 94.72727272727273,
  J: 0.0,
  St: 207.36363636363637,
  Nt: 4170.818181818182,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 6,
  TimeNow: 228.5,
  S: 0.4166666666666667,
  N: 98.58333333333333,
  J: 0.0,
  St: 28.0,
  Nt: 4350.0,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 11,
  TimeNow: 248.42105263157896,
  S: 6.578947368421052,
  N: 91.78947368421052,
  J: 0.5263157894736842,
  St: 309.36842105263156,
  Nt: 4043.3684210526317,
  Jt: 25.263157894736842,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 3,
  TimeNow: 212.42857142857142,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 2.2857142857142856,
  Nt: 4375.714285714285,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 8,
  TimeNow: 244.33333333333334,
  S: 4.5,
  N: 94.5,
  J: 0.0,
  St: 216.41666666666666,
  Nt: 4161.583333333333,
  Jt: 0.0,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 15,
  TimeNow: 685.9,
  S: 48.75,
  N: 2.3,
  J: 47.65,
  St: 2747.0,
  Nt: 143.3,
  Jt: 2697.2,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 12,
  TimeNow: 487.51612903225805,
  S: 42.61290322580645,
  N: 27.483870967741936,
  J: 28.193548387096776,
  St: 2411.5806451612902,
  Nt: 1573.2903225806451,
  Jt: 1602.1290322580646,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 13,
  TimeNow: 523.0645161290323,
  S: 43.61290322580645,
  N: 23.548387096774192,
  J: 31.225806451612904,
  St: 2469.3870967741937,
  Nt: 1346.516129032258,
  Jt: 1771.1290322580646,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 4,
  TimeNow: 233.21428571428572,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5586.0,
  Jt: 0.0,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 17,
  TimeNow: 1308.8235294117646,
  S: 8.058823529411764,
  N: 1.4705882352941178,
  J: 89.17647058823529,
  St: 482.8235294117647,
  Nt: 95.23529411764706,
  Jt: 5007.941176470588,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 9,
  TimeNow: 440.6666666666667,
  S: 43.833333333333336,
  N: 37.75,
  J: 16.666666666666668,
  St: 2483.5,
  Nt: 2147.4166666666665,
  Jt: 955.9166666666666,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 18,
  TimeNow: 1224.5625,
  S: 10.9375,
  N: 0.4375,
  J: 87.5625,
  St: 646.6875,
  Nt: 25.0,
  Jt: 4914.625,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 14,
  TimeNow: 531.9333333333333,
  S: 51.93333333333333,
  N: 16.066666666666666,
  J: 30.4,
  St: 2929.6,
  Nt: 929.0,
  Jt: 1728.6666666666667,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 19,
  TimeNow: 769.047619047619,
  S: 27.285714285714285,
  N: 3.4761904761904763,
  J: 67.95238095238095,
  St: 1555.1904761904761,
  Nt: 209.42857142857142,
  Jt: 3821.6190476190477,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 5,
  TimeNow: 233.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5586.0,
  Jt: 0.0,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 20,
  TimeNow: 473.25,
  S: 49.916666666666664,
  N: 22.5,
  J: 26.0,
  St: 2826.25,
  Nt: 1282.8333333333333,
  Jt: 1478.0833333333333,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 7,
  TimeNow: 667.4166666666666,
  S: 51.0,
  N: 3.75,
  J: 44.25,
  St: 2869.5833333333335,
  Nt: 212.5,
  Jt: 2504.5,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 16,
  TimeNow: 984.15,
  S: 19.35,
  N: 0.3,
  J: 79.3,
  St: 1111.1,
  Nt: 20.7,
  Jt: 4454.45,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 10,
  TimeNow: 480.90909090909093,
  S: 50.45454545454545,
  N: 26.636363636363637,
  J: 21.09090909090909,
  St: 2852.181818181818,
  Nt: 1530.090909090909,
  Jt: 1204.5454545454545,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 6,
  TimeNow: 332.0,
  S: 29.454545454545453,
  N: 65.0,
  J: 4.545454545454546,
  St: 1651.1818181818182,
  Nt: 3664.818181818182,
  Jt: 270.09090909090907,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 11,
  TimeNow: 501.7894736842105,
  S: 43.68421052631579,
  N: 27.210526315789473,
  J: 27.36842105263158,
  St: 2468.1052631578946,
  Nt: 1566.9473684210527,
  Jt: 1551.8947368421052,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 3,
  TimeNow: 235.64285714285714,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5586.0,
  Jt: 0.0,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 8,
  TimeNow: 628.25,
  S: 38.5,
  N: 16.166666666666668,
  J: 43.833333333333336,
  St: 2171.25,
  Nt: 933.8333333333334,
  Jt: 2481.5,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 15,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 12,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 13,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 4,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 17,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 9,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 18,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 14,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 19,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 5,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 20,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 7,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 16,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 10,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 6,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 11,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 3,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 8,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 15,
  TimeNow: 660.2,
  S: 9.75,
  N: 81.35,
  J: 7.2,
  St: 1294.6,
  Nt: 10244.6,
  Jt: 969.8,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 12,
  TimeNow: 749.7096774193549,
  S: 16.774193548387096,
  N: 72.74193548387096,
  J: 8.903225806451612,
  St: 2165.4193548387098,
  Nt: 9166.0,
  Jt: 1177.5806451612902,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 13,
  TimeNow: 825.8709677419355,
  S: 16.096774193548388,
  N: 70.6774193548387,
  J: 11.838709677419354,
  St: 2062.9032258064517,
  Nt: 8903.483870967742,
  Jt: 1542.6129032258063,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 4,
  TimeNow: 564.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 2.142857142857143,
  Nt: 12506.857142857143,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 17,
  TimeNow: 688.1176470588235,
  S: 13.529411764705882,
  N: 78.94117647058823,
  J: 6.0,
  St: 1766.9411764705883,
  Nt: 9942.823529411764,
  Jt: 799.2352941176471,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 9,
  TimeNow: 557.8333333333334,
  S: 1.1666666666666667,
  N: 97.83333333333333,
  J: 0.0,
  St: 161.58333333333334,
  Nt: 12347.416666666666,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 18,
  TimeNow: 591.3125,
  S: 7.375,
  N: 91.0,
  J: 0.5625,
  St: 1003.625,
  Nt: 11428.3125,
  Jt: 77.0625,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 14,
  TimeNow: 716.5333333333333,
  S: 10.866666666666667,
  N: 78.06666666666666,
  J: 9.533333333333333,
  St: 1410.2666666666667,
  Nt: 9840.866666666667,
  Jt: 1257.8666666666666,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 19,
  TimeNow: 641.1428571428571,
  S: 4.285714285714286,
  N: 91.0,
  J: 3.380952380952381,
  St: 609.6666666666666,
  Nt: 11440.0,
  Jt: 459.3333333333333,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 5,
  TimeNow: 576.5,
  S: 1.25,
  N: 97.75,
  J: 0.0,
  St: 179.66666666666666,
  Nt: 12329.333333333334,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 20,
  TimeNow: 655.3333333333334,
  S: 11.416666666666666,
  N: 85.91666666666667,
  J: 1.4166666666666667,
  St: 1497.6666666666667,
  Nt: 10812.833333333334,
  Jt: 198.5,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 7,
  TimeNow: 618.25,
  S: 6.25,
  N: 90.75,
  J: 2.0,
  St: 819.4166666666666,
  Nt: 11415.583333333334,
  Jt: 274.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 16,
  TimeNow: 775.15,
  S: 16.0,
  N: 70.0,
  J: 12.65,
  St: 2061.85,
  Nt: 8811.65,
  Jt: 1635.5,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 10,
  TimeNow: 590.4545454545455,
  S: 5.0,
  N: 93.36363636363636,
  J: 0.36363636363636365,
  St: 662.2727272727273,
  Nt: 11780.181818181818,
  Jt: 66.54545454545455,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 6,
  TimeNow: 570.0833333333334,
  S: 2.3333333333333335,
  N: 96.41666666666667,
  J: 0.25,
  St: 308.5833333333333,
  Nt: 12166.166666666666,
  Jt: 34.25,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 11,
  TimeNow: 724.0,
  S: 17.263157894736842,
  N: 74.3157894736842,
  J: 6.842105263157895,
  St: 2213.2631578947367,
  Nt: 9370.421052631578,
  Jt: 925.3157894736842,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 3,
  TimeNow: 556.4285714285714,
  S: 1.5714285714285714,
  N: 97.42857142857143,
  J: 0.0,
  St: 215.21428571428572,
  Nt: 12293.785714285714,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 8,
  TimeNow: 529.75,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 12509.0,
  Jt: 0.0,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 15,
  TimeNow: 2380.7,
  S: 12.15,
  N: 64.9,
  J: 21.55,
  St: 2976.85,
  Nt: 15409.45,
  Jt: 5161.7,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 12,
  TimeNow: 1844.1935483870968,
  S: 10.258064516129032,
  N: 79.29032258064517,
  J: 8.870967741935484,
  St: 2544.2580645161293,
  Nt: 18988.516129032258,
  Jt: 2240.032258064516,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 13,
  TimeNow: 2032.1935483870968,
  S: 11.161290322580646,
  N: 73.03225806451613,
  J: 14.290322580645162,
  St: 2731.3548387096776,
  Nt: 17316.0,
  Jt: 3500.6451612903224,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 4,
  TimeNow: 1176.7857142857142,
  S: 0.07142857142857142,
  N: 98.92857142857143,
  J: 0.0,
  St: 84.0,
  Nt: 23428.0,
  Jt: 36.0,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 17,
  TimeNow: 2624.0,
  S: 13.0,
  N: 59.529411764705884,
  J: 25.764705882352942,
  St: 3206.764705882353,
  Nt: 14120.29411764706,
  Jt: 6220.941176470588,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 9,
  TimeNow: 1620.25,
  S: 5.083333333333333,
  N: 88.66666666666667,
  J: 4.75,
  St: 1407.0833333333333,
  Nt: 21488.5,
  Jt: 1233.1666666666667,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 18,
  TimeNow: 2092.375,
  S: 10.4375,
  N: 67.6875,
  J: 20.5,
  St: 2580.125,
  Nt: 16058.625,
  Jt: 4909.25,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 14,
  TimeNow: 2121.133333333333,
  S: 11.466666666666667,
  N: 69.26666666666667,
  J: 17.466666666666665,
  St: 2848.0,
  Nt: 16430.133333333335,
  Jt: 4269.866666666667,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 19,
  TimeNow: 1747.904761904762,
  S: 15.285714285714286,
  N: 72.76190476190476,
  J: 10.571428571428571,
  St: 3704.3809523809523,
  Nt: 17234.714285714286,
  Jt: 2608.904761904762,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 5,
  TimeNow: 1193.4166666666667,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 60.5,
  Nt: 23471.75,
  Jt: 15.75,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 20,
  TimeNow: 1602.6666666666667,
  S: 14.0,
  N: 79.66666666666667,
  J: 4.583333333333333,
  St: 3425.5833333333335,
  Nt: 18907.75,
  Jt: 1214.6666666666667,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 7,
  TimeNow: 1491.5,
  S: 6.583333333333333,
  N: 87.91666666666667,
  J: 3.9166666666666665,
  St: 1690.4166666666667,
  Nt: 20802.666666666668,
  Jt: 1054.9166666666667,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 16,
  TimeNow: 2724.45,
  S: 13.2,
  N: 63.65,
  J: 21.65,
  St: 3386.7,
  Nt: 15799.95,
  Jt: 5389.95,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 10,
  TimeNow: 1793.090909090909,
  S: 10.090909090909092,
  N: 86.63636363636364,
  J: 1.9090909090909092,
  St: 3171.909090909091,
  Nt: 26128.363636363636,
  Jt: 583.1818181818181,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 6,
  TimeNow: 1314.75,
  S: 2.1666666666666665,
  N: 95.5,
  J: 1.25,
  St: 613.8333333333334,
  Nt: 22567.916666666668,
  Jt: 366.25,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 11,
  TimeNow: 2004.3684210526317,
  S: 9.894736842105264,
  N: 81.94736842105263,
  J: 6.7894736842105265,
  St: 2934.2105263157896,
  Nt: 23427.526315789473,
  Jt: 1954.5263157894738,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 3,
  TimeNow: 1166.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 64.85714285714286,
  Nt: 23474.14285714286,
  Jt: 9.0,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 8,
  TimeNow: 1400.8333333333333,
  S: 4.166666666666667,
  N: 91.5,
  J: 2.9166666666666665,
  St: 1100.25,
  Nt: 21638.083333333332,
  Jt: 809.6666666666666,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 15,
  TimeNow: 147.4,
  S: 17.45,
  N: 69.45,
  J: 11.9,
  St: 332.3,
  Nt: 1295.0,
  Jt: 229.85,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 12,
  TimeNow: 190.25806451612902,
  S: 33.516129032258064,
  N: 54.45161290322581,
  J: 10.483870967741936,
  St: 632.6774193548387,
  Nt: 1021.2903225806451,
  Jt: 203.5483870967742,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 13,
  TimeNow: 202.03225806451613,
  S: 35.29032258064516,
  N: 55.54838709677419,
  J: 7.774193548387097,
  St: 664.483870967742,
  Nt: 1040.258064516129,
  Jt: 152.6451612903226,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 4,
  TimeNow: 109.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1857.0,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 17,
  TimeNow: 127.58823529411765,
  S: 8.588235294117647,
  N: 84.0,
  J: 6.235294117647059,
  St: 166.2941176470588,
  Nt: 1567.0,
  Jt: 123.70588235294117,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 9,
  TimeNow: 133.16666666666666,
  S: 12.083333333333334,
  N: 79.83333333333333,
  J: 6.666666666666667,
  St: 232.91666666666666,
  Nt: 1490.6666666666667,
  Jt: 133.58333333333334,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 18,
  TimeNow: 129.75,
  S: 15.9375,
  N: 75.6875,
  J: 7.375,
  St: 301.4375,
  Nt: 1411.5,
  Jt: 144.0625,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 14,
  TimeNow: 187.0,
  S: 30.357142857142858,
  N: 60.357142857142854,
  J: 7.785714285714286,
  St: 573.2142857142857,
  Nt: 1131.2857142857142,
  Jt: 153.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 19,
  TimeNow: 128.66666666666666,
  S: 25.61904761904762,
  N: 67.0952380952381,
  J: 6.142857142857143,
  St: 486.57142857142856,
  Nt: 1251.095238095238,
  Jt: 119.52380952380952,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 5,
  TimeNow: 107.33333333333333,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1857.0,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 20,
  TimeNow: 127.0,
  S: 15.916666666666666,
  N: 81.75,
  J: 1.25,
  St: 304.8333333333333,
  Nt: 1527.4166666666667,
  Jt: 24.916666666666668,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 7,
  TimeNow: 105.08333333333333,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1857.0,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 16,
  TimeNow: 154.55,
  S: 15.65,
  N: 77.5,
  J: 5.7,
  St: 299.25,
  Nt: 1444.95,
  Jt: 112.8,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 10,
  TimeNow: 159.36363636363637,
  S: 16.272727272727273,
  N: 73.18181818181819,
  J: 9.272727272727273,
  St: 309.90909090909093,
  Nt: 1367.090909090909,
  Jt: 180.36363636363637,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 6,
  TimeNow: 109.08333333333333,
  S: 3.5,
  N: 95.5,
  J: 0.0,
  St: 70.25,
  Nt: 1787.0,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 11,
  TimeNow: 215.0,
  S: 28.263157894736842,
  N: 57.94736842105263,
  J: 12.421052631578947,
  St: 533.4736842105264,
  Nt: 1083.4736842105262,
  Jt: 240.6315789473684,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 3,
  TimeNow: 110.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1857.0,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 8,
  TimeNow: 115.16666666666667,
  S: 7.0,
  N: 89.5,
  J: 2.5,
  St: 134.66666666666666,
  Nt: 1672.8333333333333,
  Jt: 49.5,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 15,
  TimeNow: 435.35,
  S: 6.45,
  N: 90.05,
  J: 2.0,
  St: 350.2,
  Nt: 4437.75,
  Jt: 117.3,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 12,
  TimeNow: 450.73333333333335,
  S: 14.866666666666667,
  N: 81.1,
  J: 2.6666666666666665,
  St: 758.0333333333333,
  Nt: 4002.6,
  Jt: 143.66666666666666,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 13,
  TimeNow: 447.64516129032256,
  S: 8.870967741935484,
  N: 88.2258064516129,
  J: 1.5806451612903225,
  St: 463.0967741935484,
  Nt: 4350.967741935484,
  Jt: 91.19354838709677,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 4,
  TimeNow: 421.14285714285717,
  S: 4.0,
  N: 95.0,
  J: 0.0,
  St: 237.0,
  Nt: 4668.0,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 17,
  TimeNow: 448.0,
  S: 10.764705882352942,
  N: 86.0,
  J: 2.1176470588235294,
  St: 547.3529411764706,
  Nt: 4239.411764705882,
  Jt: 118.05882352941177,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 9,
  TimeNow: 441.6363636363636,
  S: 5.545454545454546,
  N: 90.54545454545455,
  J: 2.5454545454545454,
  St: 300.45454545454544,
  Nt: 4462.181818181818,
  Jt: 141.9090909090909,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 18,
  TimeNow: 438.4375,
  S: 10.4375,
  N: 86.375,
  J: 2.0,
  St: 528.875,
  Nt: 4268.9375,
  Jt: 107.125,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 14,
  TimeNow: 450.93333333333334,
  S: 12.866666666666667,
  N: 83.53333333333333,
  J: 2.4,
  St: 655.8,
  Nt: 4116.0,
  Jt: 133.13333333333333,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 19,
  TimeNow: 436.2857142857143,
  S: 6.666666666666667,
  N: 90.0952380952381,
  J: 1.9523809523809523,
  St: 344.85714285714283,
  Nt: 4445.047619047619,
  Jt: 115.0952380952381,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 5,
  TimeNow: 426.75,
  S: 4.0,
  N: 95.0,
  J: 0.0,
  St: 237.0,
  Nt: 4668.0,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 20,
  TimeNow: 424.25,
  S: 6.25,
  N: 92.75,
  J: 0.0,
  St: 327.75,
  Nt: 4576.833333333333,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 7,
  TimeNow: 442.6666666666667,
  S: 18.0,
  N: 81.0,
  J: 0.0,
  St: 901.0,
  Nt: 4003.8333333333335,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 16,
  TimeNow: 433.8,
  S: 15.85,
  N: 82.65,
  J: 0.0,
  St: 809.05,
  Nt: 4085.9,
  Jt: 10.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 10,
  TimeNow: 447.27272727272725,
  S: 14.181818181818182,
  N: 82.81818181818181,
  J: 1.4545454545454546,
  St: 717.9090909090909,
  Nt: 4091.3636363636365,
  Jt: 94.81818181818181,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 6,
  TimeNow: 433.0833333333333,
  S: 7.0,
  N: 92.0,
  J: 0.0,
  St: 380.0,
  Nt: 4525.0,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 11,
  TimeNow: 457.2631578947368,
  S: 25.94736842105263,
  N: 70.47368421052632,
  J: 2.3157894736842106,
  St: 1301.9473684210527,
  Nt: 3481.8947368421054,
  Jt: 119.89473684210526,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 3,
  TimeNow: 415.85714285714283,
  S: 4.857142857142857,
  N: 94.14285714285714,
  J: 0.0,
  St: 250.71428571428572,
  Nt: 4654.285714285715,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 8,
  TimeNow: 455.9166666666667,
  S: 14.083333333333334,
  N: 84.91666666666667,
  J: 0.0,
  St: 717.1666666666666,
  Nt: 4187.916666666667,
  Jt: 0.0,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 15,
  TimeNow: 1139.9,
  S: 7.45,
  N: 84.15,
  J: 7.15,
  St: 731.2,
  Nt: 7869.2,
  Jt: 699.6,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 12,
  TimeNow: 924.0645161290323,
  S: 3.806451612903226,
  N: 92.2258064516129,
  J: 2.4193548387096775,
  St: 407.2903225806452,
  Nt: 8613.967741935483,
  Jt: 278.741935483871,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 13,
  TimeNow: 962.741935483871,
  S: 4.741935483870968,
  N: 90.2258064516129,
  J: 3.4193548387096775,
  St: 490.7096774193548,
  Nt: 8440.967741935483,
  Jt: 368.3225806451613,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 4,
  TimeNow: 715.0,
  S: 1.8571428571428572,
  N: 96.42857142857143,
  J: 0.5,
  St: 222.71428571428572,
  Nt: 9023.785714285714,
  Jt: 53.5,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 17,
  TimeNow: 1247.5294117647059,
  S: 9.235294117647058,
  N: 80.70588235294117,
  J: 8.882352941176471,
  St: 889.5882352941177,
  Nt: 7539.588235294118,
  Jt: 870.8235294117648,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 9,
  TimeNow: 739.8333333333334,
  S: 2.75,
  N: 94.91666666666667,
  J: 0.9166666666666666,
  St: 307.25,
  Nt: 8873.416666666666,
  Jt: 119.33333333333333,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 18,
  TimeNow: 1110.8125,
  S: 8.3125,
  N: 84.9375,
  J: 5.5625,
  St: 816.6875,
  Nt: 7938.5,
  Jt: 544.8125,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 14,
  TimeNow: 1002.6666666666666,
  S: 6.533333333333333,
  N: 87.73333333333333,
  J: 4.266666666666667,
  St: 652.4,
  Nt: 8196.933333333332,
  Jt: 450.6666666666667,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 19,
  TimeNow: 1065.5238095238096,
  S: 4.476190476190476,
  N: 89.0,
  J: 4.761904761904762,
  St: 474.6666666666667,
  Nt: 8331.857142857143,
  Jt: 493.4761904761905,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 5,
  TimeNow: 736.0833333333334,
  S: 3.5,
  N: 95.41666666666667,
  J: 0.08333333333333333,
  St: 371.5,
  Nt: 8919.583333333334,
  Jt: 8.916666666666666,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 20,
  TimeNow: 1005.0,
  S: 5.916666666666667,
  N: 88.75,
  J: 3.75,
  St: 595.0833333333334,
  Nt: 8306.916666666666,
  Jt: 398.0,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 7,
  TimeNow: 802.3333333333334,
  S: 2.25,
  N: 94.25,
  J: 1.8333333333333333,
  St: 266.4166666666667,
  Nt: 8824.583333333334,
  Jt: 209.0,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 16,
  TimeNow: 1164.75,
  S: 7.5,
  N: 84.3,
  J: 6.75,
  St: 746.1,
  Nt: 7891.85,
  Jt: 662.05,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 10,
  TimeNow: 869.6363636363636,
  S: 3.909090909090909,
  N: 91.27272727272727,
  J: 3.272727272727273,
  St: 408.8181818181818,
  Nt: 8554.545454545454,
  Jt: 336.6363636363636,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 6,
  TimeNow: 791.0833333333334,
  S: 3.1666666666666665,
  N: 95.0,
  J: 0.3333333333333333,
  St: 365.75,
  Nt: 8869.75,
  Jt: 64.5,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 11,
  TimeNow: 831.9473684210526,
  S: 7.2631578947368425,
  N: 90.36842105263158,
  J: 0.9473684210526315,
  St: 718.4736842105264,
  Nt: 8455.421052631578,
  Jt: 126.10526315789474,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 3,
  TimeNow: 722.0,
  S: 14.571428571428571,
  N: 84.14285714285714,
  J: 0.21428571428571427,
  St: 1419.142857142857,
  Nt: 7857.928571428572,
  Jt: 22.928571428571427,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 8,
  TimeNow: 755.25,
  S: 3.5833333333333335,
  N: 93.66666666666667,
  J: 1.25,
  St: 371.5,
  Nt: 8771.083333333334,
  Jt: 157.41666666666666,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 15,
  TimeNow: 2245.95,
  S: 10.15,
  N: 78.25,
  J: 9.75,
  St: 2977.1,
  Nt: 21740.1,
  Jt: 2830.8,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 12,
  TimeNow: 1638.6129032258063,
  S: 7.774193548387097,
  N: 88.03225806451613,
  J: 2.6451612903225805,
  St: 2297.2258064516127,
  Nt: 24392.129032258064,
  Jt: 858.7096774193549,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 13,
  TimeNow: 1951.4193548387098,
  S: 6.225806451612903,
  N: 88.09677419354838,
  J: 4.032258064516129,
  St: 1872.2903225806451,
  Nt: 24402.870967741936,
  Jt: 1272.8387096774193,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 4,
  TimeNow: 1396.142857142857,
  S: 0.6428571428571429,
  N: 98.14285714285714,
  J: 0.0,
  St: 236.42857142857142,
  Nt: 27108.14285714286,
  Jt: 203.5,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 17,
  TimeNow: 2438.294117647059,
  S: 9.529411764705882,
  N: 73.3529411764706,
  J: 15.352941176470589,
  St: 2897.529411764706,
  Nt: 20753.647058823528,
  Jt: 4388.823529411765,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 9,
  TimeNow: 1628.4166666666667,
  S: 8.083333333333334,
  N: 87.25,
  J: 3.25,
  St: 2326.6666666666665,
  Nt: 24180.75,
  Jt: 1041.4166666666667,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 18,
  TimeNow: 2145.3125,
  S: 8.0,
  N: 77.5625,
  J: 12.8125,
  St: 2331.3125,
  Nt: 21541.0,
  Jt: 3675.6875,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 14,
  TimeNow: 2110.866666666667,
  S: 7.866666666666666,
  N: 83.66666666666667,
  J: 6.866666666666666,
  St: 2323.6,
  Nt: 23183.4,
  Jt: 2041.0,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 19,
  TimeNow: 2249.4761904761904,
  S: 9.19047619047619,
  N: 73.95238095238095,
  J: 15.380952380952381,
  St: 2680.6190476190477,
  Nt: 20486.714285714286,
  Jt: 4380.666666666667,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 5,
  TimeNow: 1405.8333333333333,
  S: 2.4166666666666665,
  N: 96.58333333333333,
  J: 0.0,
  St: 817.9166666666666,
  Nt: 26690.916666666668,
  Jt: 39.166666666666664,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 20,
  TimeNow: 1990.1666666666667,
  S: 9.666666666666666,
  N: 81.33333333333333,
  J: 7.75,
  St: 2787.25,
  Nt: 22491.333333333332,
  Jt: 2269.4166666666665,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 7,
  TimeNow: 2196.5833333333335,
  S: 9.666666666666666,
  N: 81.41666666666667,
  J: 7.416666666666667,
  St: 2761.9166666666665,
  Nt: 22594.916666666668,
  Jt: 2192.1666666666665,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 16,
  TimeNow: 2330.7,
  S: 10.8,
  N: 76.25,
  J: 11.5,
  St: 3110.25,
  Nt: 21139.7,
  Jt: 3298.05,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 10,
  TimeNow: 1553.7272727272727,
  S: 3.727272727272727,
  N: 92.36363636363636,
  J: 2.5454545454545454,
  St: 1156.909090909091,
  Nt: 25560.909090909092,
  Jt: 830.1818181818181,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 6,
  TimeNow: 1517.25,
  S: 2.1666666666666665,
  N: 96.41666666666667,
  J: 0.16666666666666666,
  St: 757.0833333333334,
  Nt: 26699.916666666668,
  Jt: 91.16666666666667,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 11,
  TimeNow: 1616.1052631578948,
  S: 9.578947368421053,
  N: 87.21052631578948,
  J: 1.736842105263158,
  St: 2774.7894736842104,
  Nt: 24159.736842105263,
  Jt: 613.5263157894736,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 3,
  TimeNow: 1372.9285714285713,
  S: 0.21428571428571427,
  N: 98.78571428571429,
  J: 0.0,
  St: 104.0,
  Nt: 27444.0,
  Jt: 0.0,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 8,
  TimeNow: 1731.25,
  S: 9.333333333333334,
  N: 85.75,
  J: 3.25,
  St: 2729.9166666666665,
  Nt: 23795.916666666668,
  Jt: 1022.25,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 15,
  TimeNow: 1746.2,
  S: 16.35,
  N: 57.5,
  J: 24.55,
  St: 3746.7,
  Nt: 12923.55,
  Jt: 5610.7,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 12,
  TimeNow: 1241.0,
  S: 9.419354838709678,
  N: 76.61290322580645,
  J: 12.516129032258064,
  St: 2201.4193548387098,
  Nt: 17146.225806451614,
  Jt: 2932.9032258064517,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 13,
  TimeNow: 1366.4516129032259,
  S: 9.258064516129032,
  N: 73.74193548387096,
  J: 15.548387096774194,
  St: 2162.2580645161293,
  Nt: 16543.451612903227,
  Jt: 3574.967741935484,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 4,
  TimeNow: 817.2142857142857,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 22281.0,
  Jt: 0.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 17,
  TimeNow: 2413.9411764705883,
  S: 18.764705882352942,
  N: 42.05882352941177,
  J: 37.76470588235294,
  St: 4271.529411764706,
  Nt: 9484.058823529413,
  Jt: 8525.411764705883,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 9,
  TimeNow: 984.5833333333334,
  S: 6.916666666666667,
  N: 90.58333333333333,
  J: 1.0,
  St: 1655.4166666666667,
  Nt: 20283.583333333332,
  Jt: 341.9166666666667,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 18,
  TimeNow: 2030.6875,
  S: 17.0625,
  N: 42.0,
  J: 39.375,
  St: 3936.25,
  Nt: 9473.1875,
  Jt: 8871.5625,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 14,
  TimeNow: 1451.4666666666667,
  S: 11.6,
  N: 69.6,
  J: 17.333333333333332,
  St: 2677.266666666667,
  Nt: 15607.0,
  Jt: 3996.6,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 19,
  TimeNow: 1452.6190476190477,
  S: 21.857142857142858,
  N: 59.523809523809526,
  J: 17.19047619047619,
  St: 4980.809523809524,
  Nt: 13378.333333333334,
  Jt: 3921.5714285714284,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 5,
  TimeNow: 814.75,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 22281.0,
  Jt: 0.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 20,
  TimeNow: 1240.6666666666667,
  S: 21.583333333333332,
  N: 67.91666666666667,
  J: 9.166666666666666,
  St: 4923.333333333333,
  Nt: 15238.166666666666,
  Jt: 2119.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 7,
  TimeNow: 1014.5,
  S: 11.0,
  N: 85.33333333333333,
  J: 2.1666666666666665,
  St: 2602.4166666666665,
  Nt: 19098.75,
  Jt: 579.5833333333334,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 16,
  TimeNow: 2149.6,
  S: 27.15,
  N: 45.9,
  J: 25.3,
  St: 6158.0,
  Nt: 10344.45,
  Jt: 5778.55,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 10,
  TimeNow: 1024.1818181818182,
  S: 15.272727272727273,
  N: 81.0,
  J: 2.3636363636363638,
  St: 3519.7272727272725,
  Nt: 18169.727272727272,
  Jt: 591.2727272727273,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 6,
  TimeNow: 835.1666666666666,
  S: 0.9166666666666666,
  N: 98.08333333333333,
  J: 0.0,
  St: 235.5,
  Nt: 22045.5,
  Jt: 0.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 11,
  TimeNow: 1100.2631578947369,
  S: 11.421052631578947,
  N: 79.94736842105263,
  J: 7.2631578947368425,
  St: 2640.9473684210525,
  Nt: 17914.21052631579,
  Jt: 1725.2631578947369,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 3,
  TimeNow: 832.5,
  S: 0.6428571428571429,
  N: 98.35714285714286,
  J: 0.0,
  St: 172.5,
  Nt: 22108.428571428572,
  Jt: 0.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 8,
  TimeNow: 935.0,
  S: 2.0833333333333335,
  N: 95.66666666666667,
  J: 0.5,
  St: 626.5,
  Nt: 21445.0,
  Jt: 209.5,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 15,
  TimeNow: 2737.85,
  S: 19.65,
  N: 40.35,
  J: 38.5,
  St: 4583.55,
  Nt: 9274.65,
  Jt: 8845.8,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 12,
  TimeNow: 1351.4516129032259,
  S: 15.709677419354838,
  N: 78.51612903225806,
  J: 4.451612903225806,
  St: 3639.4193548387098,
  Nt: 17950.483870967742,
  Jt: 1114.0967741935483,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 13,
  TimeNow: 1649.9354838709678,
  S: 15.161290322580646,
  N: 67.06451612903226,
  J: 16.419354838709676,
  St: 3551.032258064516,
  Nt: 15319.032258064517,
  Jt: 3833.935483870968,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 4,
  TimeNow: 1064.5,
  S: 1.3571428571428572,
  N: 97.64285714285714,
  J: 0.0,
  St: 420.2142857142857,
  Nt: 22280.64285714286,
  Jt: 3.142857142857143,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 17,
  TimeNow: 3619.0,
  S: 21.235294117647058,
  N: 27.705882352941178,
  J: 49.470588235294116,
  St: 5010.235294117647,
  Nt: 6474.941176470588,
  Jt: 11389.0,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 9,
  TimeNow: 1098.5833333333333,
  S: 3.4166666666666665,
  N: 95.08333333333333,
  J: 0.5,
  St: 890.8333333333334,
  Nt: 21693.666666666668,
  Jt: 119.5,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 18,
  TimeNow: 2827.8125,
  S: 24.875,
  N: 36.125,
  J: 37.375,
  St: 5756.4375,
  Nt: 8312.8125,
  Jt: 8634.75,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 14,
  TimeNow: 2119.6666666666665,
  S: 15.266666666666667,
  N: 54.6,
  J: 28.733333333333334,
  St: 3572.733333333333,
  Nt: 12492.8,
  Jt: 6638.466666666666,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 19,
  TimeNow: 1839.952380952381,
  S: 20.904761904761905,
  N: 56.19047619047619,
  J: 21.666666666666668,
  St: 4874.333333333333,
  Nt: 12836.809523809523,
  Jt: 4992.9047619047615,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 5,
  TimeNow: 1013.5833333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 13.5,
  Nt: 22686.75,
  Jt: 3.75,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 20,
  TimeNow: 1407.0833333333333,
  S: 23.75,
  N: 67.16666666666667,
  J: 7.5,
  St: 5507.416666666667,
  Nt: 15349.833333333334,
  Jt: 1846.75,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 7,
  TimeNow: 1362.0,
  S: 8.916666666666666,
  N: 83.08333333333333,
  J: 6.5,
  St: 2178.25,
  Nt: 18959.333333333332,
  Jt: 1566.4166666666667,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 16,
  TimeNow: 3175.4,
  S: 20.3,
  N: 37.45,
  J: 40.9,
  St: 4715.1,
  Nt: 8603.05,
  Jt: 9385.85,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 10,
  TimeNow: 1055.909090909091,
  S: 0.18181818181818182,
  N: 98.81818181818181,
  J: 0.0,
  St: 44.45454545454545,
  Nt: 22659.545454545456,
  Jt: 0.0,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 6,
  TimeNow: 1011.4166666666666,
  S: 0.25,
  N: 98.75,
  J: 0.0,
  St: 97.0,
  Nt: 22607.0,
  Jt: 0.0,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 11,
  TimeNow: 1096.6315789473683,
  S: 0.631578947368421,
  N: 98.3157894736842,
  J: 0.0,
  St: 205.57894736842104,
  Nt: 22498.42105263158,
  Jt: 0.0,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 3,
  TimeNow: 1127.7857142857142,
  S: 1.8571428571428572,
  N: 96.92857142857143,
  J: 0.0,
  St: 541.7142857142857,
  Nt: 22118.14285714286,
  Jt: 44.142857142857146,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 8,
  TimeNow: 1226.3333333333333,
  S: 7.166666666666667,
  N: 87.75,
  J: 3.75,
  St: 1734.5833333333333,
  Nt: 20033.75,
  Jt: 935.6666666666666,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 15,
  TimeNow: 103.1,
  S: 0.4,
  N: 98.6,
  J: 0.0,
  St: 7.85,
  Nt: 1922.15,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 12,
  TimeNow: 106.48387096774194,
  S: 0.7741935483870968,
  N: 98.2258064516129,
  J: 0.0,
  St: 15.03225806451613,
  Nt: 1914.967741935484,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 13,
  TimeNow: 104.45161290322581,
  S: 0.3870967741935484,
  N: 98.61290322580645,
  J: 0.0,
  St: 7.516129032258065,
  Nt: 1922.483870967742,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 4,
  TimeNow: 113.14285714285714,
  S: 0.8571428571428571,
  N: 98.14285714285714,
  J: 0.0,
  St: 16.642857142857142,
  Nt: 1913.357142857143,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 17,
  TimeNow: 108.52941176470588,
  S: 1.6470588235294117,
  N: 97.3529411764706,
  J: 0.0,
  St: 32.529411764705884,
  Nt: 1897.4705882352941,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 9,
  TimeNow: 102.91666666666667,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 18,
  TimeNow: 115.3125,
  S: 0.875,
  N: 98.125,
  J: 0.0,
  St: 18.125,
  Nt: 1911.875,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 14,
  TimeNow: 104.78571428571429,
  S: 0.42857142857142855,
  N: 98.57142857142857,
  J: 0.0,
  St: 8.928571428571429,
  Nt: 1921.0714285714287,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 19,
  TimeNow: 113.42857142857143,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 5,
  TimeNow: 113.16666666666667,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 20,
  TimeNow: 113.58333333333333,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 7,
  TimeNow: 107.08333333333333,
  S: 2.3333333333333335,
  N: 96.66666666666667,
  J: 0.0,
  St: 46.083333333333336,
  Nt: 1883.9166666666667,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 16,
  TimeNow: 105.95,
  S: 0.2,
  N: 98.8,
  J: 0.0,
  St: 4.35,
  Nt: 1925.65,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 10,
  TimeNow: 105.27272727272727,
  S: 1.4545454545454546,
  N: 97.54545454545455,
  J: 0.0,
  St: 29.09090909090909,
  Nt: 1900.909090909091,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 6,
  TimeNow: 105.83333333333333,
  S: 0.25,
  N: 98.75,
  J: 0.0,
  St: 5.916666666666667,
  Nt: 1924.0833333333333,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 11,
  TimeNow: 105.84210526315789,
  S: 0.631578947368421,
  N: 98.36842105263158,
  J: 0.0,
  St: 12.263157894736842,
  Nt: 1917.7368421052631,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 3,
  TimeNow: 107.42857142857143,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 8,
  TimeNow: 104.58333333333333,
  S: 2.0,
  N: 97.0,
  J: 0.0,
  St: 38.833333333333336,
  Nt: 1891.1666666666667,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 15,
  TimeNow: 108.55,
  S: 1.45,
  N: 97.55,
  J: 0.0,
  St: 16.25,
  Nt: 1045.75,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 12,
  TimeNow: 112.64516129032258,
  S: 0.12903225806451613,
  N: 98.83870967741936,
  J: 0.0,
  St: 2.161290322580645,
  Nt: 1059.8387096774193,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 13,
  TimeNow: 109.93548387096774,
  S: 0.8709677419354839,
  N: 98.12903225806451,
  J: 0.0,
  St: 10.161290322580646,
  Nt: 1051.8387096774193,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 4,
  TimeNow: 110.35714285714286,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1062.0,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 17,
  TimeNow: 124.29411764705883,
  S: 7.764705882352941,
  N: 87.47058823529412,
  J: 3.588235294117647,
  St: 86.29411764705883,
  Nt: 935.5882352941177,
  Jt: 40.11764705882353,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 9,
  TimeNow: 114.33333333333333,
  S: 1.6666666666666667,
  N: 97.33333333333333,
  J: 0.0,
  St: 19.5,
  Nt: 1042.5,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 18,
  TimeNow: 111.4375,
  S: 5.5625,
  N: 89.9375,
  J: 3.25,
  St: 63.0,
  Nt: 962.5,
  Jt: 36.5,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 14,
  TimeNow: 107.86666666666666,
  S: 0.3333333333333333,
  N: 98.66666666666667,
  J: 0.0,
  St: 4.466666666666667,
  Nt: 1057.5333333333333,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 19,
  TimeNow: 114.57142857142857,
  S: 4.095238095238095,
  N: 90.61904761904762,
  J: 4.095238095238095,
  St: 46.285714285714285,
  Nt: 969.3333333333334,
  Jt: 46.38095238095238,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 5,
  TimeNow: 118.91666666666667,
  S: 6.833333333333333,
  N: 92.16666666666667,
  J: 0.0,
  St: 74.0,
  Nt: 988.0,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 20,
  TimeNow: 114.83333333333333,
  S: 2.8333333333333335,
  N: 93.75,
  J: 2.0833333333333335,
  St: 33.0,
  Nt: 1004.8333333333334,
  Jt: 24.166666666666668,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 7,
  TimeNow: 112.08333333333333,
  S: 1.4166666666666667,
  N: 97.58333333333333,
  J: 0.0,
  St: 16.833333333333332,
  Nt: 1045.1666666666667,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 16,
  TimeNow: 118.35,
  S: 5.8,
  N: 91.65,
  J: 1.35,
  St: 67.4,
  Nt: 979.05,
  Jt: 15.55,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 10,
  TimeNow: 113.27272727272727,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1062.0,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 6,
  TimeNow: 122.0,
  S: 20.416666666666668,
  N: 78.58333333333333,
  J: 0.0,
  St: 220.16666666666666,
  Nt: 841.8333333333334,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 11,
  TimeNow: 113.05555555555556,
  S: 3.4444444444444446,
  N: 95.55555555555556,
  J: 0.0,
  St: 38.111111111111114,
  Nt: 1023.8888888888889,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 3,
  TimeNow: 120.42857142857143,
  S: 11.785714285714286,
  N: 87.21428571428571,
  J: 0.0,
  St: 128.28571428571428,
  Nt: 933.7142857142857,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 8,
  TimeNow: 109.83333333333333,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1062.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 15,
  TimeNow: 198.8,
  S: 45.05,
  N: 39.05,
  J: 14.15,
  St: 805.4,
  Nt: 695.5,
  Jt: 260.1,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 12,
  TimeNow: 444.7741935483871,
  S: 16.903225806451612,
  N: 12.870967741935484,
  J: 68.93548387096774,
  St: 305.2258064516129,
  Nt: 229.93548387096774,
  Jt: 1225.8387096774193,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 13,
  TimeNow: 484.64516129032256,
  S: 14.709677419354838,
  N: 17.838709677419356,
  J: 66.16129032258064,
  St: 265.03225806451616,
  Nt: 319.06451612903226,
  Jt: 1176.9032258064517,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 4,
  TimeNow: 70.57142857142857,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1761.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 17,
  TimeNow: 136.1764705882353,
  S: 22.294117647058822,
  N: 68.29411764705883,
  J: 8.411764705882353,
  St: 395.88235294117646,
  Nt: 1205.1176470588234,
  Jt: 160.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 9,
  TimeNow: 226.75,
  S: 34.333333333333336,
  N: 24.0,
  J: 40.25,
  St: 610.75,
  Nt: 431.5,
  Jt: 718.75,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 18,
  TimeNow: 119.375,
  S: 17.3125,
  N: 73.9375,
  J: 7.5625,
  St: 309.6875,
  Nt: 1308.125,
  Jt: 143.1875,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 14,
  TimeNow: 381.2,
  S: 42.733333333333334,
  N: 12.4,
  J: 43.333333333333336,
  St: 761.0666666666667,
  Nt: 223.2,
  Jt: 776.7333333333333,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 19,
  TimeNow: 109.28571428571429,
  S: 8.571428571428571,
  N: 84.23809523809524,
  J: 5.904761904761905,
  St: 160.47619047619048,
  Nt: 1491.0,
  Jt: 109.52380952380952,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 5,
  TimeNow: 68.75,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1761.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 20,
  TimeNow: 88.33333333333333,
  S: 10.666666666666666,
  N: 88.25,
  J: 0.08333333333333333,
  St: 195.91666666666666,
  Nt: 1563.1666666666667,
  Jt: 1.9166666666666667,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 7,
  TimeNow: 77.25,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1761.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 16,
  TimeNow: 256.35,
  S: 33.2,
  N: 38.7,
  J: 26.8,
  St: 593.0,
  Nt: 687.7,
  Jt: 480.3,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 10,
  TimeNow: 357.45454545454544,
  S: 27.90909090909091,
  N: 5.2727272727272725,
  J: 65.36363636363636,
  St: 497.72727272727275,
  Nt: 99.18181818181819,
  Jt: 1164.090909090909,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 6,
  TimeNow: 71.25,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1761.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 11,
  TimeNow: 559.5263157894736,
  S: 0.0,
  N: 0.0,
  J: 99.0,
  St: 0.0,
  Nt: 0.0,
  Jt: 1761.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 3,
  TimeNow: 70.35714285714286,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1761.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 8,
  TimeNow: 122.0,
  S: 25.416666666666668,
  N: 69.91666666666667,
  J: 3.5,
  St: 455.25,
  Nt: 1237.75,
  Jt: 68.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 15,
  TimeNow: 148.75,
  S: 24.95,
  N: 58.35,
  J: 15.0,
  St: 374.35,
  Nt: 862.85,
  Jt: 229.85,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 12,
  TimeNow: 188.38709677419354,
  S: 43.096774193548384,
  N: 42.354838709677416,
  J: 12.903225806451612,
  St: 639.5483870967741,
  Nt: 628.4193548387096,
  Jt: 199.2258064516129,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 13,
  TimeNow: 201.19354838709677,
  S: 46.935483870967744,
  N: 41.67741935483871,
  J: 9.774193548387096,
  St: 695.6451612903226,
  Nt: 618.9677419354839,
  Jt: 152.6451612903226,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 4,
  TimeNow: 107.92857142857143,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1467.0,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 17,
  TimeNow: 130.76470588235293,
  S: 13.529411764705882,
  N: 76.94117647058823,
  J: 7.764705882352941,
  St: 206.94117647058823,
  Nt: 1136.4705882352941,
  Jt: 123.70588235294117,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 9,
  TimeNow: 128.91666666666666,
  S: 17.0,
  N: 73.08333333333333,
  J: 8.5,
  St: 255.16666666666666,
  Nt: 1078.25,
  Jt: 133.58333333333334,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 18,
  TimeNow: 132.9375,
  S: 24.125,
  N: 65.25,
  J: 9.125,
  St: 359.5,
  Nt: 963.5625,
  Jt: 144.0625,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 14,
  TimeNow: 183.6,
  S: 39.06666666666667,
  N: 49.8,
  J: 9.8,
  St: 577.8,
  Nt: 736.2,
  Jt: 153.2,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 19,
  TimeNow: 128.85714285714286,
  S: 36.42857142857143,
  N: 54.57142857142857,
  J: 7.619047619047619,
  St: 540.4285714285714,
  Nt: 807.1428571428571,
  Jt: 119.52380952380952,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 5,
  TimeNow: 108.08333333333333,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.5,
  Nt: 1466.5,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 20,
  TimeNow: 127.66666666666667,
  S: 21.416666666666668,
  N: 75.66666666666667,
  J: 1.75,
  St: 325.1666666666667,
  Nt: 1114.6666666666667,
  Jt: 27.25,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 7,
  TimeNow: 111.08333333333333,
  S: 2.25,
  N: 95.75,
  J: 0.9166666666666666,
  St: 35.75,
  Nt: 1416.6666666666667,
  Jt: 14.583333333333334,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 16,
  TimeNow: 155.65,
  S: 20.8,
  N: 70.6,
  J: 7.2,
  St: 311.7,
  Nt: 1042.55,
  Jt: 112.8,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 10,
  TimeNow: 158.54545454545453,
  S: 25.545454545454547,
  N: 60.45454545454545,
  J: 12.727272727272727,
  St: 378.90909090909093,
  Nt: 891.9090909090909,
  Jt: 196.27272727272728,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 6,
  TimeNow: 101.75,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.25,
  Nt: 1466.75,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 11,
  TimeNow: 205.1578947368421,
  S: 33.68421052631579,
  N: 47.8421052631579,
  J: 16.63157894736842,
  St: 502.94736842105266,
  Nt: 709.421052631579,
  Jt: 254.73684210526315,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 3,
  TimeNow: 109.08333333333333,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1467.0,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 8,
  TimeNow: 109.83333333333333,
  S: 9.416666666666666,
  N: 86.0,
  J: 3.0,
  St: 145.91666666666666,
  Nt: 1271.5833333333333,
  Jt: 49.5,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 15,
  TimeNow: 551.0,
  S: 10.85,
  N: 58.75,
  J: 28.95,
  St: 486.15,
  Nt: 2567.7,
  Jt: 1277.15,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 12,
  TimeNow: 272.7096774193548,
  S: 0.3548387096774194,
  N: 96.6774193548387,
  J: 1.7741935483870968,
  St: 25.64516129032258,
  Nt: 4192.354838709677,
  Jt: 113.0,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 13,
  TimeNow: 296.19354838709677,
  S: 1.1612903225806452,
  N: 94.12903225806451,
  J: 3.0,
  St: 74.48387096774194,
  Nt: 4096.387096774193,
  Jt: 160.1290322580645,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 4,
  TimeNow: 230.85714285714286,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 66.78571428571429,
  Nt: 4264.214285714285,
  Jt: 0.0,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 17,
  TimeNow: 703.1176470588235,
  S: 11.411764705882353,
  N: 57.705882352941174,
  J: 29.529411764705884,
  St: 517.0,
  Nt: 2514.176470588235,
  Jt: 1299.8235294117646,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 9,
  TimeNow: 326.4166666666667,
  S: 0.6666666666666666,
  N: 95.75,
  J: 2.1666666666666665,
  St: 39.25,
  Nt: 4160.916666666667,
  Jt: 130.83333333333334,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 18,
  TimeNow: 476.3125,
  S: 3.25,
  N: 84.5625,
  J: 10.875,
  St: 152.6875,
  Nt: 3687.375,
  Jt: 490.9375,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 14,
  TimeNow: 386.93333333333334,
  S: 8.266666666666667,
  N: 74.86666666666666,
  J: 15.466666666666667,
  St: 381.8666666666667,
  Nt: 3260.2,
  Jt: 688.9333333333333,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 19,
  TimeNow: 332.23809523809524,
  S: 2.6666666666666665,
  N: 92.14285714285714,
  J: 3.8095238095238093,
  St: 133.14285714285714,
  Nt: 4005.6190476190477,
  Jt: 192.23809523809524,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 5,
  TimeNow: 238.91666666666666,
  S: 2.0,
  N: 97.0,
  J: 0.0,
  St: 127.0,
  Nt: 4204.0,
  Jt: 0.0,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 20,
  TimeNow: 328.9166666666667,
  S: 1.9166666666666667,
  N: 92.75,
  J: 3.75,
  St: 108.83333333333333,
  Nt: 4034.0,
  Jt: 188.16666666666666,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 7,
  TimeNow: 361.6666666666667,
  S: 6.5,
  N: 83.41666666666667,
  J: 8.416666666666666,
  St: 302.0833333333333,
  Nt: 3637.4166666666665,
  Jt: 391.5,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 16,
  TimeNow: 702.4,
  S: 10.45,
  N: 53.55,
  J: 34.45,
  St: 470.05,
  Nt: 2339.95,
  Jt: 1521.0,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 10,
  TimeNow: 316.72727272727275,
  S: 0.5454545454545454,
  N: 95.81818181818181,
  J: 2.272727272727273,
  St: 33.54545454545455,
  Nt: 4162.272727272727,
  Jt: 135.1818181818182,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 6,
  TimeNow: 247.58333333333334,
  S: 1.5833333333333333,
  N: 97.0,
  J: 0.4166666666666667,
  St: 101.41666666666667,
  Nt: 4204.0,
  Jt: 25.583333333333332,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 11,
  TimeNow: 268.94736842105266,
  S: 0.6842105263157895,
  N: 96.15789473684211,
  J: 1.631578947368421,
  St: 47.68421052631579,
  Nt: 4179.105263157895,
  Jt: 104.15789473684211,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 3,
  TimeNow: 240.71428571428572,
  S: 1.2857142857142858,
  N: 97.42857142857143,
  J: 0.2857142857142857,
  St: 75.21428571428571,
  Nt: 4237.642857142857,
  Jt: 18.142857142857142,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 8,
  TimeNow: 385.0,
  S: 4.083333333333333,
  N: 79.33333333333333,
  J: 14.916666666666666,
  St: 199.16666666666666,
  Nt: 3458.75,
  Jt: 673.0833333333334,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 15,
  TimeNow: 758.35,
  S: 30.75,
  N: 28.4,
  J: 39.3,
  St: 2319.1,
  Nt: 2134.3,
  Jt: 2930.6,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 12,
  TimeNow: 342.5806451612903,
  S: 5.483870967741935,
  N: 93.51612903225806,
  J: 0.0,
  St: 425.2258064516129,
  Nt: 6958.774193548387,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 13,
  TimeNow: 431.8709677419355,
  S: 32.096774193548384,
  N: 60.41935483870968,
  J: 6.225806451612903,
  St: 2401.935483870968,
  Nt: 4506.580645161291,
  Jt: 475.48387096774195,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 4,
  TimeNow: 277.57142857142856,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7384.0,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 17,
  TimeNow: 1402.4705882352941,
  S: 2.6470588235294117,
  N: 0.23529411764705882,
  J: 96.0,
  St: 232.58823529411765,
  Nt: 32.05882352941177,
  Jt: 7119.35294117647,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 9,
  TimeNow: 321.0,
  S: 0.7272727272727273,
  N: 98.27272727272727,
  J: 0.0,
  St: 58.0,
  Nt: 7326.0,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 18,
  TimeNow: 829.0,
  S: 27.5625,
  N: 7.9375,
  J: 63.0625,
  St: 2068.5625,
  Nt: 619.5,
  Jt: 4695.9375,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 14,
  TimeNow: 582.5333333333333,
  S: 44.6,
  N: 31.333333333333332,
  J: 22.6,
  St: 3326.5333333333333,
  Nt: 2349.0,
  Jt: 1708.4666666666667,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 19,
  TimeNow: 698.1428571428571,
  S: 38.904761904761905,
  N: 6.190476190476191,
  J: 53.61904761904762,
  St: 2901.0,
  Nt: 492.4761904761905,
  Jt: 3990.5238095238096,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 5,
  TimeNow: 277.1666666666667,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7384.0,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 20,
  TimeNow: 663.75,
  S: 38.666666666666664,
  N: 11.583333333333334,
  J: 48.25,
  St: 2898.8333333333335,
  Nt: 890.6666666666666,
  Jt: 3594.5,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 7,
  TimeNow: 650.0833333333334,
  S: 38.666666666666664,
  N: 29.75,
  J: 30.166666666666668,
  St: 2882.9166666666665,
  Nt: 2239.9166666666665,
  Jt: 2261.1666666666665,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 16,
  TimeNow: 1091.6,
  S: 19.25,
  N: 6.55,
  J: 73.05,
  St: 1460.4,
  Nt: 494.3,
  Jt: 5429.3,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 10,
  TimeNow: 323.72727272727275,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7384.0,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 6,
  TimeNow: 400.8333333333333,
  S: 29.666666666666668,
  N: 62.083333333333336,
  J: 6.833333333333333,
  St: 2225.3333333333335,
  Nt: 4632.666666666667,
  Jt: 526.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 11,
  TimeNow: 336.57894736842104,
  S: 2.9473684210526314,
  N: 96.05263157894737,
  J: 0.0,
  St: 242.3684210526316,
  Nt: 7141.631578947368,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 3,
  TimeNow: 277.2142857142857,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7384.0,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 8,
  TimeNow: 362.75,
  S: 11.083333333333334,
  N: 84.41666666666667,
  J: 3.3333333333333335,
  St: 845.25,
  Nt: 6283.75,
  Jt: 255.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 15,
  TimeNow: 229.95,
  S: 2.65,
  N: 96.35,
  J: 0.0,
  St: 117.4,
  Nt: 3778.6,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 12,
  TimeNow: 227.29032258064515,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3896.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 13,
  TimeNow: 226.03225806451613,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3896.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 4,
  TimeNow: 203.07142857142858,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 17,
  TimeNow: 250.94117647058823,
  S: 9.882352941176471,
  N: 89.11764705882354,
  J: 0.0,
  St: 398.94117647058823,
  Nt: 3497.0588235294117,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 9,
  TimeNow: 222.08333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3896.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 18,
  TimeNow: 244.9375,
  S: 10.75,
  N: 88.25,
  J: 0.0,
  St: 436.875,
  Nt: 3459.125,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 14,
  TimeNow: 229.86666666666667,
  S: 0.8,
  N: 98.2,
  J: 0.0,
  St: 35.06666666666667,
  Nt: 3860.9333333333334,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 19,
  TimeNow: 241.61904761904762,
  S: 3.5238095238095237,
  N: 95.47619047619048,
  J: 0.0,
  St: 142.47619047619048,
  Nt: 3753.5238095238096,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 5,
  TimeNow: 202.0,
  S: 0.16666666666666666,
  N: 98.83333333333333,
  J: 0.0,
  St: 6.333333333333333,
  Nt: 3622.6666666666665,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 20,
  TimeNow: 250.16666666666666,
  S: 2.0833333333333335,
  N: 96.91666666666667,
  J: 0.0,
  St: 83.16666666666667,
  Nt: 3812.8333333333335,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 7,
  TimeNow: 200.41666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 16,
  TimeNow: 240.35,
  S: 9.0,
  N: 90.0,
  J: 0.0,
  St: 368.2,
  Nt: 3527.8,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 10,
  TimeNow: 224.63636363636363,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3896.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 6,
  TimeNow: 196.75,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 11,
  TimeNow: 231.31578947368422,
  S: 0.05263157894736842,
  N: 98.94736842105263,
  J: 0.0,
  St: 4.947368421052632,
  Nt: 3891.0526315789475,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 3,
  TimeNow: 219.64285714285714,
  S: 3.9285714285714284,
  N: 95.07142857142857,
  J: 0.0,
  St: 149.71428571428572,
  Nt: 3479.285714285714,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 8,
  TimeNow: 213.75,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3784.75,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 15,
  TimeNow: 119.9,
  S: 46.45,
  N: 24.95,
  J: 27.1,
  St: 940.85,
  Nt: 508.05,
  Jt: 556.1,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 12,
  TimeNow: 93.87096774193549,
  S: 17.096774193548388,
  N: 76.6774193548387,
  J: 5.129032258064516,
  St: 348.741935483871,
  Nt: 1551.4516129032259,
  Jt: 104.80645161290323,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 13,
  TimeNow: 113.90322580645162,
  S: 18.06451612903226,
  N: 62.354838709677416,
  J: 18.35483870967742,
  St: 369.19354838709677,
  Nt: 1265.5483870967741,
  Jt: 370.258064516129,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 4,
  TimeNow: 142.92857142857142,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3592.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 17,
  TimeNow: 82.29411764705883,
  S: 4.647058823529412,
  N: 94.3529411764706,
  J: 0.0,
  St: 96.76470588235294,
  Nt: 1908.235294117647,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 9,
  TimeNow: 130.83333333333334,
  S: 25.25,
  N: 56.0,
  J: 17.5,
  St: 513.5833333333334,
  Nt: 1134.3333333333333,
  Jt: 357.0833333333333,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 18,
  TimeNow: 81.4375,
  S: 3.5,
  N: 95.5,
  J: 0.0,
  St: 71.875,
  Nt: 1933.125,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 14,
  TimeNow: 120.86666666666666,
  S: 42.2,
  N: 27.0,
  J: 29.266666666666666,
  St: 850.0,
  Nt: 551.9333333333333,
  Jt: 603.0666666666667,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 19,
  TimeNow: 83.14285714285714,
  S: 5.523809523809524,
  N: 93.47619047619048,
  J: 0.0,
  St: 112.19047619047619,
  Nt: 1892.8095238095239,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 5,
  TimeNow: 143.75,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3592.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 20,
  TimeNow: 82.16666666666667,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 2005.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 7,
  TimeNow: 159.75,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3592.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 16,
  TimeNow: 93.25,
  S: 18.55,
  N: 78.35,
  J: 2.0,
  St: 376.65,
  Nt: 1586.35,
  Jt: 42.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 10,
  TimeNow: 136.63636363636363,
  S: 40.09090909090909,
  N: 36.45454545454545,
  J: 22.09090909090909,
  St: 816.8181818181819,
  Nt: 736.1818181818181,
  Jt: 452.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 6,
  TimeNow: 147.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3592.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 11,
  TimeNow: 157.52631578947367,
  S: 31.526315789473685,
  N: 30.05263157894737,
  J: 37.10526315789474,
  St: 642.4736842105264,
  Nt: 613.1052631578947,
  Jt: 749.421052631579,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 3,
  TimeNow: 141.71428571428572,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3592.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 8,
  TimeNow: 135.25,
  S: 3.4166666666666665,
  N: 94.75,
  J: 0.8333333333333334,
  St: 128.58333333333334,
  Nt: 2597.5,
  Jt: 30.833333333333332,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 15,
  TimeNow: 445.8,
  S: 4.75,
  N: 51.65,
  J: 41.9,
  St: 193.35,
  Nt: 1923.65,
  Jt: 1567.15,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 12,
  TimeNow: 180.4516129032258,
  S: 8.806451612903226,
  N: 90.19354838709677,
  J: 0.0,
  St: 341.4193548387097,
  Nt: 3343.6451612903224,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 13,
  TimeNow: 192.7,
  S: 10.6,
  N: 84.7,
  J: 3.6,
  St: 405.2,
  Nt: 3143.266666666667,
  Jt: 136.6,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 4,
  TimeNow: 168.21428571428572,
  S: 0.5714285714285714,
  N: 98.42857142857143,
  J: 0.0,
  St: 23.214285714285715,
  Nt: 3661.8571428571427,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 17,
  TimeNow: 517.7058823529412,
  S: 3.176470588235294,
  N: 46.05882352941177,
  J: 49.529411764705884,
  St: 127.17647058823529,
  Nt: 1710.8823529411766,
  Jt: 1846.6470588235295,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 9,
  TimeNow: 170.75,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 18,
  TimeNow: 495.8125,
  S: 0.75,
  N: 46.1875,
  J: 51.8125,
  St: 35.75,
  Nt: 1714.25,
  Jt: 1934.6875,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 14,
  TimeNow: 314.3333333333333,
  S: 9.666666666666666,
  N: 58.6,
  J: 30.066666666666666,
  St: 373.1333333333333,
  Nt: 2178.866666666667,
  Jt: 1132.4666666666667,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 19,
  TimeNow: 267.04761904761904,
  S: 9.142857142857142,
  N: 63.285714285714285,
  J: 26.0,
  St: 351.9047619047619,
  Nt: 2348.5714285714284,
  Jt: 984.4285714285714,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 5,
  TimeNow: 172.41666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 20,
  TimeNow: 179.58333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 7,
  TimeNow: 169.16666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 16,
  TimeNow: 594.15,
  S: 4.9,
  N: 43.75,
  J: 49.95,
  St: 194.25,
  Nt: 1629.4,
  Jt: 1861.05,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 10,
  TimeNow: 248.9090909090909,
  S: 10.090909090909092,
  N: 73.0,
  J: 15.727272727272727,
  St: 385.6363636363636,
  Nt: 2702.4545454545455,
  Jt: 597.1818181818181,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 6,
  TimeNow: 167.08333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 11,
  TimeNow: 180.52631578947367,
  S: 10.052631578947368,
  N: 88.94736842105263,
  J: 0.0,
  St: 387.10526315789474,
  Nt: 3298.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 3,
  TimeNow: 165.35714285714286,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 8,
  TimeNow: 168.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 15,
  TimeNow: 173.05,
  S: 3.05,
  N: 89.65,
  J: 5.9,
  St: 62.45,
  Nt: 1624.85,
  Jt: 115.7,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 12,
  TimeNow: 180.8709677419355,
  S: 3.774193548387097,
  N: 89.51612903225806,
  J: 4.935483870967742,
  St: 81.41935483870968,
  Nt: 1621.5806451612902,
  Jt: 100.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 13,
  TimeNow: 170.19354838709677,
  S: 2.838709677419355,
  N: 91.12903225806451,
  J: 4.67741935483871,
  St: 61.45161290322581,
  Nt: 1647.0645161290322,
  Jt: 94.48387096774194,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 4,
  TimeNow: 137.28571428571428,
  S: 3.4285714285714284,
  N: 95.57142857142857,
  J: 0.0,
  St: 70.92857142857143,
  Nt: 1732.0714285714287,
  Jt: 0.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 17,
  TimeNow: 188.05882352941177,
  S: 4.176470588235294,
  N: 89.3529411764706,
  J: 5.0,
  St: 83.6470588235294,
  Nt: 1618.3529411764705,
  Jt: 101.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 9,
  TimeNow: 145.16666666666666,
  S: 5.25,
  N: 93.75,
  J: 0.0,
  St: 108.5,
  Nt: 1694.5,
  Jt: 0.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 18,
  TimeNow: 180.0625,
  S: 3.375,
  N: 90.6875,
  J: 4.75,
  St: 67.0625,
  Nt: 1639.25,
  Jt: 96.6875,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 14,
  TimeNow: 167.46666666666667,
  S: 4.0,
  N: 91.33333333333333,
  J: 3.533333333333333,
  St: 80.46666666666667,
  Nt: 1650.6,
  Jt: 71.93333333333334,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 19,
  TimeNow: 183.33333333333334,
  S: 3.6666666666666665,
  N: 89.33333333333333,
  J: 5.0,
  St: 82.0952380952381,
  Nt: 1619.904761904762,
  Jt: 101.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 5,
  TimeNow: 151.66666666666666,
  S: 8.666666666666666,
  N: 90.33333333333333,
  J: 0.0,
  St: 169.33333333333334,
  Nt: 1633.6666666666667,
  Jt: 0.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 20,
  TimeNow: 193.58333333333334,
  S: 4.0,
  N: 89.33333333333333,
  J: 5.0,
  St: 83.66666666666667,
  Nt: 1618.3333333333333,
  Jt: 101.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 7,
  TimeNow: 168.33333333333334,
  S: 4.333333333333333,
  N: 90.66666666666667,
  J: 3.75,
  St: 87.41666666666667,
  Nt: 1637.8333333333333,
  Jt: 77.75,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 16,
  TimeNow: 181.35,
  S: 4.45,
  N: 89.8,
  J: 4.6,
  St: 88.2,
  Nt: 1622.4,
  Jt: 92.4,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 10,
  TimeNow: 153.36363636363637,
  S: 10.363636363636363,
  N: 87.72727272727273,
  J: 0.7272727272727273,
  St: 200.8181818181818,
  Nt: 1586.7272727272727,
  Jt: 15.454545454545455,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 6,
  TimeNow: 164.91666666666666,
  S: 7.333333333333333,
  N: 89.75,
  J: 1.8333333333333333,
  St: 141.16666666666666,
  Nt: 1623.0833333333333,
  Jt: 38.75,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 11,
  TimeNow: 170.73684210526315,
  S: 5.315789473684211,
  N: 90.63157894736842,
  J: 2.6842105263157894,
  St: 106.15789473684211,
  Nt: 1641.7894736842106,
  Jt: 55.05263157894737,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 3,
  TimeNow: 135.57142857142858,
  S: 3.2142857142857144,
  N: 95.78571428571429,
  J: 0.0,
  St: 65.57142857142857,
  Nt: 1737.4285714285713,
  Jt: 0.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 8,
  TimeNow: 143.66666666666666,
  S: 3.75,
  N: 95.25,
  J: 0.0,
  St: 73.5,
  Nt: 1729.5,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 15,
  TimeNow: 103.0,
  S: 24.5,
  N: 74.45,
  J: 0.0,
  St: 570.65,
  Nt: 1712.3,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 12,
  TimeNow: 100.64516129032258,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 2283.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 13,
  TimeNow: 112.96774193548387,
  S: 3.3870967741935485,
  N: 95.54838709677419,
  J: 0.0,
  St: 80.64516129032258,
  Nt: 2202.2903225806454,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 4,
  TimeNow: 221.84615384615384,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5926.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 17,
  TimeNow: 97.82352941176471,
  S: 4.0,
  N: 94.94117647058823,
  J: 0.0,
  St: 94.3529411764706,
  Nt: 2188.5882352941176,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 9,
  TimeNow: 115.91666666666667,
  S: 30.583333333333332,
  N: 68.33333333333333,
  J: 0.0,
  St: 705.75,
  Nt: 1577.1666666666667,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 18,
  TimeNow: 96.5625,
  S: 2.125,
  N: 95.1875,
  J: 1.625,
  St: 50.8125,
  Nt: 2194.125,
  Jt: 38.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 14,
  TimeNow: 102.33333333333333,
  S: 10.8,
  N: 88.2,
  J: 0.0,
  St: 250.06666666666666,
  Nt: 2032.9333333333334,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 19,
  TimeNow: 102.28571428571429,
  S: 15.523809523809524,
  N: 83.47619047619048,
  J: 0.0,
  St: 364.7142857142857,
  Nt: 1918.2857142857142,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 5,
  TimeNow: 220.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5926.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 20,
  TimeNow: 95.08333333333333,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 2283.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 7,
  TimeNow: 264.3333333333333,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5926.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 16,
  TimeNow: 102.9,
  S: 0.7,
  N: 98.2,
  J: 0.0,
  St: 18.2,
  Nt: 2264.7,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 10,
  TimeNow: 105.18181818181819,
  S: 18.727272727272727,
  N: 80.0909090909091,
  J: 0.0,
  St: 438.1818181818182,
  Nt: 1844.6363636363637,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 6,
  TimeNow: 237.16666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5926.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 11,
  TimeNow: 95.57894736842105,
  S: 0.7368421052631579,
  N: 98.21052631578948,
  J: 0.0,
  St: 19.157894736842106,
  Nt: 2263.7368421052633,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 3,
  TimeNow: 218.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5926.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 8,
  TimeNow: 185.83333333333334,
  S: 2.0,
  N: 96.5,
  J: 0.5,
  St: 128.58333333333334,
  Nt: 3641.0833333333335,
  Jt: 31.166666666666668,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 15,
  TimeNow: 222.8,
  S: 35.55,
  N: 49.1,
  J: 13.65,
  St: 1402.5,
  Nt: 1920.65,
  Jt: 555.85,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 12,
  TimeNow: 173.8709677419355,
  S: 7.129032258064516,
  N: 89.64516129032258,
  J: 2.193548387096774,
  St: 284.51612903225805,
  Nt: 3506.8387096774195,
  Jt: 87.64516129032258,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 13,
  TimeNow: 217.70967741935485,
  S: 10.516129032258064,
  N: 79.70967741935483,
  J: 8.516129032258064,
  St: 422.2258064516129,
  Nt: 3120.8709677419356,
  Jt: 335.9032258064516,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 4,
  TimeNow: 150.28571428571428,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3879.0,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 17,
  TimeNow: 161.41176470588235,
  S: 4.705882352941177,
  N: 94.17647058823529,
  J: 0.0,
  St: 194.11764705882354,
  Nt: 3684.8823529411766,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 9,
  TimeNow: 230.66666666666666,
  S: 30.166666666666668,
  N: 59.666666666666664,
  J: 8.916666666666666,
  St: 1184.8333333333333,
  Nt: 2337.4166666666665,
  Jt: 357.0833333333333,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 18,
  TimeNow: 159.3125,
  S: 3.1875,
  N: 94.875,
  J: 0.9375,
  St: 125.8125,
  Nt: 3715.1875,
  Jt: 38.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 14,
  TimeNow: 221.33333333333334,
  S: 25.333333333333332,
  N: 58.06666666666667,
  J: 14.8,
  St: 1003.6666666666666,
  Nt: 2272.6666666666665,
  Jt: 602.6666666666666,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 19,
  TimeNow: 163.47619047619048,
  S: 11.80952380952381,
  N: 87.19047619047619,
  J: 0.0,
  St: 476.6666666666667,
  Nt: 3402.3333333333335,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 5,
  TimeNow: 152.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3879.0,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 20,
  TimeNow: 161.08333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3879.0,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 7,
  TimeNow: 177.08333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3879.0,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 16,
  TimeNow: 180.35,
  S: 8.45,
  N: 89.45,
  J: 1.0,
  St: 341.95,
  Nt: 3495.05,
  Jt: 42.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 10,
  TimeNow: 226.0909090909091,
  S: 29.454545454545453,
  N: 58.27272727272727,
  J: 10.818181818181818,
  St: 1163.4545454545455,
  Nt: 2282.818181818182,
  Jt: 432.72727272727275,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 6,
  TimeNow: 160.66666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3879.0,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 11,
  TimeNow: 233.31578947368422,
  S: 16.36842105263158,
  N: 65.3157894736842,
  J: 16.63157894736842,
  St: 657.6842105263158,
  Nt: 2557.0526315789475,
  Jt: 664.2631578947369,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 3,
  TimeNow: 147.53846153846155,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3879.0,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 8,
  TimeNow: 177.75,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3879.0,
  Jt: 0.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 15,
  TimeNow: 130.75,
  S: 37.8,
  N: 3.6,
  J: 57.55,
  St: 142.65,
  Nt: 13.55,
  Jt: 214.8,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 12,
  TimeNow: 132.7741935483871,
  S: 9.193548387096774,
  N: 0.7419354838709677,
  J: 89.06451612903226,
  St: 35.70967741935484,
  Nt: 3.0,
  Jt: 332.2903225806452,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 13,
  TimeNow: 98.41935483870968,
  S: 30.806451612903224,
  N: 0.0967741935483871,
  J: 68.09677419354838,
  St: 116.48387096774194,
  Nt: 0.41935483870967744,
  Jt: 254.09677419354838,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 4,
  TimeNow: 36.0,
  S: 2.142857142857143,
  N: 96.85714285714286,
  J: 0.0,
  St: 9.285714285714286,
  Nt: 361.7142857142857,
  Jt: 0.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 17,
  TimeNow: 88.05882352941177,
  S: 26.647058823529413,
  N: 0.0,
  J: 72.3529411764706,
  St: 100.52941176470588,
  Nt: 0.0,
  Jt: 270.47058823529414,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 9,
  TimeNow: 82.5,
  S: 22.25,
  N: 5.0,
  J: 71.66666666666667,
  St: 84.25,
  Nt: 19.0,
  Jt: 267.75,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 18,
  TimeNow: 107.8125,
  S: 26.0625,
  N: 0.0,
  J: 72.9375,
  St: 98.875,
  Nt: 0.0,
  Jt: 272.125,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 14,
  TimeNow: 105.26666666666667,
  S: 23.266666666666666,
  N: 3.8,
  J: 71.93333333333334,
  St: 87.93333333333334,
  Nt: 14.333333333333334,
  Jt: 268.73333333333335,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 19,
  TimeNow: 126.47619047619048,
  S: 18.952380952380953,
  N: 0.2857142857142857,
  J: 79.76190476190476,
  St: 71.61904761904762,
  Nt: 1.2380952380952381,
  Jt: 298.14285714285717,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 5,
  TimeNow: 36.416666666666664,
  S: 1.25,
  N: 97.25,
  J: 0.5,
  St: 5.416666666666667,
  Nt: 363.4166666666667,
  Jt: 2.1666666666666665,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 20,
  TimeNow: 85.25,
  S: 49.333333333333336,
  N: 5.916666666666667,
  J: 43.25,
  St: 185.75,
  Nt: 22.416666666666668,
  Jt: 162.83333333333334,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 7,
  TimeNow: 40.916666666666664,
  S: 15.25,
  N: 83.75,
  J: 0.0,
  St: 58.083333333333336,
  Nt: 312.9166666666667,
  Jt: 0.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 16,
  TimeNow: 99.7,
  S: 46.05,
  N: 1.1,
  J: 51.85,
  St: 172.95,
  Nt: 4.15,
  Jt: 193.9,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 10,
  TimeNow: 109.0909090909091,
  S: 7.636363636363637,
  N: 0.0,
  J: 91.36363636363636,
  St: 30.181818181818183,
  Nt: 0.0,
  Jt: 340.8181818181818,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 6,
  TimeNow: 33.25,
  S: 0.75,
  N: 98.25,
  J: 0.0,
  St: 3.25,
  Nt: 367.75,
  Jt: 0.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 11,
  TimeNow: 132.73684210526315,
  S: 2.526315789473684,
  N: 0.0,
  J: 96.47368421052632,
  St: 10.947368421052632,
  Nt: 0.0,
  Jt: 360.05263157894734,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 3,
  TimeNow: 52.714285714285715,
  S: 0.0,
  N: 72.0,
  J: 27.0,
  St: 0.0,
  Nt: 270.0,
  Jt: 101.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 8,
  TimeNow: 117.66666666666667,
  S: 40.583333333333336,
  N: 4.416666666666667,
  J: 54.0,
  St: 152.75,
  Nt: 16.416666666666668,
  Jt: 201.83333333333334,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 15,
  TimeNow: 912.7,
  S: 23.15,
  N: 44.6,
  J: 30.8,
  St: 1768.4,
  Nt: 3385.1,
  Jt: 2338.5,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 12,
  TimeNow: 1058.7096774193549,
  S: 15.580645161290322,
  N: 56.25806451612903,
  J: 26.70967741935484,
  St: 1200.6129032258063,
  Nt: 4253.709677419355,
  Jt: 2037.6774193548388,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 13,
  TimeNow: 933.0,
  S: 16.64516129032258,
  N: 54.064516129032256,
  J: 27.774193548387096,
  St: 1283.5483870967741,
  Nt: 4093.451612903226,
  Jt: 2115.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 4,
  TimeNow: 368.7142857142857,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7492.0,
  Jt: 0.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 17,
  TimeNow: 721.1764705882352,
  S: 29.11764705882353,
  N: 50.1764705882353,
  J: 19.11764705882353,
  St: 2217.5882352941176,
  Nt: 3804.0588235294117,
  Jt: 1470.3529411764705,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 9,
  TimeNow: 743.1666666666666,
  S: 10.833333333333334,
  N: 74.08333333333333,
  J: 13.583333333333334,
  St: 841.75,
  Nt: 5594.666666666667,
  Jt: 1055.5833333333333,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 18,
  TimeNow: 618.125,
  S: 20.125,
  N: 67.625,
  J: 11.0,
  St: 1543.625,
  Nt: 5092.875,
  Jt: 855.5,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 14,
  TimeNow: 920.9333333333333,
  S: 19.333333333333332,
  N: 55.266666666666666,
  J: 23.866666666666667,
  St: 1484.2666666666667,
  Nt: 4186.8,
  Jt: 1820.9333333333334,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 19,
  TimeNow: 601.6666666666666,
  S: 28.0,
  N: 59.857142857142854,
  J: 10.666666666666666,
  St: 2139.6190476190477,
  Nt: 4519.047619047619,
  Jt: 833.3333333333334,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 5,
  TimeNow: 375.4166666666667,
  S: 0.08333333333333333,
  N: 98.91666666666667,
  J: 0.0,
  St: 12.916666666666666,
  Nt: 7479.083333333333,
  Jt: 0.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 20,
  TimeNow: 670.1666666666666,
  S: 32.083333333333336,
  N: 51.666666666666664,
  J: 14.666666666666666,
  St: 2438.6666666666665,
  Nt: 3913.4166666666665,
  Jt: 1139.9166666666667,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 7,
  TimeNow: 759.4166666666666,
  S: 17.0,
  N: 68.91666666666667,
  J: 12.5,
  St: 1314.9166666666667,
  Nt: 5201.5,
  Jt: 975.5833333333334,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 16,
  TimeNow: 1048.35,
  S: 19.85,
  N: 44.45,
  J: 34.15,
  St: 1522.75,
  Nt: 3371.85,
  Jt: 2597.4,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 10,
  TimeNow: 1544.1818181818182,
  S: 14.636363636363637,
  N: 46.54545454545455,
  J: 37.27272727272727,
  St: 1124.909090909091,
  Nt: 3537.6363636363635,
  Jt: 2829.4545454545455,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 6,
  TimeNow: 513.5833333333334,
  S: 5.666666666666667,
  N: 88.91666666666667,
  J: 4.166666666666667,
  St: 460.25,
  Nt: 6699.5,
  Jt: 332.25,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 11,
  TimeNow: 1598.6842105263158,
  S: 13.157894736842104,
  N: 48.0,
  J: 37.421052631578945,
  St: 1017.6315789473684,
  Nt: 3636.0526315789475,
  Jt: 2838.315789473684,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 3,
  TimeNow: 366.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7492.0,
  Jt: 0.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 8,
  TimeNow: 599.1666666666666,
  S: 11.75,
  N: 77.33333333333333,
  J: 9.333333333333334,
  St: 913.6666666666666,
  Nt: 5836.0,
  Jt: 742.3333333333334,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 15,
  TimeNow: 598.25,
  S: 24.5,
  N: 18.05,
  J: 56.1,
  St: 1206.45,
  Nt: 890.9,
  Jt: 2739.65,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 12,
  TimeNow: 571.6774193548387,
  S: 31.419354838709676,
  N: 11.258064516129032,
  J: 56.064516129032256,
  St: 1537.5806451612902,
  Nt: 558.0645161290323,
  Jt: 2739.2580645161293,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 13,
  TimeNow: 727.6451612903226,
  S: 12.806451612903226,
  N: 8.806451612903226,
  J: 76.83870967741936,
  St: 641.3548387096774,
  Nt: 442.51612903225805,
  Jt: 3751.8387096774195,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 4,
  TimeNow: 194.57142857142858,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 4832.0,
  Jt: 0.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 17,
  TimeNow: 416.11764705882354,
  S: 53.1764705882353,
  N: 29.235294117647058,
  J: 16.11764705882353,
  St: 2592.294117647059,
  Nt: 1438.8235294117646,
  Jt: 805.5882352941177,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 9,
  TimeNow: 560.6666666666666,
  S: 15.416666666666666,
  N: 21.916666666666668,
  J: 60.916666666666664,
  St: 768.6666666666666,
  Nt: 1086.3333333333333,
  Jt: 2982.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 18,
  TimeNow: 356.8125,
  S: 43.8125,
  N: 33.75,
  J: 21.125,
  St: 2137.4375,
  Nt: 1656.0625,
  Jt: 1038.8125,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 14,
  TimeNow: 853.5333333333333,
  S: 20.6,
  N: 5.333333333333333,
  J: 72.4,
  St: 1018.7333333333333,
  Nt: 283.6,
  Jt: 3534.6666666666665,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 19,
  TimeNow: 316.1904761904762,
  S: 48.38095238095238,
  N: 42.04761904761905,
  J: 8.380952380952381,
  St: 2361.095238095238,
  Nt: 2053.4285714285716,
  Jt: 417.4761904761905,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 5,
  TimeNow: 202.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 4832.0,
  Jt: 0.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 20,
  TimeNow: 302.3333333333333,
  S: 47.083333333333336,
  N: 48.25,
  J: 3.5,
  St: 2300.1666666666665,
  Nt: 2357.25,
  Jt: 174.58333333333334,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 7,
  TimeNow: 647.8333333333334,
  S: 24.083333333333332,
  N: 4.916666666666667,
  J: 69.75,
  St: 1187.0,
  Nt: 247.83333333333334,
  Jt: 3398.8333333333335,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 16,
  TimeNow: 485.5,
  S: 47.9,
  N: 18.2,
  J: 32.5,
  St: 2344.55,
  Nt: 897.1,
  Jt: 1594.85,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 10,
  TimeNow: 443.8181818181818,
  S: 47.18181818181818,
  N: 16.0,
  J: 35.27272727272727,
  St: 2306.7272727272725,
  Nt: 784.4545454545455,
  Jt: 1742.1818181818182,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 6,
  TimeNow: 364.75,
  S: 32.333333333333336,
  N: 47.916666666666664,
  J: 18.5,
  St: 1577.8333333333333,
  Nt: 2337.75,
  Jt: 916.4166666666666,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 11,
  TimeNow: 495.36842105263156,
  S: 38.21052631578947,
  N: 11.368421052631579,
  J: 49.21052631578947,
  St: 1870.2631578947369,
  Nt: 561.6842105263158,
  Jt: 2401.6315789473683,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 3,
  TimeNow: 188.21428571428572,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 4832.0,
  Jt: 0.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 8,
  TimeNow: 382.1666666666667,
  S: 34.75,
  N: 40.5,
  J: 23.583333333333332,
  St: 1698.0,
  Nt: 1979.9166666666667,
  Jt: 1156.5833333333333,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 15,
  TimeNow: 237.6,
  S: 9.6,
  N: 89.3,
  J: 0.0,
  St: 225.1,
  Nt: 2011.4,
  Jt: 1.9,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 12,
  TimeNow: 234.46666666666667,
  S: 7.733333333333333,
  N: 90.9,
  J: 0.16666666666666666,
  St: 183.3,
  Nt: 2048.5333333333333,
  Jt: 6.733333333333333,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 13,
  TimeNow: 237.7741935483871,
  S: 7.612903225806452,
  N: 90.58064516129032,
  J: 0.12903225806451613,
  St: 179.38709677419354,
  Nt: 2040.1612903225807,
  Jt: 18.903225806451612,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 4,
  TimeNow: 231.85714285714286,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 34.0,
  Nt: 2204.0,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 17,
  TimeNow: 257.8235294117647,
  S: 13.588235294117647,
  N: 82.0,
  J: 2.588235294117647,
  St: 319.29411764705884,
  Nt: 1849.764705882353,
  Jt: 69.05882352941177,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 9,
  TimeNow: 237.08333333333334,
  S: 8.416666666666666,
  N: 89.75,
  J: 0.3333333333333333,
  St: 200.58333333333334,
  Nt: 2021.6666666666667,
  Jt: 15.75,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 18,
  TimeNow: 238.0625,
  S: 10.375,
  N: 85.25,
  J: 2.5625,
  St: 244.9375,
  Nt: 1921.1875,
  Jt: 72.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 14,
  TimeNow: 239.46666666666667,
  S: 9.6,
  N: 88.86666666666666,
  J: 0.06666666666666667,
  St: 224.4,
  Nt: 2001.2,
  Jt: 12.533333333333333,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 19,
  TimeNow: 245.0952380952381,
  S: 10.428571428571429,
  N: 85.04761904761905,
  J: 2.9047619047619047,
  St: 244.9047619047619,
  Nt: 1915.8095238095239,
  Jt: 77.33333333333333,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 5,
  TimeNow: 241.58333333333334,
  S: 4.5,
  N: 94.5,
  J: 0.0,
  St: 112.0,
  Nt: 2126.0,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 20,
  TimeNow: 244.33333333333334,
  S: 10.75,
  N: 87.0,
  J: 1.0833333333333333,
  St: 250.91666666666666,
  Nt: 1960.6666666666667,
  Jt: 26.583333333333332,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 7,
  TimeNow: 236.58333333333334,
  S: 10.666666666666666,
  N: 88.16666666666667,
  J: 0.0,
  St: 249.5,
  Nt: 1985.3333333333333,
  Jt: 3.1666666666666665,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 16,
  TimeNow: 247.9,
  S: 11.8,
  N: 86.25,
  J: 0.75,
  St: 277.6,
  Nt: 1941.4,
  Jt: 19.2,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 10,
  TimeNow: 241.63636363636363,
  S: 7.636363636363637,
  N: 89.63636363636364,
  J: 1.6363636363636365,
  St: 181.54545454545453,
  Nt: 2014.1818181818182,
  Jt: 42.27272727272727,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 6,
  TimeNow: 283.6666666666667,
  S: 18.5,
  N: 73.08333333333333,
  J: 7.0,
  St: 425.9166666666667,
  Nt: 1649.5833333333333,
  Jt: 162.5,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 11,
  TimeNow: 241.78947368421052,
  S: 10.210526315789474,
  N: 87.78947368421052,
  J: 0.3157894736842105,
  St: 237.52631578947367,
  Nt: 1980.0526315789473,
  Jt: 20.42105263157895,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 3,
  TimeNow: 246.42857142857142,
  S: 7.642857142857143,
  N: 91.14285714285714,
  J: 0.0,
  St: 188.42857142857142,
  Nt: 2049.5714285714284,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 8,
  TimeNow: 228.91666666666666,
  S: 7.083333333333333,
  N: 91.83333333333333,
  J: 0.0,
  St: 166.58333333333334,
  Nt: 2070.25,
  Jt: 1.5833333333333333,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 15,
  TimeNow: 297.85,
  S: 31.75,
  N: 58.45,
  J: 8.35,
  St: 802.95,
  Nt: 1470.0,
  Jt: 220.05,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 12,
  TimeNow: 252.16666666666666,
  S: 11.466666666666667,
  N: 85.23333333333333,
  J: 2.2666666666666666,
  St: 298.1333333333333,
  Nt: 2133.233333333333,
  Jt: 60.86666666666667,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 13,
  TimeNow: 264.96774193548384,
  S: 9.419354838709678,
  N: 85.41935483870968,
  J: 3.967741935483871,
  St: 247.4516129032258,
  Nt: 2139.483870967742,
  Jt: 105.48387096774194,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 4,
  TimeNow: 178.85714285714286,
  S: 0.42857142857142855,
  N: 97.92857142857143,
  J: 0.6428571428571429,
  St: 14.357142857142858,
  Nt: 2455.1428571428573,
  Jt: 22.5,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 17,
  TimeNow: 650.8235294117648,
  S: 18.88235294117647,
  N: 15.117647058823529,
  J: 64.47058823529412,
  St: 483.11764705882354,
  Nt: 390.11764705882354,
  Jt: 1619.4117647058824,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 9,
  TimeNow: 228.58333333333334,
  S: 2.0,
  N: 95.58333333333333,
  J: 1.4166666666666667,
  St: 57.0,
  Nt: 2396.4166666666665,
  Jt: 38.583333333333336,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 18,
  TimeNow: 419.0625,
  S: 26.875,
  N: 38.0,
  J: 33.5,
  St: 685.1875,
  Nt: 959.25,
  Jt: 848.375,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 14,
  TimeNow: 280.93333333333334,
  S: 16.866666666666667,
  N: 79.06666666666666,
  J: 2.933333333333333,
  St: 431.6,
  Nt: 1982.8666666666666,
  Jt: 77.8,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 19,
  TimeNow: 282.2857142857143,
  S: 18.238095238095237,
  N: 76.71428571428571,
  J: 3.6666666666666665,
  St: 467.14285714285717,
  Nt: 1926.0,
  Jt: 99.47619047619048,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 5,
  TimeNow: 183.5,
  S: 0.75,
  N: 97.83333333333333,
  J: 0.4166666666666667,
  St: 25.25,
  Nt: 2452.1666666666665,
  Jt: 14.583333333333334,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 20,
  TimeNow: 270.5833333333333,
  S: 16.166666666666668,
  N: 76.41666666666667,
  J: 6.166666666666667,
  St: 412.1666666666667,
  Nt: 1916.25,
  Jt: 164.33333333333334,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 7,
  TimeNow: 195.58333333333334,
  S: 1.6666666666666667,
  N: 96.66666666666667,
  J: 0.6666666666666666,
  St: 45.666666666666664,
  Nt: 2428.5,
  Jt: 17.833333333333332,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 16,
  TimeNow: 435.15,
  S: 30.45,
  N: 26.55,
  J: 41.2,
  St: 774.1,
  Nt: 678.1,
  Jt: 1040.5,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 10,
  TimeNow: 231.8181818181818,
  S: 3.1818181818181817,
  N: 94.9090909090909,
  J: 0.9090909090909091,
  St: 91.45454545454545,
  Nt: 2374.7272727272725,
  Jt: 25.818181818181817,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 6,
  TimeNow: 175.0,
  S: 0.3333333333333333,
  N: 98.58333333333333,
  J: 0.08333333333333333,
  St: 12.583333333333334,
  Nt: 2476.5,
  Jt: 2.9166666666666665,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 11,
  TimeNow: 237.47368421052633,
  S: 5.052631578947368,
  N: 92.47368421052632,
  J: 1.4736842105263157,
  St: 135.10526315789474,
  Nt: 2317.5263157894738,
  Jt: 39.421052631578945,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 3,
  TimeNow: 182.0,
  S: 0.6428571428571429,
  N: 98.28571428571429,
  J: 0.07142857142857142,
  St: 22.5,
  Nt: 2467.0,
  Jt: 2.5,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 8,
  TimeNow: 220.33333333333334,
  S: 1.8333333333333333,
  N: 94.75,
  J: 2.4166666666666665,
  St: 50.0,
  Nt: 2376.6666666666665,
  Jt: 65.33333333333333,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 15,
  TimeNow: 1311.35,
  S: 34.8,
  N: 25.7,
  J: 37.95,
  St: 4653.45,
  Nt: 3441.8,
  Jt: 5064.75,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 12,
  TimeNow: 1103.258064516129,
  S: 27.096774193548388,
  N: 44.193548387096776,
  J: 27.096774193548388,
  St: 3638.6129032258063,
  Nt: 5877.064516129032,
  Jt: 3644.3225806451615,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 13,
  TimeNow: 1449.225806451613,
  S: 12.258064516129032,
  N: 35.45161290322581,
  J: 50.58064516129032,
  St: 1682.741935483871,
  Nt: 4735.387096774193,
  Jt: 6741.870967741936,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 4,
  TimeNow: 501.7142857142857,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 13160.0,
  Jt: 0.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 17,
  TimeNow: 1003.2352941176471,
  S: 33.35294117647059,
  N: 46.588235294117645,
  J: 18.294117647058822,
  St: 4469.764705882353,
  Nt: 6210.117647058823,
  Jt: 2480.1176470588234,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 9,
  TimeNow: 1209.9166666666667,
  S: 24.5,
  N: 34.0,
  J: 40.0,
  St: 3282.9166666666665,
  Nt: 4539.166666666667,
  Jt: 5337.916666666667,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 18,
  TimeNow: 775.75,
  S: 25.375,
  N: 65.375,
  J: 7.625,
  St: 3397.9375,
  Nt: 8670.0625,
  Jt: 1092.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 14,
  TimeNow: 1690.0666666666666,
  S: 16.733333333333334,
  N: 28.8,
  J: 53.06666666666667,
  St: 2276.133333333333,
  Nt: 3835.0666666666666,
  Jt: 7048.8,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 19,
  TimeNow: 722.4761904761905,
  S: 31.38095238095238,
  N: 65.42857142857143,
  J: 1.9523809523809523,
  St: 4202.809523809524,
  Nt: 8677.809523809523,
  Jt: 279.3809523809524,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 5,
  TimeNow: 516.1666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 34.75,
  Nt: 13125.25,
  Jt: 0.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 20,
  TimeNow: 702.5833333333334,
  S: 22.666666666666668,
  N: 76.0,
  J: 0.3333333333333333,
  St: 3027.5833333333335,
  Nt: 10082.25,
  Jt: 50.166666666666664,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 7,
  TimeNow: 1180.0833333333333,
  S: 19.5,
  N: 44.75,
  J: 34.166666666666664,
  St: 2653.75,
  Nt: 5935.833333333333,
  Jt: 4570.416666666667,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 16,
  TimeNow: 1098.05,
  S: 33.3,
  N: 42.15,
  J: 23.15,
  St: 4435.0,
  Nt: 5613.95,
  Jt: 3111.05,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 10,
  TimeNow: 897.7272727272727,
  S: 38.63636363636363,
  N: 42.72727272727273,
  J: 17.363636363636363,
  St: 5150.0,
  Nt: 5675.181818181818,
  Jt: 2334.818181818182,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 6,
  TimeNow: 694.75,
  S: 14.416666666666666,
  N: 79.41666666666667,
  J: 4.75,
  St: 1976.1666666666667,
  Nt: 10512.25,
  Jt: 671.5833333333334,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 11,
  TimeNow: 987.578947368421,
  S: 26.57894736842105,
  N: 46.8421052631579,
  J: 25.105263157894736,
  St: 3548.3684210526317,
  Nt: 6238.473684210527,
  Jt: 3373.157894736842,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 3,
  TimeNow: 490.42857142857144,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 13160.0,
  Jt: 0.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 8,
  TimeNow: 937.0,
  S: 24.583333333333332,
  N: 55.25,
  J: 18.75,
  St: 3299.0833333333335,
  Nt: 7334.583333333333,
  Jt: 2526.3333333333335,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 15,
  TimeNow: 890.3,
  S: 15.1,
  N: 71.65,
  J: 11.9,
  St: 1358.9,
  Nt: 6329.2,
  Jt: 1080.9,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 12,
  TimeNow: 1043.516129032258,
  S: 32.483870967741936,
  N: 48.064516129032256,
  J: 18.0,
  St: 2890.516129032258,
  Nt: 4255.4838709677415,
  Jt: 1623.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 13,
  TimeNow: 1004.3225806451613,
  S: 23.387096774193548,
  N: 56.774193548387096,
  J: 18.29032258064516,
  St: 2102.5806451612902,
  Nt: 5020.709677419355,
  Jt: 1645.7096774193549,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 4,
  TimeNow: 474.07142857142856,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 1.3571428571428572,
  Nt: 8767.642857142857,
  Jt: 0.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 17,
  TimeNow: 1030.9411764705883,
  S: 31.647058823529413,
  N: 38.35294117647059,
  J: 28.705882352941178,
  St: 2814.4117647058824,
  Nt: 3391.764705882353,
  Jt: 2562.823529411765,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 9,
  TimeNow: 674.5,
  S: 0.9166666666666666,
  N: 92.41666666666667,
  J: 5.0,
  St: 117.58333333333333,
  Nt: 8151.333333333333,
  Jt: 500.0833333333333,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 18,
  TimeNow: 786.0,
  S: 19.4375,
  N: 66.9375,
  J: 12.0625,
  St: 1762.625,
  Nt: 5917.1875,
  Jt: 1089.1875,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 14,
  TimeNow: 902.2666666666667,
  S: 23.6,
  N: 62.06666666666667,
  J: 12.866666666666667,
  St: 2108.8,
  Nt: 5488.2,
  Jt: 1172.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 19,
  TimeNow: 858.3333333333334,
  S: 17.38095238095238,
  N: 64.52380952380952,
  J: 16.38095238095238,
  St: 1565.6666666666667,
  Nt: 5708.333333333333,
  Jt: 1495.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 5,
  TimeNow: 487.3333333333333,
  S: 0.16666666666666666,
  N: 98.83333333333333,
  J: 0.0,
  St: 29.333333333333332,
  Nt: 8739.666666666666,
  Jt: 0.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 20,
  TimeNow: 937.3333333333334,
  S: 21.583333333333332,
  N: 57.916666666666664,
  J: 19.166666666666668,
  St: 1930.9166666666667,
  Nt: 5110.083333333333,
  Jt: 1728.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 7,
  TimeNow: 580.25,
  S: 6.416666666666667,
  N: 90.83333333333333,
  J: 1.1666666666666667,
  St: 625.1666666666666,
  Nt: 8010.25,
  Jt: 133.58333333333334,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 16,
  TimeNow: 995.2,
  S: 33.4,
  N: 46.1,
  J: 18.9,
  St: 2972.55,
  Nt: 4086.1,
  Jt: 1710.35,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 10,
  TimeNow: 711.7272727272727,
  S: 3.8181818181818183,
  N: 88.72727272727273,
  J: 5.909090909090909,
  St: 379.09090909090907,
  Nt: 7817.090909090909,
  Jt: 572.8181818181819,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 6,
  TimeNow: 568.0,
  S: 2.8333333333333335,
  N: 95.08333333333333,
  J: 0.75,
  St: 292.5,
  Nt: 8383.583333333334,
  Jt: 92.91666666666667,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 11,
  TimeNow: 963.3684210526316,
  S: 15.526315789473685,
  N: 67.26315789473684,
  J: 15.894736842105264,
  St: 1396.157894736842,
  Nt: 5935.368421052632,
  Jt: 1437.4736842105262,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 3,
  TimeNow: 472.14285714285717,
  S: 0.14285714285714285,
  N: 98.85714285714286,
  J: 0.0,
  St: 22.785714285714285,
  Nt: 8746.214285714286,
  Jt: 0.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 8,
  TimeNow: 548.9166666666666,
  S: 2.0,
  N: 96.41666666666667,
  J: 0.5,
  St: 222.75,
  Nt: 8494.75,
  Jt: 51.5,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 15,
  TimeNow: 196.6,
  S: 3.8,
  N: 94.95,
  J: 0.1,
  St: 85.6,
  Nt: 1985.15,
  Jt: 2.5,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 12,
  TimeNow: 197.1290322580645,
  S: 0.8387096774193549,
  N: 98.16129032258064,
  J: 0.0,
  St: 20.70967741935484,
  Nt: 2052.7741935483873,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 13,
  TimeNow: 201.1290322580645,
  S: 1.3225806451612903,
  N: 97.41935483870968,
  J: 0.16129032258064516,
  St: 32.12903225806452,
  Nt: 2037.5806451612902,
  Jt: 3.935483870967742,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 4,
  TimeNow: 214.92857142857142,
  S: 12.5,
  N: 86.5,
  J: 0.0,
  St: 260.64285714285717,
  Nt: 1812.7857142857142,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 17,
  TimeNow: 274.05882352941177,
  S: 5.588235294117647,
  N: 87.41176470588235,
  J: 5.764705882352941,
  St: 123.82352941176471,
  Nt: 1823.5882352941176,
  Jt: 125.58823529411765,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 9,
  TimeNow: 195.33333333333334,
  S: 1.5833333333333333,
  N: 97.33333333333333,
  J: 0.0,
  St: 36.833333333333336,
  Nt: 2036.5,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 18,
  TimeNow: 283.875,
  S: 5.5,
  N: 86.75,
  J: 6.5,
  St: 123.0625,
  Nt: 1808.0625,
  Jt: 141.9375,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 14,
  TimeNow: 200.06666666666666,
  S: 3.533333333333333,
  N: 95.33333333333333,
  J: 0.0,
  St: 79.13333333333334,
  Nt: 1994.1333333333334,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 19,
  TimeNow: 309.42857142857144,
  S: 5.904761904761905,
  N: 82.61904761904762,
  J: 10.095238095238095,
  St: 133.9047619047619,
  Nt: 1721.2857142857142,
  Jt: 217.8095238095238,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 5,
  TimeNow: 218.66666666666666,
  S: 16.333333333333332,
  N: 82.66666666666667,
  J: 0.0,
  St: 344.8333333333333,
  Nt: 1728.25,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 20,
  TimeNow: 227.91666666666666,
  S: 7.416666666666667,
  N: 89.83333333333333,
  J: 1.4166666666666667,
  St: 165.66666666666666,
  Nt: 1876.75,
  Jt: 30.583333333333332,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 7,
  TimeNow: 227.75,
  S: 30.333333333333332,
  N: 68.66666666666667,
  J: 0.0,
  St: 634.8333333333334,
  Nt: 1438.25,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 16,
  TimeNow: 231.2,
  S: 5.55,
  N: 89.85,
  J: 3.3,
  St: 125.45,
  Nt: 1875.9,
  Jt: 71.65,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 10,
  TimeNow: 196.0,
  S: 2.5454545454545454,
  N: 96.0909090909091,
  J: 0.0,
  St: 68.54545454545455,
  Nt: 2004.4545454545455,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 6,
  TimeNow: 223.0,
  S: 8.75,
  N: 90.25,
  J: 0.0,
  St: 185.33333333333334,
  Nt: 1888.3333333333333,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 11,
  TimeNow: 198.10526315789474,
  S: 1.368421052631579,
  N: 97.57894736842105,
  J: 0.0,
  St: 35.526315789473685,
  Nt: 2038.0,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 3,
  TimeNow: 195.28571428571428,
  S: 0.7142857142857143,
  N: 98.28571428571429,
  J: 0.0,
  St: 15.285714285714286,
  Nt: 2058.5714285714284,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 8,
  TimeNow: 232.33333333333334,
  S: 19.5,
  N: 79.5,
  J: 0.0,
  St: 409.9166666666667,
  Nt: 1663.4166666666667,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 15,
  TimeNow: 358.5,
  S: 44.05,
  N: 7.85,
  J: 46.45,
  St: 869.05,
  Nt: 165.35,
  Jt: 915.15,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 12,
  TimeNow: 353.4193548387097,
  S: 33.96774193548387,
  N: 9.064516129032258,
  J: 55.516129032258064,
  St: 671.483870967742,
  Nt: 185.32258064516128,
  Jt: 1092.1935483870968,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 13,
  TimeNow: 319.19354838709677,
  S: 34.58064516129032,
  N: 10.225806451612904,
  J: 53.70967741935484,
  St: 685.8387096774194,
  Nt: 208.4516129032258,
  Jt: 1055.032258064516,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 4,
  TimeNow: 91.28571428571429,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1949.0,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 17,
  TimeNow: 293.52941176470586,
  S: 37.411764705882355,
  N: 14.941176470588236,
  J: 46.0,
  St: 740.4705882352941,
  Nt: 304.47058823529414,
  Jt: 904.6470588235294,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 9,
  TimeNow: 303.8333333333333,
  S: 18.25,
  N: 50.333333333333336,
  J: 30.083333333333332,
  St: 366.25,
  Nt: 989.1666666666666,
  Jt: 593.6666666666666,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 18,
  TimeNow: 273.4375,
  S: 34.0625,
  N: 21.5,
  J: 42.9375,
  St: 674.8125,
  Nt: 430.25,
  Jt: 844.3125,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 14,
  TimeNow: 326.46666666666664,
  S: 33.266666666666666,
  N: 14.333333333333334,
  J: 50.666666666666664,
  St: 660.3333333333334,
  Nt: 293.2,
  Jt: 995.8666666666667,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 19,
  TimeNow: 266.5238095238095,
  S: 47.857142857142854,
  N: 9.19047619047619,
  J: 41.76190476190476,
  St: 939.0,
  Nt: 188.23809523809524,
  Jt: 821.8571428571429,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 5,
  TimeNow: 92.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1949.0,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 20,
  TimeNow: 231.33333333333334,
  S: 46.0,
  N: 12.083333333333334,
  J: 40.5,
  St: 903.25,
  Nt: 243.66666666666666,
  Jt: 802.1666666666666,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 7,
  TimeNow: 118.08333333333333,
  S: 17.333333333333332,
  N: 81.0,
  J: 0.5833333333333334,
  St: 345.75,
  Nt: 1587.5833333333333,
  Jt: 15.666666666666666,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 16,
  TimeNow: 369.65,
  S: 37.1,
  N: 4.85,
  J: 56.4,
  St: 732.4,
  Nt: 106.55,
  Jt: 1110.4,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 10,
  TimeNow: 565.2727272727273,
  S: 29.90909090909091,
  N: 4.818181818181818,
  J: 63.72727272727273,
  St: 594.4545454545455,
  Nt: 98.63636363636364,
  Jt: 1255.909090909091,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 6,
  TimeNow: 95.25,
  S: 0.5833333333333334,
  N: 98.41666666666667,
  J: 0.0,
  St: 13.916666666666666,
  Nt: 1935.0833333333333,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 11,
  TimeNow: 395.2631578947368,
  S: 34.26315789473684,
  N: 6.947368421052632,
  J: 57.421052631578945,
  St: 676.2105263157895,
  Nt: 142.0,
  Jt: 1130.8947368421052,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 3,
  TimeNow: 88.66666666666667,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1949.0,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 8,
  TimeNow: 117.83333333333333,
  S: 5.916666666666667,
  N: 88.25,
  J: 4.666666666666667,
  St: 122.25,
  Nt: 1734.9166666666667,
  Jt: 91.83333333333333,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 15,
  TimeNow: 706.7,
  S: 13.85,
  N: 0.4,
  J: 84.65,
  St: 734.1,
  Nt: 25.8,
  Jt: 4339.1,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 12,
  TimeNow: 449.48387096774195,
  S: 29.096774193548388,
  N: 21.35483870967742,
  J: 47.935483870967744,
  St: 1515.225806451613,
  Nt: 1120.0645161290322,
  Jt: 2463.7096774193546,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 13,
  TimeNow: 398.35483870967744,
  S: 60.935483870967744,
  N: 19.516129032258064,
  J: 18.096774193548388,
  St: 3132.1935483870966,
  Nt: 1022.5483870967741,
  Jt: 944.258064516129,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 4,
  TimeNow: 194.64285714285714,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5099.0,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 17,
  TimeNow: 739.7647058823529,
  S: 17.294117647058822,
  N: 0.0,
  J: 81.70588235294117,
  St: 904.4117647058823,
  Nt: 0.0,
  Jt: 4194.588235294118,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 9,
  TimeNow: 267.3333333333333,
  S: 32.666666666666664,
  N: 66.33333333333333,
  J: 0.0,
  St: 1690.4166666666667,
  Nt: 3408.5833333333335,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 18,
  TimeNow: 649.1875,
  S: 20.0625,
  N: 0.4375,
  J: 78.4375,
  St: 1055.0,
  Nt: 24.1875,
  Jt: 4019.8125,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 14,
  TimeNow: 606.8,
  S: 40.266666666666666,
  N: 5.733333333333333,
  J: 52.93333333333333,
  St: 2073.3333333333335,
  Nt: 298.26666666666665,
  Jt: 2727.4,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 19,
  TimeNow: 560.75,
  S: 22.5,
  N: 2.45,
  J: 73.75,
  St: 1183.1,
  Nt: 135.45,
  Jt: 3780.45,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 5,
  TimeNow: 198.5,
  S: 1.8333333333333333,
  N: 97.16666666666667,
  J: 0.0,
  St: 99.0,
  Nt: 5000.0,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 20,
  TimeNow: 529.6666666666666,
  S: 31.666666666666668,
  N: 2.9166666666666665,
  J: 64.0,
  St: 1650.3333333333333,
  Nt: 161.25,
  Jt: 3287.4166666666665,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 7,
  TimeNow: 336.4166666666667,
  S: 42.75,
  N: 40.333333333333336,
  J: 15.833333333333334,
  St: 2201.75,
  Nt: 2074.5833333333335,
  Jt: 822.6666666666666,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 16,
  TimeNow: 700.55,
  S: 16.6,
  N: 0.0,
  J: 82.35,
  St: 877.75,
  Nt: 0.0,
  Jt: 4221.25,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 10,
  TimeNow: 275.6363636363636,
  S: 36.27272727272727,
  N: 62.72727272727273,
  J: 0.0,
  St: 1874.3636363636363,
  Nt: 3224.6363636363635,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 6,
  TimeNow: 317.9166666666667,
  S: 23.25,
  N: 54.083333333333336,
  J: 21.333333333333332,
  St: 1200.8333333333333,
  Nt: 2786.0,
  Jt: 1112.1666666666667,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 11,
  TimeNow: 437.05263157894734,
  S: 44.78947368421053,
  N: 20.894736842105264,
  J: 33.10526315789474,
  St: 2303.4210526315787,
  Nt: 1088.1052631578948,
  Jt: 1707.4736842105262,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 3,
  TimeNow: 193.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5099.0,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 8,
  TimeNow: 403.9166666666667,
  S: 40.083333333333336,
  N: 26.166666666666668,
  J: 32.083333333333336,
  St: 2076.5833333333335,
  Nt: 1364.4166666666667,
  Jt: 1658.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 15,
  TimeNow: 273.55,
  S: 21.55,
  N: 68.4,
  J: 8.65,
  St: 566.05,
  Nt: 1777.25,
  Jt: 231.8,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 12,
  TimeNow: 315.1290322580645,
  S: 30.838709677419356,
  N: 60.064516129032256,
  J: 7.903225806451613,
  St: 805.2258064516129,
  Nt: 1559.2903225806451,
  Jt: 209.80645161290323,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 13,
  TimeNow: 330.0967741935484,
  S: 32.806451612903224,
  N: 60.064516129032256,
  J: 5.741935483870968,
  St: 860.1612903225806,
  Nt: 1559.5806451612902,
  Jt: 154.6451612903226,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 4,
  TimeNow: 218.92857142857142,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 33.0,
  Nt: 2542.0,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 17,
  TimeNow: 253.1764705882353,
  S: 15.294117647058824,
  N: 77.76470588235294,
  J: 5.529411764705882,
  St: 402.7647058823529,
  Nt: 2018.2941176470588,
  Jt: 154.41176470588235,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 9,
  TimeNow: 243.25,
  S: 15.666666666666666,
  N: 77.41666666666667,
  J: 5.25,
  St: 420.0833333333333,
  Nt: 2001.8333333333333,
  Jt: 152.83333333333334,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 18,
  TimeNow: 249.625,
  S: 20.0,
  N: 72.125,
  J: 6.25,
  St: 527.3125,
  Nt: 1871.8125,
  Jt: 176.5,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 14,
  TimeNow: 313.6,
  S: 30.066666666666666,
  N: 62.733333333333334,
  J: 5.733333333333333,
  St: 790.1333333333333,
  Nt: 1629.6666666666667,
  Jt: 155.2,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 19,
  TimeNow: 247.9047619047619,
  S: 27.476190476190474,
  N: 65.71428571428571,
  J: 5.142857142857143,
  St: 722.952380952381,
  Nt: 1708.142857142857,
  Jt: 143.85714285714286,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 5,
  TimeNow: 219.58333333333334,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 35.0,
  Nt: 2539.8333333333335,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 20,
  TimeNow: 244.41666666666666,
  S: 19.25,
  N: 78.5,
  J: 1.0833333333333333,
  St: 512.3333333333334,
  Nt: 2033.5,
  Jt: 30.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 7,
  TimeNow: 224.91666666666666,
  S: 9.916666666666666,
  N: 88.41666666666667,
  J: 0.5,
  St: 268.9166666666667,
  Nt: 2288.3333333333335,
  Jt: 17.75,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 16,
  TimeNow: 281.8,
  S: 19.15,
  N: 75.15,
  J: 4.2,
  St: 506.15,
  Nt: 1951.2,
  Jt: 117.6,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 10,
  TimeNow: 274.6363636363636,
  S: 21.363636363636363,
  N: 68.63636363636364,
  J: 8.545454545454545,
  St: 566.2727272727273,
  Nt: 1780.2727272727273,
  Jt: 227.72727272727272,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 6,
  TimeNow: 251.66666666666666,
  S: 7.0,
  N: 86.0,
  J: 6.0,
  St: 188.5,
  Nt: 2224.25,
  Jt: 162.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 11,
  TimeNow: 335.94736842105266,
  S: 26.42105263157895,
  N: 62.421052631578945,
  J: 9.842105263157896,
  St: 693.1052631578947,
  Nt: 1618.4736842105262,
  Jt: 263.05263157894734,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 3,
  TimeNow: 219.07142857142858,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 33.0,
  Nt: 2542.0,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 8,
  TimeNow: 218.41666666666666,
  S: 11.166666666666666,
  N: 86.0,
  J: 1.75,
  St: 298.3333333333333,
  Nt: 2224.4166666666665,
  Jt: 52.083333333333336,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 15,
  TimeNow: 550.1,
  S: 37.2,
  N: 29.85,
  J: 31.5,
  St: 2241.8,
  Nt: 1793.9,
  Jt: 1888.3,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 12,
  TimeNow: 448.1290322580645,
  S: 32.96774193548387,
  N: 54.38709677419355,
  J: 11.35483870967742,
  St: 1980.9354838709678,
  Nt: 3250.8064516129034,
  Jt: 692.258064516129,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 13,
  TimeNow: 483.64516129032256,
  S: 35.12903225806452,
  N: 43.45161290322581,
  J: 19.93548387096774,
  St: 2114.483870967742,
  Nt: 2606.8387096774195,
  Jt: 1202.6774193548388,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 4,
  TimeNow: 277.64285714285717,
  S: 2.0,
  N: 97.0,
  J: 0.0,
  St: 130.57142857142858,
  Nt: 5793.428571428572,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 17,
  TimeNow: 760.3529411764706,
  S: 23.352941176470587,
  N: 11.882352941176471,
  J: 63.1764705882353,
  St: 1412.5882352941176,
  Nt: 743.5294117647059,
  Jt: 3767.8823529411766,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 9,
  TimeNow: 560.6666666666666,
  S: 28.833333333333332,
  N: 33.833333333333336,
  J: 36.0,
  St: 1733.8333333333333,
  Nt: 2033.4166666666667,
  Jt: 2156.75,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 18,
  TimeNow: 778.75,
  S: 25.0625,
  N: 18.5,
  J: 55.0,
  St: 1511.6875,
  Nt: 1126.4375,
  Jt: 3285.875,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 14,
  TimeNow: 383.06666666666666,
  S: 35.46666666666667,
  N: 61.733333333333334,
  J: 1.7333333333333334,
  St: 2127.4666666666667,
  Nt: 3688.9333333333334,
  Jt: 107.6,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 19,
  TimeNow: 557.8095238095239,
  S: 46.0,
  N: 29.61904761904762,
  J: 23.095238095238095,
  St: 2752.4761904761904,
  Nt: 1781.5714285714287,
  Jt: 1389.952380952381,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 20,
  TimeNow: 635.5,
  S: 33.083333333333336,
  N: 17.25,
  J: 48.25,
  St: 1990.8333333333333,
  Nt: 1050.8333333333333,
  Jt: 2882.3333333333335,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 7,
  TimeNow: 645.6666666666666,
  S: 23.25,
  N: 33.333333333333336,
  J: 41.916666666666664,
  St: 1406.1666666666667,
  Nt: 2005.0,
  Jt: 2512.8333333333335,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 16,
  TimeNow: 584.1,
  S: 42.15,
  N: 23.5,
  J: 33.0,
  St: 2521.8,
  Nt: 1420.95,
  Jt: 1981.25,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 10,
  TimeNow: 419.3636363636364,
  S: 40.27272727272727,
  N: 52.54545454545455,
  J: 5.818181818181818,
  St: 2417.818181818182,
  Nt: 3140.7272727272725,
  Jt: 365.45454545454544,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 6,
  TimeNow: 448.6666666666667,
  S: 18.083333333333332,
  N: 66.16666666666667,
  J: 14.583333333333334,
  St: 1087.0833333333333,
  Nt: 3961.25,
  Jt: 875.6666666666666,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 11,
  TimeNow: 333.05263157894734,
  S: 17.894736842105264,
  N: 81.10526315789474,
  J: 0.0,
  St: 1095.7894736842106,
  Nt: 4828.210526315789,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 3,
  TimeNow: 291.85714285714283,
  S: 3.7857142857142856,
  N: 95.21428571428571,
  J: 0.0,
  St: 253.64285714285714,
  Nt: 5670.357142857143,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 5,
  TimeNow: 271.27272727272725,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 1.3636363636363635,
  Nt: 5922.636363636364,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 8,
  TimeNow: 511.3333333333333,
  S: 31.666666666666668,
  N: 45.833333333333336,
  J: 21.333333333333332,
  St: 1895.4166666666667,
  Nt: 2740.0,
  Jt: 1288.5833333333333,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 15,
  TimeNow: 520.3,
  S: 37.25,
  N: 31.0,
  J: 30.2,
  St: 1381.1,
  Nt: 1153.35,
  Jt: 1126.55,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 12,
  TimeNow: 718.0967741935484,
  S: 29.032258064516128,
  N: 14.709677419354838,
  J: 54.806451612903224,
  St: 1082.3870967741937,
  Nt: 553.0645161290323,
  Jt: 2025.5483870967741,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 13,
  TimeNow: 708.7096774193549,
  S: 31.096774193548388,
  N: 10.419354838709678,
  J: 57.096774193548384,
  St: 1154.967741935484,
  Nt: 395.64516129032256,
  Jt: 2110.3870967741937,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 4,
  TimeNow: 185.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3661.0,
  Jt: 0.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 17,
  TimeNow: 494.94117647058823,
  S: 39.23529411764706,
  N: 26.764705882352942,
  J: 32.588235294117645,
  St: 1451.764705882353,
  Nt: 1001.3529411764706,
  Jt: 1207.8823529411766,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 9,
  TimeNow: 509.9166666666667,
  S: 19.416666666666668,
  N: 52.333333333333336,
  J: 26.833333333333332,
  St: 732.3333333333334,
  Nt: 1932.1666666666667,
  Jt: 996.5,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 18,
  TimeNow: 395.125,
  S: 31.875,
  N: 44.0,
  J: 22.8125,
  St: 1182.25,
  Nt: 1628.625,
  Jt: 850.125,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 14,
  TimeNow: 622.0666666666667,
  S: 29.6,
  N: 26.4,
  J: 42.4,
  St: 1099.8,
  Nt: 983.4666666666667,
  Jt: 1577.7333333333333,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 19,
  TimeNow: 396.0,
  S: 48.095238095238095,
  N: 28.238095238095237,
  J: 22.428571428571427,
  St: 1779.4761904761904,
  Nt: 1052.5238095238096,
  Jt: 829.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 5,
  TimeNow: 187.66666666666666,
  S: 0.25,
  N: 98.75,
  J: 0.0,
  St: 9.666666666666666,
  Nt: 3651.3333333333335,
  Jt: 0.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 20,
  TimeNow: 402.1666666666667,
  S: 50.916666666666664,
  N: 18.583333333333332,
  J: 28.916666666666668,
  St: 1881.5,
  Nt: 697.9166666666666,
  Jt: 1081.5833333333333,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 7,
  TimeNow: 357.0833333333333,
  S: 15.666666666666666,
  N: 72.16666666666667,
  J: 10.666666666666666,
  St: 592.9166666666666,
  Nt: 2661.25,
  Jt: 406.8333333333333,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 16,
  TimeNow: 749.45,
  S: 31.4,
  N: 6.65,
  J: 60.4,
  St: 1167.65,
  Nt: 258.55,
  Jt: 2234.8,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 10,
  TimeNow: 1163.2727272727273,
  S: 21.545454545454547,
  N: 5.7272727272727275,
  J: 71.27272727272727,
  St: 808.0909090909091,
  Nt: 225.27272727272728,
  Jt: 2627.6363636363635,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 6,
  TimeNow: 222.83333333333334,
  S: 4.166666666666667,
  N: 91.08333333333333,
  J: 3.5,
  St: 164.16666666666666,
  Nt: 3360.8333333333335,
  Jt: 136.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 11,
  TimeNow: 1156.0526315789473,
  S: 19.31578947368421,
  N: 5.947368421052632,
  J: 73.10526315789474,
  St: 727.7894736842105,
  Nt: 235.78947368421052,
  Jt: 2697.4210526315787,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 3,
  TimeNow: 183.57142857142858,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3661.0,
  Jt: 0.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 8,
  TimeNow: 277.5833333333333,
  S: 9.25,
  N: 80.75,
  J: 8.583333333333334,
  St: 357.0833333333333,
  Nt: 2977.75,
  Jt: 326.1666666666667,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 15,
  TimeNow: 726.65,
  S: 6.75,
  N: 84.6,
  J: 7.0,
  St: 780.6,
  Nt: 9081.25,
  Jt: 804.15,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 12,
  TimeNow: 663.516129032258,
  S: 3.838709677419355,
  N: 89.03225806451613,
  J: 5.451612903225806,
  St: 468.741935483871,
  Nt: 9552.516129032258,
  Jt: 645.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 13,
  TimeNow: 811.9677419354839,
  S: 2.225806451612903,
  N: 86.80645161290323,
  J: 9.225806451612904,
  St: 289.4516129032258,
  Nt: 9322.322580645161,
  Jt: 1054.4193548387098,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 4,
  TimeNow: 522.1428571428571,
  S: 0.07142857142857142,
  N: 98.92857142857143,
  J: 0.0,
  St: 19.142857142857142,
  Nt: 10646.857142857143,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 17,
  TimeNow: 784.3529411764706,
  S: 9.0,
  N: 80.70588235294117,
  J: 9.0,
  St: 1008.0588235294117,
  Nt: 8654.70588235294,
  Jt: 1003.3529411764706,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 9,
  TimeNow: 565.0,
  S: 6.25,
  N: 91.16666666666667,
  J: 1.5,
  St: 708.4166666666666,
  Nt: 9780.666666666666,
  Jt: 177.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 18,
  TimeNow: 718.6875,
  S: 7.8125,
  N: 81.0,
  J: 9.6875,
  St: 882.75,
  Nt: 8701.125,
  Jt: 1082.25,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 14,
  TimeNow: 811.6666666666666,
  S: 3.066666666666667,
  N: 86.6,
  J: 8.666666666666666,
  St: 382.0,
  Nt: 9285.2,
  Jt: 999.0666666666667,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 19,
  TimeNow: 829.6190476190476,
  S: 5.857142857142857,
  N: 78.57142857142857,
  J: 14.238095238095237,
  St: 669.4761904761905,
  Nt: 8424.047619047618,
  Jt: 1572.5238095238096,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 5,
  TimeNow: 522.6666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 8.0,
  Nt: 10658.0,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 20,
  TimeNow: 667.6666666666666,
  S: 8.583333333333334,
  N: 86.0,
  J: 4.083333333333333,
  St: 977.8333333333334,
  Nt: 9209.416666666666,
  Jt: 478.8333333333333,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 7,
  TimeNow: 581.5,
  S: 8.083333333333334,
  N: 90.25,
  J: 0.5,
  St: 907.0,
  Nt: 9689.833333333334,
  Jt: 69.25,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 16,
  TimeNow: 726.65,
  S: 8.95,
  N: 80.9,
  J: 8.45,
  St: 1013.95,
  Nt: 8693.6,
  Jt: 958.75,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 10,
  TimeNow: 626.9090909090909,
  S: 4.545454545454546,
  N: 90.18181818181819,
  J: 3.909090909090909,
  St: 531.3636363636364,
  Nt: 9670.272727272728,
  Jt: 464.45454545454544,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 6,
  TimeNow: 538.4166666666666,
  S: 1.1666666666666667,
  N: 97.75,
  J: 0.0,
  St: 144.91666666666666,
  Nt: 10521.083333333334,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 11,
  TimeNow: 661.7368421052631,
  S: 5.894736842105263,
  N: 88.47368421052632,
  J: 4.2105263157894735,
  St: 688.5263157894736,
  Nt: 9488.421052631578,
  Jt: 489.57894736842104,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 3,
  TimeNow: 508.35714285714283,
  S: 0.21428571428571427,
  N: 98.78571428571429,
  J: 0.0,
  St: 24.785714285714285,
  Nt: 10641.214285714286,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 8,
  TimeNow: 534.75,
  S: 1.6666666666666667,
  N: 97.08333333333333,
  J: 0.16666666666666666,
  St: 242.16666666666666,
  Nt: 10400.75,
  Jt: 23.083333333333332,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 15,
  TimeNow: 410.7,
  S: 9.35,
  N: 87.25,
  J: 2.0,
  St: 482.1,
  Nt: 4257.7,
  Jt: 118.4,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 12,
  TimeNow: 425.5806451612903,
  S: 18.64516129032258,
  N: 77.12903225806451,
  J: 2.774193548387097,
  St: 930.4516129032259,
  Nt: 3779.032258064516,
  Jt: 148.03225806451613,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 13,
  TimeNow: 435.8709677419355,
  S: 10.935483870967742,
  N: 85.48387096774194,
  J: 2.161290322580645,
  St: 559.8064516129032,
  Nt: 4176.1612903225805,
  Jt: 122.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 4,
  TimeNow: 388.7142857142857,
  S: 7.714285714285714,
  N: 91.21428571428571,
  J: 0.07142857142857142,
  St: 396.42857142857144,
  Nt: 4455.428571428572,
  Jt: 5.142857142857143,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 17,
  TimeNow: 426.05882352941177,
  S: 13.176470588235293,
  N: 83.29411764705883,
  J: 2.1176470588235294,
  St: 666.4705882352941,
  Nt: 4074.0588235294117,
  Jt: 118.05882352941177,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 9,
  TimeNow: 416.6666666666667,
  S: 6.916666666666667,
  N: 88.66666666666667,
  J: 2.75,
  St: 374.5833333333333,
  Nt: 4327.416666666667,
  Jt: 155.41666666666666,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 18,
  TimeNow: 411.0,
  S: 13.75,
  N: 82.9375,
  J: 2.0,
  St: 692.625,
  Nt: 4054.625,
  Jt: 111.25,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 14,
  TimeNow: 418.3333333333333,
  S: 15.866666666666667,
  N: 80.33333333333333,
  J: 2.4,
  St: 789.0,
  Nt: 3923.133333333333,
  Jt: 145.66666666666666,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 19,
  TimeNow: 420.9047619047619,
  S: 11.19047619047619,
  N: 84.42857142857143,
  J: 2.9047619047619047,
  St: 571.7619047619048,
  Nt: 4133.523809523809,
  Jt: 153.33333333333334,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 5,
  TimeNow: 407.1666666666667,
  S: 9.583333333333334,
  N: 88.75,
  J: 0.5,
  St: 499.5833333333333,
  Nt: 4327.833333333333,
  Jt: 29.583333333333332,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 20,
  TimeNow: 407.9166666666667,
  S: 10.583333333333334,
  N: 88.25,
  J: 0.16666666666666666,
  St: 531.5,
  Nt: 4316.416666666667,
  Jt: 10.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 7,
  TimeNow: 417.4166666666667,
  S: 20.5,
  N: 78.33333333333333,
  J: 0.16666666666666666,
  St: 1014.0833333333334,
  Nt: 3832.6666666666665,
  Jt: 10.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 16,
  TimeNow: 407.8,
  S: 18.05,
  N: 80.7,
  J: 0.1,
  St: 901.5,
  Nt: 3940.6,
  Jt: 16.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 10,
  TimeNow: 419.45454545454544,
  S: 19.272727272727273,
  N: 77.45454545454545,
  J: 1.4545454545454546,
  St: 969.9090909090909,
  Nt: 3793.2727272727275,
  Jt: 94.18181818181819,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 6,
  TimeNow: 401.0833333333333,
  S: 12.75,
  N: 86.08333333333333,
  J: 0.08333333333333333,
  St: 645.8333333333334,
  Nt: 4203.166666666667,
  Jt: 7.916666666666667,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 11,
  TimeNow: 431.63157894736844,
  S: 26.736842105263158,
  N: 69.47368421052632,
  J: 2.263157894736842,
  St: 1328.7894736842106,
  Nt: 3397.0,
  Jt: 132.10526315789474,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 3,
  TimeNow: 414.85714285714283,
  S: 13.5,
  N: 84.71428571428571,
  J: 0.5,
  St: 682.0714285714286,
  Nt: 4139.428571428572,
  Jt: 36.5,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 8,
  TimeNow: 427.5833333333333,
  S: 16.083333333333332,
  N: 82.83333333333333,
  J: 0.0,
  St: 811.9166666666666,
  Nt: 4045.0833333333335,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 15,
  TimeNow: 132.65,
  S: 2.5,
  N: 96.3,
  J: 0.1,
  St: 39.95,
  Nt: 1211.15,
  Jt: 1.9,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 12,
  TimeNow: 134.32258064516128,
  S: 1.4193548387096775,
  N: 97.48387096774194,
  J: 0.06451612903225806,
  St: 24.774193548387096,
  Nt: 1226.8064516129032,
  Jt: 1.4193548387096775,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 13,
  TimeNow: 136.93548387096774,
  S: 1.4838709677419355,
  N: 96.16129032258064,
  J: 1.032258064516129,
  St: 22.70967741935484,
  Nt: 1211.3870967741937,
  Jt: 18.903225806451612,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 4,
  TimeNow: 129.28571428571428,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1253.0,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 17,
  TimeNow: 150.1764705882353,
  S: 8.882352941176471,
  N: 87.0,
  J: 2.764705882352941,
  St: 118.0,
  Nt: 1094.8823529411766,
  Jt: 40.11764705882353,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 9,
  TimeNow: 139.0,
  S: 3.0833333333333335,
  N: 95.91666666666667,
  J: 0.0,
  St: 41.583333333333336,
  Nt: 1211.4166666666667,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 18,
  TimeNow: 136.125,
  S: 6.25,
  N: 89.5,
  J: 2.6875,
  St: 84.75,
  Nt: 1127.625,
  Jt: 40.625,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 14,
  TimeNow: 132.4,
  S: 1.0666666666666667,
  N: 97.2,
  J: 0.6,
  St: 18.733333333333334,
  Nt: 1221.7333333333333,
  Jt: 12.533333333333333,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 19,
  TimeNow: 137.42857142857142,
  S: 4.809523809523809,
  N: 89.85714285714286,
  J: 3.7142857142857144,
  St: 68.66666666666667,
  Nt: 1130.7142857142858,
  Jt: 53.61904761904762,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 5,
  TimeNow: 141.08333333333334,
  S: 6.0,
  N: 93.0,
  J: 0.0,
  St: 76.0,
  Nt: 1177.0,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 20,
  TimeNow: 138.0,
  S: 4.75,
  N: 92.16666666666667,
  J: 1.5833333333333333,
  St: 68.66666666666667,
  Nt: 1160.1666666666667,
  Jt: 24.166666666666668,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 7,
  TimeNow: 137.66666666666666,
  S: 1.25,
  N: 97.75,
  J: 0.0,
  St: 18.666666666666668,
  Nt: 1234.3333333333333,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 16,
  TimeNow: 141.75,
  S: 6.35,
  N: 91.3,
  J: 1.15,
  St: 88.15,
  Nt: 1148.85,
  Jt: 16.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 10,
  TimeNow: 136.72727272727272,
  S: 1.0909090909090908,
  N: 97.9090909090909,
  J: 0.0,
  St: 16.727272727272727,
  Nt: 1236.2727272727273,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 6,
  TimeNow: 144.83333333333334,
  S: 17.75,
  N: 81.25,
  J: 0.0,
  St: 227.16666666666666,
  Nt: 1025.8333333333333,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 11,
  TimeNow: 135.05263157894737,
  S: 1.6842105263157894,
  N: 96.57894736842105,
  J: 0.6842105263157895,
  St: 24.05263157894737,
  Nt: 1213.8947368421052,
  Jt: 15.052631578947368,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 3,
  TimeNow: 147.75,
  S: 20.0,
  N: 79.0,
  J: 0.0,
  St: 256.25,
  Nt: 996.75,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 8,
  TimeNow: 134.25,
  S: 1.25,
  N: 97.75,
  J: 0.0,
  St: 18.5,
  Nt: 1234.5,
  Jt: 0.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 15,
  TimeNow: 303.5263157894737,
  S: 1.9473684210526316,
  N: 96.47368421052632,
  J: 0.42105263157894735,
  St: 69.52631578947368,
  Nt: 3060.8947368421054,
  Jt: 17.57894736842105,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 12,
  TimeNow: 316.80645161290323,
  S: 5.354838709677419,
  N: 92.83870967741936,
  J: 0.6451612903225806,
  St: 184.25806451612902,
  Nt: 2940.1612903225805,
  Jt: 23.580645161290324,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 13,
  TimeNow: 335.06451612903226,
  S: 4.548387096774194,
  N: 92.70967741935483,
  J: 1.4838709677419355,
  St: 157.74193548387098,
  Nt: 2936.6129032258063,
  Jt: 53.645161290322584,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 4,
  TimeNow: 246.42857142857142,
  S: 1.5,
  N: 97.5,
  J: 0.0,
  St: 65.14285714285714,
  Nt: 3082.9285714285716,
  Jt: 0.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 17,
  TimeNow: 336.2352941176471,
  S: 6.0,
  N: 91.23529411764706,
  J: 1.1764705882352942,
  St: 208.23529411764707,
  Nt: 2890.5882352941176,
  Jt: 49.1764705882353,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 9,
  TimeNow: 271.75,
  S: 1.0833333333333333,
  N: 96.5,
  J: 1.3333333333333333,
  St: 40.583333333333336,
  Nt: 3059.4166666666665,
  Jt: 48.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 18,
  TimeNow: 324.6875,
  S: 4.9375,
  N: 92.4375,
  J: 1.375,
  St: 168.25,
  Nt: 2928.6875,
  Jt: 51.0625,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 14,
  TimeNow: 339.06666666666666,
  S: 3.2,
  N: 93.33333333333333,
  J: 2.066666666666667,
  St: 114.93333333333334,
  Nt: 2958.866666666667,
  Jt: 74.2,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 19,
  TimeNow: 336.6666666666667,
  S: 11.333333333333334,
  N: 85.80952380952381,
  J: 1.5714285714285714,
  St: 370.6666666666667,
  Nt: 2722.095238095238,
  Jt: 55.23809523809524,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 5,
  TimeNow: 248.41666666666666,
  S: 1.5,
  N: 97.5,
  J: 0.0,
  St: 57.833333333333336,
  Nt: 3090.1666666666665,
  Jt: 0.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 20,
  TimeNow: 352.0,
  S: 12.583333333333334,
  N: 84.75,
  J: 1.5,
  St: 412.6666666666667,
  Nt: 2682.4166666666665,
  Jt: 52.916666666666664,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 7,
  TimeNow: 429.6363636363636,
  S: 16.818181818181817,
  N: 72.36363636363636,
  J: 9.272727272727273,
  St: 543.4545454545455,
  Nt: 2298.4545454545455,
  Jt: 306.09090909090907,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 16,
  TimeNow: 304.95,
  S: 2.2,
  N: 96.15,
  J: 0.6,
  St: 79.05,
  Nt: 3047.5,
  Jt: 21.45,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 10,
  TimeNow: 289.6363636363636,
  S: 2.6363636363636362,
  N: 95.18181818181819,
  J: 1.1818181818181819,
  St: 95.45454545454545,
  Nt: 3012.0,
  Jt: 40.54545454545455,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 6,
  TimeNow: 294.0833333333333,
  S: 5.5,
  N: 91.08333333333333,
  J: 2.0833333333333335,
  St: 187.41666666666666,
  Nt: 2887.6666666666665,
  Jt: 72.91666666666667,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 11,
  TimeNow: 326.36842105263156,
  S: 3.8421052631578947,
  N: 92.21052631578948,
  J: 2.526315789473684,
  St: 138.52631578947367,
  Nt: 2920.0,
  Jt: 89.47368421052632,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 3,
  TimeNow: 248.78571428571428,
  S: 2.2857142857142856,
  N: 96.71428571428571,
  J: 0.0,
  St: 83.85714285714286,
  Nt: 3064.1428571428573,
  Jt: 0.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 8,
  TimeNow: 343.0833333333333,
  S: 2.8333333333333335,
  N: 91.58333333333333,
  J: 4.333333333333333,
  St: 97.16666666666667,
  Nt: 2900.3333333333335,
  Jt: 150.5,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 15,
  TimeNow: 395.8,
  S: 14.2,
  N: 78.2,
  J: 6.25,
  St: 1356.25,
  Nt: 7315.95,
  Jt: 625.8,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 12,
  TimeNow: 512.741935483871,
  S: 7.967741935483871,
  N: 79.0,
  J: 11.741935483870968,
  St: 799.9677419354839,
  Nt: 7378.870967741936,
  Jt: 1119.1612903225807,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 13,
  TimeNow: 506.9032258064516,
  S: 7.0,
  N: 78.51612903225806,
  J: 13.35483870967742,
  St: 704.6774193548387,
  Nt: 7326.967741935484,
  Jt: 1266.3548387096773,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 4,
  TimeNow: 331.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 17,
  TimeNow: 357.47058823529414,
  S: 4.529411764705882,
  N: 94.47058823529412,
  J: 0.0,
  St: 435.11764705882354,
  Nt: 8862.882352941177,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 9,
  TimeNow: 456.4166666666667,
  S: 10.666666666666666,
  N: 79.5,
  J: 8.75,
  St: 1043.5,
  Nt: 7408.833333333333,
  Jt: 845.6666666666666,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 18,
  TimeNow: 358.8125,
  S: 3.125,
  N: 95.875,
  J: 0.0,
  St: 306.125,
  Nt: 8991.875,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 14,
  TimeNow: 453.4,
  S: 14.866666666666667,
  N: 72.0,
  J: 11.8,
  St: 1436.4666666666667,
  Nt: 6726.866666666667,
  Jt: 1134.6666666666667,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 19,
  TimeNow: 363.9047619047619,
  S: 1.380952380952381,
  N: 97.61904761904762,
  J: 0.0,
  St: 153.0,
  Nt: 9145.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 5,
  TimeNow: 332.0833333333333,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 20,
  TimeNow: 356.75,
  S: 0.5,
  N: 98.5,
  J: 0.0,
  St: 47.25,
  Nt: 9250.75,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 7,
  TimeNow: 342.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 16,
  TimeNow: 398.6,
  S: 9.45,
  N: 85.4,
  J: 4.05,
  St: 919.6,
  Nt: 7982.65,
  Jt: 395.75,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 10,
  TimeNow: 565.1818181818181,
  S: 12.272727272727273,
  N: 72.72727272727273,
  J: 13.636363636363637,
  St: 1204.5454545454545,
  Nt: 6795.727272727273,
  Jt: 1297.7272727272727,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 6,
  TimeNow: 334.4166666666667,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 11,
  TimeNow: 665.8421052631579,
  S: 6.684210526315789,
  N: 68.6842105263158,
  J: 23.31578947368421,
  St: 667.8947368421053,
  Nt: 6442.684210526316,
  Jt: 2199.315789473684,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 3,
  TimeNow: 332.2857142857143,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 8,
  TimeNow: 358.4166666666667,
  S: 3.8333333333333335,
  N: 95.16666666666667,
  J: 0.0,
  St: 377.4166666666667,
  Nt: 8920.583333333334,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 15,
  TimeNow: 325.0,
  S: 0.8,
  N: 98.0,
  J: 0.2,
  St: 11.2,
  Nt: 1210.0,
  Jt: 2.8,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 12,
  TimeNow: 325.0,
  S: 0.7419354838709677,
  N: 98.12903225806451,
  J: 0.12903225806451613,
  St: 10.387096774193548,
  Nt: 1211.8064516129032,
  Jt: 1.8064516129032258,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 13,
  TimeNow: 325.0,
  S: 0.8387096774193549,
  N: 98.03225806451613,
  J: 0.12903225806451613,
  St: 11.741935483870968,
  Nt: 1210.4516129032259,
  Jt: 1.8064516129032258,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 4,
  TimeNow: 325.0,
  S: 0.7142857142857143,
  N: 98.28571428571429,
  J: 0.0,
  St: 10.0,
  Nt: 1214.0,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 17,
  TimeNow: 325.0,
  S: 0.6470588235294118,
  N: 98.0,
  J: 0.35294117647058826,
  St: 9.058823529411764,
  Nt: 1210.0,
  Jt: 4.9411764705882355,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 9,
  TimeNow: 325.0,
  S: 0.8333333333333334,
  N: 98.16666666666667,
  J: 0.0,
  St: 11.666666666666666,
  Nt: 1212.3333333333333,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 18,
  TimeNow: 325.0,
  S: 0.875,
  N: 98.0,
  J: 0.125,
  St: 12.25,
  Nt: 1210.0,
  Jt: 1.75,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 14,
  TimeNow: 325.0,
  S: 0.6666666666666666,
  N: 98.06666666666666,
  J: 0.26666666666666666,
  St: 9.333333333333334,
  Nt: 1210.9333333333334,
  Jt: 3.7333333333333334,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 19,
  TimeNow: 325.0,
  S: 0.5238095238095238,
  N: 98.0952380952381,
  J: 0.38095238095238093,
  St: 7.333333333333333,
  Nt: 1211.3333333333333,
  Jt: 5.333333333333333,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 5,
  TimeNow: 325.0,
  S: 0.4166666666666667,
  N: 98.41666666666667,
  J: 0.16666666666666666,
  St: 5.833333333333333,
  Nt: 1215.8333333333333,
  Jt: 2.3333333333333335,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 20,
  TimeNow: 325.0,
  S: 0.3333333333333333,
  N: 98.0,
  J: 0.6666666666666666,
  St: 4.666666666666667,
  Nt: 1210.0,
  Jt: 9.333333333333334,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 7,
  TimeNow: 325.0,
  S: 0.5833333333333334,
  N: 98.41666666666667,
  J: 0.0,
  St: 8.166666666666666,
  Nt: 1215.8333333333333,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 16,
  TimeNow: 325.0,
  S: 0.75,
  N: 98.0,
  J: 0.25,
  St: 10.5,
  Nt: 1210.0,
  Jt: 3.5,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 10,
  TimeNow: 325.0,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 14.0,
  Nt: 1210.0,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 6,
  TimeNow: 325.0,
  S: 0.25,
  N: 98.75,
  J: 0.0,
  St: 3.5,
  Nt: 1220.5,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 11,
  TimeNow: 325.0,
  S: 0.8421052631578947,
  N: 98.0,
  J: 0.15789473684210525,
  St: 11.789473684210526,
  Nt: 1210.0,
  Jt: 2.210526315789474,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 3,
  TimeNow: 325.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1224.0,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 8,
  TimeNow: 325.0,
  S: 0.9090909090909091,
  N: 98.0,
  J: 0.09090909090909091,
  St: 12.727272727272727,
  Nt: 1210.0,
  Jt: 1.2727272727272727,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 15,
  TimeNow: 840.8,
  S: 26.45,
  N: 35.05,
  J: 36.85,
  St: 1657.45,
  Nt: 2204.0,
  Jt: 2313.55,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 12,
  TimeNow: 984.0,
  S: 17.774193548387096,
  N: 48.354838709677416,
  J: 32.45161290322581,
  St: 1128.741935483871,
  Nt: 3013.032258064516,
  Jt: 2033.225806451613,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 13,
  TimeNow: 862.0967741935484,
  S: 19.451612903225808,
  N: 45.29032258064516,
  J: 33.74193548387097,
  St: 1233.774193548387,
  Nt: 2826.8387096774195,
  Jt: 2114.3870967741937,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 4,
  TimeNow: 307.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 6175.0,
  Jt: 0.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 17,
  TimeNow: 641.0,
  S: 34.1764705882353,
  N: 41.11764705882353,
  J: 23.235294117647058,
  St: 2143.1176470588234,
  Nt: 2570.9411764705883,
  Jt: 1460.9411764705883,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 9,
  TimeNow: 667.25,
  S: 12.0,
  N: 70.91666666666667,
  J: 15.583333333333334,
  St: 768.9166666666666,
  Nt: 4408.0,
  Jt: 998.0833333333334,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 18,
  TimeNow: 541.8125,
  S: 23.125,
  N: 62.0625,
  J: 13.4375,
  St: 1459.125,
  Nt: 3861.375,
  Jt: 854.5,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 14,
  TimeNow: 836.4666666666667,
  S: 22.266666666666666,
  N: 47.6,
  J: 28.666666666666668,
  St: 1404.0,
  Nt: 2964.4,
  Jt: 1806.6,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 19,
  TimeNow: 525.952380952381,
  S: 33.523809523809526,
  N: 52.0,
  J: 13.0,
  St: 2097.809523809524,
  Nt: 3244.8571428571427,
  Jt: 832.3333333333334,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 5,
  TimeNow: 310.5833333333333,
  S: 0.08333333333333333,
  N: 98.91666666666667,
  J: 0.0,
  St: 9.666666666666666,
  Nt: 6165.333333333333,
  Jt: 0.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 20,
  TimeNow: 592.25,
  S: 38.083333333333336,
  N: 42.416666666666664,
  J: 18.083333333333332,
  St: 2387.0,
  Nt: 2647.9166666666665,
  Jt: 1140.0833333333333,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 7,
  TimeNow: 566.6666666666666,
  S: 12.333333333333334,
  N: 75.16666666666667,
  J: 11.0,
  St: 793.3333333333334,
  Nt: 4677.583333333333,
  Jt: 704.0833333333334,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 16,
  TimeNow: 978.7,
  S: 23.15,
  N: 34.0,
  J: 41.35,
  St: 1463.85,
  Nt: 2126.05,
  Jt: 2585.1,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 10,
  TimeNow: 1476.4545454545455,
  S: 16.09090909090909,
  N: 38.09090909090909,
  J: 44.45454545454545,
  St: 1022.0909090909091,
  Nt: 2384.4545454545455,
  Jt: 2768.4545454545455,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 6,
  TimeNow: 384.0,
  S: 4.25,
  N: 90.91666666666667,
  J: 3.5833333333333335,
  St: 289.8333333333333,
  Nt: 5648.583333333333,
  Jt: 236.58333333333334,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 11,
  TimeNow: 1511.7894736842106,
  S: 15.578947368421053,
  N: 37.8421052631579,
  J: 44.94736842105263,
  St: 998.9473684210526,
  Nt: 2371.5789473684213,
  Jt: 2804.4736842105262,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 3,
  TimeNow: 304.14285714285717,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 6175.0,
  Jt: 0.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 8,
  TimeNow: 434.9166666666667,
  S: 9.416666666666666,
  N: 83.25,
  J: 6.083333333333333,
  St: 608.9166666666666,
  Nt: 5168.583333333333,
  Jt: 397.5,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 15,
  TimeNow: 177.45,
  S: 63.35,
  N: 2.6,
  J: 33.0,
  St: 420.35,
  Nt: 17.65,
  Jt: 218.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 12,
  TimeNow: 171.61290322580646,
  S: 41.354838709677416,
  N: 6.870967741935484,
  J: 50.774193548387096,
  St: 276.06451612903226,
  Nt: 45.83870967741935,
  Jt: 334.0967741935484,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 13,
  TimeNow: 137.1290322580645,
  S: 51.516129032258064,
  N: 9.096774193548388,
  J: 38.32258064516129,
  St: 342.1290322580645,
  Nt: 60.774193548387096,
  Jt: 253.09677419354838,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 4,
  TimeNow: 86.42857142857143,
  S: 34.0,
  N: 65.0,
  J: 0.0,
  St: 226.71428571428572,
  Nt: 429.42857142857144,
  Jt: 0.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 17,
  TimeNow: 139.94117647058823,
  S: 54.88235294117647,
  N: 2.823529411764706,
  J: 41.23529411764706,
  St: 364.29411764705884,
  Nt: 19.470588235294116,
  Jt: 272.29411764705884,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 9,
  TimeNow: 160.58333333333334,
  S: 38.25,
  N: 19.083333333333332,
  J: 41.666666666666664,
  St: 253.75,
  Nt: 126.75,
  Jt: 275.5,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 18,
  TimeNow: 148.4375,
  S: 55.5625,
  N: 2.4375,
  J: 40.75,
  St: 369.1875,
  Nt: 16.875,
  Jt: 269.9375,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 14,
  TimeNow: 146.66666666666666,
  S: 54.0,
  N: 3.6,
  J: 41.4,
  St: 358.53333333333336,
  Nt: 24.266666666666666,
  Jt: 273.2,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 19,
  TimeNow: 159.95238095238096,
  S: 37.0,
  N: 15.857142857142858,
  J: 45.61904761904762,
  St: 247.04761904761904,
  Nt: 106.14285714285714,
  Jt: 302.8095238095238,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 5,
  TimeNow: 91.0,
  S: 32.833333333333336,
  N: 65.16666666666667,
  J: 1.0,
  St: 218.83333333333334,
  Nt: 430.5833333333333,
  Jt: 6.666666666666667,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 20,
  TimeNow: 132.08333333333334,
  S: 63.833333333333336,
  N: 7.75,
  J: 26.916666666666668,
  St: 422.9166666666667,
  Nt: 52.333333333333336,
  Jt: 180.75,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 7,
  TimeNow: 84.08333333333333,
  S: 25.416666666666668,
  N: 73.58333333333333,
  J: 0.0,
  St: 169.58333333333334,
  Nt: 486.8333333333333,
  Jt: 0.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 16,
  TimeNow: 147.9,
  S: 65.4,
  N: 3.75,
  J: 29.85,
  St: 433.05,
  Nt: 25.45,
  Jt: 197.55,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 10,
  TimeNow: 190.9090909090909,
  S: 44.27272727272727,
  N: 2.727272727272727,
  J: 52.0,
  St: 294.6363636363636,
  Nt: 18.181818181818183,
  Jt: 343.1818181818182,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 6,
  TimeNow: 82.25,
  S: 19.166666666666668,
  N: 79.66666666666667,
  J: 0.08333333333333333,
  St: 127.75,
  Nt: 527.1666666666666,
  Jt: 1.0833333333333333,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 11,
  TimeNow: 216.1578947368421,
  S: 44.473684210526315,
  N: 0.0,
  J: 54.526315789473685,
  St: 297.42105263157896,
  Nt: 0.0,
  Jt: 358.57894736842104,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 3,
  TimeNow: 88.85714285714286,
  S: 15.0,
  N: 69.0,
  J: 15.0,
  St: 100.0,
  Nt: 455.0,
  Jt: 101.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 8,
  TimeNow: 157.41666666666666,
  S: 62.75,
  N: 5.666666666666667,
  J: 30.5,
  St: 415.0833333333333,
  Nt: 37.833333333333336,
  Jt: 203.08333333333334,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 15,
  TimeNow: 1136.85,
  S: 5.25,
  N: 26.6,
  J: 66.35,
  St: 316.4,
  Nt: 1494.0,
  Jt: 3687.1,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 12,
  TimeNow: 311.26666666666665,
  S: 23.333333333333332,
  N: 75.4,
  J: 0.23333333333333334,
  St: 1312.3666666666666,
  Nt: 4175.3,
  Jt: 14.333333333333334,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 13,
  TimeNow: 436.741935483871,
  S: 31.903225806451612,
  N: 51.83870967741935,
  J: 14.709677419354838,
  St: 1788.032258064516,
  Nt: 2881.3548387096776,
  Jt: 832.6129032258065,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 4,
  TimeNow: 284.7142857142857,
  S: 6.571428571428571,
  N: 92.42857142857143,
  J: 0.0,
  St: 390.35714285714283,
  Nt: 5108.5,
  Jt: 3.142857142857143,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 17,
  TimeNow: 1796.3529411764705,
  S: 3.2941176470588234,
  N: 27.470588235294116,
  J: 67.52941176470588,
  St: 211.52941176470588,
  Nt: 1538.1764705882354,
  Jt: 3747.705882352941,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 9,
  TimeNow: 354.8333333333333,
  S: 18.583333333333332,
  N: 76.16666666666667,
  J: 4.083333333333333,
  St: 1049.5833333333333,
  Nt: 4213.166666666667,
  Jt: 239.25,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 18,
  TimeNow: 1297.75,
  S: 4.375,
  N: 29.3125,
  J: 64.75,
  St: 265.4375,
  Nt: 1635.0,
  Jt: 3597.4375,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 14,
  TimeNow: 503.4,
  S: 23.933333333333334,
  N: 51.266666666666666,
  J: 23.2,
  St: 1346.4,
  Nt: 2854.733333333333,
  Jt: 1300.4666666666667,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 19,
  TimeNow: 526.2380952380952,
  S: 11.571428571428571,
  N: 57.142857142857146,
  J: 29.952380952380953,
  St: 655.8095238095239,
  Nt: 3175.5238095238096,
  Jt: 1670.3809523809523,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 5,
  TimeNow: 250.33333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5502.0,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 20,
  TimeNow: 302.8333333333333,
  S: 17.0,
  N: 80.25,
  J: 1.75,
  St: 960.6666666666666,
  Nt: 4441.0,
  Jt: 100.33333333333333,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 7,
  TimeNow: 541.0,
  S: 29.333333333333332,
  N: 43.5,
  J: 25.75,
  St: 1641.6666666666667,
  Nt: 2415.4166666666665,
  Jt: 1442.9166666666667,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 16,
  TimeNow: 1395.25,
  S: 4.3,
  N: 25.5,
  J: 68.6,
  St: 265.2,
  Nt: 1426.25,
  Jt: 3807.55,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 10,
  TimeNow: 288.54545454545456,
  S: 2.8181818181818183,
  N: 95.81818181818181,
  J: 0.36363636363636365,
  St: 176.1818181818182,
  Nt: 5303.818181818182,
  Jt: 22.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 6,
  TimeNow: 265.6666666666667,
  S: 1.3333333333333333,
  N: 97.25,
  J: 0.4166666666666667,
  St: 85.5,
  Nt: 5390.75,
  Jt: 25.75,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 11,
  TimeNow: 275.10526315789474,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 66.89473684210526,
  Nt: 5435.105263157895,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 3,
  TimeNow: 306.92857142857144,
  S: 5.928571428571429,
  N: 92.21428571428571,
  J: 0.5,
  St: 352.42857142857144,
  Nt: 5105.428571428572,
  Jt: 44.142857142857146,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 8,
  TimeNow: 453.8333333333333,
  S: 28.5,
  N: 54.0,
  J: 16.083333333333332,
  St: 1600.5833333333333,
  Nt: 2995.8333333333335,
  Jt: 905.5833333333334,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 15,
  TimeNow: 984.75,
  S: 3.8,
  N: 84.95,
  J: 9.55,
  St: 779.25,
  Nt: 15734.0,
  Jt: 1872.75,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 12,
  TimeNow: 711.8709677419355,
  S: 0.06451612903225806,
  N: 98.93548387096774,
  J: 0.0,
  St: 25.548387096774192,
  Nt: 18360.451612903227,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 13,
  TimeNow: 1268.9354838709678,
  S: 2.7096774193548385,
  N: 86.7741935483871,
  J: 9.258064516129032,
  St: 560.3870967741935,
  Nt: 16068.161290322581,
  Jt: 1757.4516129032259,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 4,
  TimeNow: 679.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 17,
  TimeNow: 829.2352941176471,
  S: 5.529411764705882,
  N: 89.88235294117646,
  J: 3.1176470588235294,
  St: 1127.4117647058824,
  Nt: 16570.58823529412,
  Jt: 688.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 9,
  TimeNow: 676.2727272727273,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 18,
  TimeNow: 780.125,
  S: 6.25,
  N: 92.625,
  J: 0.125,
  St: 1250.375,
  Nt: 17111.25,
  Jt: 24.375,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 14,
  TimeNow: 1166.5333333333333,
  S: 5.933333333333334,
  N: 78.13333333333334,
  J: 14.666666666666666,
  St: 1161.9333333333334,
  Nt: 14442.4,
  Jt: 2781.6666666666665,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 19,
  TimeNow: 755.6190476190476,
  S: 2.619047619047619,
  N: 96.38095238095238,
  J: 0.0,
  St: 563.0952380952381,
  Nt: 17822.904761904763,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 5,
  TimeNow: 680.6666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 20,
  TimeNow: 763.4166666666666,
  S: 4.166666666666667,
  N: 94.83333333333333,
  J: 0.0,
  St: 870.9166666666666,
  Nt: 17515.083333333332,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 7,
  TimeNow: 671.8333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 16,
  TimeNow: 984.65,
  S: 7.8,
  N: 82.3,
  J: 8.3,
  St: 1528.95,
  Nt: 15221.65,
  Jt: 1635.4,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 10,
  TimeNow: 686.1818181818181,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 6,
  TimeNow: 670.75,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 2.5,
  Nt: 18383.5,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 11,
  TimeNow: 691.3157894736842,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 3,
  TimeNow: 691.3571428571429,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 8,
  TimeNow: 666.5,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 12,
  TimeNow: 161.41935483870967,
  S: 8.580645161290322,
  N: 90.41935483870968,
  J: 0.0,
  St: 150.80645161290323,
  Nt: 1510.1935483870968,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 13,
  TimeNow: 166.10344827586206,
  S: 14.241379310344827,
  N: 84.75862068965517,
  J: 0.0,
  St: 245.27586206896552,
  Nt: 1415.7241379310344,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 4,
  TimeNow: 133.14285714285714,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1661.0,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 15,
  TimeNow: 159.44444444444446,
  S: 7.722222222222222,
  N: 90.83333333333333,
  J: 0.4444444444444444,
  St: 140.44444444444446,
  Nt: 1512.2777777777778,
  Jt: 8.277777777777779,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 17,
  TimeNow: 168.05882352941177,
  S: 10.058823529411764,
  N: 88.94117647058823,
  J: 0.0,
  St: 179.0,
  Nt: 1482.0,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 9,
  TimeNow: 144.5,
  S: 4.166666666666667,
  N: 94.83333333333333,
  J: 0.0,
  St: 77.41666666666667,
  Nt: 1583.5833333333333,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 18,
  TimeNow: 160.86666666666667,
  S: 10.2,
  N: 88.73333333333333,
  J: 0.0,
  St: 180.2,
  Nt: 1479.8666666666666,
  Jt: 0.9333333333333333,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 14,
  TimeNow: 160.23076923076923,
  S: 7.615384615384615,
  N: 91.38461538461539,
  J: 0.0,
  St: 133.6153846153846,
  Nt: 1527.3846153846155,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 19,
  TimeNow: 165.7,
  S: 12.85,
  N: 86.15,
  J: 0.0,
  St: 222.95,
  Nt: 1438.05,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 5,
  TimeNow: 138.25,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1661.0,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 20,
  TimeNow: 167.33333333333334,
  S: 8.25,
  N: 90.75,
  J: 0.0,
  St: 147.41666666666666,
  Nt: 1513.5833333333333,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 7,
  TimeNow: 149.83333333333334,
  S: 5.333333333333333,
  N: 93.66666666666667,
  J: 0.0,
  St: 99.33333333333333,
  Nt: 1561.6666666666667,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 16,
  TimeNow: 164.52631578947367,
  S: 13.421052631578947,
  N: 85.57894736842105,
  J: 0.0,
  St: 233.94736842105263,
  Nt: 1427.0526315789473,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 10,
  TimeNow: 153.63636363636363,
  S: 7.818181818181818,
  N: 89.54545454545455,
  J: 1.3636363636363635,
  St: 139.1818181818182,
  Nt: 1496.7272727272727,
  Jt: 25.09090909090909,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 6,
  TimeNow: 149.72727272727272,
  S: 6.545454545454546,
  N: 92.45454545454545,
  J: 0.0,
  St: 121.9090909090909,
  Nt: 1539.090909090909,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 11,
  TimeNow: 161.10526315789474,
  S: 10.894736842105264,
  N: 86.21052631578948,
  J: 1.5789473684210527,
  St: 188.78947368421052,
  Nt: 1443.157894736842,
  Jt: 29.05263157894737,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 3,
  TimeNow: 137.42857142857142,
  S: 2.2857142857142856,
  N: 96.71428571428571,
  J: 0.0,
  St: 39.142857142857146,
  Nt: 1621.857142857143,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 8,
  TimeNow: 144.91666666666666,
  S: 2.6666666666666665,
  N: 96.33333333333333,
  J: 0.0,
  St: 49.666666666666664,
  Nt: 1611.3333333333333,
  Jt: 0.0,
}]

const groupByCategory = arr.reduce((group, product) => {
  const { Id } = product
  group[Id] = group[Id] ?? []
  group[Id].push(product)
  return group
}, {})
export default groupByCategory
