const arr = [{
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 19,
  TimeNow: 169.4,
  S: 15.0,
  N: 84.0,
  J: 0.0,
  St: 262.6,
  Nt: 1398.4,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 15,
  TimeNow: 155.55555555555554,
  S: 3.9444444444444446,
  N: 95.05555555555556,
  J: 0.0,
  St: 74.44444444444444,
  Nt: 1586.5555555555557,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 13,
  TimeNow: 164.92857142857142,
  S: 12.071428571428571,
  N: 86.5,
  J: 0.35714285714285715,
  St: 207.71428571428572,
  Nt: 1446.7142857142858,
  Jt: 6.571428571428571,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 17,
  TimeNow: 164.66666666666666,
  S: 14.222222222222221,
  N: 84.77777777777777,
  J: 0.0,
  St: 244.55555555555554,
  Nt: 1416.4444444444443,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 20,
  TimeNow: 179.875,
  S: 16.125,
  N: 82.625,
  J: 0.0625,
  St: 281.1875,
  Nt: 1376.125,
  Jt: 3.6875,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 12,
  TimeNow: 157.07692307692307,
  S: 8.923076923076923,
  N: 90.07692307692308,
  J: 0.0,
  St: 159.23076923076923,
  Nt: 1501.7692307692307,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 8,
  TimeNow: 143.08333333333334,
  S: 5.666666666666667,
  N: 93.33333333333333,
  J: 0.0,
  St: 104.0,
  Nt: 1557.0,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 14,
  TimeNow: 160.41666666666666,
  S: 12.333333333333334,
  N: 86.66666666666667,
  J: 0.0,
  St: 211.5,
  Nt: 1449.5,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 10,
  TimeNow: 142.0,
  S: 0.7272727272727273,
  N: 98.27272727272727,
  J: 0.0,
  St: 13.545454545454545,
  Nt: 1647.4545454545455,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 9,
  TimeNow: 137.06666666666666,
  S: 2.1333333333333333,
  N: 96.86666666666666,
  J: 0.0,
  St: 39.733333333333334,
  Nt: 1621.2666666666667,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 16,
  TimeNow: 160.52631578947367,
  S: 9.894736842105264,
  N: 89.10526315789474,
  J: 0.0,
  St: 177.6315789473684,
  Nt: 1483.3684210526317,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 11,
  TimeNow: 146.58333333333334,
  S: 0.4166666666666667,
  N: 98.58333333333333,
  J: 0.0,
  St: 7.666666666666667,
  Nt: 1653.3333333333333,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 18,
  TimeNow: 163.0,
  S: 10.25,
  N: 88.75,
  J: 0.0,
  St: 181.0,
  Nt: 1480.0,
  Jt: 0.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 19,
  TimeNow: 263.6,
  S: 11.0,
  N: 75.4,
  J: 12.2,
  St: 272.6,
  Nt: 1819.6,
  Jt: 302.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 15,
  TimeNow: 550.5555555555555,
  S: 17.61111111111111,
  N: 40.77777777777778,
  J: 40.166666666666664,
  St: 431.3333333333333,
  Nt: 987.3333333333334,
  Jt: 973.9444444444445,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 13,
  TimeNow: 243.35714285714286,
  S: 18.0,
  N: 66.5,
  J: 14.142857142857142,
  St: 439.35714285714283,
  Nt: 1606.357142857143,
  Jt: 348.14285714285717,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 17,
  TimeNow: 475.0,
  S: 18.333333333333332,
  N: 39.55555555555556,
  J: 40.333333333333336,
  St: 451.6666666666667,
  Nt: 959.1111111111111,
  Jt: 982.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 20,
  TimeNow: 342.875,
  S: 17.3125,
  N: 67.375,
  J: 13.875,
  St: 427.625,
  Nt: 1624.3125,
  Jt: 341.375,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 12,
  TimeNow: 165.84615384615384,
  S: 12.153846153846153,
  N: 85.15384615384616,
  J: 1.6923076923076923,
  St: 304.46153846153845,
  Nt: 2048.6923076923076,
  Jt: 40.92307692307692,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 8,
  TimeNow: 240.8181818181818,
  S: 27.09090909090909,
  N: 64.18181818181819,
  J: 7.2727272727272725,
  St: 662.0909090909091,
  Nt: 1548.6363636363637,
  Jt: 182.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 14,
  TimeNow: 350.5833333333333,
  S: 28.25,
  N: 49.666666666666664,
  J: 20.666666666666668,
  St: 686.75,
  Nt: 1199.4166666666667,
  Jt: 507.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 10,
  TimeNow: 210.1818181818182,
  S: 27.0,
  N: 69.9090909090909,
  J: 2.0,
  St: 657.1818181818181,
  Nt: 1684.5454545454545,
  Jt: 51.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 9,
  TimeNow: 305.4,
  S: 42.666666666666664,
  N: 26.266666666666666,
  J: 29.466666666666665,
  St: 1033.8,
  Nt: 642.4666666666667,
  Jt: 717.1333333333333,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 16,
  TimeNow: 499.42105263157896,
  S: 20.473684210526315,
  N: 31.210526315789473,
  J: 46.68421052631579,
  St: 502.57894736842104,
  Nt: 760.421052631579,
  Jt: 1129.9473684210527,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 11,
  TimeNow: 156.58333333333334,
  S: 7.166666666666667,
  N: 91.41666666666667,
  J: 0.4166666666666667,
  St: 182.66666666666666,
  Nt: 2200.8333333333335,
  Jt: 10.416666666666666,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 18,
  TimeNow: 297.5,
  S: 7.5,
  N: 73.375,
  J: 17.25,
  St: 192.125,
  Nt: 1772.25,
  Jt: 429.125,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 19,
  TimeNow: 397.0,
  S: 33.0,
  N: 40.4,
  J: 25.2,
  St: 1459.0,
  Nt: 1799.0,
  Jt: 1120.2,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 15,
  TimeNow: 993.0,
  S: 12.777777777777779,
  N: 50.333333333333336,
  J: 35.44444444444444,
  St: 579.1111111111111,
  Nt: 2224.1666666666665,
  Jt: 1575.7222222222222,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 13,
  TimeNow: 296.7142857142857,
  S: 35.785714285714285,
  N: 53.714285714285715,
  J: 8.928571428571429,
  St: 1591.9285714285713,
  Nt: 2382.1428571428573,
  Jt: 403.92857142857144,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 17,
  TimeNow: 640.8888888888889,
  S: 19.88888888888889,
  N: 32.333333333333336,
  J: 46.22222222222222,
  St: 892.7777777777778,
  Nt: 1441.0,
  Jt: 2044.6666666666667,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 20,
  TimeNow: 333.875,
  S: 42.5625,
  N: 41.1875,
  J: 15.125,
  St: 1879.5,
  Nt: 1825.0,
  Jt: 673.5,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 12,
  TimeNow: 376.38461538461536,
  S: 46.07692307692308,
  N: 29.0,
  J: 23.46153846153846,
  St: 2038.7692307692307,
  Nt: 1296.4615384615386,
  Jt: 1042.7692307692307,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 8,
  TimeNow: 225.16666666666666,
  S: 0.3333333333333333,
  N: 98.66666666666667,
  J: 0.0,
  St: 31.75,
  Nt: 4346.333333333333,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 14,
  TimeNow: 512.4166666666666,
  S: 31.083333333333332,
  N: 38.0,
  J: 29.416666666666668,
  St: 1379.8333333333333,
  Nt: 1689.5833333333333,
  Jt: 1309.1666666666667,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 10,
  TimeNow: 271.8181818181818,
  S: 24.636363636363637,
  N: 68.63636363636364,
  J: 5.545454545454546,
  St: 1100.5454545454545,
  Nt: 3026.909090909091,
  Jt: 250.54545454545453,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 9,
  TimeNow: 231.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 6.4,
  Nt: 4371.6,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 16,
  TimeNow: 657.0,
  S: 22.105263157894736,
  N: 43.8421052631579,
  J: 32.73684210526316,
  St: 988.421052631579,
  Nt: 1940.157894736842,
  Jt: 1450.421052631579,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 11,
  TimeNow: 323.5,
  S: 21.0,
  N: 66.16666666666667,
  J: 11.5,
  St: 938.8333333333334,
  Nt: 2922.5833333333335,
  Jt: 516.75,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 18,
  TimeNow: 413.5,
  S: 37.25,
  N: 32.875,
  J: 28.25,
  St: 1653.375,
  Nt: 1467.25,
  Jt: 1257.5,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 19,
  TimeNow: 942.2,
  S: 13.4,
  N: 17.8,
  J: 67.4,
  St: 780.8,
  Nt: 1015.4,
  Jt: 3793.6,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 15,
  TimeNow: 1095.4444444444443,
  S: 15.166666666666666,
  N: 1.6666666666666667,
  J: 82.05555555555556,
  St: 865.8333333333334,
  Nt: 102.94444444444444,
  Jt: 4617.444444444444,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 13,
  TimeNow: 500.42857142857144,
  S: 56.07142857142857,
  N: 14.5,
  J: 27.928571428571427,
  St: 3162.9285714285716,
  Nt: 837.2857142857143,
  Jt: 1587.5,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 17,
  TimeNow: 1689.375,
  S: 0.0,
  N: 0.0,
  J: 99.0,
  St: 0.0,
  Nt: 0.0,
  Jt: 5586.0,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 20,
  TimeNow: 525.8125,
  S: 42.3125,
  N: 22.0,
  J: 34.375,
  St: 2386.375,
  Nt: 1256.6875,
  Jt: 1944.0625,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 12,
  TimeNow: 452.3076923076923,
  S: 59.61538461538461,
  N: 27.46153846153846,
  J: 11.461538461538462,
  St: 3365.3846153846152,
  Nt: 1567.8461538461538,
  Jt: 653.0769230769231,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 8,
  TimeNow: 485.25,
  S: 57.0,
  N: 24.0,
  J: 17.583333333333332,
  St: 3223.75,
  Nt: 1356.0833333333333,
  Jt: 1007.1666666666666,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 14,
  TimeNow: 541.6666666666666,
  S: 51.833333333333336,
  N: 15.333333333333334,
  J: 31.083333333333332,
  St: 2929.0833333333335,
  Nt: 894.3333333333334,
  Jt: 1763.5833333333333,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 10,
  TimeNow: 370.27272727272725,
  S: 31.09090909090909,
  N: 63.45454545454545,
  J: 4.2727272727272725,
  St: 1767.4545454545455,
  Nt: 3573.6363636363635,
  Jt: 245.36363636363637,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 9,
  TimeNow: 436.73333333333335,
  S: 50.2,
  N: 32.733333333333334,
  J: 15.466666666666667,
  St: 2828.6666666666665,
  Nt: 1868.2666666666667,
  Jt: 889.3333333333334,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 16,
  TimeNow: 1455.7368421052631,
  S: 0.42105263157894735,
  N: 0.0,
  J: 98.57894736842105,
  St: 30.57894736842105,
  Nt: 0.0,
  Jt: 5555.421052631579,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 11,
  TimeNow: 481.09090909090907,
  S: 54.09090909090909,
  N: 24.181818181818183,
  J: 20.0,
  St: 3050.090909090909,
  Nt: 1387.7272727272727,
  Jt: 1149.090909090909,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 18,
  TimeNow: 1283.25,
  S: 2.75,
  N: 0.0,
  J: 96.25,
  St: 175.25,
  Nt: 0.0,
  Jt: 5411.75,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 19,
  TimeNow: 58.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 15,
  TimeNow: 58.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 13,
  TimeNow: 58.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 17,
  TimeNow: 58.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 20,
  TimeNow: 58.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 12,
  TimeNow: 58.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 8,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 14,
  TimeNow: 58.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 10,
  TimeNow: 61.6,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 9,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 16,
  TimeNow: 58.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 11,
  TimeNow: 59.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 18,
  TimeNow: 58.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 19,
  TimeNow: 807.4,
  S: 17.0,
  N: 66.8,
  J: 14.6,
  St: 2206.8,
  Nt: 8411.2,
  Jt: 1891.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 15,
  TimeNow: 807.5555555555555,
  S: 18.555555555555557,
  N: 71.16666666666667,
  J: 8.88888888888889,
  St: 2360.9444444444443,
  Nt: 8962.888888888889,
  Jt: 1185.1666666666667,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 13,
  TimeNow: 754.8571428571429,
  S: 12.5,
  N: 77.64285714285714,
  J: 8.357142857142858,
  St: 1625.7142857142858,
  Nt: 9797.92857142857,
  Jt: 1085.357142857143,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 17,
  TimeNow: 812.7777777777778,
  S: 21.666666666666668,
  N: 69.88888888888889,
  J: 7.0,
  St: 2764.6666666666665,
  Nt: 8799.111111111111,
  Jt: 945.2222222222222,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 20,
  TimeNow: 1021.75,
  S: 14.0,
  N: 59.6875,
  J: 24.75,
  St: 1825.0,
  Nt: 7531.25,
  Jt: 3152.75,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 12,
  TimeNow: 845.8461538461538,
  S: 9.076923076923077,
  N: 74.3076923076923,
  J: 15.0,
  St: 1202.076923076923,
  Nt: 9343.615384615385,
  Jt: 1963.3076923076924,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 8,
  TimeNow: 526.9090909090909,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 12509.0,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 14,
  TimeNow: 880.6666666666666,
  S: 16.916666666666668,
  N: 66.91666666666667,
  J: 14.583333333333334,
  St: 2193.3333333333335,
  Nt: 8426.333333333334,
  Jt: 1889.3333333333333,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 10,
  TimeNow: 589.4545454545455,
  S: 8.727272727272727,
  N: 88.18181818181819,
  J: 1.8181818181818181,
  St: 1144.8181818181818,
  Nt: 11107.818181818182,
  Jt: 256.3636363636364,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 9,
  TimeNow: 571.8,
  S: 3.7333333333333334,
  N: 95.26666666666667,
  J: 0.0,
  St: 502.26666666666665,
  Nt: 12004.733333333334,
  Jt: 2.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 16,
  TimeNow: 722.5,
  S: 16.5,
  N: 78.83333333333333,
  J: 3.5,
  St: 2123.6666666666665,
  Nt: 9911.333333333334,
  Jt: 474.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 11,
  TimeNow: 672.9166666666666,
  S: 14.166666666666666,
  N: 76.41666666666667,
  J: 8.0,
  St: 1821.6666666666667,
  Nt: 9620.333333333334,
  Jt: 1067.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 18,
  TimeNow: 770.25,
  S: 16.125,
  N: 74.875,
  J: 7.625,
  St: 2073.5,
  Nt: 9429.0,
  Jt: 1006.5,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 19,
  TimeNow: 1974.0,
  S: 17.8,
  N: 65.0,
  J: 15.4,
  St: 4308.6,
  Nt: 15453.4,
  Jt: 3786.0,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 15,
  TimeNow: 2317.8333333333335,
  S: 10.555555555555555,
  N: 70.22222222222223,
  J: 17.72222222222222,
  St: 2598.3888888888887,
  Nt: 16660.5,
  Jt: 4289.111111111111,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 13,
  TimeNow: 2035.5,
  S: 13.714285714285714,
  N: 72.14285714285714,
  J: 12.642857142857142,
  St: 3327.0,
  Nt: 17114.14285714286,
  Jt: 3106.8571428571427,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 17,
  TimeNow: 2859.5555555555557,
  S: 11.0,
  N: 62.0,
  J: 25.88888888888889,
  St: 2683.1111111111113,
  Nt: 14694.222222222223,
  Jt: 6170.666666666667,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 20,
  TimeNow: 1982.6875,
  S: 14.3125,
  N: 64.1875,
  J: 20.125,
  St: 3499.5,
  Nt: 15194.5625,
  Jt: 4853.9375,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 12,
  TimeNow: 1882.923076923077,
  S: 10.76923076923077,
  N: 78.46153846153847,
  J: 9.0,
  St: 2674.5384615384614,
  Nt: 18592.076923076922,
  Jt: 2281.3846153846152,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 8,
  TimeNow: 1209.4166666666667,
  S: 0.25,
  N: 98.66666666666667,
  J: 0.08333333333333333,
  St: 198.91666666666666,
  Nt: 23314.083333333332,
  Jt: 35.0,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 14,
  TimeNow: 2266.4166666666665,
  S: 13.833333333333334,
  N: 65.33333333333333,
  J: 19.333333333333332,
  St: 3428.25,
  Nt: 15947.75,
  Jt: 4752.75,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 10,
  TimeNow: 1419.2727272727273,
  S: 5.636363636363637,
  N: 90.9090909090909,
  J: 2.1818181818181817,
  St: 1422.5454545454545,
  Nt: 21535.636363636364,
  Jt: 589.8181818181819,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 9,
  TimeNow: 1288.857142857143,
  S: 2.0714285714285716,
  N: 96.0,
  J: 0.7857142857142857,
  St: 580.5714285714286,
  Nt: 22729.14285714286,
  Jt: 238.28571428571428,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 16,
  TimeNow: 2509.0526315789475,
  S: 12.578947368421053,
  N: 66.42105263157895,
  J: 19.31578947368421,
  St: 3090.684210526316,
  Nt: 15764.0,
  Jt: 4693.315789473684,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 11,
  TimeNow: 1358.75,
  S: 5.666666666666667,
  N: 91.33333333333333,
  J: 1.5,
  St: 1464.0833333333333,
  Nt: 21626.333333333332,
  Jt: 457.5833333333333,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 18,
  TimeNow: 2142.375,
  S: 13.5,
  N: 64.875,
  J: 20.125,
  St: 3260.375,
  Nt: 15385.25,
  Jt: 4902.375,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 19,
  TimeNow: 146.6,
  S: 8.8,
  N: 75.0,
  J: 15.0,
  St: 168.2,
  Nt: 1399.0,
  Jt: 290.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 15,
  TimeNow: 155.83333333333334,
  S: 27.555555555555557,
  N: 66.83333333333333,
  J: 4.222222222222222,
  St: 523.5,
  Nt: 1248.8333333333333,
  Jt: 85.22222222222223,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 13,
  TimeNow: 127.92857142857143,
  S: 14.285714285714286,
  N: 80.64285714285714,
  J: 4.0,
  St: 272.7857142857143,
  Nt: 1503.7142857142858,
  Jt: 80.5,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 17,
  TimeNow: 143.0,
  S: 20.11111111111111,
  N: 73.22222222222223,
  J: 5.444444444444445,
  St: 382.0,
  Nt: 1367.6666666666667,
  Jt: 107.44444444444444,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 20,
  TimeNow: 128.25,
  S: 10.625,
  N: 83.9375,
  J: 4.4375,
  St: 205.5625,
  Nt: 1566.875,
  Jt: 84.625,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 12,
  TimeNow: 129.15384615384616,
  S: 14.538461538461538,
  N: 78.6923076923077,
  J: 5.6923076923076925,
  St: 276.6923076923077,
  Nt: 1468.3846153846155,
  Jt: 112.07692307692308,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 14,
  TimeNow: 140.33333333333334,
  S: 25.0,
  N: 68.66666666666667,
  J: 4.833333333333333,
  St: 475.1666666666667,
  Nt: 1283.8333333333333,
  Jt: 98.16666666666667,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 8,
  TimeNow: 114.18181818181819,
  S: 8.454545454545455,
  N: 87.45454545454545,
  J: 3.090909090909091,
  St: 162.72727272727272,
  Nt: 1633.7272727272727,
  Jt: 60.54545454545455,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 10,
  TimeNow: 182.6,
  S: 31.5,
  N: 58.5,
  J: 8.7,
  St: 595.1,
  Nt: 1093.3,
  Jt: 169.4,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 9,
  TimeNow: 133.5,
  S: 8.357142857142858,
  N: 86.35714285714286,
  J: 3.857142857142857,
  St: 164.57142857142858,
  Nt: 1612.7857142857142,
  Jt: 79.71428571428571,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 16,
  TimeNow: 142.31578947368422,
  S: 24.210526315789473,
  N: 70.0,
  J: 4.7368421052631575,
  St: 459.10526315789474,
  Nt: 1305.1052631578948,
  Jt: 93.15789473684211,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 11,
  TimeNow: 162.08333333333334,
  S: 27.0,
  N: 63.666666666666664,
  J: 8.25,
  St: 509.1666666666667,
  Nt: 1186.25,
  Jt: 162.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 18,
  TimeNow: 130.5,
  S: 8.25,
  N: 81.25,
  J: 9.25,
  St: 160.75,
  Nt: 1517.5,
  Jt: 179.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 19,
  TimeNow: 473.2,
  S: 16.0,
  N: 76.6,
  J: 6.2,
  St: 808.4,
  Nt: 3784.6,
  Jt: 312.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 15,
  TimeNow: 449.22222222222223,
  S: 21.333333333333332,
  N: 76.55555555555556,
  J: 0.6666666666666666,
  St: 1074.1666666666667,
  Nt: 3783.5555555555557,
  Jt: 47.27777777777778,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 13,
  TimeNow: 501.7142857142857,
  S: 8.785714285714286,
  N: 81.64285714285714,
  J: 8.0,
  St: 461.2857142857143,
  Nt: 4038.285714285714,
  Jt: 405.14285714285717,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 17,
  TimeNow: 438.44444444444446,
  S: 8.333333333333334,
  N: 87.66666666666667,
  J: 2.2222222222222223,
  St: 439.0,
  Nt: 4335.444444444444,
  Jt: 130.77777777777777,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 20,
  TimeNow: 452.1875,
  S: 2.5,
  N: 93.9375,
  J: 2.25,
  St: 148.625,
  Nt: 4636.375,
  Jt: 119.8125,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 12,
  TimeNow: 480.46153846153845,
  S: 7.769230769230769,
  N: 86.23076923076923,
  J: 4.615384615384615,
  St: 409.7692307692308,
  Nt: 4247.2307692307695,
  Jt: 247.76923076923077,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 8,
  TimeNow: 458.8181818181818,
  S: 4.363636363636363,
  N: 90.45454545454545,
  J: 4.0,
  St: 227.1818181818182,
  Nt: 4453.363636363636,
  Jt: 224.45454545454547,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 14,
  TimeNow: 492.4166666666667,
  S: 18.416666666666668,
  N: 73.0,
  J: 7.333333333333333,
  St: 931.3333333333334,
  Nt: 3599.5833333333335,
  Jt: 373.8333333333333,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 10,
  TimeNow: 434.4,
  S: 12.6,
  N: 86.4,
  J: 0.0,
  St: 633.4,
  Nt: 4269.6,
  Jt: 2.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 9,
  TimeNow: 440.3333333333333,
  S: 6.933333333333334,
  N: 90.26666666666667,
  J: 1.6,
  St: 350.73333333333335,
  Nt: 4463.466666666666,
  Jt: 90.8,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 16,
  TimeNow: 438.7894736842105,
  S: 17.31578947368421,
  N: 81.21052631578948,
  J: 0.0,
  St: 872.0526315789474,
  Nt: 4021.0526315789475,
  Jt: 11.68421052631579,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 11,
  TimeNow: 448.0833333333333,
  S: 13.666666666666666,
  N: 81.08333333333333,
  J: 3.8333333333333335,
  St: 700.25,
  Nt: 3996.9166666666665,
  Jt: 207.58333333333334,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 18,
  TimeNow: 438.375,
  S: 8.0,
  N: 91.0,
  J: 0.0,
  St: 432.0,
  Nt: 4472.875,
  Jt: 0.0,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 19,
  TimeNow: 1003.0,
  S: 5.6,
  N: 88.2,
  J: 5.0,
  St: 557.0,
  Nt: 8251.2,
  Jt: 491.8,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 15,
  TimeNow: 995.5,
  S: 4.277777777777778,
  N: 90.16666666666667,
  J: 4.0,
  St: 436.3333333333333,
  Nt: 8439.111111111111,
  Jt: 424.55555555555554,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 13,
  TimeNow: 901.9285714285714,
  S: 3.0714285714285716,
  N: 92.07142857142857,
  J: 3.0714285714285716,
  St: 345.64285714285717,
  Nt: 8612.57142857143,
  Jt: 341.7857142857143,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 17,
  TimeNow: 1152.75,
  S: 5.5,
  N: 84.75,
  J: 8.375,
  St: 565.125,
  Nt: 7923.375,
  Jt: 811.5,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 20,
  TimeNow: 1016.1875,
  S: 6.75,
  N: 86.5,
  J: 5.5,
  St: 665.5,
  Nt: 8090.625,
  Jt: 543.875,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 12,
  TimeNow: 793.3846153846154,
  S: 2.6153846153846154,
  N: 94.53846153846153,
  J: 1.3846153846153846,
  St: 297.0769230769231,
  Nt: 8821.923076923076,
  Jt: 181.0,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 8,
  TimeNow: 746.75,
  S: 4.333333333333333,
  N: 93.83333333333333,
  J: 0.6666666666666666,
  St: 473.5,
  Nt: 8753.166666666666,
  Jt: 73.33333333333333,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 14,
  TimeNow: 895.25,
  S: 2.8333333333333335,
  N: 92.33333333333333,
  J: 3.3333333333333335,
  St: 317.0833333333333,
  Nt: 8622.25,
  Jt: 360.6666666666667,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 10,
  TimeNow: 756.6363636363636,
  S: 1.9090909090909092,
  N: 96.54545454545455,
  J: 0.36363636363636365,
  St: 238.0,
  Nt: 9012.363636363636,
  Jt: 49.63636363636363,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 9,
  TimeNow: 749.7333333333333,
  S: 3.533333333333333,
  N: 94.86666666666666,
  J: 0.3333333333333333,
  St: 387.6666666666667,
  Nt: 8861.466666666667,
  Jt: 50.86666666666667,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 16,
  TimeNow: 1052.3684210526317,
  S: 7.368421052631579,
  N: 85.84210526315789,
  J: 5.368421052631579,
  St: 735.7894736842105,
  Nt: 8023.578947368421,
  Jt: 540.6315789473684,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 11,
  TimeNow: 753.7272727272727,
  S: 1.6363636363636365,
  N: 96.27272727272727,
  J: 1.0,
  St: 209.63636363636363,
  Nt: 8976.363636363636,
  Jt: 114.0,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 18,
  TimeNow: 1022.125,
  S: 3.5,
  N: 90.375,
  J: 4.75,
  St: 387.0,
  Nt: 8440.375,
  Jt: 472.625,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 19,
  TimeNow: 2076.0,
  S: 14.8,
  N: 73.2,
  J: 10.2,
  St: 4215.8,
  Nt: 20334.6,
  Jt: 2997.6,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 15,
  TimeNow: 2037.7222222222222,
  S: 7.222222222222222,
  N: 83.27777777777777,
  J: 8.166666666666666,
  St: 2153.0555555555557,
  Nt: 23063.833333333332,
  Jt: 2331.1111111111113,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 13,
  TimeNow: 1741.5384615384614,
  S: 7.153846153846154,
  N: 87.53846153846153,
  J: 3.8461538461538463,
  St: 2100.3846153846152,
  Nt: 24262.076923076922,
  Jt: 1185.5384615384614,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 17,
  TimeNow: 2378.0,
  S: 7.333333333333333,
  N: 73.88888888888889,
  J: 17.22222222222222,
  St: 2182.1111111111113,
  Nt: 20474.666666666668,
  Jt: 4891.222222222223,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 20,
  TimeNow: 1992.6875,
  S: 12.625,
  N: 80.8125,
  J: 4.875,
  St: 3616.3125,
  Nt: 22417.375,
  Jt: 1514.3125,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 12,
  TimeNow: 1645.923076923077,
  S: 7.615384615384615,
  N: 88.38461538461539,
  J: 2.6923076923076925,
  St: 2225.5384615384614,
  Nt: 24479.615384615383,
  Jt: 842.8461538461538,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 8,
  TimeNow: 1569.0833333333333,
  S: 4.0,
  N: 93.66666666666667,
  J: 1.0833333333333333,
  St: 1305.6666666666667,
  Nt: 25865.333333333332,
  Jt: 377.0,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 14,
  TimeNow: 1862.1666666666667,
  S: 10.416666666666666,
  N: 83.66666666666667,
  J: 4.416666666666667,
  St: 2992.1666666666665,
  Nt: 23201.666666666668,
  Jt: 1354.1666666666667,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 10,
  TimeNow: 1677.4545454545455,
  S: 8.090909090909092,
  N: 87.0,
  J: 3.4545454545454546,
  St: 2379.181818181818,
  Nt: 24078.909090909092,
  Jt: 1089.909090909091,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 9,
  TimeNow: 1544.3333333333333,
  S: 7.733333333333333,
  N: 90.46666666666667,
  J: 0.6,
  St: 2250.2,
  Nt: 25061.533333333333,
  Jt: 236.26666666666668,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 16,
  TimeNow: 2327.684210526316,
  S: 8.631578947368421,
  N: 77.52631578947368,
  J: 12.421052631578947,
  St: 2506.9473684210525,
  Nt: 21470.947368421053,
  Jt: 3570.1052631578946,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 11,
  TimeNow: 1714.6666666666667,
  S: 4.5,
  N: 90.0,
  J: 3.9166666666666665,
  St: 1361.75,
  Nt: 24921.583333333332,
  Jt: 1264.6666666666667,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 18,
  TimeNow: 1954.0,
  S: 11.25,
  N: 79.0,
  J: 8.0,
  St: 3233.875,
  Nt: 21904.375,
  Jt: 2409.75,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 19,
  TimeNow: 1568.2,
  S: 27.2,
  N: 49.4,
  J: 22.0,
  St: 6193.4,
  Nt: 11066.2,
  Jt: 5020.8,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 15,
  TimeNow: 1926.611111111111,
  S: 24.11111111111111,
  N: 43.333333333333336,
  J: 31.333333333333332,
  St: 5447.888888888889,
  Nt: 9765.777777777777,
  Jt: 7067.277777777777,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 13,
  TimeNow: 1640.4285714285713,
  S: 6.785714285714286,
  N: 65.85714285714286,
  J: 25.857142857142858,
  St: 1629.7142857142858,
  Nt: 14787.642857142857,
  Jt: 5863.5,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 17,
  TimeNow: 2921.8888888888887,
  S: 15.333333333333334,
  N: 37.111111111111114,
  J: 46.111111111111114,
  St: 3524.4444444444443,
  Nt: 8384.555555555555,
  Jt: 10372.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 20,
  TimeNow: 1233.5,
  S: 22.8125,
  N: 65.375,
  J: 10.1875,
  St: 5202.25,
  Nt: 14700.875,
  Jt: 2377.375,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 12,
  TimeNow: 1800.923076923077,
  S: 9.76923076923077,
  N: 61.0,
  J: 27.46153846153846,
  St: 2338.6153846153848,
  Nt: 13692.615384615385,
  Jt: 6249.384615384615,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 8,
  TimeNow: 932.9,
  S: 2.7,
  N: 96.0,
  J: 0.3,
  St: 672.1,
  Nt: 21410.2,
  Jt: 198.7,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 14,
  TimeNow: 1760.75,
  S: 17.0,
  N: 58.583333333333336,
  J: 22.916666666666668,
  St: 3877.4166666666665,
  Nt: 13171.0,
  Jt: 5232.166666666667,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 10,
  TimeNow: 985.5454545454545,
  S: 8.454545454545455,
  N: 89.81818181818181,
  J: 0.6363636363636364,
  St: 2019.8181818181818,
  Nt: 20105.090909090908,
  Jt: 156.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 9,
  TimeNow: 964.0,
  S: 11.466666666666667,
  N: 86.86666666666666,
  J: 0.4,
  St: 2659.5333333333333,
  Nt: 19488.333333333332,
  Jt: 132.93333333333334,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 16,
  TimeNow: 2277.0526315789475,
  S: 17.736842105263158,
  N: 40.31578947368421,
  J: 40.26315789473684,
  St: 4078.7894736842104,
  Nt: 9087.052631578947,
  Jt: 9115.052631578947,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 11,
  TimeNow: 1263.8333333333333,
  S: 11.916666666666666,
  N: 76.33333333333333,
  J: 10.25,
  St: 2792.4166666666665,
  Nt: 17099.25,
  Jt: 2388.75,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 18,
  TimeNow: 2179.375,
  S: 27.875,
  N: 34.625,
  J: 35.875,
  St: 6320.375,
  Nt: 7869.25,
  Jt: 8091.375,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 19,
  TimeNow: 1843.8,
  S: 28.4,
  N: 49.6,
  J: 20.4,
  St: 6594.8,
  Nt: 11373.6,
  Jt: 4735.6,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 15,
  TimeNow: 2778.4444444444443,
  S: 19.444444444444443,
  N: 47.111111111111114,
  J: 32.05555555555556,
  St: 4491.777777777777,
  Nt: 10790.444444444445,
  Jt: 7421.777777777777,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 13,
  TimeNow: 1660.857142857143,
  S: 16.285714285714285,
  N: 67.5,
  J: 15.071428571428571,
  St: 3799.0,
  Nt: 15409.285714285714,
  Jt: 3495.714285714286,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 17,
  TimeNow: 3419.6666666666665,
  S: 26.555555555555557,
  N: 35.666666666666664,
  J: 36.44444444444444,
  St: 6452.222222222223,
  Nt: 8629.111111111111,
  Jt: 8745.777777777777,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 20,
  TimeNow: 1471.5,
  S: 25.4375,
  N: 63.125,
  J: 10.0625,
  St: 5902.4375,
  Nt: 14441.4375,
  Jt: 2360.125,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 12,
  TimeNow: 1409.3076923076924,
  S: 14.76923076923077,
  N: 76.92307692307692,
  J: 7.0,
  St: 3456.3846153846152,
  Nt: 17558.846153846152,
  Jt: 1688.7692307692307,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 14,
  TimeNow: 2104.5,
  S: 14.666666666666666,
  N: 60.583333333333336,
  J: 23.083333333333332,
  St: 3446.0833333333335,
  Nt: 13881.833333333334,
  Jt: 5376.083333333333,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 8,
  TimeNow: 1385.6363636363637,
  S: 6.818181818181818,
  N: 83.45454545454545,
  J: 8.363636363636363,
  St: 1672.2727272727273,
  Nt: 19044.81818181818,
  Jt: 1986.909090909091,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 10,
  TimeNow: 1062.5454545454545,
  S: 0.7272727272727273,
  N: 98.27272727272727,
  J: 0.0,
  St: 198.1818181818182,
  Nt: 22505.81818181818,
  Jt: 0.0,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 9,
  TimeNow: 1037.6,
  S: 0.06666666666666667,
  N: 98.93333333333334,
  J: 0.0,
  St: 24.466666666666665,
  Nt: 22679.533333333333,
  Jt: 0.0,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 16,
  TimeNow: 3230.8947368421054,
  S: 21.31578947368421,
  N: 40.1578947368421,
  J: 37.05263157894737,
  St: 5010.789473684211,
  Nt: 9345.052631578947,
  Jt: 8614.157894736842,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 11,
  TimeNow: 1119.0833333333333,
  S: 3.5,
  N: 95.5,
  J: 0.0,
  St: 885.5,
  Nt: 21818.5,
  Jt: 0.0,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 18,
  TimeNow: 2343.0,
  S: 24.375,
  N: 36.0,
  J: 38.125,
  St: 5622.0,
  Nt: 8280.5,
  Jt: 8801.5,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 19,
  TimeNow: 114.4,
  S: 2.4,
  N: 96.6,
  J: 0.0,
  St: 46.6,
  Nt: 1883.4,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 15,
  TimeNow: 103.05555555555556,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 13,
  TimeNow: 107.14285714285714,
  S: 1.7142857142857142,
  N: 97.28571428571429,
  J: 0.0,
  St: 33.285714285714285,
  Nt: 1896.7142857142858,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 17,
  TimeNow: 105.11111111111111,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 20,
  TimeNow: 110.0625,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 12,
  TimeNow: 108.46153846153847,
  S: 1.8461538461538463,
  N: 97.15384615384616,
  J: 0.0,
  St: 35.84615384615385,
  Nt: 1894.1538461538462,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 8,
  TimeNow: 103.36363636363636,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 14,
  TimeNow: 104.58333333333333,
  S: 1.5833333333333333,
  N: 97.41666666666667,
  J: 0.0,
  St: 31.333333333333332,
  Nt: 1898.6666666666667,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 10,
  TimeNow: 114.54545454545455,
  S: 6.2727272727272725,
  N: 92.72727272727273,
  J: 0.0,
  St: 126.9090909090909,
  Nt: 1803.090909090909,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 9,
  TimeNow: 105.2,
  S: 3.2,
  N: 95.8,
  J: 0.0,
  St: 62.13333333333333,
  Nt: 1867.8666666666666,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 16,
  TimeNow: 104.73684210526316,
  S: 1.263157894736842,
  N: 97.73684210526316,
  J: 0.0,
  St: 24.526315789473685,
  Nt: 1905.4736842105262,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 11,
  TimeNow: 104.5,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 19.416666666666668,
  Nt: 1910.5833333333333,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 18,
  TimeNow: 109.625,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 19,
  TimeNow: 110.8,
  S: 2.2,
  N: 96.8,
  J: 0.0,
  St: 23.8,
  Nt: 1038.2,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 15,
  TimeNow: 109.11111111111111,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1062.0,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 13,
  TimeNow: 109.21428571428571,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1062.0,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 17,
  TimeNow: 129.11111111111111,
  S: 21.555555555555557,
  N: 74.66666666666667,
  J: 2.4444444444444446,
  St: 235.33333333333334,
  Nt: 799.5555555555555,
  Jt: 27.11111111111111,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 20,
  TimeNow: 109.25,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1062.0,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 12,
  TimeNow: 108.6923076923077,
  S: 1.5384615384615385,
  N: 97.46153846153847,
  J: 0.0,
  St: 18.846153846153847,
  Nt: 1043.1538461538462,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 8,
  TimeNow: 109.625,
  S: 1.125,
  N: 97.75,
  J: 0.0,
  St: 14.875,
  Nt: 1047.125,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 14,
  TimeNow: 109.54545454545455,
  S: 2.4545454545454546,
  N: 96.54545454545455,
  J: 0.0,
  St: 27.727272727272727,
  Nt: 1034.2727272727273,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 10,
  TimeNow: 118.0,
  S: 0.7272727272727273,
  N: 98.27272727272727,
  J: 0.0,
  St: 8.727272727272727,
  Nt: 1053.2727272727273,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 9,
  TimeNow: 112.93333333333334,
  S: 0.06666666666666667,
  N: 98.86666666666666,
  J: 0.0,
  St: 1.4,
  Nt: 1060.6,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 16,
  TimeNow: 113.52631578947368,
  S: 7.473684210526316,
  N: 91.10526315789474,
  J: 0.21052631578947367,
  St: 82.42105263157895,
  Nt: 977.0526315789474,
  Jt: 2.526315789473684,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 11,
  TimeNow: 110.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1062.0,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 18,
  TimeNow: 113.25,
  S: 3.875,
  N: 95.125,
  J: 0.0,
  St: 42.75,
  Nt: 1019.25,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 19,
  TimeNow: 168.0,
  S: 40.2,
  N: 46.0,
  J: 12.2,
  St: 716.4,
  Nt: 817.6,
  Jt: 227.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 15,
  TimeNow: 255.66666666666666,
  S: 46.333333333333336,
  N: 14.833333333333334,
  J: 37.55555555555556,
  St: 825.7777777777778,
  Nt: 265.6111111111111,
  Jt: 669.6111111111111,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 13,
  TimeNow: 164.35714285714286,
  S: 38.142857142857146,
  N: 47.5,
  J: 12.928571428571429,
  St: 676.7857142857143,
  Nt: 843.5,
  Jt: 240.71428571428572,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 17,
  TimeNow: 173.66666666666666,
  S: 56.77777777777778,
  N: 26.0,
  J: 16.0,
  St: 1004.3333333333334,
  Nt: 466.22222222222223,
  Jt: 290.44444444444446,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 20,
  TimeNow: 97.0625,
  S: 9.25,
  N: 87.5,
  J: 2.25,
  St: 174.3125,
  Nt: 1546.9375,
  Jt: 39.75,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 12,
  TimeNow: 172.3846153846154,
  S: 33.15384615384615,
  N: 49.38461538461539,
  J: 16.076923076923077,
  St: 592.5384615384615,
  Nt: 875.6153846153846,
  Jt: 292.84615384615387,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 14,
  TimeNow: 207.75,
  S: 54.333333333333336,
  N: 19.25,
  J: 24.916666666666668,
  St: 965.5,
  Nt: 345.8333333333333,
  Jt: 449.6666666666667,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 8,
  TimeNow: 111.72727272727273,
  S: 16.363636363636363,
  N: 78.63636363636364,
  J: 4.0,
  St: 294.1818181818182,
  Nt: 1390.5454545454545,
  Jt: 76.27272727272727,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 10,
  TimeNow: 398.1818181818182,
  S: 14.0,
  N: 0.0,
  J: 85.0,
  St: 254.0909090909091,
  Nt: 0.0,
  Jt: 1506.909090909091,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 9,
  TimeNow: 195.6,
  S: 41.8,
  N: 30.466666666666665,
  J: 26.466666666666665,
  St: 746.5333333333333,
  Nt: 539.9333333333333,
  Jt: 474.53333333333336,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 16,
  TimeNow: 224.83333333333334,
  S: 31.555555555555557,
  N: 40.55555555555556,
  J: 26.22222222222222,
  St: 565.2222222222222,
  Nt: 722.2222222222222,
  Jt: 473.55555555555554,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 11,
  TimeNow: 307.9166666666667,
  S: 25.25,
  N: 16.833333333333332,
  J: 56.583333333333336,
  St: 454.25,
  Nt: 300.25,
  Jt: 1006.5,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 18,
  TimeNow: 124.625,
  S: 19.25,
  N: 70.625,
  J: 9.0,
  St: 344.625,
  Nt: 1248.125,
  Jt: 168.25,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 19,
  TimeNow: 144.2,
  S: 16.0,
  N: 63.0,
  J: 19.0,
  St: 240.8,
  Nt: 936.6,
  Jt: 290.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 15,
  TimeNow: 149.72222222222223,
  S: 34.72222222222222,
  N: 58.94444444444444,
  J: 5.0,
  St: 516.2777777777778,
  Nt: 867.8888888888889,
  Jt: 82.83333333333333,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 13,
  TimeNow: 125.6923076923077,
  S: 19.53846153846154,
  N: 74.3076923076923,
  J: 4.3076923076923075,
  St: 295.7692307692308,
  Nt: 1096.8461538461538,
  Jt: 74.6923076923077,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 17,
  TimeNow: 140.88888888888889,
  S: 28.555555555555557,
  N: 63.44444444444444,
  J: 6.444444444444445,
  St: 426.22222222222223,
  Nt: 938.4444444444445,
  Jt: 102.77777777777777,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 20,
  TimeNow: 127.375,
  S: 14.5625,
  N: 78.6875,
  J: 5.4375,
  St: 224.625,
  Nt: 1157.875,
  Jt: 84.625,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 12,
  TimeNow: 125.53846153846153,
  S: 18.76923076923077,
  N: 72.92307692307692,
  J: 7.076923076923077,
  St: 281.15384615384613,
  Nt: 1073.8461538461538,
  Jt: 112.07692307692308,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 8,
  TimeNow: 111.0,
  S: 9.75,
  N: 85.5,
  J: 3.5,
  St: 150.16666666666666,
  Nt: 1261.3333333333333,
  Jt: 55.5,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 14,
  TimeNow: 134.91666666666666,
  S: 29.25,
  N: 63.0,
  J: 6.0,
  St: 437.5833333333333,
  Nt: 931.75,
  Jt: 98.16666666666667,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 10,
  TimeNow: 179.0,
  S: 40.27272727272727,
  N: 47.36363636363637,
  J: 10.818181818181818,
  St: 598.0909090909091,
  Nt: 700.9090909090909,
  Jt: 168.1818181818182,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 9,
  TimeNow: 135.26666666666668,
  S: 11.266666666666667,
  N: 82.33333333333333,
  J: 5.066666666666666,
  St: 171.93333333333334,
  Nt: 1212.4,
  Jt: 82.73333333333333,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 16,
  TimeNow: 138.68421052631578,
  S: 32.68421052631579,
  N: 59.78947368421053,
  J: 5.7894736842105265,
  St: 486.2631578947368,
  Nt: 887.6315789473684,
  Jt: 93.15789473684211,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 11,
  TimeNow: 157.41666666666666,
  S: 34.416666666666664,
  N: 53.583333333333336,
  J: 10.416666666666666,
  St: 511.5,
  Nt: 793.5833333333334,
  Jt: 162.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 18,
  TimeNow: 132.625,
  S: 20.75,
  N: 66.125,
  J: 11.5,
  St: 309.875,
  Nt: 977.375,
  Jt: 180.125,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 19,
  TimeNow: 361.6,
  S: 3.8,
  N: 89.4,
  J: 5.2,
  St: 184.0,
  Nt: 3893.6,
  Jt: 253.4,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 15,
  TimeNow: 717.8888888888889,
  S: 3.8333333333333335,
  N: 56.05555555555556,
  J: 38.666666666666664,
  St: 177.83333333333334,
  Nt: 2452.777777777778,
  Jt: 1700.388888888889,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 13,
  TimeNow: 353.7142857142857,
  S: 4.5,
  N: 89.07142857142857,
  J: 4.785714285714286,
  St: 221.0,
  Nt: 3878.4285714285716,
  Jt: 231.57142857142858,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 17,
  TimeNow: 904.0,
  S: 6.625,
  N: 65.5,
  J: 26.75,
  St: 304.125,
  Nt: 2861.375,
  Jt: 1165.5,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 20,
  TimeNow: 378.9375,
  S: 4.0625,
  N: 87.75,
  J: 6.375,
  St: 203.0625,
  Nt: 3828.0,
  Jt: 299.9375,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 12,
  TimeNow: 305.46153846153845,
  S: 1.7692307692307692,
  N: 93.92307692307692,
  J: 2.923076923076923,
  St: 92.07692307692308,
  Nt: 4081.076923076923,
  Jt: 157.84615384615384,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 14,
  TimeNow: 602.25,
  S: 9.833333333333334,
  N: 66.58333333333333,
  J: 22.333333333333332,
  St: 442.9166666666667,
  Nt: 2904.6666666666665,
  Jt: 983.4166666666666,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 8,
  TimeNow: 340.27272727272725,
  S: 0.7272727272727273,
  N: 95.0,
  J: 3.0,
  St: 41.36363636363637,
  Nt: 4132.0,
  Jt: 157.63636363636363,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 10,
  TimeNow: 263.27272727272725,
  S: 0.8181818181818182,
  N: 96.81818181818181,
  J: 1.3636363636363635,
  St: 52.09090909090909,
  Nt: 4197.909090909091,
  Jt: 81.0,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 9,
  TimeNow: 270.2142857142857,
  S: 0.5,
  N: 96.5,
  J: 1.8571428571428572,
  St: 26.642857142857142,
  Nt: 4186.428571428572,
  Jt: 117.92857142857143,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 16,
  TimeNow: 865.6315789473684,
  S: 7.368421052631579,
  N: 47.10526315789474,
  J: 44.05263157894737,
  St: 335.3157894736842,
  Nt: 2060.684210526316,
  Jt: 1935.0,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 11,
  TimeNow: 287.75,
  S: 1.0,
  N: 95.58333333333333,
  J: 1.8333333333333333,
  St: 58.0,
  Nt: 4154.833333333333,
  Jt: 118.16666666666667,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 18,
  TimeNow: 539.0,
  S: 1.125,
  N: 86.25,
  J: 11.5,
  St: 55.25,
  Nt: 3757.75,
  Jt: 518.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 19,
  TimeNow: 802.0,
  S: 23.4,
  N: 14.4,
  J: 60.6,
  St: 1759.0,
  Nt: 1103.2,
  Jt: 4521.8,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 15,
  TimeNow: 754.6666666666666,
  S: 36.27777777777778,
  N: 22.77777777777778,
  J: 39.388888888888886,
  St: 2719.277777777778,
  Nt: 1718.388888888889,
  Jt: 2946.3333333333335,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 13,
  TimeNow: 460.14285714285717,
  S: 29.142857142857142,
  N: 57.785714285714285,
  J: 12.071428571428571,
  St: 2175.8571428571427,
  Nt: 4301.0,
  Jt: 907.1428571428571,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 17,
  TimeNow: 1171.6666666666667,
  S: 7.444444444444445,
  N: 7.333333333333333,
  J: 83.88888888888889,
  St: 583.1111111111111,
  Nt: 571.8888888888889,
  Jt: 6229.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 20,
  TimeNow: 687.4375,
  S: 37.8125,
  N: 14.75,
  J: 46.125,
  St: 2822.6875,
  Nt: 1125.6875,
  Jt: 3435.625,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 12,
  TimeNow: 340.3076923076923,
  S: 7.538461538461538,
  N: 91.46153846153847,
  J: 0.0,
  St: 584.5384615384615,
  Nt: 6799.461538461538,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 8,
  TimeNow: 309.3333333333333,
  S: 1.25,
  N: 97.75,
  J: 0.0,
  St: 99.16666666666667,
  Nt: 7284.833333333333,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 14,
  TimeNow: 757.4545454545455,
  S: 25.363636363636363,
  N: 39.72727272727273,
  J: 33.54545454545455,
  St: 1915.909090909091,
  Nt: 2966.0,
  Jt: 2502.090909090909,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 10,
  TimeNow: 319.45454545454544,
  S: 1.4545454545454546,
  N: 97.54545454545455,
  J: 0.0,
  St: 119.81818181818181,
  Nt: 7264.181818181818,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 9,
  TimeNow: 321.2142857142857,
  S: 0.5714285714285714,
  N: 98.42857142857143,
  J: 0.0,
  St: 44.285714285714285,
  Nt: 7339.714285714285,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 16,
  TimeNow: 1359.0,
  S: 8.052631578947368,
  N: 3.3684210526315788,
  J: 86.89473684210526,
  St: 633.4736842105264,
  Nt: 292.2105263157895,
  Jt: 6458.315789473684,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 11,
  TimeNow: 336.1666666666667,
  S: 6.833333333333333,
  N: 92.16666666666667,
  J: 0.0,
  St: 528.5,
  Nt: 6855.5,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 18,
  TimeNow: 824.875,
  S: 25.875,
  N: 11.25,
  J: 61.375,
  St: 1951.625,
  Nt: 868.5,
  Jt: 4563.875,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 19,
  TimeNow: 243.2,
  S: 1.2,
  N: 97.2,
  J: 0.4,
  St: 51.0,
  Nt: 3823.2,
  Jt: 21.8,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 15,
  TimeNow: 241.05555555555554,
  S: 3.6666666666666665,
  N: 95.33333333333333,
  J: 0.0,
  St: 157.11111111111111,
  Nt: 3738.8888888888887,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 13,
  TimeNow: 227.78571428571428,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 1.2142857142857142,
  Nt: 3894.785714285714,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 17,
  TimeNow: 383.8888888888889,
  S: 23.333333333333332,
  N: 50.44444444444444,
  J: 24.88888888888889,
  St: 928.6666666666666,
  Nt: 1978.6666666666667,
  Jt: 988.6666666666666,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 20,
  TimeNow: 242.4375,
  S: 0.0625,
  N: 98.9375,
  J: 0.0,
  St: 9.25,
  Nt: 3886.75,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 12,
  TimeNow: 226.46153846153845,
  S: 0.15384615384615385,
  N: 98.84615384615384,
  J: 0.0,
  St: 6.923076923076923,
  Nt: 3889.076923076923,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 8,
  TimeNow: 218.54545454545453,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 2.272727272727273,
  Nt: 3893.7272727272725,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 14,
  TimeNow: 232.75,
  S: 1.5833333333333333,
  N: 97.41666666666667,
  J: 0.0,
  St: 63.583333333333336,
  Nt: 3832.4166666666665,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 10,
  TimeNow: 228.9090909090909,
  S: 1.6363636363636365,
  N: 97.36363636363636,
  J: 0.0,
  St: 70.72727272727273,
  Nt: 3825.2727272727275,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 9,
  TimeNow: 217.73333333333332,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3896.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 16,
  TimeNow: 378.42105263157896,
  S: 19.105263157894736,
  N: 56.73684210526316,
  J: 22.842105263157894,
  St: 764.421052631579,
  Nt: 2227.5789473684213,
  Jt: 904.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 11,
  TimeNow: 227.33333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3896.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 18,
  TimeNow: 246.85714285714286,
  S: 7.857142857142857,
  N: 91.14285714285714,
  J: 0.0,
  St: 325.0,
  Nt: 3571.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 19,
  TimeNow: 86.0,
  S: 18.6,
  N: 80.4,
  J: 0.0,
  St: 379.0,
  Nt: 1626.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 15,
  TimeNow: 210.11111111111111,
  S: 21.77777777777778,
  N: 1.6111111111111112,
  J: 75.61111111111111,
  St: 443.6666666666667,
  Nt: 32.666666666666664,
  Jt: 1528.6666666666667,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 13,
  TimeNow: 125.07142857142857,
  S: 17.785714285714285,
  N: 76.64285714285714,
  J: 4.428571428571429,
  St: 362.92857142857144,
  Nt: 1549.142857142857,
  Jt: 92.92857142857143,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 17,
  TimeNow: 124.77777777777777,
  S: 34.77777777777778,
  N: 25.11111111111111,
  J: 38.22222222222222,
  St: 709.3333333333334,
  Nt: 515.1111111111111,
  Jt: 780.5555555555555,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 20,
  TimeNow: 84.1875,
  S: 0.25,
  N: 98.75,
  J: 0.0,
  St: 5.125,
  Nt: 1999.875,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 12,
  TimeNow: 85.38461538461539,
  S: 0.3076923076923077,
  N: 98.6923076923077,
  J: 0.0,
  St: 7.230769230769231,
  Nt: 1997.7692307692307,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 14,
  TimeNow: 223.83333333333334,
  S: 26.583333333333332,
  N: 14.583333333333334,
  J: 57.583333333333336,
  St: 541.1666666666666,
  Nt: 298.0833333333333,
  Jt: 1165.75,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 8,
  TimeNow: 80.72727272727273,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 2005.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 10,
  TimeNow: 114.72727272727273,
  S: 32.0,
  N: 45.36363636363637,
  J: 21.181818181818183,
  St: 651.8181818181819,
  Nt: 918.2727272727273,
  Jt: 434.90909090909093,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 9,
  TimeNow: 88.92857142857143,
  S: 16.571428571428573,
  N: 78.92857142857143,
  J: 3.4285714285714284,
  St: 335.42857142857144,
  Nt: 1596.142857142857,
  Jt: 73.42857142857143,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 16,
  TimeNow: 135.47368421052633,
  S: 40.473684210526315,
  N: 14.421052631578947,
  J: 43.36842105263158,
  St: 824.2105263157895,
  Nt: 298.2631578947368,
  Jt: 882.5263157894736,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 11,
  TimeNow: 91.58333333333333,
  S: 18.5,
  N: 75.33333333333333,
  J: 5.083333333333333,
  St: 376.6666666666667,
  Nt: 1523.4166666666667,
  Jt: 104.91666666666667,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 18,
  TimeNow: 83.875,
  S: 2.375,
  N: 94.0,
  J: 2.5,
  St: 51.375,
  Nt: 1901.875,
  Jt: 51.75,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 19,
  TimeNow: 220.6,
  S: 15.0,
  N: 71.6,
  J: 12.0,
  St: 572.6,
  Nt: 2653.4,
  Jt: 459.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 15,
  TimeNow: 373.94444444444446,
  S: 7.277777777777778,
  N: 52.833333333333336,
  J: 38.22222222222222,
  St: 287.77777777777777,
  Nt: 1964.111111111111,
  Jt: 1432.2777777777778,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 13,
  TimeNow: 179.21428571428572,
  S: 9.785714285714286,
  N: 89.21428571428571,
  J: 0.0,
  St: 381.0,
  Nt: 3304.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 17,
  TimeNow: 581.375,
  S: 1.625,
  N: 45.75,
  J: 51.375,
  St: 68.625,
  Nt: 1697.25,
  Jt: 1919.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 20,
  TimeNow: 241.6875,
  S: 12.6875,
  N: 68.6875,
  J: 17.25,
  St: 484.625,
  Nt: 2545.0,
  Jt: 655.125,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 12,
  TimeNow: 175.69230769230768,
  S: 2.6153846153846154,
  N: 96.38461538461539,
  J: 0.0,
  St: 99.38461538461539,
  Nt: 3585.6153846153848,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 8,
  TimeNow: 166.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 14,
  TimeNow: 274.5833333333333,
  S: 11.666666666666666,
  N: 60.166666666666664,
  J: 26.583333333333332,
  St: 450.4166666666667,
  Nt: 2236.1666666666665,
  Jt: 998.8333333333334,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 10,
  TimeNow: 173.5,
  S: 2.3,
  N: 96.7,
  J: 0.0,
  St: 89.3,
  Nt: 3595.7,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 9,
  TimeNow: 169.46666666666667,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 16,
  TimeNow: 431.7368421052632,
  S: 3.263157894736842,
  N: 48.21052631578947,
  J: 47.05263157894737,
  St: 138.68421052631578,
  Nt: 1791.578947368421,
  Jt: 1754.1052631578948,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 11,
  TimeNow: 174.83333333333334,
  S: 3.1666666666666665,
  N: 95.83333333333333,
  J: 0.0,
  St: 122.0,
  Nt: 3563.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 18,
  TimeNow: 406.125,
  S: 10.0,
  N: 46.75,
  J: 42.25,
  St: 379.875,
  Nt: 1736.125,
  Jt: 1568.75,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 19,
  TimeNow: 184.4,
  S: 3.6,
  N: 89.6,
  J: 5.0,
  St: 78.6,
  Nt: 1623.4,
  Jt: 101.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 15,
  TimeNow: 167.61111111111111,
  S: 4.944444444444445,
  N: 90.88888888888889,
  J: 2.7222222222222223,
  St: 101.77777777777777,
  Nt: 1642.7777777777778,
  Jt: 58.44444444444444,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 13,
  TimeNow: 174.21428571428572,
  S: 3.4285714285714284,
  N: 90.0,
  J: 5.285714285714286,
  St: 70.35714285714286,
  Nt: 1627.142857142857,
  Jt: 105.5,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 17,
  TimeNow: 181.11111111111111,
  S: 3.888888888888889,
  N: 90.0,
  J: 5.0,
  St: 76.22222222222223,
  Nt: 1625.7777777777778,
  Jt: 101.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 20,
  TimeNow: 205.75,
  S: 4.875,
  N: 85.375,
  J: 8.5625,
  St: 97.75,
  Nt: 1545.5,
  Jt: 159.75,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 12,
  TimeNow: 166.07692307692307,
  S: 2.0,
  N: 92.53846153846153,
  J: 4.230769230769231,
  St: 44.15384615384615,
  Nt: 1673.3846153846155,
  Jt: 85.46153846153847,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 8,
  TimeNow: 148.58333333333334,
  S: 6.583333333333333,
  N: 92.0,
  J: 0.4166666666666667,
  St: 134.58333333333334,
  Nt: 1660.0,
  Jt: 8.416666666666666,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 14,
  TimeNow: 176.25,
  S: 2.75,
  N: 89.66666666666667,
  J: 6.166666666666667,
  St: 57.833333333333336,
  Nt: 1623.8333333333333,
  Jt: 121.33333333333333,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 10,
  TimeNow: 154.54545454545453,
  S: 16.90909090909091,
  N: 82.0909090909091,
  J: 0.0,
  St: 317.90909090909093,
  Nt: 1485.090909090909,
  Jt: 0.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 16,
  TimeNow: 175.52631578947367,
  S: 2.789473684210526,
  N: 91.10526315789474,
  J: 5.0,
  St: 56.94736842105263,
  Nt: 1645.0526315789473,
  Jt: 101.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 9,
  TimeNow: 146.28571428571428,
  S: 3.857142857142857,
  N: 95.14285714285714,
  J: 0.0,
  St: 79.28571428571429,
  Nt: 1723.7142857142858,
  Jt: 0.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 11,
  TimeNow: 167.5,
  S: 8.25,
  N: 88.5,
  J: 1.9166666666666667,
  St: 160.08333333333334,
  Nt: 1603.5,
  Jt: 39.416666666666664,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 18,
  TimeNow: 170.625,
  S: 2.625,
  N: 91.75,
  J: 4.375,
  St: 57.625,
  Nt: 1657.0,
  Jt: 88.375,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 19,
  TimeNow: 95.0,
  S: 5.2,
  N: 93.8,
  J: 0.0,
  St: 121.6,
  Nt: 2161.4,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 15,
  TimeNow: 176.88888888888889,
  S: 31.72222222222222,
  N: 31.88888888888889,
  J: 34.94444444444444,
  St: 737.8888888888889,
  Nt: 739.7777777777778,
  Jt: 805.0555555555555,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 13,
  TimeNow: 178.85714285714286,
  S: 30.357142857142858,
  N: 15.714285714285714,
  J: 52.214285714285715,
  St: 703.3571428571429,
  Nt: 378.35714285714283,
  Jt: 1200.5714285714287,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 17,
  TimeNow: 100.11111111111111,
  S: 6.777777777777778,
  N: 92.22222222222223,
  J: 0.0,
  St: 158.0,
  Nt: 2125.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 20,
  TimeNow: 94.8125,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 2283.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 12,
  TimeNow: 106.3076923076923,
  S: 8.0,
  N: 89.15384615384616,
  J: 1.8461538461538463,
  St: 184.15384615384616,
  Nt: 2055.6153846153848,
  Jt: 43.23076923076923,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 8,
  TimeNow: 102.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 2283.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 14,
  TimeNow: 199.58333333333334,
  S: 40.416666666666664,
  N: 20.333333333333332,
  J: 37.75,
  St: 931.4166666666666,
  Nt: 475.5833333333333,
  Jt: 875.6666666666666,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 10,
  TimeNow: 101.6,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 2283.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 9,
  TimeNow: 101.3076923076923,
  S: 2.230769230769231,
  N: 96.76923076923077,
  J: 0.0,
  St: 53.46153846153846,
  Nt: 2229.5384615384614,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 16,
  TimeNow: 105.52631578947368,
  S: 22.105263157894736,
  N: 76.84210526315789,
  J: 0.0,
  St: 512.9473684210526,
  Nt: 1770.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 11,
  TimeNow: 96.91666666666667,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 2283.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 18,
  TimeNow: 95.25,
  S: 3.75,
  N: 95.25,
  J: 0.0,
  St: 91.0,
  Nt: 2192.0,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 19,
  TimeNow: 164.2,
  S: 11.4,
  N: 87.6,
  J: 0.0,
  St: 458.0,
  Nt: 3421.0,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 15,
  TimeNow: 391.8888888888889,
  S: 26.333333333333332,
  N: 13.444444444444445,
  J: 58.611111111111114,
  St: 1045.2777777777778,
  Nt: 537.7777777777778,
  Jt: 2295.9444444444443,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 13,
  TimeNow: 251.42857142857142,
  S: 22.642857142857142,
  N: 48.857142857142854,
  J: 26.857142857142858,
  St: 897.4285714285714,
  Nt: 1914.4285714285713,
  Jt: 1067.142857142857,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 17,
  TimeNow: 228.88888888888889,
  S: 19.555555555555557,
  N: 59.55555555555556,
  J: 19.0,
  St: 778.1111111111111,
  Nt: 2332.4444444444443,
  Jt: 768.4444444444445,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 20,
  TimeNow: 163.25,
  S: 0.1875,
  N: 98.8125,
  J: 0.0,
  St: 7.5,
  Nt: 3871.5,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 12,
  TimeNow: 168.83333333333334,
  S: 5.25,
  N: 92.5,
  J: 1.1666666666666667,
  St: 207.33333333333334,
  Nt: 3624.8333333333335,
  Jt: 46.833333333333336,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 8,
  TimeNow: 163.36363636363637,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3879.0,
  Jt: 0.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 14,
  TimeNow: 412.5,
  S: 34.75,
  N: 16.75,
  J: 47.166666666666664,
  St: 1363.3333333333333,
  Nt: 664.3333333333334,
  Jt: 1851.3333333333333,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 10,
  TimeNow: 208.0,
  S: 16.636363636363637,
  N: 72.81818181818181,
  J: 9.363636363636363,
  St: 658.5454545454545,
  Nt: 2843.4545454545455,
  Jt: 377.0,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 9,
  TimeNow: 175.93333333333334,
  S: 9.6,
  N: 87.46666666666667,
  J: 1.7333333333333334,
  St: 381.46666666666664,
  Nt: 3422.8,
  Jt: 74.8,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 16,
  TimeNow: 245.10526315789474,
  S: 32.1578947368421,
  N: 44.36842105263158,
  J: 21.842105263157894,
  St: 1265.7368421052631,
  Nt: 1736.9473684210527,
  Jt: 876.3157894736842,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 11,
  TimeNow: 172.66666666666666,
  S: 8.083333333333334,
  N: 88.41666666666667,
  J: 2.3333333333333335,
  St: 323.5833333333333,
  Nt: 3459.3333333333335,
  Jt: 96.08333333333333,
}, {
  Id: 'TJx7jVaVTtCaRhj8chu4',
  Hour: 18,
  TimeNow: 162.125,
  S: 3.375,
  N: 94.25,
  J: 1.25,
  St: 142.375,
  Nt: 3685.0,
  Jt: 51.625,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 19,
  TimeNow: 158.6,
  S: 1.2,
  N: 0.0,
  J: 97.8,
  St: 5.2,
  Nt: 0.0,
  Jt: 365.8,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 15,
  TimeNow: 85.05555555555556,
  S: 54.111111111111114,
  N: 7.222222222222222,
  J: 37.611111111111114,
  St: 203.44444444444446,
  Nt: 27.055555555555557,
  Jt: 140.5,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 13,
  TimeNow: 83.85714285714286,
  S: 49.42857142857143,
  N: 0.8571428571428571,
  J: 48.642857142857146,
  St: 185.57142857142858,
  Nt: 3.7142857142857144,
  Jt: 181.71428571428572,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 17,
  TimeNow: 81.55555555555556,
  S: 50.666666666666664,
  N: 5.555555555555555,
  J: 42.77777777777778,
  St: 190.11111111111111,
  Nt: 21.333333333333332,
  Jt: 159.55555555555554,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 20,
  TimeNow: 106.75,
  S: 30.5625,
  N: 1.375,
  J: 66.9375,
  St: 115.0625,
  Nt: 5.125,
  Jt: 250.8125,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 12,
  TimeNow: 112.84615384615384,
  S: 19.076923076923077,
  N: 0.0,
  J: 79.92307692307692,
  St: 72.6923076923077,
  Nt: 0.0,
  Jt: 298.3076923076923,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 8,
  TimeNow: 72.33333333333333,
  S: 35.416666666666664,
  N: 26.166666666666668,
  J: 37.416666666666664,
  St: 133.58333333333334,
  Nt: 97.83333333333333,
  Jt: 139.58333333333334,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 14,
  TimeNow: 72.58333333333333,
  S: 62.666666666666664,
  N: 1.0,
  J: 35.333333333333336,
  St: 234.5,
  Nt: 4.333333333333333,
  Jt: 132.16666666666666,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 10,
  TimeNow: 103.0909090909091,
  S: 7.363636363636363,
  N: 0.2727272727272727,
  J: 91.36363636363636,
  St: 29.0,
  Nt: 1.1818181818181819,
  Jt: 340.8181818181818,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 9,
  TimeNow: 64.21428571428571,
  S: 41.785714285714285,
  N: 16.714285714285715,
  J: 40.357142857142854,
  St: 156.64285714285714,
  Nt: 63.285714285714285,
  Jt: 151.07142857142858,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 16,
  TimeNow: 98.52631578947368,
  S: 36.21052631578947,
  N: 6.473684210526316,
  J: 56.26315789473684,
  St: 136.78947368421052,
  Nt: 24.36842105263158,
  Jt: 209.8421052631579,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 11,
  TimeNow: 111.0,
  S: 4.25,
  N: 0.0,
  J: 94.75,
  St: 17.25,
  Nt: 0.0,
  Jt: 353.75,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 18,
  TimeNow: 135.57142857142858,
  S: 5.714285714285714,
  N: 0.0,
  J: 93.28571428571429,
  St: 22.857142857142858,
  Nt: 0.0,
  Jt: 348.14285714285717,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 19,
  TimeNow: 733.6,
  S: 30.4,
  N: 44.8,
  J: 23.6,
  St: 2304.4,
  Nt: 3387.4,
  Jt: 1800.2,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 15,
  TimeNow: 654.7777777777778,
  S: 16.22222222222222,
  N: 70.38888888888889,
  J: 12.11111111111111,
  St: 1256.0,
  Nt: 5302.166666666667,
  Jt: 933.8333333333334,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 13,
  TimeNow: 1073.7857142857142,
  S: 25.714285714285715,
  N: 43.5,
  J: 29.285714285714285,
  St: 1957.7857142857142,
  Nt: 3305.0,
  Jt: 2229.214285714286,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 17,
  TimeNow: 732.4444444444445,
  S: 22.444444444444443,
  N: 60.22222222222222,
  J: 15.88888888888889,
  St: 1723.2222222222222,
  Nt: 4546.777777777777,
  Jt: 1222.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 20,
  TimeNow: 1006.0,
  S: 27.5,
  N: 21.0,
  J: 50.25,
  St: 2097.4375,
  Nt: 1607.8125,
  Jt: 3786.75,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 12,
  TimeNow: 765.9230769230769,
  S: 20.923076923076923,
  N: 58.0,
  J: 19.692307692307693,
  St: 1601.4615384615386,
  Nt: 4384.846153846154,
  Jt: 1505.6923076923076,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 8,
  TimeNow: 395.09090909090907,
  S: 0.2727272727272727,
  N: 98.45454545454545,
  J: 0.09090909090909091,
  St: 29.09090909090909,
  Nt: 7445.454545454545,
  Jt: 17.454545454545453,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 14,
  TimeNow: 1087.1666666666667,
  S: 26.333333333333332,
  N: 38.416666666666664,
  J: 33.833333333333336,
  St: 1998.25,
  Nt: 2921.3333333333335,
  Jt: 2572.4166666666665,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 10,
  TimeNow: 571.7272727272727,
  S: 13.636363636363637,
  N: 77.54545454545455,
  J: 7.2727272727272725,
  St: 1070.5454545454545,
  Nt: 5837.090909090909,
  Jt: 584.3636363636364,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 9,
  TimeNow: 460.7142857142857,
  S: 5.857142857142857,
  N: 89.5,
  J: 3.2857142857142856,
  St: 474.7857142857143,
  Nt: 6759.857142857143,
  Jt: 257.35714285714283,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 16,
  TimeNow: 652.6315789473684,
  S: 24.57894736842105,
  N: 62.526315789473685,
  J: 11.263157894736842,
  St: 1885.6842105263158,
  Nt: 4730.105263157895,
  Jt: 876.2105263157895,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 11,
  TimeNow: 476.5833333333333,
  S: 11.583333333333334,
  N: 83.5,
  J: 3.75,
  St: 897.3333333333334,
  Nt: 6289.083333333333,
  Jt: 305.5833333333333,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 18,
  TimeNow: 708.125,
  S: 25.375,
  N: 54.0,
  J: 19.125,
  St: 1942.375,
  Nt: 4083.125,
  Jt: 1466.5,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 19,
  TimeNow: 425.2,
  S: 46.4,
  N: 21.4,
  J: 30.8,
  St: 2272.8,
  Nt: 1055.0,
  Jt: 1506.2,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 15,
  TimeNow: 676.7777777777778,
  S: 21.555555555555557,
  N: 8.166666666666666,
  J: 68.66666666666667,
  St: 1058.9444444444443,
  Nt: 421.3333333333333,
  Jt: 3356.722222222222,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 13,
  TimeNow: 627.0,
  S: 15.785714285714286,
  N: 14.071428571428571,
  J: 68.21428571428571,
  St: 796.9285714285714,
  Nt: 708.2142857142857,
  Jt: 3331.8571428571427,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 17,
  TimeNow: 736.4444444444445,
  S: 20.77777777777778,
  N: 8.555555555555555,
  J: 68.77777777777777,
  St: 1037.0,
  Nt: 438.1111111111111,
  Jt: 3361.8888888888887,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 20,
  TimeNow: 467.1875,
  S: 55.5,
  N: 7.25,
  J: 35.9375,
  St: 2706.25,
  Nt: 366.5625,
  Jt: 1762.3125,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 12,
  TimeNow: 512.7692307692307,
  S: 34.92307692307692,
  N: 14.307692307692308,
  J: 49.30769230769231,
  St: 1721.0,
  Nt: 709.6923076923077,
  Jt: 2404.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 14,
  TimeNow: 708.6666666666666,
  S: 24.083333333333332,
  N: 4.916666666666667,
  J: 69.33333333333333,
  St: 1188.3333333333333,
  Nt: 262.5,
  Jt: 3386.1666666666665,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 8,
  TimeNow: 497.2,
  S: 40.2,
  N: 11.4,
  J: 47.4,
  St: 1960.0,
  Nt: 556.0,
  Jt: 2317.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 10,
  TimeNow: 420.1818181818182,
  S: 50.81818181818182,
  N: 15.272727272727273,
  J: 32.54545454545455,
  St: 2477.7272727272725,
  Nt: 756.1818181818181,
  Jt: 1598.090909090909,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 9,
  TimeNow: 407.93333333333334,
  S: 51.6,
  N: 20.533333333333335,
  J: 26.533333333333335,
  St: 2516.0,
  Nt: 1012.9333333333333,
  Jt: 1303.4,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 16,
  TimeNow: 812.3157894736842,
  S: 19.36842105263158,
  N: 4.0,
  J: 74.89473684210526,
  St: 960.3684210526316,
  Nt: 223.78947368421052,
  Jt: 3652.842105263158,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 11,
  TimeNow: 401.5833333333333,
  S: 51.083333333333336,
  N: 25.083333333333332,
  J: 22.5,
  St: 2493.5833333333335,
  Nt: 1227.5833333333333,
  Jt: 1110.8333333333333,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 18,
  TimeNow: 500.0,
  S: 44.625,
  N: 14.625,
  J: 39.125,
  St: 2183.25,
  Nt: 727.25,
  Jt: 1926.5,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 19,
  TimeNow: 240.4,
  S: 8.2,
  N: 90.0,
  J: 0.0,
  St: 195.2,
  Nt: 2026.6,
  Jt: 16.4,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 15,
  TimeNow: 234.55555555555554,
  S: 12.38888888888889,
  N: 86.38888888888889,
  J: 0.05555555555555555,
  St: 286.5,
  Nt: 1948.0555555555557,
  Jt: 3.6666666666666665,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 13,
  TimeNow: 228.07142857142858,
  S: 6.571428571428571,
  N: 92.42857142857143,
  J: 0.0,
  St: 157.0,
  Nt: 2080.3571428571427,
  Jt: 1.1428571428571428,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 17,
  TimeNow: 258.1111111111111,
  S: 19.88888888888889,
  N: 77.0,
  J: 1.8888888888888888,
  St: 458.1111111111111,
  Nt: 1731.2222222222222,
  Jt: 49.22222222222222,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 20,
  TimeNow: 245.3125,
  S: 13.75,
  N: 85.1875,
  J: 0.0,
  St: 319.6875,
  Nt: 1918.1875,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 12,
  TimeNow: 227.92307692307693,
  S: 10.461538461538462,
  N: 88.46153846153847,
  J: 0.0,
  St: 241.0,
  Nt: 1995.4615384615386,
  Jt: 1.6923076923076923,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 8,
  TimeNow: 226.25,
  S: 7.5,
  N: 91.16666666666667,
  J: 0.16666666666666666,
  St: 175.66666666666666,
  Nt: 2058.5,
  Jt: 3.9166666666666665,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 14,
  TimeNow: 233.08333333333334,
  S: 10.416666666666666,
  N: 88.58333333333333,
  J: 0.0,
  St: 243.58333333333334,
  Nt: 1994.75,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 10,
  TimeNow: 230.0909090909091,
  S: 4.2727272727272725,
  N: 94.72727272727273,
  J: 0.0,
  St: 106.9090909090909,
  Nt: 2131.181818181818,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 9,
  TimeNow: 226.93333333333334,
  S: 4.8,
  N: 94.2,
  J: 0.0,
  St: 115.53333333333333,
  Nt: 2122.4666666666667,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 16,
  TimeNow: 240.52631578947367,
  S: 10.157894736842104,
  N: 88.15789473684211,
  J: 0.21052631578947367,
  St: 241.47368421052633,
  Nt: 1982.157894736842,
  Jt: 14.789473684210526,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 11,
  TimeNow: 224.0,
  S: 5.166666666666667,
  N: 93.83333333333333,
  J: 0.0,
  St: 128.75,
  Nt: 2109.8333333333335,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 18,
  TimeNow: 239.0,
  S: 10.714285714285714,
  N: 87.85714285714286,
  J: 0.0,
  St: 256.2857142857143,
  Nt: 1976.2857142857142,
  Jt: 5.571428571428571,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 19,
  TimeNow: 290.6,
  S: 14.2,
  N: 78.0,
  J: 6.2,
  St: 370.6,
  Nt: 1960.8,
  Jt: 160.6,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 15,
  TimeNow: 328.27777777777777,
  S: 30.61111111111111,
  N: 58.333333333333336,
  J: 9.722222222222221,
  St: 777.7777777777778,
  Nt: 1469.388888888889,
  Jt: 246.16666666666666,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 13,
  TimeNow: 274.14285714285717,
  S: 12.214285714285714,
  N: 82.21428571428571,
  J: 4.285714285714286,
  St: 317.14285714285717,
  Nt: 2058.8571428571427,
  Jt: 116.42857142857143,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 17,
  TimeNow: 677.3333333333334,
  S: 22.333333333333332,
  N: 2.4444444444444446,
  J: 73.55555555555556,
  St: 570.3333333333334,
  Nt: 74.22222222222223,
  Jt: 1848.2222222222222,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 20,
  TimeNow: 263.75,
  S: 24.6875,
  N: 68.3125,
  J: 5.6875,
  St: 628.3125,
  Nt: 1714.625,
  Jt: 150.0,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 12,
  TimeNow: 251.07692307692307,
  S: 7.769230769230769,
  N: 88.15384615384616,
  J: 2.923076923076923,
  St: 207.6153846153846,
  Nt: 2206.846153846154,
  Jt: 77.53846153846153,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 8,
  TimeNow: 234.33333333333334,
  S: 2.4444444444444446,
  N: 93.66666666666667,
  J: 2.7777777777777777,
  St: 71.11111111111111,
  Nt: 2345.6666666666665,
  Jt: 75.22222222222223,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 14,
  TimeNow: 273.09090909090907,
  S: 21.363636363636363,
  N: 74.27272727272727,
  J: 3.090909090909091,
  St: 545.6363636363636,
  Nt: 1864.2727272727273,
  Jt: 82.9090909090909,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 10,
  TimeNow: 229.36363636363637,
  S: 3.3636363636363638,
  N: 94.0,
  J: 1.6363636363636365,
  St: 97.81818181818181,
  Nt: 2351.818181818182,
  Jt: 42.36363636363637,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 9,
  TimeNow: 238.06666666666666,
  S: 2.4,
  N: 93.6,
  J: 2.8,
  St: 72.06666666666666,
  Nt: 2345.0666666666666,
  Jt: 74.86666666666666,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 16,
  TimeNow: 456.89473684210526,
  S: 21.63157894736842,
  N: 28.31578947368421,
  J: 48.578947368421055,
  St: 551.8947368421053,
  Nt: 719.9473684210526,
  Jt: 1221.3157894736842,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 11,
  TimeNow: 237.75,
  S: 7.416666666666667,
  N: 90.91666666666667,
  J: 0.6666666666666666,
  St: 197.5,
  Nt: 2276.75,
  Jt: 17.833333333333332,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 18,
  TimeNow: 431.14285714285717,
  S: 34.142857142857146,
  N: 37.57142857142857,
  J: 26.714285714285715,
  St: 866.7142857142857,
  Nt: 949.5714285714286,
  Jt: 676.7142857142857,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 19,
  TimeNow: 922.0,
  S: 34.8,
  N: 49.0,
  J: 14.2,
  St: 4677.6,
  Nt: 6509.2,
  Jt: 1973.2,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 15,
  TimeNow: 1484.111111111111,
  S: 24.22222222222222,
  N: 13.0,
  J: 61.22222222222222,
  St: 3270.5,
  Nt: 1770.0555555555557,
  Jt: 8119.444444444444,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 13,
  TimeNow: 1554.0,
  S: 19.615384615384617,
  N: 17.846153846153847,
  J: 60.61538461538461,
  St: 2650.3076923076924,
  Nt: 2434.3076923076924,
  Jt: 8075.384615384615,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 17,
  TimeNow: 1556.2222222222222,
  S: 16.333333333333332,
  N: 28.666666666666668,
  J: 53.666666666666664,
  St: 2201.0,
  Nt: 3819.4444444444443,
  Jt: 7139.555555555556,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 20,
  TimeNow: 997.8125,
  S: 29.0,
  N: 50.625,
  J: 19.0625,
  St: 3859.1875,
  Nt: 6724.3125,
  Jt: 2576.5,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 12,
  TimeNow: 1080.076923076923,
  S: 29.153846153846153,
  N: 44.69230769230769,
  J: 24.846153846153847,
  St: 3894.6923076923076,
  Nt: 5930.307692307692,
  Jt: 3335.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 8,
  TimeNow: 958.8333333333334,
  S: 31.333333333333332,
  N: 47.0,
  J: 20.333333333333332,
  St: 4174.0,
  Nt: 6223.833333333333,
  Jt: 2762.1666666666665,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 14,
  TimeNow: 1699.9166666666667,
  S: 21.833333333333332,
  N: 10.166666666666666,
  J: 66.41666666666667,
  St: 2927.8333333333335,
  Nt: 1417.9166666666667,
  Jt: 8814.25,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 10,
  TimeNow: 873.1,
  S: 43.1,
  N: 43.8,
  J: 11.5,
  St: 5738.9,
  Nt: 5819.9,
  Jt: 1601.2,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 9,
  TimeNow: 869.2857142857143,
  S: 39.785714285714285,
  N: 50.42857142857143,
  J: 8.571428571428571,
  St: 5293.0,
  Nt: 6702.214285714285,
  Jt: 1164.7857142857142,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 16,
  TimeNow: 1587.842105263158,
  S: 21.94736842105263,
  N: 27.263157894736842,
  J: 49.26315789473684,
  St: 2947.7368421052633,
  Nt: 3656.9473684210525,
  Jt: 6555.315789473684,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 11,
  TimeNow: 832.8333333333334,
  S: 32.25,
  N: 59.75,
  J: 6.666666666666667,
  St: 4303.166666666667,
  Nt: 7929.833333333333,
  Jt: 927.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 18,
  TimeNow: 1132.0,
  S: 25.375,
  N: 46.875,
  J: 26.5,
  St: 3388.875,
  Nt: 6235.75,
  Jt: 3535.375,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 19,
  TimeNow: 1070.0,
  S: 30.6,
  N: 40.0,
  J: 28.0,
  St: 2716.0,
  Nt: 3555.4,
  Jt: 2497.6,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 15,
  TimeNow: 1150.888888888889,
  S: 27.88888888888889,
  N: 48.44444444444444,
  J: 22.11111111111111,
  St: 2483.8333333333335,
  Nt: 4285.555555555556,
  Jt: 1999.611111111111,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 13,
  TimeNow: 1066.2857142857142,
  S: 22.5,
  N: 57.07142857142857,
  J: 18.928571428571427,
  St: 2017.4285714285713,
  Nt: 5044.5,
  Jt: 1707.0714285714287,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 17,
  TimeNow: 1433.6666666666667,
  S: 24.333333333333332,
  N: 27.444444444444443,
  J: 46.666666666666664,
  St: 2188.0,
  Nt: 2443.6666666666665,
  Jt: 4137.333333333333,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 20,
  TimeNow: 1248.5625,
  S: 29.0625,
  N: 26.9375,
  J: 42.625,
  St: 2590.125,
  Nt: 2399.9375,
  Jt: 3778.9375,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 12,
  TimeNow: 1198.2307692307693,
  S: 31.307692307692307,
  N: 46.53846153846154,
  J: 20.46153846153846,
  St: 2800.5384615384614,
  Nt: 4127.076923076923,
  Jt: 1841.3846153846155,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 14,
  TimeNow: 1153.1666666666667,
  S: 14.916666666666666,
  N: 67.0,
  J: 16.5,
  St: 1352.9166666666667,
  Nt: 5906.25,
  Jt: 1509.8333333333333,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 8,
  TimeNow: 587.7,
  S: 2.0,
  N: 94.0,
  J: 2.5,
  St: 213.9,
  Nt: 8284.8,
  Jt: 270.3,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 10,
  TimeNow: 698.4545454545455,
  S: 2.1818181818181817,
  N: 91.63636363636364,
  J: 4.7272727272727275,
  St: 230.9090909090909,
  Nt: 8073.090909090909,
  Jt: 465.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 9,
  TimeNow: 680.2857142857143,
  S: 1.2857142857142858,
  N: 93.14285714285714,
  J: 4.071428571428571,
  St: 166.64285714285714,
  Nt: 8193.285714285714,
  Jt: 409.07142857142856,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 16,
  TimeNow: 1168.0526315789473,
  S: 32.21052631578947,
  N: 38.473684210526315,
  J: 27.63157894736842,
  St: 2882.6315789473683,
  Nt: 3412.315789473684,
  Jt: 2474.0526315789475,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 11,
  TimeNow: 938.8333333333334,
  S: 10.0,
  N: 75.16666666666667,
  J: 13.416666666666666,
  St: 908.9166666666666,
  Nt: 6634.0,
  Jt: 1226.0833333333333,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 18,
  TimeNow: 1202.875,
  S: 21.625,
  N: 37.0,
  J: 40.125,
  St: 1921.0,
  Nt: 3287.125,
  Jt: 3560.875,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 19,
  TimeNow: 286.0,
  S: 14.4,
  N: 78.4,
  J: 5.8,
  St: 310.4,
  Nt: 1635.2,
  Jt: 127.4,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 15,
  TimeNow: 206.61111111111111,
  S: 5.777777777777778,
  N: 91.33333333333333,
  J: 1.3888888888888888,
  St: 133.5,
  Nt: 1909.8333333333333,
  Jt: 29.72222222222222,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 13,
  TimeNow: 200.57142857142858,
  S: 3.5714285714285716,
  N: 95.28571428571429,
  J: 0.0,
  St: 90.71428571428571,
  Nt: 1982.2857142857142,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 17,
  TimeNow: 280.6666666666667,
  S: 6.0,
  N: 86.0,
  J: 6.555555555555555,
  St: 135.0,
  Nt: 1795.5555555555557,
  Jt: 142.44444444444446,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 20,
  TimeNow: 214.0625,
  S: 2.1875,
  N: 96.75,
  J: 0.0,
  St: 50.0625,
  Nt: 2023.4375,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 12,
  TimeNow: 198.30769230769232,
  S: 1.6153846153846154,
  N: 97.3076923076923,
  J: 0.0,
  St: 42.30769230769231,
  Nt: 2031.1538461538462,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 8,
  TimeNow: 202.83333333333334,
  S: 1.75,
  N: 96.66666666666667,
  J: 0.0,
  St: 49.0,
  Nt: 2024.1666666666667,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 14,
  TimeNow: 214.41666666666666,
  S: 10.666666666666666,
  N: 87.5,
  J: 0.5,
  St: 230.25,
  Nt: 1831.8333333333333,
  Jt: 10.916666666666666,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 10,
  TimeNow: 198.9090909090909,
  S: 0.8181818181818182,
  N: 97.36363636363636,
  J: 0.0,
  St: 33.54545454545455,
  Nt: 2039.6363636363637,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 9,
  TimeNow: 199.35714285714286,
  S: 1.2857142857142858,
  N: 96.85714285714286,
  J: 0.0,
  St: 44.57142857142857,
  Nt: 2028.5,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 16,
  TimeNow: 225.61111111111111,
  S: 6.888888888888889,
  N: 88.77777777777777,
  J: 2.9444444444444446,
  St: 152.77777777777777,
  Nt: 1855.7777777777778,
  Jt: 64.44444444444444,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 11,
  TimeNow: 198.08333333333334,
  S: 0.3333333333333333,
  N: 98.5,
  J: 0.0,
  St: 15.0,
  Nt: 2058.5833333333335,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 18,
  TimeNow: 239.5,
  S: 5.5,
  N: 89.375,
  J: 3.375,
  St: 130.5,
  Nt: 1869.875,
  Jt: 72.625,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 19,
  TimeNow: 266.0,
  S: 49.0,
  N: 4.2,
  J: 45.6,
  St: 964.4,
  Nt: 86.6,
  Jt: 898.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 15,
  TimeNow: 333.6111111111111,
  S: 45.111111111111114,
  N: 9.833333333333334,
  J: 43.611111111111114,
  St: 889.7777777777778,
  Nt: 201.22222222222223,
  Jt: 858.3333333333334,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 13,
  TimeNow: 228.92857142857142,
  S: 62.5,
  N: 6.785714285714286,
  J: 29.285714285714285,
  St: 1229.2142857142858,
  Nt: 140.57142857142858,
  Jt: 579.5,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 17,
  TimeNow: 342.44444444444446,
  S: 41.77777777777778,
  N: 8.333333333333334,
  J: 48.44444444444444,
  St: 824.5555555555555,
  Nt: 172.55555555555554,
  Jt: 952.1111111111111,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 20,
  TimeNow: 337.4375,
  S: 20.8125,
  N: 2.375,
  J: 75.625,
  St: 415.4375,
  Nt: 48.75,
  Jt: 1484.8125,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 12,
  TimeNow: 187.0,
  S: 55.38461538461539,
  N: 17.615384615384617,
  J: 25.153846153846153,
  St: 1091.5384615384614,
  Nt: 354.3076923076923,
  Jt: 503.3076923076923,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 8,
  TimeNow: 96.33333333333333,
  S: 0.25,
  N: 98.66666666666667,
  J: 0.0,
  St: 6.0,
  Nt: 1942.1666666666667,
  Jt: 0.8333333333333334,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 14,
  TimeNow: 318.8333333333333,
  S: 49.583333333333336,
  N: 4.25,
  J: 44.916666666666664,
  St: 975.75,
  Nt: 89.16666666666667,
  Jt: 884.4166666666666,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 10,
  TimeNow: 242.9090909090909,
  S: 40.54545454545455,
  N: 29.272727272727273,
  J: 28.545454545454547,
  St: 799.6363636363636,
  Nt: 581.4545454545455,
  Jt: 568.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 9,
  TimeNow: 158.57142857142858,
  S: 13.571428571428571,
  N: 73.35714285714286,
  J: 12.071428571428571,
  St: 272.5,
  Nt: 1439.2857142857142,
  Jt: 237.21428571428572,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 16,
  TimeNow: 330.57894736842104,
  S: 47.26315789473684,
  N: 7.684210526315789,
  J: 43.63157894736842,
  St: 931.6842105263158,
  Nt: 158.78947368421052,
  Jt: 858.6842105263158,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 11,
  TimeNow: 169.75,
  S: 44.5,
  N: 38.833333333333336,
  J: 15.416666666666666,
  St: 876.9166666666666,
  Nt: 767.9166666666666,
  Jt: 304.5833333333333,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 18,
  TimeNow: 262.75,
  S: 32.0,
  N: 24.5,
  J: 42.25,
  St: 631.75,
  Nt: 487.875,
  Jt: 829.75,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 19,
  TimeNow: 642.4,
  S: 24.0,
  N: 0.0,
  J: 75.0,
  St: 1253.0,
  Nt: 0.0,
  Jt: 3846.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 15,
  TimeNow: 1028.388888888889,
  S: 4.222222222222222,
  N: 0.0,
  J: 94.77777777777777,
  St: 230.38888888888889,
  Nt: 0.0,
  Jt: 4868.611111111111,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 13,
  TimeNow: 481.57142857142856,
  S: 40.142857142857146,
  N: 9.071428571428571,
  J: 49.142857142857146,
  St: 2076.0714285714284,
  Nt: 489.07142857142856,
  Jt: 2533.8571428571427,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 17,
  TimeNow: 921.0,
  S: 1.1111111111111112,
  N: 0.0,
  J: 97.88888888888889,
  St: 61.55555555555556,
  Nt: 0.0,
  Jt: 5037.444444444444,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 20,
  TimeNow: 661.75,
  S: 10.9375,
  N: 0.0,
  J: 88.0,
  St: 576.9375,
  Nt: 0.0,
  Jt: 4522.0625,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 12,
  TimeNow: 474.5833333333333,
  S: 25.833333333333332,
  N: 13.166666666666666,
  J: 59.666666666666664,
  St: 1344.1666666666667,
  Nt: 697.1666666666666,
  Jt: 3057.6666666666665,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 14,
  TimeNow: 829.4166666666666,
  S: 14.083333333333334,
  N: 4.75,
  J: 79.91666666666667,
  St: 737.75,
  Nt: 250.66666666666666,
  Jt: 4110.583333333333,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 8,
  TimeNow: 305.54545454545456,
  S: 26.818181818181817,
  N: 59.09090909090909,
  J: 12.909090909090908,
  St: 1381.0,
  Nt: 3044.5454545454545,
  Jt: 673.4545454545455,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 10,
  TimeNow: 251.45454545454547,
  S: 13.181818181818182,
  N: 85.81818181818181,
  J: 0.0,
  St: 681.9090909090909,
  Nt: 4417.090909090909,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 9,
  TimeNow: 226.73333333333332,
  S: 4.4,
  N: 94.6,
  J: 0.0,
  St: 231.13333333333333,
  Nt: 4867.933333333333,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 16,
  TimeNow: 1006.7368421052631,
  S: 7.684210526315789,
  N: 0.0,
  J: 91.3157894736842,
  St: 397.0,
  Nt: 0.0,
  Jt: 4702.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 11,
  TimeNow: 479.6666666666667,
  S: 38.166666666666664,
  N: 10.25,
  J: 50.083333333333336,
  St: 1975.25,
  Nt: 544.0833333333334,
  Jt: 2579.6666666666665,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 18,
  TimeNow: 792.0,
  S: 2.375,
  N: 0.0,
  J: 96.625,
  St: 131.125,
  Nt: 0.0,
  Jt: 4967.875,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 19,
  TimeNow: 269.4,
  S: 14.8,
  N: 73.0,
  J: 11.0,
  St: 392.6,
  Nt: 1891.2,
  Jt: 292.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 15,
  TimeNow: 274.44444444444446,
  S: 30.22222222222222,
  N: 65.66666666666667,
  J: 2.888888888888889,
  St: 787.2777777777778,
  Nt: 1700.611111111111,
  Jt: 86.44444444444444,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 13,
  TimeNow: 239.5,
  S: 17.142857142857142,
  N: 78.5,
  J: 2.642857142857143,
  St: 455.57142857142856,
  Nt: 2037.9285714285713,
  Jt: 82.5,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 17,
  TimeNow: 264.0,
  S: 24.11111111111111,
  N: 70.0,
  J: 4.555555555555555,
  St: 630.8888888888889,
  Nt: 1817.2222222222222,
  Jt: 127.33333333333333,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 20,
  TimeNow: 253.8125,
  S: 19.375,
  N: 76.4375,
  J: 3.0625,
  St: 508.6875,
  Nt: 1981.5625,
  Jt: 84.625,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 12,
  TimeNow: 241.15384615384616,
  S: 18.384615384615383,
  N: 76.23076923076923,
  J: 4.153846153846154,
  St: 485.53846153846155,
  Nt: 1975.5384615384614,
  Jt: 113.76923076923077,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 8,
  TimeNow: 218.0,
  S: 11.818181818181818,
  N: 85.18181818181819,
  J: 1.9090909090909092,
  St: 318.8181818181818,
  Nt: 2204.0,
  Jt: 52.18181818181818,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 14,
  TimeNow: 255.5,
  S: 24.333333333333332,
  N: 70.66666666666667,
  J: 3.25,
  St: 640.6666666666666,
  Nt: 1834.25,
  Jt: 100.16666666666667,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 10,
  TimeNow: 297.6363636363636,
  S: 26.09090909090909,
  N: 65.9090909090909,
  J: 6.454545454545454,
  St: 689.6363636363636,
  Nt: 1714.4545454545455,
  Jt: 170.1818181818182,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 9,
  TimeNow: 244.42857142857142,
  S: 10.714285714285714,
  N: 85.0,
  J: 2.5,
  St: 289.64285714285717,
  Nt: 2203.9285714285716,
  Jt: 81.42857142857143,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 16,
  TimeNow: 256.2105263157895,
  S: 24.05263157894737,
  N: 71.3157894736842,
  J: 3.4210526315789473,
  St: 629.6315789473684,
  Nt: 1850.578947368421,
  Jt: 94.73684210526316,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 11,
  TimeNow: 273.8333333333333,
  S: 24.083333333333332,
  N: 68.41666666666667,
  J: 6.083333333333333,
  St: 634.8333333333334,
  Nt: 1775.3333333333333,
  Jt: 164.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 18,
  TimeNow: 252.625,
  S: 18.875,
  N: 73.0,
  J: 6.75,
  St: 499.75,
  Nt: 1889.5,
  Jt: 185.375,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 19,
  TimeNow: 513.2,
  S: 51.4,
  N: 24.2,
  J: 23.0,
  St: 3077.4,
  Nt: 1465.0,
  Jt: 1381.6,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 15,
  TimeNow: 523.6666666666666,
  S: 37.72222222222222,
  N: 32.333333333333336,
  J: 28.666666666666668,
  St: 2265.277777777778,
  Nt: 1940.3333333333333,
  Jt: 1718.388888888889,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 13,
  TimeNow: 411.2142857142857,
  S: 35.714285714285715,
  N: 56.785714285714285,
  J: 6.357142857142857,
  St: 2143.4285714285716,
  Nt: 3395.0,
  Jt: 385.57142857142856,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 17,
  TimeNow: 545.8888888888889,
  S: 56.888888888888886,
  N: 20.88888888888889,
  J: 20.666666666666668,
  St: 3400.5555555555557,
  Nt: 1271.3333333333333,
  Jt: 1252.111111111111,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 20,
  TimeNow: 570.5,
  S: 41.5625,
  N: 24.875,
  J: 32.125,
  St: 2493.375,
  Nt: 1504.4375,
  Jt: 1926.1875,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 12,
  TimeNow: 526.8461538461538,
  S: 35.07692307692308,
  N: 37.76923076923077,
  J: 25.615384615384617,
  St: 2111.153846153846,
  Nt: 2268.5384615384614,
  Jt: 1544.3076923076924,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 8,
  TimeNow: 407.90909090909093,
  S: 36.36363636363637,
  N: 56.54545454545455,
  J: 5.636363636363637,
  St: 2186.090909090909,
  Nt: 3387.5454545454545,
  Jt: 350.3636363636364,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 14,
  TimeNow: 409.0,
  S: 42.583333333333336,
  N: 54.166666666666664,
  J: 2.0833333333333335,
  St: 2550.5833333333335,
  Nt: 3236.8333333333335,
  Jt: 136.58333333333334,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 10,
  TimeNow: 503.8,
  S: 39.1,
  N: 43.1,
  J: 16.3,
  St: 2350.5,
  Nt: 2587.5,
  Jt: 986.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 9,
  TimeNow: 437.7142857142857,
  S: 43.07142857142857,
  N: 46.92857142857143,
  J: 8.5,
  St: 2580.1428571428573,
  Nt: 2812.214285714286,
  Jt: 531.6428571428571,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 16,
  TimeNow: 597.7894736842105,
  S: 40.473684210526315,
  N: 22.94736842105263,
  J: 35.21052631578947,
  St: 2428.1052631578946,
  Nt: 1386.8947368421052,
  Jt: 2109.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 11,
  TimeNow: 420.0,
  S: 52.75,
  N: 44.333333333333336,
  J: 1.6666666666666667,
  St: 3156.5833333333335,
  Nt: 2656.0,
  Jt: 111.41666666666667,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 18,
  TimeNow: 442.0,
  S: 42.375,
  N: 49.375,
  J: 7.0,
  St: 2542.375,
  Nt: 2957.125,
  Jt: 424.5,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 19,
  TimeNow: 525.0,
  S: 45.6,
  N: 4.6,
  J: 48.4,
  St: 1684.4,
  Nt: 184.0,
  Jt: 1792.6,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 15,
  TimeNow: 466.11764705882354,
  S: 31.11764705882353,
  N: 42.529411764705884,
  J: 25.11764705882353,
  St: 1149.8823529411766,
  Nt: 1578.9411764705883,
  Jt: 932.1764705882352,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 13,
  TimeNow: 732.2857142857143,
  S: 42.92857142857143,
  N: 6.071428571428571,
  J: 49.357142857142854,
  St: 1595.357142857143,
  Nt: 243.35714285714286,
  Jt: 1822.2857142857142,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 17,
  TimeNow: 518.8888888888889,
  S: 41.333333333333336,
  N: 24.22222222222222,
  J: 32.888888888888886,
  St: 1533.6666666666667,
  Nt: 906.3333333333334,
  Jt: 1221.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 20,
  TimeNow: 533.625,
  S: 28.8125,
  N: 17.75,
  J: 52.0,
  St: 1070.0625,
  Nt: 669.1875,
  Jt: 1921.75,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 12,
  TimeNow: 520.9230769230769,
  S: 41.61538461538461,
  N: 16.307692307692307,
  J: 40.46153846153846,
  St: 1539.8461538461538,
  Nt: 620.7692307692307,
  Jt: 1500.3846153846155,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 8,
  TimeNow: 199.0,
  S: 0.25,
  N: 98.66666666666667,
  J: 0.08333333333333333,
  St: 10.75,
  Nt: 3645.75,
  Jt: 4.5,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 14,
  TimeNow: 707.8333333333334,
  S: 39.333333333333336,
  N: 9.0,
  J: 50.416666666666664,
  St: 1455.5,
  Nt: 342.1666666666667,
  Jt: 1863.3333333333333,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 10,
  TimeNow: 352.6363636363636,
  S: 27.636363636363637,
  N: 55.45454545454545,
  J: 15.545454545454545,
  St: 1028.0,
  Nt: 2048.818181818182,
  Jt: 584.1818181818181,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 9,
  TimeNow: 261.4,
  S: 10.466666666666667,
  N: 82.0,
  J: 6.533333333333333,
  St: 391.6666666666667,
  Nt: 3029.133333333333,
  Jt: 240.2,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 16,
  TimeNow: 469.36842105263156,
  S: 46.526315789473685,
  N: 28.526315789473685,
  J: 23.42105263157895,
  St: 1727.4736842105262,
  Nt: 1067.5263157894738,
  Jt: 866.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 11,
  TimeNow: 273.0,
  S: 24.25,
  N: 66.25,
  J: 8.083333333333334,
  St: 909.5833333333334,
  Nt: 2446.0833333333335,
  Jt: 305.3333333333333,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 18,
  TimeNow: 482.5,
  S: 41.25,
  N: 17.625,
  J: 39.5,
  St: 1531.0,
  Nt: 664.375,
  Jt: 1465.625,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 19,
  TimeNow: 625.4,
  S: 7.8,
  N: 86.2,
  J: 4.6,
  St: 867.6,
  Nt: 9263.0,
  Jt: 535.4,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 15,
  TimeNow: 701.3333333333334,
  S: 4.444444444444445,
  N: 86.61111111111111,
  J: 7.277777777777778,
  St: 542.8333333333334,
  Nt: 9284.777777777777,
  Jt: 838.6111111111111,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 13,
  TimeNow: 611.2857142857143,
  S: 2.7857142857142856,
  N: 92.07142857142857,
  J: 3.7142857142857144,
  St: 345.0,
  Nt: 9862.92857142857,
  Jt: 458.14285714285717,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 17,
  TimeNow: 868.25,
  S: 5.125,
  N: 80.0,
  J: 13.25,
  St: 598.0,
  Nt: 8587.875,
  Jt: 1480.5,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 20,
  TimeNow: 592.25,
  S: 9.875,
  N: 87.6875,
  J: 1.1875,
  St: 1112.25,
  Nt: 9405.375,
  Jt: 148.5,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 12,
  TimeNow: 627.2307692307693,
  S: 2.076923076923077,
  N: 93.07692307692308,
  J: 3.5384615384615383,
  St: 274.0,
  Nt: 9960.615384615385,
  Jt: 431.38461538461536,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 8,
  TimeNow: 536.1666666666666,
  S: 2.25,
  N: 96.58333333333333,
  J: 0.16666666666666666,
  St: 266.5833333333333,
  Nt: 10375.916666666666,
  Jt: 23.5,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 14,
  TimeNow: 673.3333333333334,
  S: 6.333333333333333,
  N: 86.16666666666667,
  J: 6.083333333333333,
  St: 728.0833333333334,
  Nt: 9243.5,
  Jt: 694.6666666666666,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 10,
  TimeNow: 761.1818181818181,
  S: 3.8181818181818183,
  N: 86.27272727272727,
  J: 8.272727272727273,
  St: 448.45454545454544,
  Nt: 9269.818181818182,
  Jt: 947.9090909090909,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 9,
  TimeNow: 554.9333333333333,
  S: 4.2,
  N: 93.8,
  J: 0.8666666666666667,
  St: 500.8666666666667,
  Nt: 10051.6,
  Jt: 113.53333333333333,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 16,
  TimeNow: 798.3684210526316,
  S: 7.2105263157894735,
  N: 81.10526315789474,
  J: 10.052631578947368,
  St: 813.2105263157895,
  Nt: 8716.315789473685,
  Jt: 1136.5263157894738,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 11,
  TimeNow: 795.9,
  S: 2.9,
  N: 85.8,
  J: 9.8,
  St: 356.1,
  Nt: 9213.2,
  Jt: 1097.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 18,
  TimeNow: 639.75,
  S: 7.125,
  N: 85.125,
  J: 6.0,
  St: 819.25,
  Nt: 9144.625,
  Jt: 702.375,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 19,
  TimeNow: 465.2,
  S: 20.6,
  N: 72.0,
  J: 6.2,
  St: 1022.6,
  Nt: 3515.2,
  Jt: 320.2,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 15,
  TimeNow: 422.77777777777777,
  S: 24.0,
  N: 74.05555555555556,
  J: 0.6666666666666666,
  St: 1187.0555555555557,
  Nt: 3624.0,
  Jt: 47.27777777777778,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 13,
  TimeNow: 476.92857142857144,
  S: 11.928571428571429,
  N: 78.71428571428571,
  J: 8.142857142857142,
  St: 598.9285714285714,
  Nt: 3850.1428571428573,
  Jt: 408.7142857142857,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 17,
  TimeNow: 416.8888888888889,
  S: 10.333333333333334,
  N: 85.44444444444444,
  J: 2.5555555555555554,
  St: 527.8888888888889,
  Nt: 4185.777777777777,
  Jt: 144.55555555555554,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 20,
  TimeNow: 433.375,
  S: 6.0625,
  N: 90.25,
  J: 2.25,
  St: 320.8125,
  Nt: 4417.5,
  Jt: 119.8125,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 12,
  TimeNow: 446.61538461538464,
  S: 10.307692307692308,
  N: 83.6923076923077,
  J: 4.6923076923076925,
  St: 521.7692307692307,
  Nt: 4087.230769230769,
  Jt: 248.69230769230768,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 8,
  TimeNow: 436.0,
  S: 5.75,
  N: 88.83333333333333,
  J: 4.0,
  St: 301.5833333333333,
  Nt: 4330.75,
  Jt: 224.66666666666666,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 14,
  TimeNow: 469.3636363636364,
  S: 21.454545454545453,
  N: 69.9090909090909,
  J: 7.545454545454546,
  St: 1063.2727272727273,
  Nt: 3416.2727272727275,
  Jt: 377.90909090909093,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 10,
  TimeNow: 404.27272727272725,
  S: 14.272727272727273,
  N: 84.54545454545455,
  J: 0.0,
  St: 715.9090909090909,
  Nt: 4136.454545454545,
  Jt: 5.090909090909091,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 9,
  TimeNow: 404.06666666666666,
  S: 8.133333333333333,
  N: 88.93333333333334,
  J: 1.6,
  St: 422.8,
  Nt: 4343.466666666666,
  Jt: 90.8,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 16,
  TimeNow: 408.36842105263156,
  S: 19.05263157894737,
  N: 79.52631578947368,
  J: 0.0,
  St: 948.6842105263158,
  Nt: 3889.2631578947367,
  Jt: 20.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 11,
  TimeNow: 416.4166666666667,
  S: 16.416666666666668,
  N: 78.25,
  J: 3.8333333333333335,
  St: 823.5,
  Nt: 3826.6666666666665,
  Jt: 207.58333333333334,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 18,
  TimeNow: 409.5,
  S: 15.375,
  N: 83.5,
  J: 0.125,
  St: 763.125,
  Nt: 4087.375,
  Jt: 7.5,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 19,
  TimeNow: 132.4,
  S: 3.2,
  N: 94.4,
  J: 0.8,
  St: 44.0,
  Nt: 1192.6,
  Jt: 16.4,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 15,
  TimeNow: 133.66666666666666,
  S: 1.3333333333333333,
  N: 97.44444444444444,
  J: 0.1111111111111111,
  St: 24.666666666666668,
  Nt: 1226.5555555555557,
  Jt: 1.7777777777777777,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 13,
  TimeNow: 131.07142857142858,
  S: 1.1428571428571428,
  N: 97.57142857142857,
  J: 0.07142857142857142,
  St: 20.428571428571427,
  Nt: 1231.4285714285713,
  Jt: 1.1428571428571428,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 17,
  TimeNow: 152.88888888888889,
  S: 20.555555555555557,
  N: 75.88888888888889,
  J: 2.111111111111111,
  St: 261.44444444444446,
  Nt: 960.8888888888889,
  Jt: 30.666666666666668,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 20,
  TimeNow: 129.75,
  S: 2.5,
  N: 96.125,
  J: 0.0,
  St: 40.0,
  Nt: 1213.0,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 12,
  TimeNow: 129.30769230769232,
  S: 2.8461538461538463,
  N: 96.07692307692308,
  J: 0.07692307692307693,
  St: 44.38461538461539,
  Nt: 1206.923076923077,
  Jt: 1.6923076923076923,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 8,
  TimeNow: 131.91666666666666,
  S: 0.9166666666666666,
  N: 98.08333333333333,
  J: 0.0,
  St: 15.333333333333334,
  Nt: 1237.6666666666667,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 14,
  TimeNow: 133.25,
  S: 3.1666666666666665,
  N: 95.83333333333333,
  J: 0.0,
  St: 48.083333333333336,
  Nt: 1204.9166666666667,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 10,
  TimeNow: 138.36363636363637,
  S: 1.5454545454545454,
  N: 97.45454545454545,
  J: 0.0,
  St: 22.727272727272727,
  Nt: 1230.2727272727273,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 9,
  TimeNow: 134.4,
  S: 0.3333333333333333,
  N: 98.66666666666667,
  J: 0.0,
  St: 6.066666666666666,
  Nt: 1246.9333333333334,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 16,
  TimeNow: 136.05263157894737,
  S: 7.2105263157894735,
  N: 90.89473684210526,
  J: 0.5263157894736842,
  St: 98.26315789473684,
  Nt: 1144.1052631578948,
  Jt: 10.631578947368421,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 11,
  TimeNow: 131.66666666666666,
  S: 0.75,
  N: 98.25,
  J: 0.0,
  St: 13.333333333333334,
  Nt: 1239.6666666666667,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 18,
  TimeNow: 134.0,
  S: 5.0,
  N: 94.0,
  J: 0.0,
  St: 69.75,
  Nt: 1183.25,
  Jt: 0.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 19,
  TimeNow: 357.4,
  S: 14.0,
  N: 80.8,
  J: 3.8,
  St: 458.4,
  Nt: 2558.0,
  Jt: 131.6,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 15,
  TimeNow: 324.94444444444446,
  S: 4.111111111111111,
  N: 92.22222222222223,
  J: 2.2777777777777777,
  St: 141.22222222222223,
  Nt: 2925.5555555555557,
  Jt: 81.22222222222223,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 13,
  TimeNow: 329.2857142857143,
  S: 3.0714285714285716,
  N: 94.07142857142857,
  J: 1.5,
  St: 112.42857142857143,
  Nt: 2982.1428571428573,
  Jt: 53.42857142857143,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 17,
  TimeNow: 333.3333333333333,
  S: 12.11111111111111,
  N: 84.77777777777777,
  J: 1.8888888888888888,
  St: 397.8888888888889,
  Nt: 2681.0,
  Jt: 69.11111111111111,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 20,
  TimeNow: 410.6875,
  S: 16.625,
  N: 76.5,
  J: 5.375,
  St: 538.0,
  Nt: 2425.625,
  Jt: 184.375,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 12,
  TimeNow: 310.2307692307692,
  S: 4.076923076923077,
  N: 93.46153846153847,
  J: 1.2307692307692308,
  St: 140.3846153846154,
  Nt: 2963.6153846153848,
  Jt: 44.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 8,
  TimeNow: 282.72727272727275,
  S: 1.0909090909090908,
  N: 96.72727272727273,
  J: 1.0909090909090908,
  St: 42.45454545454545,
  Nt: 3062.181818181818,
  Jt: 43.36363636363637,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 14,
  TimeNow: 308.5,
  S: 2.0,
  N: 95.91666666666667,
  J: 1.0,
  St: 73.16666666666667,
  Nt: 3039.0833333333335,
  Jt: 35.75,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 10,
  TimeNow: 295.8181818181818,
  S: 2.0,
  N: 96.0909090909091,
  J: 0.8181818181818182,
  St: 77.27272727272727,
  Nt: 3043.2727272727275,
  Jt: 27.454545454545453,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 9,
  TimeNow: 291.73333333333335,
  S: 0.6666666666666666,
  N: 96.53333333333333,
  J: 1.6,
  St: 29.6,
  Nt: 3061.2,
  Jt: 57.2,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 16,
  TimeNow: 318.8421052631579,
  S: 5.894736842105263,
  N: 90.89473684210526,
  J: 2.0,
  St: 197.05263157894737,
  Nt: 2880.842105263158,
  Jt: 70.10526315789474,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 11,
  TimeNow: 312.75,
  S: 1.3333333333333333,
  N: 94.41666666666667,
  J: 3.0833333333333335,
  St: 51.666666666666664,
  Nt: 2987.75,
  Jt: 108.58333333333333,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 18,
  TimeNow: 336.125,
  S: 9.125,
  N: 85.875,
  J: 3.5,
  St: 305.875,
  Nt: 2722.75,
  Jt: 119.375,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 19,
  TimeNow: 377.4,
  S: 12.2,
  N: 86.4,
  J: 0.4,
  St: 1177.4,
  Nt: 8082.6,
  Jt: 38.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 15,
  TimeNow: 494.8888888888889,
  S: 11.166666666666666,
  N: 71.94444444444444,
  J: 15.555555555555555,
  St: 1096.1666666666667,
  Nt: 6724.555555555556,
  Jt: 1477.2777777777778,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 13,
  TimeNow: 392.5,
  S: 8.785714285714286,
  N: 87.92857142857143,
  J: 2.2142857142857144,
  St: 865.6428571428571,
  Nt: 8218.0,
  Jt: 214.35714285714286,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 17,
  TimeNow: 405.1111111111111,
  S: 15.444444444444445,
  N: 74.66666666666667,
  J: 8.444444444444445,
  St: 1474.0,
  Nt: 6979.222222222223,
  Jt: 844.7777777777778,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 20,
  TimeNow: 357.8125,
  S: 0.375,
  N: 98.625,
  J: 0.0,
  St: 40.4375,
  Nt: 9257.5625,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 12,
  TimeNow: 371.84615384615387,
  S: 6.0,
  N: 91.38461538461539,
  J: 1.5384615384615385,
  St: 590.4615384615385,
  Nt: 8556.538461538461,
  Jt: 151.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 8,
  TimeNow: 343.8333333333333,
  S: 2.0,
  N: 97.0,
  J: 0.0,
  St: 189.0,
  Nt: 9109.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 14,
  TimeNow: 479.0,
  S: 12.090909090909092,
  N: 75.45454545454545,
  J: 10.818181818181818,
  St: 1166.8181818181818,
  Nt: 7077.818181818182,
  Jt: 1053.3636363636363,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 10,
  TimeNow: 542.3636363636364,
  S: 10.090909090909092,
  N: 71.0,
  J: 17.454545454545453,
  St: 984.3636363636364,
  Nt: 6653.727272727273,
  Jt: 1659.909090909091,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 9,
  TimeNow: 403.3333333333333,
  S: 11.266666666666667,
  N: 82.93333333333334,
  J: 4.466666666666667,
  St: 1089.4,
  Nt: 7777.0,
  Jt: 431.6,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 16,
  TimeNow: 418.3157894736842,
  S: 10.157894736842104,
  N: 77.6842105263158,
  J: 10.68421052631579,
  St: 983.2631578947369,
  Nt: 7272.736842105263,
  Jt: 1042.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 11,
  TimeNow: 451.0833333333333,
  S: 9.916666666666666,
  N: 79.16666666666667,
  J: 9.583333333333334,
  St: 975.8333333333334,
  Nt: 7410.083333333333,
  Jt: 912.0833333333334,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 18,
  TimeNow: 364.125,
  S: 3.875,
  N: 94.75,
  J: 0.25,
  St: 375.125,
  Nt: 8889.625,
  Jt: 33.25,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 19,
  TimeNow: 287.0,
  S: 0.4,
  N: 98.0,
  J: 0.6,
  St: 5.6,
  Nt: 1210.0,
  Jt: 8.4,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 15,
  TimeNow: 287.0,
  S: 0.8333333333333334,
  N: 98.0,
  J: 0.16666666666666666,
  St: 11.666666666666666,
  Nt: 1210.0,
  Jt: 2.3333333333333335,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 13,
  TimeNow: 287.0,
  S: 0.6428571428571429,
  N: 98.28571428571429,
  J: 0.07142857142857142,
  St: 9.0,
  Nt: 1214.0,
  Jt: 1.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 17,
  TimeNow: 287.0,
  S: 0.8888888888888888,
  N: 98.11111111111111,
  J: 0.0,
  St: 12.444444444444445,
  Nt: 1211.5555555555557,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 20,
  TimeNow: 287.0,
  S: 0.5625,
  N: 98.0,
  J: 0.4375,
  St: 7.875,
  Nt: 1210.0,
  Jt: 6.125,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 12,
  TimeNow: 287.0,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 14.0,
  Nt: 1210.0,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 8,
  TimeNow: 325.0,
  S: 0.9166666666666666,
  N: 98.08333333333333,
  J: 0.0,
  St: 12.833333333333334,
  Nt: 1211.1666666666667,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 14,
  TimeNow: 287.0,
  S: 0.5833333333333334,
  N: 98.33333333333333,
  J: 0.08333333333333333,
  St: 8.166666666666666,
  Nt: 1214.6666666666667,
  Jt: 1.1666666666666667,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 10,
  TimeNow: 323.0,
  S: 0.8181818181818182,
  N: 98.0909090909091,
  J: 0.09090909090909091,
  St: 11.454545454545455,
  Nt: 1211.2727272727273,
  Jt: 1.2727272727272727,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 9,
  TimeNow: 325.0,
  S: 0.6,
  N: 98.4,
  J: 0.0,
  St: 8.4,
  Nt: 1215.6,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 16,
  TimeNow: 287.0,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 14.0,
  Nt: 1210.0,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 11,
  TimeNow: 297.25,
  S: 0.9166666666666666,
  N: 98.0,
  J: 0.08333333333333333,
  St: 12.833333333333334,
  Nt: 1210.0,
  Jt: 1.1666666666666667,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 18,
  TimeNow: 287.0,
  S: 0.875,
  N: 98.0,
  J: 0.125,
  St: 12.25,
  Nt: 1210.0,
  Jt: 1.75,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 19,
  TimeNow: 660.2,
  S: 35.2,
  N: 34.6,
  J: 28.6,
  St: 2208.8,
  Nt: 2166.8,
  Jt: 1799.4,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 15,
  TimeNow: 578.6666666666666,
  S: 19.11111111111111,
  N: 65.0,
  J: 14.666666666666666,
  St: 1203.6666666666667,
  Nt: 4038.5,
  Jt: 932.8333333333334,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 13,
  TimeNow: 1005.6428571428571,
  S: 28.857142857142858,
  N: 34.5,
  J: 35.214285714285715,
  St: 1810.9285714285713,
  Nt: 2160.0,
  Jt: 2204.0714285714284,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 17,
  TimeNow: 655.8888888888889,
  S: 26.444444444444443,
  N: 52.888888888888886,
  J: 19.444444444444443,
  St: 1661.3333333333333,
  Nt: 3292.6666666666665,
  Jt: 1221.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 20,
  TimeNow: 939.125,
  S: 28.5625,
  N: 12.5625,
  J: 57.6875,
  St: 1788.75,
  Nt: 798.25,
  Jt: 3588.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 12,
  TimeNow: 696.9230769230769,
  S: 25.153846153846153,
  N: 49.30769230769231,
  J: 23.846153846153847,
  St: 1586.076923076923,
  Nt: 3083.3076923076924,
  Jt: 1505.6153846153845,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 8,
  TimeNow: 326.4166666666667,
  S: 0.08333333333333333,
  N: 98.83333333333333,
  J: 0.0,
  St: 10.75,
  Nt: 6159.75,
  Jt: 4.5,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 14,
  TimeNow: 1021.5,
  S: 29.833333333333332,
  N: 28.0,
  J: 40.666666666666664,
  St: 1865.8333333333333,
  Nt: 1763.3333333333333,
  Jt: 2545.8333333333335,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 10,
  TimeNow: 501.45454545454544,
  S: 16.636363636363637,
  N: 72.9090909090909,
  J: 9.090909090909092,
  St: 1057.090909090909,
  Nt: 4533.545454545455,
  Jt: 584.3636363636364,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 9,
  TimeNow: 389.0,
  S: 6.066666666666666,
  N: 89.13333333333334,
  J: 3.533333333333333,
  St: 396.73333333333335,
  Nt: 5538.066666666667,
  Jt: 240.2,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 16,
  TimeNow: 577.7894736842105,
  S: 29.42105263157895,
  N: 55.21052631578947,
  J: 13.68421052631579,
  St: 1853.4736842105262,
  Nt: 3446.315789473684,
  Jt: 875.2105263157895,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 11,
  TimeNow: 407.3333333333333,
  S: 14.25,
  N: 79.58333333333333,
  J: 4.75,
  St: 915.4166666666666,
  Nt: 4954.25,
  Jt: 305.3333333333333,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 18,
  TimeNow: 635.25,
  S: 29.5,
  N: 45.875,
  J: 23.125,
  St: 1858.0,
  Nt: 2851.5,
  Jt: 1465.5,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 19,
  TimeNow: 178.2,
  S: 36.4,
  N: 5.4,
  J: 57.2,
  St: 243.0,
  Nt: 36.6,
  Jt: 376.4,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 15,
  TimeNow: 137.22222222222223,
  S: 66.83333333333333,
  N: 4.0,
  J: 28.166666666666668,
  St: 442.6666666666667,
  Nt: 27.0,
  Jt: 186.38888888888889,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 13,
  TimeNow: 133.14285714285714,
  S: 69.78571428571429,
  N: 1.7142857142857142,
  J: 27.428571428571427,
  St: 462.57142857142856,
  Nt: 11.428571428571429,
  Jt: 182.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 17,
  TimeNow: 126.33333333333333,
  S: 68.33333333333333,
  N: 6.777777777777778,
  J: 23.88888888888889,
  St: 452.1111111111111,
  Nt: 46.111111111111114,
  Jt: 157.77777777777777,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 20,
  TimeNow: 135.75,
  S: 51.625,
  N: 7.6875,
  J: 39.0,
  St: 343.9375,
  Nt: 51.8125,
  Jt: 260.25,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 12,
  TimeNow: 143.30769230769232,
  S: 51.0,
  N: 3.076923076923077,
  J: 44.84615384615385,
  St: 338.7692307692308,
  Nt: 21.076923076923077,
  Jt: 296.15384615384613,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 14,
  TimeNow: 126.41666666666667,
  S: 76.08333333333333,
  N: 3.25,
  J: 19.5,
  St: 502.9166666666667,
  Nt: 22.75,
  Jt: 130.41666666666666,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 8,
  TimeNow: 116.27272727272727,
  S: 35.54545454545455,
  N: 39.63636363636363,
  J: 23.727272727272727,
  St: 236.54545454545453,
  Nt: 263.0,
  Jt: 156.63636363636363,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 10,
  TimeNow: 141.8181818181818,
  S: 46.09090909090909,
  N: 1.4545454545454546,
  J: 51.36363636363637,
  St: 307.72727272727275,
  Nt: 10.363636363636363,
  Jt: 337.90909090909093,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 9,
  TimeNow: 110.46666666666667,
  S: 58.46666666666667,
  N: 16.266666666666666,
  J: 23.866666666666667,
  St: 387.46666666666664,
  Nt: 109.93333333333334,
  Jt: 158.6,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 16,
  TimeNow: 136.05263157894737,
  S: 57.526315789473685,
  N: 9.842105263157896,
  J: 31.473684210526315,
  St: 381.5263157894737,
  Nt: 66.47368421052632,
  Jt: 208.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 11,
  TimeNow: 140.0909090909091,
  S: 39.90909090909091,
  N: 5.454545454545454,
  J: 53.63636363636363,
  St: 266.6363636363636,
  Nt: 36.36363636363637,
  Jt: 353.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 18,
  TimeNow: 158.25,
  S: 42.5,
  N: 3.75,
  J: 52.5,
  St: 284.25,
  Nt: 25.0,
  Jt: 346.75,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 19,
  TimeNow: 458.2,
  S: 21.6,
  N: 61.0,
  J: 16.2,
  St: 1218.6,
  Nt: 3380.4,
  Jt: 903.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 15,
  TimeNow: 1193.7222222222222,
  S: 7.111111111111111,
  N: 25.0,
  J: 66.22222222222223,
  St: 422.8888888888889,
  Nt: 1402.0,
  Jt: 3677.1111111111113,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 13,
  TimeNow: 389.0,
  S: 35.07142857142857,
  N: 57.0,
  J: 6.714285714285714,
  St: 1954.7142857142858,
  Nt: 3167.5,
  Jt: 379.7857142857143,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 17,
  TimeNow: 1687.3333333333333,
  S: 5.0,
  N: 28.555555555555557,
  J: 64.66666666666667,
  St: 309.8888888888889,
  Nt: 1608.7777777777778,
  Jt: 3583.3333333333335,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 20,
  TimeNow: 319.0,
  S: 19.0,
  N: 70.0,
  J: 9.875,
  St: 1070.0,
  Nt: 3880.625,
  Jt: 551.375,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 12,
  TimeNow: 328.6923076923077,
  S: 25.46153846153846,
  N: 72.61538461538461,
  J: 0.7692307692307693,
  St: 1432.3846153846155,
  Nt: 4021.0,
  Jt: 48.61538461538461,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 8,
  TimeNow: 608.6666666666666,
  S: 31.5,
  N: 31.75,
  J: 35.333333333333336,
  St: 1764.1666666666667,
  Nt: 1769.4166666666667,
  Jt: 1968.4166666666667,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 14,
  TimeNow: 588.5,
  S: 19.166666666666668,
  N: 43.833333333333336,
  J: 35.416666666666664,
  St: 1090.8333333333333,
  Nt: 2437.4166666666665,
  Jt: 1973.75,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 10,
  TimeNow: 268.54545454545456,
  S: 2.090909090909091,
  N: 96.9090909090909,
  J: 0.0,
  St: 123.81818181818181,
  Nt: 5378.181818181818,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 9,
  TimeNow: 273.73333333333335,
  S: 2.2,
  N: 96.8,
  J: 0.0,
  St: 133.13333333333333,
  Nt: 5368.866666666667,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 16,
  TimeNow: 1450.5263157894738,
  S: 7.315789473684211,
  N: 22.736842105263158,
  J: 68.3157894736842,
  St: 432.1578947368421,
  Nt: 1285.2631578947369,
  Jt: 3784.5789473684213,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 11,
  TimeNow: 276.3333333333333,
  S: 1.0833333333333333,
  N: 97.91666666666667,
  J: 0.0,
  St: 67.08333333333333,
  Nt: 5434.916666666667,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 18,
  TimeNow: 835.0,
  S: 6.625,
  N: 38.75,
  J: 53.25,
  St: 382.875,
  Nt: 2160.625,
  Jt: 2958.5,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 19,
  TimeNow: 784.6,
  S: 3.8,
  N: 94.0,
  J: 1.2,
  St: 808.4,
  Nt: 17342.0,
  Jt: 235.6,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 15,
  TimeNow: 921.8333333333334,
  S: 4.777777777777778,
  N: 85.94444444444444,
  J: 7.833333333333333,
  St: 943.0,
  Nt: 15895.0,
  Jt: 1548.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 13,
  TimeNow: 784.8571428571429,
  S: 3.2142857142857144,
  N: 92.42857142857143,
  J: 3.0,
  St: 678.0,
  Nt: 17094.928571428572,
  Jt: 613.0714285714286,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 17,
  TimeNow: 991.2222222222222,
  S: 5.555555555555555,
  N: 83.33333333333333,
  J: 9.555555555555555,
  St: 1125.5555555555557,
  Nt: 15396.333333333334,
  Jt: 1864.111111111111,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 20,
  TimeNow: 767.0,
  S: 5.625,
  N: 93.1875,
  J: 0.125,
  St: 1115.6875,
  Nt: 17229.75,
  Jt: 40.5625,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 12,
  TimeNow: 710.8461538461538,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 27.153846153846153,
  Nt: 18358.846153846152,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 8,
  TimeNow: 672.5454545454545,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 14,
  TimeNow: 764.8333333333334,
  S: 4.166666666666667,
  N: 93.5,
  J: 1.0833333333333333,
  St: 829.0833333333334,
  Nt: 17316.25,
  Jt: 240.66666666666666,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 10,
  TimeNow: 691.7,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 9,
  TimeNow: 684.0666666666667,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 22.933333333333334,
  Nt: 18363.066666666666,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 16,
  TimeNow: 926.1578947368421,
  S: 8.368421052631579,
  N: 82.94736842105263,
  J: 7.2631578947368425,
  St: 1630.9473684210527,
  Nt: 15328.105263157895,
  Jt: 1426.9473684210527,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 11,
  TimeNow: 700.75,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 18,
  TimeNow: 895.625,
  S: 6.625,
  N: 85.375,
  J: 6.75,
  St: 1279.0,
  Nt: 15799.125,
  Jt: 1307.875,
}]
const groupByCategory = arr.reduce((group, product) => {
  const { Id } = product
  group[Id] = group[Id] ?? []
  group[Id].push(product)
  return group
}, {})
export default groupByCategory
