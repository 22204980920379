<template>
  <div class="va-chart">
    <canvas ref="chartld" class="chartjs" :width="width" :height="height"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js' // With moment.js
const types = ['line', 'bar', 'radar', 'polarArea', 'pie', 'doughnut', 'bubble', 'scatter']
export default {
  props: {
    width: Number,
    height: Number,
    type: {
      type: String,
      required: true,
      validator: val => types.includes(val),
    },
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    plugins: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  mounted () {
    this.resetChart()
  },
  data () {
    return {
      chart: null,
    }
  },
  methods: {
    resetChart () {
      if (this.chart) { this.chart.destroy() }
      this.chart = new Chart(this.$refs.chartld, {
        type: this.type,
        data: this.data,
        options: this.options,
        plugins: this.plugins,
      })
    },
  },
  watch: {
    type () {
      this.$nextTick(() => this.resetChart())
    },
    data () {
      this.chart.update()
    },
    options () {
      this.$nextTick(() => this.resetChart())
    },
  },
}
</script>

<style lang="scss">
.va-chart {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    height: 100%;
    width: 100%;
  }

  canvas {
    width: 100%;
    height: auto;
  }
}
</style>
