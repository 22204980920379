import { hex2rgb } from '../../services/vuestic-ui'
import data6 from '../MapChart2/AVg6'
import data7 from '../MapChart2/AVg7'
import data8 from '../MapChart2/AVg8'
import data9 from '../MapChart2/AVg9'

const generateYLabels = () => {
  const flip = !!Math.floor(Math.random() * 2)
  return flip ? ['اليوم 1', 'اليوم 2', 'اليوم 3', 'اليوم 4'] : ['اليوم 4', 'اليوم 3', 'اليوم 2', 'اليوم 1']
}

let generatedData

export const getLineChartData = (themes, id) => {
  const months = ['8AM', '9AM', '10AM', '11AM', '12PM', '1PM', '2PM', '3PM', '4PM', '5PM', '6PM', '7PM', '8PM', '9PM', '10PM']
  const yLabels = generateYLabels()
  console.log(data7)
  let d1 = []
  let d2 = []
  let d3 = []
  let d4 = []
  let nd = null
  if (id !== '') {
    d1 = data6[id]?.filter(x => (x.Hour >= 8 && x.Hour <= 22))
    .sort(function (a, b) {
      var x = a.Hour; var y = b.Hour
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
    d2 = data7[id]?.filter(x => (x.Hour >= 8 && x.Hour <= 22))
    .sort(function (a, b) {
      var x = a.Hour; var y = b.Hour
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
    d3 = data8[id]?.filter(x => (x.Hour >= 8 && x.Hour <= 22))
    .sort(function (a, b) {
      var x = a.Hour; var y = b.Hour
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
    d4 = data9[id]?.filter(x => (x.Hour >= 8 && x.Hour <= 22))
    .sort(function (a, b) {
      var x = a.Hour; var y = b.Hour
      return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
    nd = CheckData(d1, d2, d3, d4)
  }
  console.log('---------', nd)
  if (generatedData) {
    generatedData.datasets[0].backgroundColor = hex2rgb('#12135e').css
    generatedData.datasets[1].backgroundColor = hex2rgb('#13d00f').css
    generatedData.datasets[2].backgroundColor = hex2rgb('#000000').css
    generatedData.datasets[3].backgroundColor = hex2rgb('#20b5bd').css
    generatedData.datasets[0].data = nd?.nd1 ?? []
    generatedData.datasets[1].data = nd?.nd2 ?? []
    generatedData.datasets[2].data = nd?.nd3 ?? []
    generatedData.datasets[3].data = nd?.nd4 ?? []
  } else {
    generatedData = {
      labels: months,
      datasets: [
        {
          label: yLabels[0],
          backgroundColor: hex2rgb('#12135e').css,
          borderColor: hex2rgb('#12135e').css,
          data: nd?.nd1 ?? [],
          fill: false,
        },
        {
          label: yLabels[1],
          backgroundColor: hex2rgb('#13d00f').css,
          borderColor: hex2rgb('#13d00f').css,
          data: nd?.nd2 ?? [],
          fill: false,
        },
        {
          label: yLabels[2],
          backgroundColor: hex2rgb('#000000').css,
          borderColor: hex2rgb('#000000').css,
          data: nd?.nd3 ?? [],
          fill: false,
        },
        {
          label: yLabels[3],
          backgroundColor: hex2rgb('#20b5bd').css,
          borderColor: hex2rgb('#20b5bd').css,
          data: nd?.nd4 ?? [],
          fill: false,
        },
      ],
    }
  }

  return generatedData
}

function CheckData (d1, d2, d3, d4) {
  let nd1 = []
  let nd2 = []
  let nd3 = []
  let nd4 = []
  for (let i = 0; i < 15; i++) {
    const ti = i + 8
    let obj
    if (d1[i]?.Hour === ti) {
      obj = {
        Hour: ti,
        TimeNow: (d1[i].TimeNow),
      }
      nd1.push(obj)
    } else if (d1[i]?.Hour !== ti && d1[i + 1]?.Hour === ti + 1 && d1[i]?.Hour === ti - 1) {
      obj = {
        Hour: ti,
        TimeNow: (d1[i + 1].TimeNow + d1[i - 1].TimeNow) / 2,
      }
      nd1.push(obj)
    } else if (d2[i]?.Hour === ti) {
      if (d1[i]?.TimeNow) {
        obj = {
          Hour: ti,
          TimeNow: (d1[i].TimeNow + d2[i].TimeNow) / 2,
        }
        nd1.push(obj)
      } else {
        obj = {
          Hour: ti,
          TimeNow: (d2[i].TimeNow + 3),
        }
        nd1.push(obj)
      }
    } else if (d3[i]?.Hour === ti) {
      if (d1[i]?.TimeNow) {
        obj = {
          Hour: ti,
          TimeNow: (d1[i].TimeNow + d3[i].TimeNow) / 2,
        }
        nd1.push(obj)
      } else {
        obj = {
          Hour: ti,
          TimeNow: (d3[i].TimeNow + 3),
        }
        nd1.push(obj)
      }
    } else if (d4[i]?.Hour === ti) {
      if (d1[i]?.TimeNow) {
        obj = {
          Hour: ti,
          TimeNow: (d1[i].TimeNow + d3[i].TimeNow) / 2,
        }
        nd1.push(obj)
      } else {
        obj = {
          Hour: ti,
          TimeNow: (d3[i].TimeNow + 3),
        }
        nd1.push(obj)
      }
    } else {
      if (d1[i]?.TimeNow) {
        obj = {
          Hour: ti,
          TimeNow: (d1[i].TimeNow - 0.5),
        }
        nd1.push(obj)
      } else {
        obj = {
          Hour: ti,
          TimeNow: (d1[0].TimeNow - 3),
        }
        nd1.push(obj)
      }
    }

    if (d2[i]?.Hour === ti) {
      obj = {
        Hour: ti,
        TimeNow: (d2[i].TimeNow),
      }
      nd2.push(obj)
    } else if (d2[i]?.Hour !== ti && d2[i + 1]?.Hour === ti + 1 && d2[i]?.Hour === ti - 1) {
      obj = {
        Hour: ti,
        TimeNow: (d2[i + 1].TimeNow + d2[i - 1].TimeNow) / 2,
      }
      nd2.push(obj)
    } else if (d1[i]?.Hour === ti) {
      if (d2[i]?.TimeNow) {
        obj = {
          Hour: ti,
          TimeNow: (d2[i].TimeNow + d1[i].TimeNow) / 2,
        }
        nd2.push(obj)
      } else {
        obj = {
          Hour: ti,
          TimeNow: (d1[i].TimeNow + 3),
        }
        nd2.push(obj)
      }
    } else if (d3[i]?.Hour === ti) {
      if (d2[i]?.TimeNow) {
        obj = {
          Hour: ti,
          TimeNow: (d2[i].TimeNow + d3[i].TimeNow) / 2,
        }
        nd2.push(obj)
      } else {
        obj = {
          Hour: ti,
          TimeNow: (d3[i].TimeNow + 3),
        }
        nd2.push(obj)
      }
    } else if (d4[i]?.Hour === ti) {
      if (d2[i]?.TimeNow) {
        obj = {
          Hour: ti,
          TimeNow: (d2[i].TimeNow + d3[i].TimeNow) / 2,
        }
        nd2.push(obj)
      } else {
        obj = {
          Hour: ti,
          TimeNow: (d3[i].TimeNow + 3),
        }
        nd2.push(obj)
      }
    } else {
      if (d2[i]?.TimeNow) {
        obj = {
          Hour: ti,
          TimeNow: (d2[i].TimeNow - 0.5),
        }
        nd2.push(obj)
      } else {
        obj = {
          Hour: ti,
          TimeNow: (d2[0].TimeNow - 3),
        }
        nd2.push(obj)
      }
    }
    if (d3[i]?.Hour === ti) {
      obj = {
        Hour: ti,
        TimeNow: (d3[i].TimeNow),
      }
      nd3.push(obj)
    } else if (d3[i]?.Hour !== ti && d3[i + 1]?.Hour === ti + 1 && d3[i]?.Hour === ti - 1) {
      obj = {
        Hour: ti,
        TimeNow: (d3[i + 1].TimeNow + d3[i - 1].TimeNow) / 2,
      }
      nd3.push(obj)
    } else if (d1[i]?.Hour === ti) {
      if (d3[i]?.TimeNow) {
        obj = {
          Hour: ti,
          TimeNow: (d3[i].TimeNow + d1[i].TimeNow) / 2,
        }
        nd3.push(obj)
      } else {
        obj = {
          Hour: ti,
          TimeNow: (d1[i].TimeNow + 3),
        }
        nd3.push(obj)
      }
    } else if (d2[i]?.Hour === ti) {
      if (d3[i]?.TimeNow) {
        obj = {
          Hour: ti,
          TimeNow: (d3[i].TimeNow + d2[i].TimeNow) / 2,
        }
        nd3.push(obj)
      } else {
        obj = {
          Hour: ti,
          TimeNow: (d2[i].TimeNow + 3),
        }
        nd3.push(obj)
      }
    } else if (d4[i]?.Hour === ti) {
      if (d3[i]?.TimeNow) {
        obj = {
          Hour: ti,
          TimeNow: (d3[i].TimeNow + d3[i].TimeNow) / 2,
        }
        nd3.push(obj)
      } else {
        obj = {
          Hour: ti,
          TimeNow: (d3[i].TimeNow + 3),
        }
        nd3.push(obj)
      }
    } else {
      if (d3[i]?.TimeNow) {
        obj = {
          Hour: ti,
          TimeNow: (d3[i].TimeNow - 0.5),
        }
        nd3.push(obj)
      } else {
        obj = {
          Hour: ti,
          TimeNow: (d3[0].TimeNow - 3),
        }
        nd3.push(obj)
      }
    }
    if (d4[i]?.Hour === ti) {
      obj = {
        Hour: ti,
        TimeNow: (d4[i].TimeNow),
      }
      nd4.push(obj)
    } else if (d4[i]?.Hour !== ti && d4[i + 1]?.Hour === ti + 1 && d4[i]?.Hour === ti - 1) {
      obj = {
        Hour: ti,
        TimeNow: (d4[i + 1].TimeNow + d4[i - 1].TimeNow) / 2,
      }
      nd4.push(obj)
    } else if (d1[i]?.Hour === ti) {
      if (d4[i]?.TimeNow) {
        obj = {
          Hour: ti,
          TimeNow: (d4[i].TimeNow + d1[i].TimeNow) / 2,
        }
        nd4.push(obj)
      } else {
        obj = {
          Hour: ti,
          TimeNow: (d1[i].TimeNow + 3),
        }
        nd4.push(obj)
      }
    } else if (d2[i]?.Hour === ti) {
      if (d4[i]?.TimeNow) {
        obj = {
          Hour: ti,
          TimeNow: (d4[i].TimeNow + d2[i].TimeNow) / 2,
        }
        nd4.push(obj)
      } else {
        obj = {
          Hour: ti,
          TimeNow: (d2[i].TimeNow + 3),
        }
        nd4.push(obj)
      }
    } else if (d3[i]?.Hour === ti) {
      if (d4[i]?.TimeNow) {
        obj = {
          Hour: ti,
          TimeNow: (d4[i].TimeNow + d3[i].TimeNow) / 2,
        }
        nd4.push(obj)
      } else {
        obj = {
          Hour: ti,
          TimeNow: (d3[i].TimeNow + 3),
        }
        nd4.push(obj)
      }
    } else {
      if (d4[i]?.TimeNow) {
        obj = {
          Hour: ti,
          TimeNow: (d4[i].TimeNow - 0.5),
        }
        nd4.push(obj)
      } else {
        obj = {
          Hour: ti,
          TimeNow: (d4[0].TimeNow - 3),
        }
        nd4.push(obj)
      }
    }
  }
  nd1 = nd1.map(x => (x.TimeNow / 60).toFixed(2))
  nd2 = nd2.map(x => (x.TimeNow / 60).toFixed(2))
  nd3 = nd3.map(x => (x.TimeNow / 60).toFixed(2))
  nd4 = nd4.map(x => (x.TimeNow / 60).toFixed(2))
  return {
    nd1,
    nd2,
    nd3,
    nd4,
  }
}
