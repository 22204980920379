const arr = [{
  '24fBwLf4cYSZpeKnJh7z': {
    s: 8.21951219512195,
    n: 90.71951219512195,
    J: 0.036585365853658534,
    St: 144.98170731707316,
    Nt: 1515.0975609756097,
    Jt: 0.9207317073170732,
  },
}, {
  A6noUNQaeqBvu9nw2W9l: {
    s: 20.447852760736197,
    n: 57.20858895705521,
    J: 20.926380368098158,
    St: 501.11042944785277,
    Nt: 1381.8650306748466,
    Jt: 510.29447852760734,
  },
}, {
  AK045s1PEwVSnG7u00jZ: {
    s: 24.225609756097562,
    n: 54.61585365853659,
    J: 19.829268292682926,
    St: 1079.4573170731708,
    Nt: 2416.9268292682927,
    Jt: 881.939024390244,
  },
}, {
  D4QgJsuHSWponcYfbvR0: {
    s: 34.58641975308642,
    n: 18.25925925925926,
    J: 45.81481481481482,
    St: 1956.432098765432,
    Nt: 1041.6358024691358,
    Jt: 2588.6728395061727,
  },
}, {
  EpkvexvRz5okcvZNB9Uz: {
    s: 0.0,
    n: 99.0,
    J: 0.0,
    St: 0.0,
    Nt: 337.0,
    Jt: 0.0,
  },
}, {
  FYCoCtE3WUyaAPEE64Ng: {
    s: 12.851851851851851,
    n: 77.0246913580247,
    J: 8.75925925925926,
    St: 1661.2407407407406,
    Nt: 9703.518518518518,
    Jt: 1144.2407407407406,
  },
}, {
  FzRd6op64jHeWdsfQBoL: {
    s: 9.901840490797547,
    n: 76.03067484662577,
    J: 12.631901840490798,
    St: 2450.7852760736196,
    Nt: 18050.110429447854,
    Jt: 3089.8588957055213,
  },
}, {
  GAbS3czb5upPGpUZCaYv: {
    s: 18.354037267080745,
    n: 74.88198757763975,
    J: 5.571428571428571,
    St: 349.5217391304348,
    Nt: 1397.857142857143,
    Jt: 109.8695652173913,
  },
}, {
  GnSJS1Tf4aZHLFVixC1c: {
    s: 11.475308641975309,
    n: 84.25925925925925,
    J: 2.919753086419753,
    St: 587.5864197530864,
    Nt: 4160.20987654321,
    Jt: 157.08641975308643,
  },
}, {
  GuUxAcTrgDuZq36ona8p: {
    s: 4.197530864197531,
    n: 91.20987654320987,
    J: 3.2160493827160495,
    St: 443.27777777777777,
    Nt: 8521.802469135802,
    Jt: 334.91975308641975,
  },
}, {
  JJ0MT1LnOLOzJAa1uWEv: {
    s: 8.282208588957054,
    n: 84.2760736196319,
    J: 5.987730061349693,
    St: 2422.7791411042945,
    Nt: 23345.0981595092,
    Jt: 1780.122699386503,
  },
}, {
  JZiIa8J7JXL9yIWQtDKC: {
    s: 15.395061728395062,
    n: 61.94444444444444,
    J: 21.21604938271605,
    St: 3544.0308641975307,
    Nt: 13908.981481481482,
    Jt: 4827.759259259259,
  },
}, {
  KK6CpiSlPPG6ChUKmN5g: {
    s: 15.0920245398773,
    n: 66.12883435582822,
    J: 17.47239263803681,
    St: 3543.288343558282,
    Nt: 15173.435582822085,
    Jt: 4080.294478527607,
  },
}, {
  KTabvSUbDkLllhZ9M0fz: {
    s: 1.4233128834355828,
    n: 97.57668711656441,
    J: 0.0,
    St: 28.024539877300615,
    Nt: 1901.9754601226994,
    Jt: 0.0,
  },
}, {
  Lh8UanQXJltQacha7VGq: {
    s: 2.7861635220125787,
    n: 95.99371069182389,
    J: 0.16352201257861634,
    St: 31.0125786163522,
    Nt: 1029.1509433962265,
    Jt: 1.8364779874213837,
  },
}, {
  MvAnpzEUy1wRjUknb2Wq: {
    s: 32.69753086419753,
    n: 40.04938271604938,
    J: 25.95679012345679,
    St: 584.2530864197531,
    Nt: 710.7469135802469,
    Jt: 466.0,
  },
}, {
  NkQgYfbxHPHM9xFmIzWK: {
    s: 24.374233128834355,
    n: 67.2760736196319,
    J: 6.8343558282208585,
    St: 364.99386503067484,
    Nt: 993.3496932515337,
    Jt: 108.82208588957056,
  },
}, {
  NlrvbOqaEZ95gJLZQJHK: {
    s: 3.6956521739130435,
    n: 79.77639751552795,
    J: 15.130434782608695,
    St: 175.8944099378882,
    Nt: 3473.7701863354037,
    Jt: 681.335403726708,
  },
}, {
  PElsdRZHXvD54ZwV4qsi: {
    s: 16.71604938271605,
    n: 50.01851851851852,
    J: 32.00617283950617,
    St: 1261.7777777777778,
    Nt: 3738.7901234567903,
    Jt: 2383.432098765432,
  },
}, {
  Q5nsyZclh4X6utoxdCBr: {
    s: 4.567901234567901,
    n: 90.29629629629629,
    J: 4.074074074074074,
    St: 185.56172839506172,
    Nt: 3548.814814814815,
    Jt: 161.62345679012347,
  },
}, {
  QTnF9muZIf3AKWIzWJqq: {
    s: 18.320987654320987,
    n: 57.96913580246913,
    J: 22.493827160493826,
    St: 373.2901234567901,
    Nt: 1174.4012345679012,
    Jt: 457.30864197530866,
  },
}, {
  Qz3oMEwGZDIqB05vTeD5: {
    s: 5.813664596273292,
    n: 74.40372670807453,
    J: 18.546583850931675,
    St: 226.13664596273293,
    Nt: 2764.4347826086955,
    Jt: 694.2484472049689,
  },
}, {
  Spo0PQkpY5nEurTi1JZV: {
    s: 5.024539877300613,
    n: 89.96932515337423,
    J: 3.785276073619632,
    St: 100.69938650306749,
    Nt: 1627.0490797546013,
    Jt: 75.25153374233129,
  },
}, {
  SvsubPFf0KJzL2nE7zav: {
    s: 13.44375,
    n: 73.91875,
    J: 11.48125,
    St: 311.86875,
    Nt: 1706.2,
    Jt: 264.80625,
  },
}, {
  TJx7jVaVTtCaRhj8chu4: {
    s: 15.858024691358025,
    n: 65.75308641975309,
    J: 17.061728395061728,
    St: 627.6358024691358,
    Nt: 2575.777777777778,
    Jt: 675.5925925925926,
  },
}, {
  U8EjK5bVsRkjAZ9HEc0G: {
    s: 33.870370370370374,
    n: 5.555555555555555,
    J: 59.53086419753087,
    St: 127.64197530864197,
    Nt: 20.993827160493826,
    Jt: 222.3641975308642,
  },
}, {
  VHifwHAt6iuP9XKJQdK6: {
    s: 19.0,
    n: 61.75308641975309,
    J: 17.864197530864196,
    St: 1458.537037037037,
    Nt: 4667.302469135802,
    Jt: 1366.1604938271605,
  },
}, {
  ZMDmlhS8v6rgDCJZPWo0: {
    s: 35.382716049382715,
    n: 12.179012345679013,
    J: 50.91358024691358,
    St: 1735.0555555555557,
    Nt: 610.0679012345679,
    Jt: 2490.0246913580245,
  },
}, {
  ZTXeIZP0br2MbYiKaX1G: {
    s: 9.539877300613497,
    n: 89.15950920245399,
    J: 0.147239263803681,
    St: 224.34969325153375,
    Nt: 2007.7791411042945,
    Jt: 6.110429447852761,
  },
}, {
  bkIY4932t5SVR7hf8fRX: {
    s: 16.09433962264151,
    n: 68.18867924528301,
    J: 14.427672955974844,
    St: 414.4654088050315,
    Nt: 1711.817610062893,
    Jt: 366.28930817610063,
  },
}, {
  cRyuvsyxZFjaFD1hqjfl: {
    s: 27.875776397515526,
    n: 36.024844720496894,
    J: 34.63354037267081,
    St: 3728.9875776397516,
    Nt: 4803.527950310559,
    Jt: 4627.484472049689,
  },
}, {
  fdNNCQ6kPiglUszPfuR2: {
    s: 19.919254658385093,
    n: 56.7639751552795,
    J: 21.801242236024844,
    St: 1791.0931677018634,
    Nt: 5015.875776397515,
    Jt: 1962.0310559006211,
  },
}, {
  hZrY5SFfLR6WbPUf2bMJ: {
    s: 4.222222222222222,
    n: 93.14197530864197,
    J: 1.228395061728395,
    St: 99.43827160493827,
    Nt: 1947.0246913580247,
    Jt: 26.703703703703702,
  },
}, {
  jIIiFSIkF4FGpc3d8FGX: {
    s: 38.54601226993865,
    n: 24.70552147239264,
    J: 35.39877300613497,
    St: 760.5889570552147,
    Nt: 490.9141104294479,
    Jt: 697.680981595092,
  },
}, {
  jovVzfQYAGFJa3xfBccZ: {
    s: 15.746913580246913,
    n: 21.469135802469136,
    J: 61.629629629629626,
    St: 819.1296296296297,
    Nt: 1110.1666666666667,
    Jt: 3169.7098765432097,
  },
}, {
  kuGkReF6XUPgehdO2Obd: {
    s: 20.864197530864196,
    n: 73.76543209876543,
    J: 3.9876543209876543,
    St: 549.7283950617284,
    Nt: 1912.9012345679012,
    Jt: 112.27777777777777,
  },
}, {
  lD4aGchJfjRI2LTzcXE0: {
    s: 41.70807453416149,
    n: 39.11801242236025,
    J: 17.80745341614907,
    St: 2501.5341614906833,
    Nt: 2347.9254658385094,
    Jt: 1074.5403726708075,
  },
}, {
  lF2ALyrRClB2hYH5aUKY: {
    s: 31.736196319018404,
    n: 37.760736196319016,
    J: 29.12269938650307,
    St: 1177.9754601226994,
    Nt: 1404.1901840490798,
    Jt: 1078.8343558282209,
  },
}, {
  m7jwb0WGHCZ86rI2Mlgs: {
    s: 5.062111801242236,
    n: 87.95652173913044,
    J: 5.546583850931677,
    St: 590.2422360248447,
    Nt: 9436.478260869566,
    Jt: 639.4099378881988,
  },
}, {
  qJjMaaYZxNoHTnmI8FGY: {
    s: 14.061349693251534,
    n: 81.67484662576688,
    J: 2.938650306748466,
    St: 705.9877300613497,
    Nt: 3993.325153374233,
    Jt: 158.4478527607362,
  },
}, {
  qpGOFcJPWgOaCilvlspC: {
    s: 3.5060975609756095,
    n: 95.11585365853658,
    J: 0.22560975609756098,
    St: 50.042682926829265,
    Nt: 1199.1158536585365,
    Jt: 3.841463414634146,
  },
}, {
  qrfVyESuKWArso9aUxGe: {
    s: 5.423312883435583,
    n: 91.1042944785276,
    J: 2.208588957055215,
    St: 183.2638036809816,
    Nt: 2887.0184049079753,
    Jt: 77.71779141104294,
  },
}, {
  qwET8nsY5FqeF3tcphSJ: {
    s: 8.515337423312884,
    n: 83.42944785276073,
    J: 6.791411042944786,
    St: 828.1656441717791,
    Nt: 7815.374233128834,
    Jt: 654.4601226993865,
  },
}, {
  sCRXgLM8YvJVvw0h8R2d: {
    s: 0.7865853658536586,
    n: 98.10365853658537,
    J: 0.10975609756097561,
    St: 11.012195121951219,
    Nt: 1211.4512195121952,
    Jt: 1.5365853658536586,
  },
}, {
  u5qDYGjH2IVqp1qUHfRb: {
    s: 21.615853658536587,
    n: 55.951219512195124,
    J: 21.04268292682927,
    St: 1362.2987804878048,
    Nt: 3487.9024390243903,
    Jt: 1324.7987804878048,
  },
}, {
  vLYYPYJhRtmdqMvfqqdl: {
    s: 55.71604938271605,
    n: 8.38888888888889,
    J: 34.72222222222222,
    St: 369.9074074074074,
    Nt: 56.41358024691358,
    Jt: 229.7037037037037,
  },
}, {
  yaXl7CiWAjijl2XPONca: {
    s: 13.878048780487806,
    n: 56.19512195121951,
    J: 28.597560975609756,
    St: 788.4085365853658,
    Nt: 3124.5365853658536,
    Jt: 1589.0548780487804,
  },
}, {
  zo7i6MU1XWSiVHPOcuho: {
    s: 3.4074074074074074,
    n: 92.41975308641975,
    J: 2.9753086419753085,
    St: 681.2037037037037,
    Nt: 17115.203703703704,
    Jt: 589.5925925925926,
  },
}]

arr.sort(function (a, b) {
  var x = Object.keys(a)[0]; var y = Object.keys(b)[0]
  return ((x < y) ? -1 : ((x > y) ? 1 : 0))
})
export default arr
