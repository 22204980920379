<template>
  <div class="dashboard">
    <dashboard-charts />
    <div class="row">
    <!--  <div class="flex md12 xs12">
        <va-card
          class="google-maps-page__widget"
          :title="$t('generic.heatMap')"
        >
           <google-map  style="height: 55vh;"/>
        </va-card>
      </div> -->
    </div>
    <!-- <div class="row row-equal">
      <div class="flex md6 xs12">
        <va-card
          class="chart-widget"
          :title="$t('charts.verticalBarChart')"
        >
          <va-chart :data="verticalBarChartData" type="vertical-bar"/>
        </va-card>
      </div> -->
    <!-- <div class="flex md6 xs12">
        <dashboard-info-block></dashboard-info-block>

      </div>
  </div>-->
  </div>
</template>

<script>
import DashboardCharts from './DashboardCharts'
// import DashboardInfoBlock from './DashboardInfoBlock'
import { getVerticalBarChartData } from '../../data/charts/VerticalBarChartData'
import { getHorizontalBarChartData } from '../../data/charts/HorizontalBarChartData'
// import VaChart from '../statistics/charts/va-charts/VaChart'
// import DashboardMap from './DashboardMap'
// import GoogleMap from './GoogleMap.vue'

export default {
  name: 'dashboard',
  components: {
    DashboardCharts,
    // GoogleMap,
    // DashboardInfoBlock,
    // VaChart,
    // DashboardMap,
  },
  data () {
    return {
      verticalBarChartData: getVerticalBarChartData(this.$themes),
      horizontalBarChartData: getHorizontalBarChartData(this.$themes),
    }
  },
  created () {
    document.title = this.$t('generic.Leap') + '-' + this.$t('menu.dashboard')
  },
  methods: {
    addAddressToMap ({ city, country }) {
      this.$refs.dashboardMap.addAddress({ city: city.text, country })
    },
  },
}
</script>

<style lang="scss">
  .row-equal .flex {
    .va-card {
      height: 100%;
    }
  }

  .dashboard {
    .va-card {
      margin-bottom: 0 !important;
    }
  }
</style>
