export default [
  {
    '24fBwLf4cYSZpeKnJh7z': {
      s: 8.219816819317236,
      n: 90.6361365528726,
      J: 0.12156536219816819,
      St: 145.41382181515405,
      Nt: 1513.3147377185678,
      Jt: 2.2714404662781016,
    },
  },
  {
    A6noUNQaeqBvu9nw2W9l: {
      s: 14.231596360628618,
      n: 72.42762613730356,
      J: 12.033085194375516,
      St: 350.7866004962779,
      Nt: 1747.7278742762614,
      Jt: 294.9255583126551,
    },
  },
  {
    AK045s1PEwVSnG7u00jZ: {
      s: 20.805785123966942,
      n: 61.38181818181818,
      J: 16.54297520661157,
      St: 929.1305785123967,
      Nt: 2714.294214876033,
      Jt: 734.7512396694215,
    },
  },
  {
    D4QgJsuHSWponcYfbvR0: {
      s: 28.420878210439106,
      n: 31.661971830985916,
      J: 38.630488815244405,
      St: 1609.8202154101077,
      Nt: 1794.9983429991714,
      Jt: 2181.714995857498,
    },
  },
  {
    EpkvexvRz5okcvZNB9Uz: {
      s: 0.0,
      n: 99.0,
      J: 0.0,
      St: 0.0,
      Nt: 337.0,
      Jt: 0.0,
    },
  },
  {
    FYCoCtE3WUyaAPEE64Ng: {
      s: 9.442880794701987,
      n: 84.55960264900662,
      J: 4.729304635761589,
      St: 1232.021523178808,
      Nt: 10652.942880794702,
      Jt: 624.0355960264901,
    },
  },
  {
    FzRd6op64jHeWdsfQBoL: {
      s: 8.935537190082645,
      n: 80.03801652892562,
      J: 9.64793388429752,
      St: 2272.7429752066114,
      Nt: 19262.604132231405,
      Jt: 2404.295041322314,
    },
  },
  {
    GAbS3czb5upPGpUZCaYv: {
      s: 15.017398508699255,
      n: 75.1698425849213,
      J: 8.55923777961889,
      St: 286.07622203811104,
      Nt: 1405.8525269262634,
      Jt: 165.36536868268433,
    },
  },
  {
    GnSJS1Tf4aZHLFVixC1c: {
      s: 12.871582435791218,
      n: 84.003314001657,
      J: 1.8889809444904722,
      St: 657.5269262634631,
      Nt: 4143.604805302402,
      Jt: 103.77133388566695,
    },
  },
  {
    GuUxAcTrgDuZq36ona8p: {
      s: 4.950289975144988,
      n: 90.6288318144159,
      J: 2.9975144987572495,
      St: 511.6984258492129,
      Nt: 8471.024855012427,
      Jt: 317.27671913835957,
    },
  },
  {
    JJ0MT1LnOLOzJAa1uWEv: {
      s: 8.114333057166528,
      n: 84.36536868268435,
      J: 6.094449047224524,
      St: 2412.8193869096935,
      Nt: 23625.725766362884,
      Jt: 1814.80115990058,
    },
  },
  {
    JZiIa8J7JXL9yIWQtDKC: {
      s: 12.825041459369817,
      n: 72.04063018242122,
      J: 13.729684908789386,
      St: 2957.8432835820895,
      Nt: 16172.483416252073,
      Jt: 3150.461857379768,
    },
  },
  {
    KK6CpiSlPPG6ChUKmN5g: {
      s: 12.7408940397351,
      n: 69.8501655629139,
      J: 16.06208609271523,
      St: 2999.0173841059604,
      Nt: 15993.594370860927,
      Jt: 3734.7061258278145,
    },
  },
  {
    KTabvSUbDkLllhZ9M0fz: {
      s: 0.8511166253101737,
      n: 98.11993382961126,
      J: 0.028122415219189414,
      St: 16.887510339123242,
      Nt: 1912.5591397849462,
      Jt: 0.5533498759305211,
    },
  },
  {
    Lh8UanQXJltQacha7VGq: {
      s: 4.029900332225914,
      n: 94.11461794019934,
      J: 0.7549833887043189,
      St: 45.56893687707641,
      Nt: 1007.8455149501661,
      Jt: 8.585548172757475,
    },
  },
  {
    MvAnpzEUy1wRjUknb2Wq: {
      s: 14.105355575065847,
      n: 53.07462686567164,
      J: 31.663740122914838,
      St: 253.19754170324848,
      Nt: 942.2572431957858,
      Jt: 565.5452151009657,
    },
  },
  {
    NkQgYfbxHPHM9xFmIzWK: {
      s: 20.191542288557216,
      n: 67.4469320066335,
      J: 10.904643449419568,
      St: 303.1442786069652,
      Nt: 998.8051409618574,
      Jt: 166.84825870646767,
    },
  },
  {
    NlrvbOqaEZ95gJLZQJHK: {
      s: 3.663628831814416,
      n: 86.26014913007457,
      J: 8.704225352112676,
      St: 177.2129246064623,
      Nt: 3752.086164043082,
      Jt: 401.70008285004144,
    },
  },
  {
    PElsdRZHXvD54ZwV4qsi: {
      s: 17.956953642384107,
      n: 51.83278145695364,
      J: 28.97433774834437,
      St: 1351.4793046357615,
      Nt: 3874.098509933775,
      Jt: 2158.4221854304637,
    },
  },
  {
    Q5nsyZclh4X6utoxdCBr: {
      s: 2.5062137531068767,
      n: 95.26926263463132,
      J: 1.1938690969345485,
      St: 99.14415907207953,
      Nt: 3570.8185584092794,
      Jt: 46.19386909693455,
    },
  },
  {
    QTnF9muZIf3AKWIzWJqq: {
      s: 10.87982456140351,
      n: 65.32543859649122,
      J: 22.63157894736842,
      St: 292.5719298245614,
      Nt: 2021.6263157894737,
      Jt: 730.2666666666667,
    },
  },
  {
    Qz3oMEwGZDIqB05vTeD5: {
      s: 4.839929639401935,
      n: 77.43359718557608,
      J: 16.525065963060687,
      St: 188.60158311345646,
      Nt: 2877.8276165347406,
      Jt: 618.4951627088831,
    },
  },
  {
    Spo0PQkpY5nEurTi1JZV: {
      s: 4.7214876033057855,
      n: 90.29338842975207,
      J: 3.693388429752066,
      St: 94.83388429752065,
      Nt: 1633.6132231404958,
      Jt: 74.55206611570247,
    },
  },
  {
    SvsubPFf0KJzL2nE7zav: {
      s: 7.1125769569041335,
      n: 79.04485488126649,
      J: 12.6948109058927,
      St: 306.8953386103782,
      Nt: 3660.7862796833774,
      Jt: 702.1644678979771,
    },
  },
  {
    U8EjK5bVsRkjAZ9HEc0G: {
      s: 21.65728476821192,
      n: 23.927152317880793,
      J: 53.3932119205298,
      St: 81.90976821192054,
      Nt: 89.54470198675497,
      Jt: 199.5455298013245,
    },
  },
  {
    VHifwHAt6iuP9XKJQdK6: {
      s: 15.830701754385965,
      n: 68.23421052631579,
      J: 14.55877192982456,
      St: 1218.4877192982456,
      Nt: 5155.7271929824565,
      Jt: 1117.7850877192982,
    },
  },
  {
    ZMDmlhS8v6rgDCJZPWo0: {
      s: 27.739850869925434,
      n: 40.35791217895609,
      J: 30.572493786246895,
      St: 1360.1342170671085,
      Nt: 1976.275062137531,
      Jt: 1497.5542667771333,
    },
  },
  {
    ZTXeIZP0br2MbYiKaX1G: {
      s: 11.625207296849087,
      n: 86.08872305140962,
      J: 1.0323383084577114,
      St: 271.17661691542287,
      Nt: 1939.240464344942,
      Jt: 27.76865671641791,
    },
  },
  {
    bkIY4932t5SVR7hf8fRX: {
      s: 12.435577722360765,
      n: 73.69077306733168,
      J: 12.598503740648379,
      St: 321.3632585203658,
      Nt: 1850.3990024937657,
      Jt: 320.7173732335827,
    },
  },
  {
    cRyuvsyxZFjaFD1hqjfl: {
      s: 20.084437086092716,
      n: 57.68460264900662,
      J: 20.89072847682119,
      St: 2698.6903973509934,
      Nt: 7666.80215231788,
      Jt: 2794.5074503311257,
    },
  },
  {
    fdNNCQ6kPiglUszPfuR2: {
      s: 16.68791390728477,
      n: 69.29304635761589,
      J: 12.56705298013245,
      St: 1504.7334437086092,
      Nt: 6123.316225165563,
      Jt: 1140.950331125828,
    },
  },
  {
    hZrY5SFfLR6WbPUf2bMJ: {
      s: 7.938741721854305,
      n: 88.84602649006622,
      J: 2.017384105960265,
      St: 173.12665562913907,
      Nt: 1856.216059602649,
      Jt: 43.89900662251656,
    },
  },
  {
    jIIiFSIkF4FGpc3d8FGX: {
      s: 30.70939420544337,
      n: 37.437225636523266,
      J: 30.548726953467956,
      St: 606.8630377524144,
      Nt: 740.307287093942,
      Jt: 601.9850746268656,
    },
  },
  {
    jovVzfQYAGFJa3xfBccZ: {
      s: 24.76304888152444,
      n: 29.04722452361226,
      J: 44.98922949461475,
      St: 1282.6835128417565,
      Nt: 1502.5708367854184,
      Jt: 2313.8798674399336,
    },
  },
  {
    kuGkReF6XUPgehdO2Obd: {
      s: 19.514498757249378,
      n: 72.20546810273405,
      J: 6.934548467274234,
      St: 514.9908864954432,
      Nt: 1873.7854183927093,
      Jt: 186.16735708367855,
    },
  },
  {
    lD4aGchJfjRI2LTzcXE0: {
      s: 29.90016638935108,
      n: 53.28202995008319,
      J: 15.56405990016639,
      St: 1797.440931780366,
      Nt: 3190.4392678868553,
      Jt: 936.1198003327787,
    },
  },
  {
    lF2ALyrRClB2hYH5aUKY: {
      s: 25.90008764241893,
      n: 47.76599474145486,
      J: 25.012269938650306,
      St: 963.2892199824715,
      Nt: 1769.8650306748466,
      Jt: 927.8457493426819,
    },
  },
  {
    m7jwb0WGHCZ86rI2Mlgs: {
      s: 5.056288478452067,
      n: 87.08707124010554,
      J: 6.467897977132806,
      St: 585.0413368513632,
      Nt: 9349.970976253298,
      Jt: 731.0888302550571,
    },
  },
  {
    qJjMaaYZxNoHTnmI8FGY: {
      s: 15.94205298013245,
      n: 80.74420529801324,
      J: 2.0124172185430464,
      St: 800.1092715231788,
      Nt: 3946.4834437086092,
      Jt: 111.09271523178808,
    },
  },
  {
    qpGOFcJPWgOaCilvlspC: {
      s: 4.90976821192053,
      n: 93.13824503311258,
      J: 0.7764900662251656,
      St: 67.58112582781457,
      Nt: 1173.7930463576158,
      Jt: 11.625827814569536,
    },
  },
  {
    qrfVyESuKWArso9aUxGe: {
      s: 4.870860927152318,
      n: 92.21688741721854,
      J: 1.6986754966887416,
      St: 167.15149006622516,
      Nt: 2920.562086092715,
      Jt: 60.28725165562914,
    },
  },
  {
    qwET8nsY5FqeF3tcphSJ: {
      s: 4.468102734051367,
      n: 86.26926263463132,
      J: 8.12344656172328,
      St: 442.73487986743993,
      Nt: 8087.84009942005,
      Jt: 772.7613918806959,
    },
  },
  {
    sCRXgLM8YvJVvw0h8R2d: {
      s: 0.6266556291390728,
      n: 98.16804635761589,
      J: 0.2052980132450331,
      St: 8.77317880794702,
      Nt: 1212.3526490066224,
      Jt: 2.8741721854304636,
    },
  },
  {
    u5qDYGjH2IVqp1qUHfRb: {
      s: 18.031496062992126,
      n: 63.3254593175853,
      J: 17.292213473315837,
      St: 1140.002624671916,
      Nt: 3945.5240594925635,
      Jt: 1089.4733158355205,
    },
  },
  {
    vLYYPYJhRtmdqMvfqqdl: {
      s: 44.60844370860927,
      n: 22.181291390728475,
      J: 32.13907284768212,
      St: 296.5612582781457,
      Nt: 147.07615894039736,
      Jt: 212.39817880794703,
    },
  },
  {
    yaXl7CiWAjijl2XPONca: {
      s: 10.739454094292803,
      n: 65.23325062034739,
      J: 22.741935483870968,
      St: 613.0496277915632,
      Nt: 3621.33746898263,
      Jt: 1267.0918114143922,
    },
  },
  {
    zo7i6MU1XWSiVHPOcuho: {
      s: 2.2386081193040597,
      n: 94.54101077050538,
      J: 2.071251035625518,
      St: 458.4299917149959,
      Nt: 17518.431648715825,
      Jt: 409.1383595691798,
    },
  },
  {
    TJx7jVaVTtCaRhj8chu4: {
      s: 13.131510416666666,
      n: 74.546875,
      J: 11.040364583333334,
      St: 923.7916666666666,
      Nt: 4497.494791666667,
      Jt: 836.8046875,
    },
  },
]
