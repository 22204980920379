<template>
  <div class="row row-equal">
    <div class="flex xs12 md6 xl5">
      <va-card :title="$t('dashboard.charts.loadingSpeed')">
        <div class="radio-btn-group pt-4">
          <div class="radio">
            <input type="radio" name="radio" :value="true" v-model="TrafficCondition" id="pri1"/>
            <label for="pri1">{{$t('dashboard.charts.primary')}}</label>
          </div>
          <div class="radio">
            <input type="radio" name="radio" :value="false" v-model="TrafficCondition" id="sec1"/>
            <label for="sec1">{{$t('dashboard.charts.secondary')}}</label>
          </div>
        </div>
        <div class="flex text-start">
          <label class="input-label"> {{$t('placeholder.ChooseRoute')}} </label>
          <a-select
            :show-search="true"
            :allowClear="false"
            :placeholder="$t('placeholder.ChooseRoute')"
            option-filter-prop="children"
            style="width: 100%;"
            v-model="routsSelect.singleAvg"
            :notFoundContent="$t('placeholder.ItemsNotFound')"
            v-if="TrafficCondition"
          >
            <a-select-option :value="option.id" v-for="(option,i) in routsSelect.optionsPR" :key="i">
              {{option['RouteName']}}
            </a-select-option>
          </a-select>
          <a-select
            :show-search="true"
            :allowClear="false"
            :placeholder="$t('placeholder.ChooseRoute')"
            option-filter-prop="children"
            style="width: 100%;"
            v-model="routsSelect.singleAvg"
            :notFoundContent="$t('placeholder.ItemsNotFound')"
            v-else
          >
            <a-select-option :value="option.id" v-for="(option,i) in routsSelect.optionsSC" :key="i">
              {{option['RouteName']}}
            </a-select-option>
          </a-select>
        </div>
        <chart :type="'bar'" :data="verticalBarChartData" :options="options3" :height="400" :key="cartOneComponentKey"></chart>

      </va-card>
    </div>
    <div class="flex xs12 xl7">
      <va-card :title="$t('dashboard.charts.trendyTrends')">
        <!-- <span slot="subTitle" class="sub-tilte">{{$t('dashboard.charts.trendyTrends2')}}</span> -->
        <div class="radio-btn-group">
          <div class="radio">
            <input type="radio" name="radio2" :value="true" v-model="estmateArrvail" id="pri2"/>
            <label for="pri2">{{$t('dashboard.charts.primary')}}</label>
          </div>
          <div class="radio">
            <input type="radio" name="radio2" :value="false" v-model="estmateArrvail" id="sec2"/>
            <label for="sec2">{{$t('dashboard.charts.secondary')}}</label>
          </div>
        </div>
        <div class="flex text-start">
          <label class="input-label"> {{$t('placeholder.ChooseRoute')}} </label>
          <a-select
            :show-search="true"
            :allowClear="false"
            :placeholder="$t('placeholder.ChooseRoute')"
            option-filter-prop="children"
            style="width: 100%;"
            v-model="routsSelect.value"
            :notFoundContent="$t('placeholder.ItemsNotFound')"
            v-if="estmateArrvail"
          >
            <a-select-option :value="option.id" v-for="(option,i) in routsSelect.optionsPR" :key="i">
              {{option['RouteName']}}
            </a-select-option>
          </a-select>
          <a-select
            :show-search="true"
            :allowClear="false"
            :placeholder="$t('placeholder.ChooseRoute')"
            option-filter-prop="children"
            style="width: 100%;"
            v-model="routsSelect.value"
            :notFoundContent="$t('placeholder.ItemsNotFound')"
            v-else
          >
            <a-select-option :value="option.id" v-for="(option,i) in routsSelect.optionsSC" :key="i">
              {{option['RouteName']}}
            </a-select-option>
          </a-select>
        </div>
        <chart :type="'line'" :data="lineChartData" :options="options" :key="cartThreeComponentKey5" :height="400"></chart>
      </va-card>
    </div>
    <div class="flex xs12 xl12">
      <va-card :title="$t('dashboard.charts.AvgTraffic')">
        <h5 slot="subTitle" class="sub-tilte">{{$t('dashboard.charts.day1')}}</h5>
        <h5 slot="actions">القيمة السالبة = Improvement, القيمة الموجبة= Degraded</h5>

        <div class="flex text-start">
          <label class="input-label"> {{$t('placeholder.showPrimariy')}} </label>
          <a-select
            :show-search="true"
            :allowClear="false"
            :placeholder="$t('placeholder.ChooseRoute')"
            option-filter-prop="children"
            style="width: 100%; max-width: 320px;"
            v-model="routsSelect.primaryChart"
            mode='multiple'
            :notFoundContent="$t('placeholder.ItemsNotFound')"
          >
            <a-select-option :value="option.id" v-for="(option,i) in routsSelect.options" :key="i">
              {{option['RouteName']}}
            </a-select-option>
          </a-select>
        </div>
        <div class="radio-btn-group">
          <div class="radio">
            <input type="radio" name="radio3" :value="true" v-model="AvgRoadTraf" id="pri3"/>
            <label for="pri3">{{$t('dashboard.charts.inPercentage')}}</label>
          </div>
          <div class="radio">
            <input type="radio" name="radio3" :value="false" v-model="AvgRoadTraf" id="sec3"/>
            <label for="sec3">{{$t('dashboard.charts.inminuts')}}</label>
          </div>
        </div>
        <chart :type="'bar'" :data="AvgRoadTraftData" :key="cartThreeComponentKey" :options="options2" :height="400"></chart>
      </va-card>
    </div>
    <div class="flex xs12 xl12">
      <va-card :title="$t('dashboard.charts.AvgTraffic')">
        <h5 slot="subTitle" class="sub-tilte">{{$t('dashboard.charts.day2')}}</h5>
        <h5 slot="actions">القيمة السالبة = Improvement
          القيمة الموجبة= Degraded</h5>
        <div class="flex text-start">
          <label class="input-label"> {{$t('placeholder.showsec')}} </label>
          <a-select
            :show-search="true"
            :allowClear="false"
            :placeholder="$t('placeholder.ChooseRoute')"
            option-filter-prop="children"
            style="width: 100%; max-width: 320px;"
            v-model="routsSelect.SecChart"
            mode='multiple'
            :notFoundContent="$t('placeholder.ItemsNotFound')"
          >
            <a-select-option :value="option.id" v-for="(option,i) in routsSelect.options" :key="i">
              {{option['RouteName']}}
            </a-select-option>
          </a-select>
        </div>
        <div class="radio-btn-group">
          <div class="radio">
            <input type="radio" name="radio5" :value="true" v-model="AvgRoadTraf2" id="pri4"/>
            <label for="pri4">{{$t('dashboard.charts.inPercentage')}}</label>
          </div>
          <div class="radio">
            <input type="radio" name="radio5" :value="false" v-model="AvgRoadTraf2" id="sec4"/>
            <label for="sec4">{{$t('dashboard.charts.inminuts')}}</label>
          </div>
        </div>
        <chart :type="'bar'" :data="AvgRoadTraftData2" :key="cartThreeComponentKey2" :options="options2" :height="400"></chart>
      </va-card>
    </div>
    <div class="flex xs12 xl12">
      <va-card :title="$t('dashboard.charts.AvgTraffic')">
        <h5 slot="subTitle" class="sub-tilte">{{$t('dashboard.charts.day3')}}</h5>
        <h5 slot="actions">القيمة السالبة = Improvement
          القيمة الموجبة= Degraded</h5>
        <div class="flex text-start">
          <label class="input-label"> {{$t('placeholder.showsec')}} </label>
          <a-select
            :show-search="true"
            :allowClear="false"
            :placeholder="$t('placeholder.ChooseRoute')"
            option-filter-prop="children"
            style="width: 100%; max-width: 320px;"
            v-model="routsSelect.chart3"
            mode='multiple'
            :notFoundContent="$t('placeholder.ItemsNotFound')"
          >
            <a-select-option :value="option.id" v-for="(option,i) in routsSelect.options" :key="i">
              {{option['RouteName']}}
            </a-select-option>
          </a-select>
        </div>
        <div class="radio-btn-group">
          <div class="radio">
            <input type="radio" name="radio4" :value="true" v-model="AvgRoadTraf3" id="pri5"/>
            <label for="pri5">{{$t('dashboard.charts.inPercentage')}}</label>
          </div>
          <div class="radio">
            <input type="radio" name="radio4" :value="false" v-model="AvgRoadTraf3" id="sec5"/>
            <label for="sec5">{{$t('dashboard.charts.inminuts')}}</label>
          </div>
        </div>
        <chart :type="'bar'" :data="AvgRoadTraftData3" :key="cartThreeComponentKey3" :options="options2" :height="400"></chart>
      </va-card>
    </div>
  </div>
</template>

<script>
import { getDonutChartData } from '../../data/charts/DonutChartData'
import { getLineChartData } from '../../data/charts/LineChartData'
import firebase from 'firebase'

// import DashboardContributorsChart from './DashboardContributorsList'
// import { GChart } from 'vue-google-charts/legacy'
import Chart from './charts'

import { getVerticalBarChartData, getVerticalBarChartData2, getVerticalBarChartData3, getVerticalBarChartData4 } from '../../data/charts/VerticalBarChartData'

export default {
  name: 'dashboard-charts',
  components: { Chart },
  data () {
    return {
      lineChartData: getLineChartData(this.$themes, ''),
      donutChartData: getDonutChartData(this.$themes),
      lineChartFirstMonthIndex: 0,
      cartOneComponentKey: 0,
      cartThreeComponentKey: 0,
      cartThreeComponentKey2: 0,
      cartThreeComponentKey3: 0,
      cartThreeComponentKey5: 0,
      verticalBarChartData: getVerticalBarChartData(this.$themes, ''),
      AvgRoadTraftData: getVerticalBarChartData2(this.$themes, ''),
      AvgRoadTraftData2: getVerticalBarChartData3(this.$themes, ''),
      AvgRoadTraftData3: getVerticalBarChartData4(this.$themes, ''),
      TrafficCondition: true,
      estmateArrvail: true,
      AvgRoadTraf: true,
      AvgRoadTraf2: true,
      AvgRoadTraf3: true,

      options: {
        legend: {
          position: 'right',
          labels: {
            fontColor: '#34495e',
            fontFamily: 'sans-serif',
            fontSize: 14,
            padding: 20,
            usePointStyle: true,
          },
        },
        tooltips: {
          bodyFontSize: 14,
          bodyFontFamily: 'sans-serif',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              reverse: false,
              beginAtZero: true,
            },
            title: {
              display: true,
              text: 'الدقائق',
            },
          }],
        },
      },
      options2: {
        legend: {
          position: 'bottom',
          labels: {
            fontColor: '#34495e',
            fontFamily: 'sans-serif',
            fontSize: 14,
            padding: 20,
            usePointStyle: true,
          },
        },
        tooltips: {
          bodyFontSize: 14,
          bodyFontFamily: 'sans-serif',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              max: 100,
              reverse: false,
              beginAtZero: true,
            },
            title: {
              display: true,
              text: 'Month',
            },
          }],
          xAxes: [{
            ticks: {
              fontSize: 7,
            },
          }],
        },
      },
      options3: {
        legend: {
          position: 'bottom',
          labels: {
            fontColor: '#34495e',
            fontFamily: 'sans-serif',
            fontSize: 14,
            padding: 20,
            usePointStyle: true,
          },
        },
        tooltips: {
          bodyFontSize: 14,
          bodyFontFamily: 'sans-serif',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              max: 100,
              reverse: false,
              beginAtZero: true,
            },
            title: {
              display: true,
              text: 'Month',
            },
          }],
          xAxes: [{
            ticks: {
              fontSize: 8,
            },
          }],
        },
      },
      routsSelect: {
        value: '',
        primaryChart: [],
        SecChart: [],
        chart3: [],
        singleAvg: '',
        optionsPR: [],
        optionsSC: [],
        options: [],
      },
    }
  },
  watch: {
    'routsSelect.singleAvg' () {
      this.lineChartData = getVerticalBarChartData(this.$themes, this.routsSelect.singleAvg)
      this.cartOneComponentKey += 1
      this.$forceUpdate()
    },
    'TrafficCondition' () {
      if (this.TrafficCondition) {
        this.routsSelect.singleAvg = this.routsSelect.optionsPR[0]?.id
      } else {
        this.routsSelect.singleAvg = this.routsSelect.optionsSC[0]?.id
      }
    },
    'estmateArrvail' () {
      if (this.estmateArrvail) {
        this.routsSelect.value = this.routsSelect.optionsPR[0]?.id
      } else {
        this.routsSelect.value = this.routsSelect.optionsSC[0]?.id
      }
    },
    'routsSelect.value' () {
      this.lineChartData = getLineChartData(this.$themes, this.routsSelect.value)
      this.cartThreeComponentKey5 += 1
      this.$forceUpdate()
    },
    'routsSelect.primaryChart' () {
      this.AvgRoadTraftData = getVerticalBarChartData2(this.$themes, this.routsSelect.primaryChart, !this.AvgRoadTraf)
      this.cartThreeComponentKey += 1
      this.$forceUpdate()
    },
    'AvgRoadTraf' () {
      this.AvgRoadTraftData = getVerticalBarChartData2(this.$themes, this.routsSelect.primaryChart, !this.AvgRoadTraf)

      if (!this.AvgRoadTraf) {
        this.options2.scales.yAxes[0].ticks = {}
      }
      this.cartThreeComponentKey += 1
      this.$forceUpdate()
    },
    'routsSelect.SecChart' () {
      this.AvgRoadTraftData2 = getVerticalBarChartData3(this.$themes, this.routsSelect.SecChart, !this.AvgRoadTraf2)
      this.cartThreeComponentKey2 += 1
      this.$forceUpdate()
    },
    'AvgRoadTraf2' () {
      this.AvgRoadTraftData2 = getVerticalBarChartData3(this.$themes, this.routsSelect.SecChart, !this.AvgRoadTraf2)

      if (!this.AvgRoadTraf2) {
        this.options2.scales.yAxes[0].ticks = {}
      }
      this.cartThreeComponentKey2 += 1
      this.$forceUpdate()
    },
    'routsSelect.chart3' () {
      this.AvgRoadTraftData3 = getVerticalBarChartData4(this.$themes, this.routsSelect.chart3, !this.AvgRoadTraf2)
      this.cartThreeComponentKey3 += 1
      this.$forceUpdate()
    },
    'AvgRoadTraf3' () {
      this.AvgRoadTraftData3 = getVerticalBarChartData4(this.$themes, this.routsSelect.chart3, !this.AvgRoadTraf3)

      if (!this.AvgRoadTraf3) {
        this.options2.scales.yAxes[0].ticks = {}
      }
      this.cartThreeComponentKey3 += 1
      this.$forceUpdate()
    },
  },
  methods: {
    printChart () {
      var arrrayBuffer = base64ToArrayBuffer('JVBERi0xLjYKJdP0zOEKMSAwIG9iago8PAovQ3JlYXRpb25EYXRl') // data is the base64 encoded string
      function base64ToArrayBuffer (base64) {
        var binaryString = window.atob(base64)
        var binaryLen = binaryString.length
        var bytes = new Uint8Array(binaryLen)
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i)
          bytes[i] = ascii
        }
        return bytes
      }
      var pdfFile = new Blob([arrrayBuffer], {
        type: 'application/pdf',
      })
      var pdfUrl = URL.createObjectURL(pdfFile)
      const win = window.open(pdfUrl, 'Print', 'height=600,width=800')
      // const win = window.open('http://mahmmoudahmed-001-site4.ftempurl.com/images/AhmedMaghraby.pdf', 'Print', 'height=600,width=800')
      // win.document.write(`<br><img src='${this.donutChartDataURL}'/>`)
      // TODO: find better solution how to remove timeout
      setTimeout(() => {
        win.document.close()
        win.focus()
        win.print()
        win.close()
      }, 1000)
    },
  },
  computed: {
    donutChartDataURL () {
      return document.querySelector('.chart--donut canvas').toDataURL('image/png')
    },
  },
  async mounted () {
    const firestore = firebase.firestore()
    await firestore
      .collection('routes')
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          var obj = doc.data()
          obj.id = doc.id
          if (obj.iSPrimary) {
            this.routsSelect.optionsPR.push(obj)
          } else {
            this.routsSelect.optionsSC.push(obj)
          }
          this.routsSelect.options.push(obj)
        })
        if (this.TrafficCondition) {
          this.routsSelect.singleAvg = this.routsSelect.optionsPR[0]?.id
        } else {
          this.routsSelect.singleAvg = this.routsSelect.optionsSC[0]?.id
        }
        if (this.estmateArrvail) {
          this.routsSelect.value = this.routsSelect.optionsPR[0]?.id
        } else {
          this.routsSelect.value = this.routsSelect.optionsSC[0]?.id
        }
        if (this.routsSelect.options.length > 12) {
          for (let i = 0; i < 12; i++) {
            this.routsSelect.primaryChart.push(this.routsSelect.options[i].id)
            this.routsSelect.SecChart.push(this.routsSelect.options[i].id)
            this.routsSelect.chart3.push(this.routsSelect.options[i].id)
          }
        } else {
          this.routsSelect.primaryChart = this.routsSelect.options.map(x => {
            return x.id
          })
          this.routsSelect.SecChart = this.routsSelect.options.map(x => {
            return x.id
          })
          this.routsSelect.chart3 = this.routsSelect.options.map(x => {
            return x.id
          })
        }
      })
  },
}
</script>

<style lang="scss" scoped>
  .chart {
    height: 400px;
  }

  .radio-btn-group {
    display: flex;
    padding: 1rem 0;

    .radio {
      margin: 0 0.25rem;

      label {
        background: #ffffff;
        border: 0;
        padding: 0.5rem 1.25rem;
        border-radius: 5px;
        cursor: pointer;
        color: #555555;
        transition: box-shadow 400ms ease;

        &:hover {
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        }
      }

      input[type="radio"] {
        display: none;
      }

      input[type="radio"]:checked + label {
        background: #555555;
        color: #ffffff;
        border: 1px solid #555555;
      }
    }
  }

  .input-label {
    display: block;
  }

  .ant-select {
    background: #ffffff;
    width: 100%;
    border: 1px solid;
    border-radius: 0.5rem;
  }

</style>
