const arr = [{
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 21,
  TimeNow: 164.43478260869566,
  S: 11.043478260869565,
  N: 87.95652173913044,
  J: 0.0,
  St: 190.65217391304347,
  Nt: 1470.3478260869565,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 18,
  TimeNow: 165.0,
  S: 11.04,
  N: 87.96,
  J: 0.0,
  St: 194.64,
  Nt: 1466.36,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 12,
  TimeNow: 158.2941176470588,
  S: 7.0588235294117645,
  N: 91.94117647058823,
  J: 0.0,
  St: 126.88235294117646,
  Nt: 1534.1176470588234,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 14,
  TimeNow: 151.91666666666666,
  S: 2.5833333333333335,
  N: 96.41666666666667,
  J: 0.0,
  St: 49.416666666666664,
  Nt: 1611.5833333333333,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 13,
  TimeNow: 161.1764705882353,
  S: 10.823529411764707,
  N: 88.17647058823529,
  J: 0.0,
  St: 191.23529411764707,
  Nt: 1469.764705882353,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 3,
  TimeNow: 134.64705882352942,
  S: 0.35294117647058826,
  N: 98.6470588235294,
  J: 0.0,
  St: 14.117647058823529,
  Nt: 1646.8823529411766,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 0,
  TimeNow: 149.89473684210526,
  S: 4.842105263157895,
  N: 94.15789473684211,
  J: 0.0,
  St: 89.36842105263158,
  Nt: 1571.6315789473683,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 22,
  TimeNow: 161.05263157894737,
  S: 8.578947368421053,
  N: 90.42105263157895,
  J: 0.0,
  St: 152.21052631578948,
  Nt: 1508.7894736842106,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 20,
  TimeNow: 168.62962962962962,
  S: 12.037037037037036,
  N: 86.96296296296296,
  J: 0.0,
  St: 208.92592592592592,
  Nt: 1452.0740740740741,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 19,
  TimeNow: 165.96428571428572,
  S: 12.964285714285714,
  N: 86.03571428571429,
  J: 0.0,
  St: 226.14285714285714,
  Nt: 1434.857142857143,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 23,
  TimeNow: 157.44444444444446,
  S: 7.0,
  N: 92.0,
  J: 0.0,
  St: 125.38888888888889,
  Nt: 1535.611111111111,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 17,
  TimeNow: 159.22222222222223,
  S: 8.277777777777779,
  N: 90.72222222222223,
  J: 0.0,
  St: 152.36111111111111,
  Nt: 1508.638888888889,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 2,
  TimeNow: 145.66666666666666,
  S: 6.666666666666667,
  N: 92.33333333333333,
  J: 0.0,
  St: 124.16666666666667,
  Nt: 1536.8333333333333,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 1,
  TimeNow: 143.52941176470588,
  S: 2.3529411764705883,
  N: 96.6470588235294,
  J: 0.0,
  St: 43.8235294117647,
  Nt: 1617.1764705882354,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 16,
  TimeNow: 159.0,
  S: 7.909090909090909,
  N: 91.0909090909091,
  J: 0.0,
  St: 145.72727272727272,
  Nt: 1515.2727272727273,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 15,
  TimeNow: 152.9090909090909,
  S: 2.5454545454545454,
  N: 96.45454545454545,
  J: 0.0,
  St: 50.27272727272727,
  Nt: 1610.7272727272727,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 4,
  TimeNow: 144.22222222222223,
  S: 0.2222222222222222,
  N: 98.77777777777777,
  J: 0.0,
  St: 5.777777777777778,
  Nt: 1655.2222222222222,
  Jt: 0.0,
}, {
  Id: '24fBwLf4cYSZpeKnJh7z',
  Hour: 11,
  TimeNow: 172.23076923076923,
  S: 10.76923076923077,
  N: 84.38461538461539,
  J: 3.3846153846153846,
  St: 188.30769230769232,
  Nt: 1411.6923076923076,
  Jt: 61.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 21,
  TimeNow: 222.17391304347825,
  S: 9.565217391304348,
  N: 82.26086956521739,
  J: 6.956521739130435,
  St: 238.3913043478261,
  Nt: 1981.6521739130435,
  Jt: 174.04347826086956,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 18,
  TimeNow: 341.84,
  S: 6.2,
  N: 74.28,
  J: 18.0,
  St: 159.56,
  Nt: 1790.68,
  Jt: 443.08,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 12,
  TimeNow: 167.94117647058823,
  S: 12.823529411764707,
  N: 84.58823529411765,
  J: 1.5294117647058822,
  St: 319.29411764705884,
  Nt: 2036.9411764705883,
  Jt: 37.88235294117647,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 14,
  TimeNow: 232.58333333333334,
  S: 24.5,
  N: 57.5,
  J: 16.166666666666668,
  St: 601.6666666666666,
  Nt: 1392.0833333333333,
  Jt: 400.25,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 13,
  TimeNow: 199.94117647058823,
  S: 15.058823529411764,
  N: 77.29411764705883,
  J: 6.411764705882353,
  St: 371.11764705882354,
  Nt: 1862.7058823529412,
  Jt: 159.76470588235293,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 3,
  TimeNow: 131.47058823529412,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 2392.0,
  Jt: 0.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 0,
  TimeNow: 139.78947368421052,
  S: 0.8421052631578947,
  N: 98.15789473684211,
  J: 0.0,
  St: 20.789473684210527,
  Nt: 2371.4210526315787,
  Jt: 0.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 22,
  TimeNow: 150.1578947368421,
  S: 3.6315789473684212,
  N: 94.21052631578948,
  J: 1.105263157894737,
  St: 90.57894736842105,
  Nt: 2275.4736842105262,
  Jt: 26.789473684210527,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 20,
  TimeNow: 216.33333333333334,
  S: 11.703703703703704,
  N: 79.51851851851852,
  J: 7.481481481481482,
  St: 292.0740740740741,
  Nt: 1914.5555555555557,
  Jt: 187.55555555555554,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 19,
  TimeNow: 232.53571428571428,
  S: 8.714285714285714,
  N: 81.17857142857143,
  J: 8.678571428571429,
  St: 222.35714285714286,
  Nt: 1953.857142857143,
  Jt: 217.64285714285714,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 23,
  TimeNow: 146.05555555555554,
  S: 2.3333333333333335,
  N: 96.44444444444444,
  J: 0.2222222222222222,
  St: 57.611111111111114,
  Nt: 2329.4444444444443,
  Jt: 5.333333333333333,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 17,
  TimeNow: 306.02777777777777,
  S: 14.194444444444445,
  N: 66.36111111111111,
  J: 18.083333333333332,
  St: 350.5,
  Nt: 1599.8055555555557,
  Jt: 444.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 2,
  TimeNow: 135.16666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 2392.0,
  Jt: 0.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 1,
  TimeNow: 137.1764705882353,
  S: 1.411764705882353,
  N: 97.58823529411765,
  J: 0.0,
  St: 34.294117647058826,
  Nt: 2358.0,
  Jt: 0.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 16,
  TimeNow: 262.72727272727275,
  S: 19.136363636363637,
  N: 62.0,
  J: 17.318181818181817,
  St: 469.54545454545456,
  Nt: 1498.5,
  Jt: 426.04545454545456,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 15,
  TimeNow: 271.6363636363636,
  S: 18.818181818181817,
  N: 62.36363636363637,
  J: 17.363636363636363,
  St: 461.0,
  Nt: 1504.0,
  Jt: 428.6363636363636,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 4,
  TimeNow: 128.22222222222223,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 1.2222222222222223,
  Nt: 2390.777777777778,
  Jt: 0.0,
}, {
  Id: 'A6noUNQaeqBvu9nw2W9l',
  Hour: 11,
  TimeNow: 163.3846153846154,
  S: 7.384615384615385,
  N: 90.84615384615384,
  J: 0.7692307692307693,
  St: 185.07692307692307,
  Nt: 2189.5384615384614,
  Jt: 19.23076923076923,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 21,
  TimeNow: 270.69565217391306,
  S: 15.347826086956522,
  N: 82.43478260869566,
  J: 1.0869565217391304,
  St: 695.1304347826087,
  Nt: 3630.695652173913,
  Jt: 52.17391304347826,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 18,
  TimeNow: 616.68,
  S: 21.24,
  N: 30.6,
  J: 46.68,
  St: 954.76,
  Nt: 1356.0,
  Jt: 2067.72,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 12,
  TimeNow: 289.29411764705884,
  S: 31.529411764705884,
  N: 63.470588235294116,
  J: 3.588235294117647,
  St: 1407.8823529411766,
  Nt: 2800.4117647058824,
  Jt: 169.7058823529412,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 14,
  TimeNow: 500.0,
  S: 25.583333333333332,
  N: 33.416666666666664,
  J: 39.666666666666664,
  St: 1140.5833333333333,
  Nt: 1486.5833333333333,
  Jt: 1751.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 13,
  TimeNow: 289.88235294117646,
  S: 28.352941176470587,
  N: 69.29411764705883,
  J: 1.2941176470588236,
  St: 1266.764705882353,
  Nt: 3053.176470588235,
  Jt: 58.05882352941177,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 3,
  TimeNow: 212.88235294117646,
  S: 0.058823529411764705,
  N: 98.94117647058823,
  J: 0.0,
  St: 5.470588235294118,
  Nt: 4372.529411764706,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 0,
  TimeNow: 223.42105263157896,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 10.105263157894736,
  Nt: 4367.894736842105,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 22,
  TimeNow: 326.2105263157895,
  S: 22.05263157894737,
  N: 65.15789473684211,
  J: 11.578947368421053,
  St: 984.578947368421,
  Nt: 2880.2105263157896,
  Jt: 513.3157894736842,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 20,
  TimeNow: 279.8888888888889,
  S: 21.0,
  N: 72.11111111111111,
  J: 5.703703703703703,
  St: 943.7037037037037,
  Nt: 3178.3333333333335,
  Jt: 255.96296296296296,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 19,
  TimeNow: 378.7142857142857,
  S: 30.928571428571427,
  N: 52.607142857142854,
  J: 15.071428571428571,
  St: 1373.857142857143,
  Nt: 2327.6428571428573,
  Jt: 676.5357142857143,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 23,
  TimeNow: 425.6666666666667,
  S: 16.22222222222222,
  N: 69.33333333333333,
  J: 13.0,
  St: 729.1111111111111,
  Nt: 3065.0555555555557,
  Jt: 584.1666666666666,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 17,
  TimeNow: 700.6666666666666,
  S: 20.88888888888889,
  N: 30.444444444444443,
  J: 47.138888888888886,
  St: 935.6388888888889,
  Nt: 1358.0833333333333,
  Jt: 2084.9166666666665,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 2,
  TimeNow: 214.33333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 2.6666666666666665,
  Nt: 4375.333333333333,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 1,
  TimeNow: 217.35294117647058,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 14.117647058823529,
  Nt: 4363.882352941177,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 16,
  TimeNow: 814.0454545454545,
  S: 24.181818181818183,
  N: 29.59090909090909,
  J: 44.45454545454545,
  St: 1081.590909090909,
  Nt: 1320.9545454545455,
  Jt: 1976.3181818181818,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 15,
  TimeNow: 705.7272727272727,
  S: 18.363636363636363,
  N: 45.27272727272727,
  J: 34.81818181818182,
  St: 825.0909090909091,
  Nt: 2000.2727272727273,
  Jt: 1553.6363636363637,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 4,
  TimeNow: 206.55555555555554,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 4378.0,
  Jt: 0.0,
}, {
  Id: 'AK045s1PEwVSnG7u00jZ',
  Hour: 11,
  TimeNow: 279.2307692307692,
  S: 11.153846153846153,
  N: 87.84615384615384,
  J: 0.0,
  St: 511.2307692307692,
  Nt: 3866.923076923077,
  Jt: 0.0,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 21,
  TimeNow: 424.4782608695652,
  S: 53.56521739130435,
  N: 29.652173913043477,
  J: 15.304347826086957,
  St: 3019.913043478261,
  Nt: 1692.8695652173913,
  Jt: 873.8695652173913,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 18,
  TimeNow: 1249.84,
  S: 7.24,
  N: 0.56,
  J: 91.2,
  St: 423.24,
  Nt: 31.72,
  Jt: 5131.16,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 12,
  TimeNow: 455.6470588235294,
  S: 34.529411764705884,
  N: 40.529411764705884,
  J: 23.235294117647058,
  St: 1957.7058823529412,
  Nt: 2299.470588235294,
  Jt: 1329.6470588235295,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 14,
  TimeNow: 409.3333333333333,
  S: 51.833333333333336,
  N: 34.666666666666664,
  J: 12.25,
  St: 2926.75,
  Nt: 1964.0833333333333,
  Jt: 695.8333333333334,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 13,
  TimeNow: 477.3529411764706,
  S: 43.470588235294116,
  N: 30.058823529411764,
  J: 24.88235294117647,
  St: 2459.9411764705883,
  Nt: 1711.764705882353,
  Jt: 1415.1176470588234,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 3,
  TimeNow: 235.76470588235293,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5586.0,
  Jt: 0.0,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 0,
  TimeNow: 319.1578947368421,
  S: 14.31578947368421,
  N: 78.73684210526316,
  J: 5.947368421052632,
  St: 806.7368421052631,
  Nt: 4444.210526315789,
  Jt: 335.2631578947368,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 22,
  TimeNow: 466.94736842105266,
  S: 38.8421052631579,
  N: 35.1578947368421,
  J: 24.57894736842105,
  St: 2194.842105263158,
  Nt: 1993.157894736842,
  Jt: 1398.8947368421052,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 20,
  TimeNow: 372.3703703703704,
  S: 23.037037037037038,
  N: 71.81481481481481,
  J: 4.074074074074074,
  St: 1308.8148148148148,
  Nt: 4046.4444444444443,
  Jt: 231.59259259259258,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 19,
  TimeNow: 685.4642857142857,
  S: 29.857142857142858,
  N: 24.142857142857142,
  J: 44.42857142857143,
  St: 1692.75,
  Nt: 1379.4285714285713,
  Jt: 2514.5,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 23,
  TimeNow: 458.77777777777777,
  S: 56.0,
  N: 26.38888888888889,
  J: 16.166666666666668,
  St: 3166.5,
  Nt: 1499.5555555555557,
  Jt: 920.6666666666666,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 17,
  TimeNow: 1540.4444444444443,
  S: 0.25,
  N: 0.0,
  J: 98.75,
  St: 17.916666666666668,
  Nt: 0.0,
  Jt: 5568.111111111111,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 2,
  TimeNow: 233.16666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5586.0,
  Jt: 0.0,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 1,
  TimeNow: 242.8235294117647,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5586.0,
  Jt: 0.0,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 16,
  TimeNow: 1379.6818181818182,
  S: 4.2272727272727275,
  N: 0.0,
  J: 94.77272727272727,
  St: 248.72727272727272,
  Nt: 0.0,
  Jt: 5337.272727272727,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 15,
  TimeNow: 740.7272727272727,
  S: 37.27272727272727,
  N: 1.3636363636363635,
  J: 60.18181818181818,
  St: 2116.3636363636365,
  Nt: 80.81818181818181,
  Jt: 3389.818181818182,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 4,
  TimeNow: 236.88888888888889,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5586.0,
  Jt: 0.0,
}, {
  Id: 'D4QgJsuHSWponcYfbvR0',
  Hour: 11,
  TimeNow: 374.9230769230769,
  S: 53.92307692307692,
  N: 41.0,
  J: 3.8461538461538463,
  St: 3037.153846153846,
  Nt: 2324.076923076923,
  Jt: 225.3846153846154,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 21,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 18,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 12,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 14,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 13,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 3,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 0,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 22,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 20,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 19,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 23,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 17,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 2,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 1,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 16,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 15,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 4,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'EpkvexvRz5okcvZNB9Uz',
  Hour: 11,
  TimeNow: 62.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 337.0,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 21,
  TimeNow: 682.8695652173913,
  S: 13.826086956521738,
  N: 81.65217391304348,
  J: 3.217391304347826,
  St: 1794.608695652174,
  Nt: 10282.739130434782,
  Jt: 431.6521739130435,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 18,
  TimeNow: 617.0416666666666,
  S: 9.083333333333334,
  N: 88.16666666666667,
  J: 1.5416666666666667,
  St: 1194.75,
  Nt: 11103.041666666666,
  Jt: 211.20833333333334,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 12,
  TimeNow: 913.5294117647059,
  S: 24.0,
  N: 62.529411764705884,
  J: 11.823529411764707,
  St: 3064.470588235294,
  Nt: 7884.764705882353,
  Jt: 1559.764705882353,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 14,
  TimeNow: 688.4166666666666,
  S: 14.083333333333334,
  N: 78.58333333333333,
  J: 6.0,
  St: 1811.4166666666667,
  Nt: 9874.166666666666,
  Jt: 823.4166666666666,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 13,
  TimeNow: 984.1764705882352,
  S: 11.235294117647058,
  N: 68.47058823529412,
  J: 18.88235294117647,
  St: 1460.0,
  Nt: 8626.882352941177,
  Jt: 2422.1176470588234,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 3,
  TimeNow: 536.0,
  S: 0.17647058823529413,
  N: 98.82352941176471,
  J: 0.0,
  St: 28.176470588235293,
  Nt: 12480.823529411764,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 0,
  TimeNow: 554.9473684210526,
  S: 0.5789473684210527,
  N: 98.42105263157895,
  J: 0.0,
  St: 85.0,
  Nt: 12424.0,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 22,
  TimeNow: 614.6842105263158,
  S: 6.7894736842105265,
  N: 92.0,
  J: 0.21052631578947367,
  St: 905.8421052631579,
  Nt: 11571.78947368421,
  Jt: 31.36842105263158,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 20,
  TimeNow: 595.6296296296297,
  S: 3.4814814814814814,
  N: 94.44444444444444,
  J: 0.9259259259259259,
  St: 480.77777777777777,
  Nt: 11904.444444444445,
  Jt: 123.77777777777777,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 19,
  TimeNow: 592.0,
  S: 2.607142857142857,
  N: 95.32142857142857,
  J: 0.8928571428571429,
  St: 367.57142857142856,
  Nt: 12022.07142857143,
  Jt: 119.35714285714286,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 23,
  TimeNow: 577.9444444444445,
  S: 2.888888888888889,
  N: 96.11111111111111,
  J: 0.0,
  St: 408.72222222222223,
  Nt: 12100.277777777777,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 17,
  TimeNow: 726.8055555555555,
  S: 15.027777777777779,
  N: 75.08333333333333,
  J: 8.63888888888889,
  St: 1935.6666666666667,
  Nt: 9455.833333333334,
  Jt: 1117.5,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 2,
  TimeNow: 541.5,
  S: 0.3333333333333333,
  N: 98.66666666666667,
  J: 0.0,
  St: 49.666666666666664,
  Nt: 12459.333333333334,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 1,
  TimeNow: 537.9411764705883,
  S: 0.058823529411764705,
  N: 98.94117647058823,
  J: 0.0,
  St: 8.764705882352942,
  Nt: 12500.235294117647,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 16,
  TimeNow: 631.1818181818181,
  S: 8.636363636363637,
  N: 86.5909090909091,
  J: 3.5454545454545454,
  St: 1147.8636363636363,
  Nt: 10890.0,
  Jt: 471.1363636363636,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 15,
  TimeNow: 596.2727272727273,
  S: 7.7272727272727275,
  N: 89.81818181818181,
  J: 1.3636363636363635,
  St: 1022.8181818181819,
  Nt: 11299.363636363636,
  Jt: 186.8181818181818,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 4,
  TimeNow: 538.7777777777778,
  S: 1.8888888888888888,
  N: 97.11111111111111,
  J: 0.0,
  St: 257.44444444444446,
  Nt: 12251.555555555555,
  Jt: 0.0,
}, {
  Id: 'FYCoCtE3WUyaAPEE64Ng',
  Hour: 11,
  TimeNow: 733.0,
  S: 19.307692307692307,
  N: 71.46153846153847,
  J: 7.846153846153846,
  St: 2470.6153846153848,
  Nt: 9012.461538461539,
  Jt: 1025.923076923077,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 21,
  TimeNow: 1514.8260869565217,
  S: 8.73913043478261,
  N: 88.6086956521739,
  J: 1.4782608695652173,
  St: 2163.6521739130435,
  Nt: 20974.304347826088,
  Jt: 410.04347826086956,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 18,
  TimeNow: 2195.96,
  S: 12.24,
  N: 72.32,
  J: 13.84,
  St: 3168.76,
  Nt: 17725.28,
  Jt: 3491.56,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 12,
  TimeNow: 1548.5294117647059,
  S: 10.882352941176471,
  N: 83.88235294117646,
  J: 3.764705882352941,
  St: 2683.0,
  Nt: 19907.470588235294,
  Jt: 957.5294117647059,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 14,
  TimeNow: 2066.3333333333335,
  S: 10.333333333333334,
  N: 75.33333333333333,
  J: 12.916666666666666,
  St: 2532.8333333333335,
  Nt: 17863.583333333332,
  Jt: 3151.5833333333335,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 13,
  TimeNow: 1837.6470588235295,
  S: 11.529411764705882,
  N: 75.82352941176471,
  J: 11.176470588235293,
  St: 2841.235294117647,
  Nt: 17974.058823529413,
  Jt: 2732.705882352941,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 3,
  TimeNow: 1158.0588235294117,
  S: 0.23529411764705882,
  N: 98.76470588235294,
  J: 0.0,
  St: 138.88235294117646,
  Nt: 23409.117647058825,
  Jt: 0.0,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 0,
  TimeNow: 1252.0526315789473,
  S: 0.10526315789473684,
  N: 98.89473684210526,
  J: 0.0,
  St: 81.89473684210526,
  Nt: 23399.736842105263,
  Jt: 66.36842105263158,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 22,
  TimeNow: 1403.3684210526317,
  S: 5.7894736842105265,
  N: 93.15789473684211,
  J: 0.0,
  St: 1457.0526315789473,
  Nt: 22072.684210526317,
  Jt: 18.263157894736842,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 20,
  TimeNow: 1564.888888888889,
  S: 13.444444444444445,
  N: 82.11111111111111,
  J: 2.888888888888889,
  St: 3354.703703703704,
  Nt: 19571.037037037036,
  Jt: 816.1481481481482,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 19,
  TimeNow: 1800.4642857142858,
  S: 13.857142857142858,
  N: 76.71428571428571,
  J: 8.071428571428571,
  St: 3438.535714285714,
  Nt: 18291.75,
  Jt: 2004.6785714285713,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 23,
  TimeNow: 1325.6666666666667,
  S: 1.6111111111111112,
  N: 97.11111111111111,
  J: 0.0,
  St: 510.94444444444446,
  Nt: 23009.777777777777,
  Jt: 27.27777777777778,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 17,
  TimeNow: 2596.222222222222,
  S: 15.13888888888889,
  N: 66.58333333333333,
  J: 16.805555555555557,
  St: 4267.722222222223,
  Nt: 17780.666666666668,
  Jt: 4553.361111111111,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 2,
  TimeNow: 1164.6666666666667,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 54.666666666666664,
  Nt: 23472.333333333332,
  Jt: 21.0,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 1,
  TimeNow: 1206.1764705882354,
  S: 0.0,
  N: 98.94117647058823,
  J: 0.0,
  St: 77.94117647058823,
  Nt: 23433.0,
  Jt: 37.05882352941177,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 16,
  TimeNow: 2536.3636363636365,
  S: 10.909090909090908,
  N: 70.45454545454545,
  J: 17.181818181818183,
  St: 2924.681818181818,
  Nt: 17878.454545454544,
  Jt: 4410.545454545455,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 15,
  TimeNow: 2571.0,
  S: 7.090909090909091,
  N: 71.81818181818181,
  J: 19.636363636363637,
  St: 1777.3636363636363,
  Nt: 17028.363636363636,
  Jt: 4742.272727272727,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 4,
  TimeNow: 1177.111111111111,
  S: 0.7777777777777778,
  N: 98.11111111111111,
  J: 0.0,
  St: 259.44444444444446,
  Nt: 23263.666666666668,
  Jt: 24.88888888888889,
}, {
  Id: 'FzRd6op64jHeWdsfQBoL',
  Hour: 11,
  TimeNow: 2248.0,
  S: 9.461538461538462,
  N: 68.84615384615384,
  J: 20.076923076923077,
  St: 2361.0,
  Nt: 16341.23076923077,
  Jt: 4845.7692307692305,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 21,
  TimeNow: 123.04347826086956,
  S: 5.521739130434782,
  N: 93.47826086956522,
  J: 0.0,
  St: 106.8695652173913,
  Nt: 1750.1304347826087,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 18,
  TimeNow: 127.96,
  S: 13.32,
  N: 80.96,
  J: 4.64,
  St: 255.16,
  Nt: 1510.36,
  Jt: 91.56,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 12,
  TimeNow: 198.2941176470588,
  S: 22.176470588235293,
  N: 51.0,
  J: 24.941176470588236,
  St: 420.1764705882353,
  Nt: 960.0,
  Jt: 477.2352941176471,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 14,
  TimeNow: 191.91666666666666,
  S: 25.583333333333332,
  N: 54.666666666666664,
  J: 18.25,
  St: 483.0,
  Nt: 1023.5833333333334,
  Jt: 350.9166666666667,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 13,
  TimeNow: 183.94117647058823,
  S: 18.294117647058822,
  N: 50.94117647058823,
  J: 28.88235294117647,
  St: 347.70588235294116,
  Nt: 958.8823529411765,
  Jt: 550.7647058823529,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 3,
  TimeNow: 114.94117647058823,
  S: 7.117647058823529,
  N: 91.88235294117646,
  J: 0.0,
  St: 136.7058823529412,
  Nt: 1720.2941176470588,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 0,
  TimeNow: 118.0,
  S: 3.4210526315789473,
  N: 95.57894736842105,
  J: 0.0,
  St: 65.78947368421052,
  Nt: 1791.578947368421,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 22,
  TimeNow: 123.52631578947368,
  S: 3.1578947368421053,
  N: 95.84210526315789,
  J: 0.0,
  St: 61.26315789473684,
  Nt: 1796.3684210526317,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 20,
  TimeNow: 129.62962962962962,
  S: 15.814814814814815,
  N: 80.4074074074074,
  J: 2.740740740740741,
  St: 303.51851851851853,
  Nt: 1499.8148148148148,
  Jt: 53.7037037037037,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 19,
  TimeNow: 138.57142857142858,
  S: 9.892857142857142,
  N: 74.53571428571429,
  J: 14.428571428571429,
  St: 188.42857142857142,
  Nt: 1390.0,
  Jt: 278.92857142857144,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 23,
  TimeNow: 125.44444444444444,
  S: 4.777777777777778,
  N: 94.22222222222223,
  J: 0.0,
  St: 92.66666666666667,
  Nt: 1765.2222222222222,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 17,
  TimeNow: 210.47222222222223,
  S: 27.416666666666668,
  N: 52.30555555555556,
  J: 18.75,
  St: 518.9166666666666,
  Nt: 979.5,
  Jt: 359.52777777777777,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 2,
  TimeNow: 109.33333333333333,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1857.0,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 1,
  TimeNow: 112.82352941176471,
  S: 0.8235294117647058,
  N: 98.17647058823529,
  J: 0.0,
  St: 15.764705882352942,
  Nt: 1841.235294117647,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 16,
  TimeNow: 214.04545454545453,
  S: 27.454545454545453,
  N: 47.18181818181818,
  J: 23.636363636363637,
  St: 518.5454545454545,
  Nt: 887.2727272727273,
  Jt: 451.8636363636364,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 15,
  TimeNow: 167.8181818181818,
  S: 26.272727272727273,
  N: 63.90909090909091,
  J: 8.454545454545455,
  St: 498.1818181818182,
  Nt: 1194.090909090909,
  Jt: 165.27272727272728,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 4,
  TimeNow: 122.66666666666667,
  S: 7.0,
  N: 92.0,
  J: 0.0,
  St: 137.33333333333334,
  Nt: 1719.6666666666667,
  Jt: 0.0,
}, {
  Id: 'GAbS3czb5upPGpUZCaYv',
  Hour: 11,
  TimeNow: 209.69230769230768,
  S: 28.846153846153847,
  N: 52.53846153846154,
  J: 17.0,
  St: 547.4615384615385,
  Nt: 983.7692307692307,
  Jt: 326.15384615384613,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 21,
  TimeNow: 446.5217391304348,
  S: 4.869565217391305,
  N: 91.8695652173913,
  J: 2.130434782608696,
  St: 263.30434782608694,
  Nt: 4528.086956521739,
  Jt: 113.69565217391305,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 18,
  TimeNow: 446.44,
  S: 7.76,
  N: 89.68,
  J: 1.44,
  St: 402.8,
  Nt: 4425.08,
  Jt: 77.32,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 12,
  TimeNow: 484.29411764705884,
  S: 17.176470588235293,
  N: 72.05882352941177,
  J: 8.941176470588236,
  St: 869.7058823529412,
  Nt: 3567.8823529411766,
  Jt: 467.3529411764706,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 14,
  TimeNow: 458.0833333333333,
  S: 10.833333333333334,
  N: 83.33333333333333,
  J: 4.166666666666667,
  St: 553.4166666666666,
  Nt: 4123.166666666667,
  Jt: 228.33333333333334,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 13,
  TimeNow: 471.5882352941176,
  S: 20.823529411764707,
  N: 72.52941176470588,
  J: 5.176470588235294,
  St: 1054.0,
  Nt: 3577.176470588235,
  Jt: 273.4117647058824,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 3,
  TimeNow: 418.2352941176471,
  S: 4.0,
  N: 95.0,
  J: 0.0,
  St: 237.0,
  Nt: 4668.0,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 0,
  TimeNow: 440.57894736842104,
  S: 12.526315789473685,
  N: 86.47368421052632,
  J: 0.0,
  St: 643.421052631579,
  Nt: 4261.578947368421,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 22,
  TimeNow: 435.6842105263158,
  S: 4.0,
  N: 92.6842105263158,
  J: 1.894736842105263,
  St: 225.8421052631579,
  Nt: 4578.1578947368425,
  Jt: 100.89473684210526,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 20,
  TimeNow: 436.9259259259259,
  S: 13.925925925925926,
  N: 84.4074074074074,
  J: 0.6296296296296297,
  St: 702.5185185185185,
  Nt: 4163.259259259259,
  Jt: 39.666666666666664,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 19,
  TimeNow: 440.60714285714283,
  S: 17.142857142857142,
  N: 81.39285714285714,
  J: 0.35714285714285715,
  St: 862.6071428571429,
  Nt: 4019.25,
  Jt: 23.464285714285715,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 23,
  TimeNow: 425.44444444444446,
  S: 4.777777777777778,
  N: 94.22222222222223,
  J: 0.0,
  St: 268.0,
  Nt: 4636.277777777777,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 17,
  TimeNow: 462.02777777777777,
  S: 17.5,
  N: 78.61111111111111,
  J: 2.5555555555555554,
  St: 885.3611111111111,
  Nt: 3873.5555555555557,
  Jt: 146.25,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 2,
  TimeNow: 425.6666666666667,
  S: 4.0,
  N: 95.0,
  J: 0.0,
  St: 237.0,
  Nt: 4668.0,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 1,
  TimeNow: 434.29411764705884,
  S: 10.352941176470589,
  N: 88.6470588235294,
  J: 0.0,
  St: 538.1764705882352,
  Nt: 4366.705882352941,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 16,
  TimeNow: 451.72727272727275,
  S: 10.363636363636363,
  N: 85.86363636363636,
  J: 2.409090909090909,
  St: 536.1818181818181,
  Nt: 4237.727272727273,
  Jt: 131.13636363636363,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 15,
  TimeNow: 472.3636363636364,
  S: 17.0,
  N: 75.18181818181819,
  J: 6.545454545454546,
  St: 859.9090909090909,
  Nt: 3714.0,
  Jt: 330.90909090909093,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 4,
  TimeNow: 417.6666666666667,
  S: 4.0,
  N: 95.0,
  J: 0.0,
  St: 237.0,
  Nt: 4668.0,
  Jt: 0.0,
}, {
  Id: 'GnSJS1Tf4aZHLFVixC1c',
  Hour: 11,
  TimeNow: 509.2307692307692,
  S: 18.384615384615383,
  N: 74.92307692307692,
  J: 5.076923076923077,
  St: 926.6153846153846,
  Nt: 3698.0,
  Jt: 279.53846153846155,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 21,
  TimeNow: 831.6818181818181,
  S: 4.909090909090909,
  N: 92.27272727272727,
  J: 1.2272727272727273,
  St: 502.22727272727275,
  Nt: 8623.181818181818,
  Jt: 174.5909090909091,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 18,
  TimeNow: 1010.84,
  S: 3.36,
  N: 91.04,
  J: 4.16,
  St: 365.36,
  Nt: 8502.04,
  Jt: 432.6,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 12,
  TimeNow: 836.4117647058823,
  S: 3.1176470588235294,
  N: 93.47058823529412,
  J: 1.5294117647058822,
  St: 352.5882352941176,
  Nt: 8742.588235294117,
  Jt: 204.8235294117647,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 14,
  TimeNow: 862.4166666666666,
  S: 3.75,
  N: 92.75,
  J: 2.25,
  St: 378.0,
  Nt: 8668.166666666666,
  Jt: 253.83333333333334,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 13,
  TimeNow: 866.1176470588235,
  S: 3.176470588235294,
  N: 92.41176470588235,
  J: 2.764705882352941,
  St: 350.47058823529414,
  Nt: 8638.529411764706,
  Jt: 311.0,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 3,
  TimeNow: 706.8823529411765,
  S: 12.352941176470589,
  N: 86.52941176470588,
  J: 0.11764705882352941,
  St: 1208.9411764705883,
  Nt: 8078.470588235294,
  Jt: 12.588235294117647,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 0,
  TimeNow: 736.8947368421053,
  S: 2.263157894736842,
  N: 96.21052631578948,
  J: 0.5263157894736842,
  St: 252.05263157894737,
  Nt: 8991.631578947368,
  Jt: 56.31578947368421,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 22,
  TimeNow: 815.8421052631579,
  S: 2.789473684210526,
  N: 94.42105263157895,
  J: 1.1578947368421053,
  St: 320.8421052631579,
  Nt: 8817.368421052632,
  Jt: 161.78947368421052,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 20,
  TimeNow: 894.074074074074,
  S: 5.888888888888889,
  N: 90.37037037037037,
  J: 2.1481481481481484,
  St: 600.5185185185185,
  Nt: 8449.74074074074,
  Jt: 249.74074074074073,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 19,
  TimeNow: 893.6071428571429,
  S: 4.392857142857143,
  N: 91.85714285714286,
  J: 2.1785714285714284,
  St: 460.0357142857143,
  Nt: 8584.67857142857,
  Jt: 255.28571428571428,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 23,
  TimeNow: 771.5,
  S: 2.0,
  N: 95.38888888888889,
  J: 1.3333333333333333,
  St: 236.0,
  Nt: 8902.388888888889,
  Jt: 161.61111111111111,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 17,
  TimeNow: 1092.6666666666667,
  S: 5.888888888888889,
  N: 87.69444444444444,
  J: 5.166666666666667,
  St: 601.9444444444445,
  Nt: 8190.861111111111,
  Jt: 507.19444444444446,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 2,
  TimeNow: 692.8333333333334,
  S: 2.3333333333333335,
  N: 96.33333333333333,
  J: 0.3333333333333333,
  St: 278.1666666666667,
  Nt: 8986.166666666666,
  Jt: 35.666666666666664,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 1,
  TimeNow: 716.4117647058823,
  S: 2.8823529411764706,
  N: 95.76470588235294,
  J: 0.35294117647058826,
  St: 331.88235294117646,
  Nt: 8930.35294117647,
  Jt: 37.76470588235294,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 16,
  TimeNow: 1083.8181818181818,
  S: 6.590909090909091,
  N: 87.0909090909091,
  J: 5.0,
  St: 662.0454545454545,
  Nt: 8138.454545454545,
  Jt: 499.5,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 15,
  TimeNow: 1023.5454545454545,
  S: 4.181818181818182,
  N: 90.0909090909091,
  J: 4.363636363636363,
  St: 444.54545454545456,
  Nt: 8412.818181818182,
  Jt: 442.6363636363636,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 4,
  TimeNow: 734.3333333333334,
  S: 21.666666666666668,
  N: 77.22222222222223,
  J: 0.1111111111111111,
  St: 2052.3333333333335,
  Nt: 7235.777777777777,
  Jt: 11.88888888888889,
}, {
  Id: 'GuUxAcTrgDuZq36ona8p',
  Hour: 11,
  TimeNow: 832.1538461538462,
  S: 2.076923076923077,
  N: 93.53846153846153,
  J: 2.5384615384615383,
  St: 253.92307692307693,
  Nt: 8755.153846153846,
  Jt: 290.9230769230769,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 21,
  TimeNow: 1660.6818181818182,
  S: 6.545454545454546,
  N: 90.72727272727273,
  J: 1.2272727272727273,
  St: 1935.5454545454545,
  Nt: 25121.954545454544,
  Jt: 490.72727272727275,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 18,
  TimeNow: 2100.88,
  S: 9.08,
  N: 78.96,
  J: 10.6,
  St: 2654.64,
  Nt: 23425.64,
  Jt: 3141.68,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 12,
  TimeNow: 1985.3529411764705,
  S: 6.176470588235294,
  N: 87.58823529411765,
  J: 4.588235294117647,
  St: 1834.9411764705883,
  Nt: 24272.058823529413,
  Jt: 1441.5294117647059,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 14,
  TimeNow: 2469.5,
  S: 8.166666666666666,
  N: 80.33333333333333,
  J: 10.0,
  St: 2462.5833333333335,
  Nt: 22798.25,
  Jt: 2984.8333333333335,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 13,
  TimeNow: 1935.764705882353,
  S: 8.058823529411764,
  N: 84.94117647058823,
  J: 5.470588235294118,
  St: 2358.470588235294,
  Nt: 23569.0,
  Jt: 1621.1176470588234,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 3,
  TimeNow: 1390.7058823529412,
  S: 0.5882352941176471,
  N: 98.41176470588235,
  J: 0.0,
  St: 256.88235294117646,
  Nt: 27291.117647058825,
  Jt: 0.0,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 0,
  TimeNow: 1418.7777777777778,
  S: 2.1666666666666665,
  N: 96.83333333333333,
  J: 0.0,
  St: 660.2777777777778,
  Nt: 26883.777777777777,
  Jt: 4.055555555555555,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 22,
  TimeNow: 1526.6315789473683,
  S: 4.052631578947368,
  N: 94.73684210526316,
  J: 0.0,
  St: 1264.8947368421052,
  Nt: 26229.736842105263,
  Jt: 53.73684210526316,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 20,
  TimeNow: 1822.5555555555557,
  S: 11.37037037037037,
  N: 81.96296296296296,
  J: 5.037037037037037,
  St: 3255.3703703703704,
  Nt: 23048.925925925927,
  Jt: 1554.6296296296296,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 19,
  TimeNow: 1993.357142857143,
  S: 11.285714285714286,
  N: 80.03571428571429,
  J: 7.428571428571429,
  St: 3218.464285714286,
  Nt: 22445.928571428572,
  Jt: 2183.5714285714284,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 23,
  TimeNow: 1461.7777777777778,
  S: 2.5,
  N: 96.38888888888889,
  J: 0.0,
  St: 895.2222222222222,
  Nt: 26639.166666666668,
  Jt: 13.666666666666666,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 17,
  TimeNow: 2374.3055555555557,
  S: 13.36111111111111,
  N: 75.72222222222223,
  J: 9.277777777777779,
  St: 4785.305555555556,
  Nt: 25710.0,
  Jt: 3093.6944444444443,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 2,
  TimeNow: 1382.8333333333333,
  S: 3.8333333333333335,
  N: 95.16666666666667,
  J: 0.0,
  St: 1221.8333333333333,
  Nt: 26326.166666666668,
  Jt: 0.0,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 1,
  TimeNow: 1373.1764705882354,
  S: 0.7058823529411765,
  N: 98.29411764705883,
  J: 0.0,
  St: 270.47058823529414,
  Nt: 27277.58823529412,
  Jt: 0.0,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 16,
  TimeNow: 2538.7272727272725,
  S: 9.681818181818182,
  N: 73.5,
  J: 15.363636363636363,
  St: 2773.9545454545455,
  Nt: 20416.5,
  Jt: 4358.681818181818,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 15,
  TimeNow: 2402.818181818182,
  S: 11.0,
  N: 79.45454545454545,
  J: 7.909090909090909,
  St: 3180.6363636363635,
  Nt: 22022.81818181818,
  Jt: 2345.5454545454545,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 4,
  TimeNow: 1392.7777777777778,
  S: 0.2222222222222222,
  N: 98.77777777777777,
  J: 0.0,
  St: 159.55555555555554,
  Nt: 27388.444444444445,
  Jt: 0.0,
}, {
  Id: 'JJ0MT1LnOLOzJAa1uWEv',
  Hour: 11,
  TimeNow: 1820.3846153846155,
  S: 8.153846153846153,
  N: 84.84615384615384,
  J: 5.461538461538462,
  St: 2362.0,
  Nt: 23534.615384615383,
  Jt: 1652.4615384615386,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 21,
  TimeNow: 1078.304347826087,
  S: 18.52173913043478,
  N: 76.95652173913044,
  J: 3.1739130434782608,
  St: 4259.565217391304,
  Nt: 17237.130434782608,
  Jt: 784.1304347826087,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 18,
  TimeNow: 1565.125,
  S: 16.916666666666668,
  N: 57.666666666666664,
  J: 23.916666666666668,
  St: 3870.5416666666665,
  Nt: 12946.291666666666,
  Jt: 5463.916666666667,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 12,
  TimeNow: 1376.3529411764705,
  S: 10.294117647058824,
  N: 74.17647058823529,
  J: 14.117647058823529,
  St: 2401.294117647059,
  Nt: 16643.29411764706,
  Jt: 3235.5882352941176,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 14,
  TimeNow: 1958.25,
  S: 7.916666666666667,
  N: 59.5,
  J: 30.75,
  St: 1887.4166666666667,
  Nt: 13393.083333333334,
  Jt: 7000.333333333333,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 13,
  TimeNow: 1652.4705882352941,
  S: 5.117647058823529,
  N: 71.29411764705883,
  J: 21.764705882352942,
  St: 1281.0,
  Nt: 15990.64705882353,
  Jt: 5009.235294117647,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 3,
  TimeNow: 836.2941176470588,
  S: 1.4705882352941178,
  N: 97.41176470588235,
  J: 0.0,
  St: 421.88235294117646,
  Nt: 21840.058823529413,
  Jt: 19.058823529411764,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 0,
  TimeNow: 870.3333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 22281.0,
  Jt: 0.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 22,
  TimeNow: 1087.421052631579,
  S: 13.894736842105264,
  N: 79.6842105263158,
  J: 5.2631578947368425,
  St: 3179.2631578947367,
  Nt: 17855.842105263157,
  Jt: 1245.578947368421,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 20,
  TimeNow: 1155.111111111111,
  S: 19.296296296296298,
  N: 73.88888888888889,
  J: 5.2592592592592595,
  St: 4425.925925925926,
  Nt: 16589.777777777777,
  Jt: 1264.7037037037037,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 19,
  TimeNow: 1371.1785714285713,
  S: 20.535714285714285,
  N: 61.607142857142854,
  J: 16.535714285714285,
  St: 4665.821428571428,
  Nt: 13824.285714285714,
  Jt: 3790.3571428571427,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 23,
  TimeNow: 1008.5555555555555,
  S: 11.222222222222221,
  N: 84.61111111111111,
  J: 2.8333333333333335,
  St: 2633.1666666666665,
  Nt: 18946.722222222223,
  Jt: 700.9444444444445,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 17,
  TimeNow: 2141.0833333333335,
  S: 24.944444444444443,
  N: 43.583333333333336,
  J: 29.944444444444443,
  St: 5684.777777777777,
  Nt: 9825.694444444445,
  Jt: 6770.472222222223,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 2,
  TimeNow: 826.1666666666666,
  S: 0.16666666666666666,
  N: 98.83333333333333,
  J: 0.0,
  St: 94.0,
  Nt: 22187.0,
  Jt: 0.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 1,
  TimeNow: 838.7058823529412,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 22281.0,
  Jt: 0.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 16,
  TimeNow: 2372.4545454545455,
  S: 21.772727272727273,
  N: 46.59090909090909,
  J: 29.863636363636363,
  St: 4989.818181818182,
  Nt: 10509.90909090909,
  Jt: 6781.272727272727,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 15,
  TimeNow: 2217.7272727272725,
  S: 16.818181818181817,
  N: 44.27272727272727,
  J: 37.36363636363637,
  St: 3854.3636363636365,
  Nt: 9968.0,
  Jt: 8458.636363636364,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 4,
  TimeNow: 835.6666666666666,
  S: 4.777777777777778,
  N: 94.22222222222223,
  J: 0.0,
  St: 1149.111111111111,
  Nt: 21131.88888888889,
  Jt: 0.0,
}, {
  Id: 'JZiIa8J7JXL9yIWQtDKC',
  Hour: 11,
  TimeNow: 1078.3076923076924,
  S: 13.153846153846153,
  N: 78.92307692307692,
  J: 6.230769230769231,
  St: 3048.076923076923,
  Nt: 17720.0,
  Jt: 1512.3846153846155,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 21,
  TimeNow: 1179.608695652174,
  S: 5.434782608695652,
  N: 93.34782608695652,
  J: 0.21739130434782608,
  St: 1331.1739130434783,
  Nt: 21314.0,
  Jt: 58.82608695652174,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 18,
  TimeNow: 2395.48,
  S: 21.72,
  N: 37.96,
  J: 38.76,
  St: 5055.32,
  Nt: 8727.88,
  Jt: 8920.8,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 12,
  TimeNow: 1679.0588235294117,
  S: 11.588235294117647,
  N: 69.6470588235294,
  J: 17.058823529411764,
  St: 2779.176470588235,
  Nt: 15930.588235294117,
  Jt: 3994.235294117647,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 14,
  TimeNow: 2415.25,
  S: 22.416666666666668,
  N: 39.25,
  J: 36.75,
  St: 5219.5,
  Nt: 9025.25,
  Jt: 8459.25,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 13,
  TimeNow: 1948.3529411764705,
  S: 10.235294117647058,
  N: 65.0,
  J: 23.176470588235293,
  St: 2458.3529411764707,
  Nt: 14864.176470588236,
  Jt: 5381.470588235294,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 3,
  TimeNow: 1152.6470588235295,
  S: 3.4705882352941178,
  N: 94.05882352941177,
  J: 0.8823529411764706,
  St: 937.9411764705883,
  Nt: 21474.29411764706,
  Jt: 291.7647058823529,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 0,
  TimeNow: 1034.5555555555557,
  S: 0.16666666666666666,
  N: 98.83333333333333,
  J: 0.0,
  St: 74.44444444444444,
  Nt: 22629.555555555555,
  Jt: 0.0,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 22,
  TimeNow: 1188.2105263157894,
  S: 6.315789473684211,
  N: 90.78947368421052,
  J: 1.6842105263157894,
  St: 1510.9473684210527,
  Nt: 20758.842105263157,
  Jt: 434.2105263157895,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 20,
  TimeNow: 1367.0,
  S: 15.592592592592593,
  N: 77.4074074074074,
  J: 5.62962962962963,
  St: 3640.6296296296296,
  Nt: 17687.333333333332,
  Jt: 1376.037037037037,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 19,
  TimeNow: 1663.7857142857142,
  S: 21.357142857142858,
  N: 58.285714285714285,
  J: 18.964285714285715,
  St: 4938.821428571428,
  Nt: 13353.607142857143,
  Jt: 4411.642857142857,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 23,
  TimeNow: 1151.388888888889,
  S: 5.222222222222222,
  N: 90.77777777777777,
  J: 2.5555555555555554,
  St: 1264.388888888889,
  Nt: 20786.055555555555,
  Jt: 653.5555555555555,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 17,
  TimeNow: 3134.5714285714284,
  S: 26.17142857142857,
  N: 31.885714285714286,
  J: 40.457142857142856,
  St: 6056.085714285714,
  Nt: 7347.6,
  Jt: 9300.371428571429,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 2,
  TimeNow: 1265.0,
  S: 6.0,
  N: 89.33333333333333,
  J: 3.5,
  St: 1436.6666666666667,
  Nt: 20435.166666666668,
  Jt: 832.1666666666666,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 1,
  TimeNow: 1382.9411764705883,
  S: 5.470588235294118,
  N: 87.94117647058823,
  J: 5.176470588235294,
  St: 1352.1176470588234,
  Nt: 20102.823529411766,
  Jt: 1249.0588235294117,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 16,
  TimeNow: 3503.5,
  S: 20.227272727272727,
  N: 34.18181818181818,
  J: 44.04545454545455,
  St: 4732.636363636364,
  Nt: 8030.863636363636,
  Jt: 10170.227272727272,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 15,
  TimeNow: 2719.6363636363635,
  S: 20.545454545454547,
  N: 35.36363636363637,
  J: 42.72727272727273,
  St: 4895.909090909091,
  Nt: 8367.545454545454,
  Jt: 9900.0,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 4,
  TimeNow: 1098.888888888889,
  S: 1.1111111111111112,
  N: 97.44444444444444,
  J: 0.2222222222222222,
  St: 352.77777777777777,
  Nt: 22193.444444444445,
  Jt: 157.77777777777777,
}, {
  Id: 'KK6CpiSlPPG6ChUKmN5g',
  Hour: 11,
  TimeNow: 1141.0,
  S: 3.3846153846153846,
  N: 94.61538461538461,
  J: 1.0,
  St: 843.3846153846154,
  Nt: 21619.69230769231,
  Jt: 240.92307692307693,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 21,
  TimeNow: 113.17391304347827,
  S: 1.0434782608695652,
  N: 97.95652173913044,
  J: 0.0,
  St: 20.26086956521739,
  Nt: 1909.7391304347825,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 18,
  TimeNow: 110.64,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 12,
  TimeNow: 106.47058823529412,
  S: 1.0588235294117647,
  N: 97.94117647058823,
  J: 0.0,
  St: 22.352941176470587,
  Nt: 1907.6470588235295,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 14,
  TimeNow: 104.5,
  S: 0.0,
  N: 98.58333333333333,
  J: 0.3333333333333333,
  St: 1.25,
  Nt: 1921.5,
  Jt: 7.25,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 13,
  TimeNow: 105.17647058823529,
  S: 0.35294117647058826,
  N: 98.6470588235294,
  J: 0.0,
  St: 7.352941176470588,
  Nt: 1922.6470588235295,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 3,
  TimeNow: 105.41176470588235,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 0,
  TimeNow: 106.36842105263158,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 22,
  TimeNow: 114.05263157894737,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 20,
  TimeNow: 114.03703703703704,
  S: 2.111111111111111,
  N: 96.88888888888889,
  J: 0.0,
  St: 40.96296296296296,
  Nt: 1889.037037037037,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 19,
  TimeNow: 113.82142857142857,
  S: 0.42857142857142855,
  N: 98.57142857142857,
  J: 0.0,
  St: 8.321428571428571,
  Nt: 1921.6785714285713,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 23,
  TimeNow: 111.88888888888889,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 17,
  TimeNow: 107.77777777777777,
  S: 0.8333333333333334,
  N: 98.16666666666667,
  J: 0.0,
  St: 16.166666666666668,
  Nt: 1913.8333333333333,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 2,
  TimeNow: 111.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 1,
  TimeNow: 108.94117647058823,
  S: 0.7058823529411765,
  N: 98.29411764705883,
  J: 0.0,
  St: 16.352941176470587,
  Nt: 1913.6470588235295,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 16,
  TimeNow: 103.5909090909091,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 15,
  TimeNow: 104.0909090909091,
  S: 1.4545454545454546,
  N: 97.54545454545455,
  J: 0.0,
  St: 29.727272727272727,
  Nt: 1900.2727272727273,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 4,
  TimeNow: 100.44444444444444,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'KTabvSUbDkLllhZ9M0fz',
  Hour: 11,
  TimeNow: 104.23076923076923,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1930.0,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 21,
  TimeNow: 114.21739130434783,
  S: 0.2608695652173913,
  N: 98.73913043478261,
  J: 0.0,
  St: 3.391304347826087,
  Nt: 1058.608695652174,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 18,
  TimeNow: 146.72,
  S: 9.88,
  N: 82.92,
  J: 6.0,
  St: 110.16,
  Nt: 885.56,
  Jt: 66.28,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 12,
  TimeNow: 119.23529411764706,
  S: 9.882352941176471,
  N: 88.58823529411765,
  J: 0.4117647058823529,
  St: 107.82352941176471,
  Nt: 949.0,
  Jt: 5.176470588235294,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 14,
  TimeNow: 111.75,
  S: 0.5,
  N: 98.33333333333333,
  J: 0.0,
  St: 7.583333333333333,
  Nt: 1054.4166666666667,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 13,
  TimeNow: 111.94117647058823,
  S: 0.7647058823529411,
  N: 98.17647058823529,
  J: 0.0,
  St: 9.823529411764707,
  Nt: 1052.1764705882354,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 3,
  TimeNow: 114.52941176470588,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1062.0,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 0,
  TimeNow: 112.6842105263158,
  S: 0.21052631578947367,
  N: 98.57894736842105,
  J: 0.0,
  St: 4.421052631578948,
  Nt: 1057.578947368421,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 22,
  TimeNow: 112.26315789473684,
  S: 0.2631578947368421,
  N: 98.47368421052632,
  J: 0.0,
  St: 5.526315789473684,
  Nt: 1056.4736842105262,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 20,
  TimeNow: 117.70370370370371,
  S: 5.888888888888889,
  N: 92.11111111111111,
  J: 0.7777777777777778,
  St: 68.22222222222223,
  Nt: 984.8148148148148,
  Jt: 8.962962962962964,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 19,
  TimeNow: 118.77777777777777,
  S: 3.3703703703703702,
  N: 94.4074074074074,
  J: 1.1111111111111112,
  St: 37.888888888888886,
  Nt: 1011.5185185185185,
  Jt: 12.592592592592593,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 23,
  TimeNow: 109.61111111111111,
  S: 1.2222222222222223,
  N: 97.72222222222223,
  J: 0.0,
  St: 15.833333333333334,
  Nt: 1046.1666666666667,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 17,
  TimeNow: 128.27777777777777,
  S: 10.666666666666666,
  N: 85.22222222222223,
  J: 2.75,
  St: 119.19444444444444,
  Nt: 911.0,
  Jt: 31.805555555555557,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 2,
  TimeNow: 112.33333333333333,
  S: 1.8333333333333333,
  N: 97.16666666666667,
  J: 0.0,
  St: 19.833333333333332,
  Nt: 1042.1666666666667,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 1,
  TimeNow: 112.29411764705883,
  S: 3.0,
  N: 95.88235294117646,
  J: 0.0,
  St: 38.11764705882353,
  Nt: 1023.8823529411765,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 16,
  TimeNow: 120.54545454545455,
  S: 11.136363636363637,
  N: 87.86363636363636,
  J: 0.0,
  St: 123.18181818181819,
  Nt: 938.8181818181819,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 15,
  TimeNow: 110.9090909090909,
  S: 2.3636363636363638,
  N: 96.54545454545455,
  J: 0.0,
  St: 27.454545454545453,
  Nt: 1034.5454545454545,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 4,
  TimeNow: 112.22222222222223,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1062.0,
  Jt: 0.0,
}, {
  Id: 'Lh8UanQXJltQacha7VGq',
  Hour: 11,
  TimeNow: 116.15384615384616,
  S: 11.0,
  N: 87.38461538461539,
  J: 0.3076923076923077,
  St: 121.6923076923077,
  Nt: 933.8461538461538,
  Jt: 6.461538461538462,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 21,
  TimeNow: 81.30434782608695,
  S: 2.1739130434782608,
  N: 96.82608695652173,
  J: 0.0,
  St: 40.608695652173914,
  Nt: 1720.391304347826,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 18,
  TimeNow: 111.64,
  S: 13.28,
  N: 79.44,
  J: 6.0,
  St: 240.48,
  Nt: 1406.56,
  Jt: 113.96,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 14,
  TimeNow: 565.0,
  S: 22.416666666666668,
  N: 1.6666666666666667,
  J: 74.91666666666667,
  St: 399.9166666666667,
  Nt: 29.416666666666668,
  Jt: 1331.6666666666667,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 0,
  TimeNow: 72.44444444444444,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1761.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 22,
  TimeNow: 78.15789473684211,
  S: 0.3157894736842105,
  N: 98.6842105263158,
  J: 0.0,
  St: 5.578947368421052,
  Nt: 1755.421052631579,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 20,
  TimeNow: 92.22222222222223,
  S: 8.88888888888889,
  N: 88.11111111111111,
  J: 2.0,
  St: 166.2962962962963,
  Nt: 1557.148148148148,
  Jt: 37.55555555555556,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 19,
  TimeNow: 192.57142857142858,
  S: 17.571428571428573,
  N: 58.57142857142857,
  J: 22.428571428571427,
  St: 316.07142857142856,
  Nt: 1036.142857142857,
  Jt: 408.7857142857143,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 23,
  TimeNow: 75.22222222222223,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1761.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 17,
  TimeNow: 534.6666666666666,
  S: 7.888888888888889,
  N: 22.27777777777778,
  J: 68.63888888888889,
  St: 142.05555555555554,
  Nt: 395.22222222222223,
  Jt: 1223.7222222222222,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 2,
  TimeNow: 69.33333333333333,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1761.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 1,
  TimeNow: 71.0625,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1761.0,
  Jt: 0.0,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 16,
  TimeNow: 611.8636363636364,
  S: 12.227272727272727,
  N: 0.0,
  J: 86.77272727272727,
  St: 222.22727272727272,
  Nt: 0.0,
  Jt: 1538.7727272727273,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 15,
  TimeNow: 479.72727272727275,
  S: 38.90909090909091,
  N: 3.272727272727273,
  J: 56.63636363636363,
  St: 691.6363636363636,
  Nt: 60.18181818181818,
  Jt: 1009.1818181818181,
}, {
  Id: 'MvAnpzEUy1wRjUknb2Wq',
  Hour: 13,
  TimeNow: 650.5714285714286,
  S: 27.285714285714285,
  N: 4.857142857142857,
  J: 66.57142857142857,
  St: 490.2857142857143,
  Nt: 90.14285714285714,
  Jt: 1180.5714285714287,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 21,
  TimeNow: 120.82608695652173,
  S: 9.91304347826087,
  N: 89.04347826086956,
  J: 0.0,
  St: 151.43478260869566,
  Nt: 1315.608695652174,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 18,
  TimeNow: 126.33333333333333,
  S: 18.25,
  N: 74.625,
  J: 5.541666666666667,
  St: 274.9583333333333,
  Nt: 1103.25,
  Jt: 88.875,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 12,
  TimeNow: 182.76470588235293,
  S: 27.823529411764707,
  N: 38.529411764705884,
  J: 31.705882352941178,
  St: 415.88235294117646,
  Nt: 573.8823529411765,
  Jt: 477.2352941176471,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 14,
  TimeNow: 183.58333333333334,
  S: 37.416666666666664,
  N: 37.666666666666664,
  J: 23.166666666666668,
  St: 556.3333333333334,
  Nt: 560.1666666666666,
  Jt: 350.9166666666667,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 13,
  TimeNow: 176.47058823529412,
  S: 26.647058823529413,
  N: 34.76470588235294,
  J: 36.705882352941174,
  St: 397.88235294117646,
  Nt: 518.7058823529412,
  Jt: 550.7647058823529,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 3,
  TimeNow: 110.52941176470588,
  S: 6.235294117647059,
  N: 92.76470588235294,
  J: 0.0,
  St: 94.70588235294117,
  Nt: 1372.2941176470588,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 0,
  TimeNow: 115.42105263157895,
  S: 3.5789473684210527,
  N: 95.42105263157895,
  J: 0.0,
  St: 54.421052631578945,
  Nt: 1412.578947368421,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 22,
  TimeNow: 119.3157894736842,
  S: 1.5789473684210527,
  N: 97.42105263157895,
  J: 0.0,
  St: 31.842105263157894,
  Nt: 1435.2105263157894,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 20,
  TimeNow: 124.81481481481481,
  S: 22.62962962962963,
  N: 72.48148148148148,
  J: 3.4814814814814814,
  St: 340.81481481481484,
  Nt: 1072.5185185185185,
  Jt: 53.7037037037037,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 19,
  TimeNow: 136.71428571428572,
  S: 14.857142857142858,
  N: 65.28571428571429,
  J: 18.25,
  St: 222.42857142857142,
  Nt: 965.7142857142857,
  Jt: 278.92857142857144,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 23,
  TimeNow: 116.94444444444444,
  S: 1.3888888888888888,
  N: 97.61111111111111,
  J: 0.0,
  St: 21.444444444444443,
  Nt: 1445.5555555555557,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 17,
  TimeNow: 300.94444444444446,
  S: 30.27777777777778,
  N: 45.69444444444444,
  J: 22.416666666666668,
  St: 468.52777777777777,
  Nt: 708.8888888888889,
  Jt: 345.22222222222223,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 2,
  TimeNow: 113.66666666666667,
  S: 0.16666666666666666,
  N: 98.83333333333333,
  J: 0.0,
  St: 3.3333333333333335,
  Nt: 1463.8333333333333,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 1,
  TimeNow: 114.23529411764706,
  S: 1.6470588235294117,
  N: 97.3529411764706,
  J: 0.0,
  St: 27.529411764705884,
  Nt: 1439.9411764705883,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 16,
  TimeNow: 256.8181818181818,
  S: 36.90909090909091,
  N: 25.272727272727273,
  J: 36.27272727272727,
  St: 550.0,
  Nt: 376.90909090909093,
  Jt: 540.1363636363636,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 15,
  TimeNow: 160.36363636363637,
  S: 34.63636363636363,
  N: 53.27272727272727,
  J: 10.636363636363637,
  St: 514.4545454545455,
  Nt: 787.7272727272727,
  Jt: 165.27272727272728,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 4,
  TimeNow: 112.77777777777777,
  S: 1.2222222222222223,
  N: 97.77777777777777,
  J: 0.0,
  St: 18.333333333333332,
  Nt: 1448.6666666666667,
  Jt: 0.0,
}, {
  Id: 'NkQgYfbxHPHM9xFmIzWK',
  Hour: 11,
  TimeNow: 192.3846153846154,
  S: 38.69230769230769,
  N: 38.23076923076923,
  J: 21.53846153846154,
  St: 574.7692307692307,
  Nt: 566.0769230769231,
  Jt: 326.15384615384613,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 21,
  TimeNow: 278.95652173913044,
  S: 1.391304347826087,
  N: 94.47826086956522,
  J: 2.391304347826087,
  St: 88.1304347826087,
  Nt: 4104.086956521739,
  Jt: 138.7826086956522,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 18,
  TimeNow: 413.0,
  S: 1.92,
  N: 88.36,
  J: 8.32,
  St: 96.92,
  Nt: 3851.56,
  Jt: 382.52,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 12,
  TimeNow: 270.05882352941177,
  S: 0.7647058823529411,
  N: 96.11764705882354,
  J: 1.7647058823529411,
  St: 46.1764705882353,
  Nt: 4174.529411764706,
  Jt: 110.29411764705883,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 14,
  TimeNow: 483.0,
  S: 8.25,
  N: 74.25,
  J: 15.916666666666666,
  St: 378.3333333333333,
  Nt: 3234.0833333333335,
  Jt: 718.5833333333334,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 13,
  TimeNow: 289.7647058823529,
  S: 2.176470588235294,
  N: 94.23529411764706,
  J: 2.1176470588235294,
  St: 109.11764705882354,
  Nt: 4100.176470588235,
  Jt: 121.70588235294117,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 3,
  TimeNow: 232.05882352941177,
  S: 1.1176470588235294,
  N: 97.88235294117646,
  J: 0.0,
  St: 74.05882352941177,
  Nt: 4256.941176470588,
  Jt: 0.0,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 0,
  TimeNow: 256.57894736842104,
  S: 0.7894736842105263,
  N: 96.78947368421052,
  J: 1.263157894736842,
  St: 55.526315789473685,
  Nt: 4196.368421052632,
  Jt: 79.10526315789474,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 22,
  TimeNow: 268.2631578947368,
  S: 0.8421052631578947,
  N: 96.15789473684211,
  J: 1.6842105263157894,
  St: 53.63157894736842,
  Nt: 4172.0,
  Jt: 105.36842105263158,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 20,
  TimeNow: 294.9259259259259,
  S: 1.7037037037037037,
  N: 94.14814814814815,
  J: 2.7777777777777777,
  St: 91.22222222222223,
  Nt: 4093.037037037037,
  Jt: 146.74074074074073,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 19,
  TimeNow: 298.82142857142856,
  S: 1.6071428571428572,
  N: 94.32142857142857,
  J: 2.5357142857142856,
  St: 88.32142857142857,
  Nt: 4098.142857142857,
  Jt: 144.53571428571428,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 23,
  TimeNow: 260.5,
  S: 1.3333333333333333,
  N: 96.11111111111111,
  J: 1.3888888888888888,
  St: 68.94444444444444,
  Nt: 4177.277777777777,
  Jt: 84.77777777777777,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 17,
  TimeNow: 627.75,
  S: 8.25,
  N: 72.52777777777777,
  J: 17.86111111111111,
  St: 374.25,
  Nt: 3163.75,
  Jt: 793.0,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 2,
  TimeNow: 234.33333333333334,
  S: 2.1666666666666665,
  N: 96.83333333333333,
  J: 0.0,
  St: 133.5,
  Nt: 4197.5,
  Jt: 0.0,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 1,
  TimeNow: 242.41176470588235,
  S: 2.0588235294117645,
  N: 96.82352941176471,
  J: 0.11764705882352941,
  St: 121.17647058823529,
  Nt: 4202.35294117647,
  Jt: 7.470588235294118,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 16,
  TimeNow: 681.4090909090909,
  S: 10.545454545454545,
  N: 56.68181818181818,
  J: 31.40909090909091,
  St: 474.77272727272725,
  Nt: 2472.3636363636365,
  Jt: 1383.8636363636363,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 15,
  TimeNow: 611.0909090909091,
  S: 21.363636363636363,
  N: 58.63636363636363,
  J: 18.636363636363637,
  St: 946.0,
  Nt: 2556.5454545454545,
  Jt: 828.4545454545455,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 4,
  TimeNow: 215.11111111111111,
  S: 0.1111111111111111,
  N: 98.88888888888889,
  J: 0.0,
  St: 8.222222222222221,
  Nt: 4322.777777777777,
  Jt: 0.0,
}, {
  Id: 'NlrvbOqaEZ95gJLZQJHK',
  Hour: 11,
  TimeNow: 278.0,
  S: 0.6923076923076923,
  N: 96.46153846153847,
  J: 1.6923076923076923,
  St: 44.30769230769231,
  Nt: 4185.461538461538,
  Jt: 101.23076923076923,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 21,
  TimeNow: 382.5652173913044,
  S: 28.347826086956523,
  N: 67.0,
  J: 3.5217391304347827,
  St: 2133.9565217391305,
  Nt: 4978.260869565217,
  Jt: 271.7826086956522,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 18,
  TimeNow: 1071.56,
  S: 21.08,
  N: 6.36,
  J: 71.04,
  St: 1590.0,
  Nt: 506.8,
  Jt: 5287.2,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 12,
  TimeNow: 354.3529411764706,
  S: 10.588235294117647,
  N: 88.41176470588235,
  J: 0.0,
  St: 811.3529411764706,
  Nt: 6572.64705882353,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 14,
  TimeNow: 636.4166666666666,
  S: 53.833333333333336,
  N: 21.25,
  J: 23.5,
  St: 4015.8333333333335,
  Nt: 1609.9166666666667,
  Jt: 1758.25,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 13,
  TimeNow: 478.94117647058823,
  S: 34.88235294117647,
  N: 49.411764705882355,
  J: 14.588235294117647,
  St: 2610.823529411765,
  Nt: 3675.0,
  Jt: 1098.1764705882354,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 3,
  TimeNow: 269.4117647058824,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7384.0,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 0,
  TimeNow: 299.2631578947368,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7384.0,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 22,
  TimeNow: 330.10526315789474,
  S: 5.578947368421052,
  N: 93.42105263157895,
  J: 0.0,
  St: 419.3157894736842,
  Nt: 6964.684210526316,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 20,
  TimeNow: 536.2592592592592,
  S: 47.51851851851852,
  N: 16.74074074074074,
  J: 34.51851851851852,
  St: 3539.5185185185187,
  Nt: 1263.0,
  Jt: 2581.4814814814813,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 19,
  TimeNow: 584.0,
  S: 47.17857142857143,
  N: 25.25,
  J: 26.357142857142858,
  St: 3518.035714285714,
  Nt: 1895.642857142857,
  Jt: 1970.3214285714287,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 23,
  TimeNow: 315.6111111111111,
  S: 0.05555555555555555,
  N: 98.94444444444444,
  J: 0.0,
  St: 5.666666666666667,
  Nt: 7378.333333333333,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 17,
  TimeNow: 1526.7222222222222,
  S: 4.583333333333333,
  N: 3.4166666666666665,
  J: 90.41666666666667,
  St: 372.80555555555554,
  Nt: 291.94444444444446,
  Jt: 6719.25,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 2,
  TimeNow: 276.1666666666667,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7384.0,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 1,
  TimeNow: 283.52941176470586,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7384.0,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 16,
  TimeNow: 1254.9545454545455,
  S: 14.909090909090908,
  N: 5.681818181818182,
  J: 77.54545454545455,
  St: 1152.1363636363637,
  Nt: 464.72727272727275,
  Jt: 5767.136363636364,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 15,
  TimeNow: 901.5454545454545,
  S: 27.90909090909091,
  N: 21.454545454545453,
  J: 49.09090909090909,
  St: 2092.181818181818,
  Nt: 1623.090909090909,
  Jt: 3668.7272727272725,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 4,
  TimeNow: 269.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7384.0,
  Jt: 0.0,
}, {
  Id: 'PElsdRZHXvD54ZwV4qsi',
  Hour: 11,
  TimeNow: 374.61538461538464,
  S: 16.384615384615383,
  N: 74.76923076923077,
  J: 7.769230769230769,
  St: 1238.6153846153845,
  Nt: 5557.615384615385,
  Jt: 587.7692307692307,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 21,
  TimeNow: 227.30434782608697,
  S: 2.739130434782609,
  N: 96.1304347826087,
  J: 0.08695652173913043,
  St: 104.82608695652173,
  Nt: 3520.478260869565,
  Jt: 3.6956521739130435,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 18,
  TimeNow: 225.91666666666666,
  S: 0.2916666666666667,
  N: 97.75,
  J: 0.9166666666666666,
  St: 18.875,
  Nt: 3572.2916666666665,
  Jt: 37.833333333333336,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 12,
  TimeNow: 209.1764705882353,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 14,
  TimeNow: 213.83333333333334,
  S: 1.5,
  N: 97.5,
  J: 0.0,
  St: 57.416666666666664,
  Nt: 3571.5833333333335,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 13,
  TimeNow: 210.58823529411765,
  S: 0.058823529411764705,
  N: 98.94117647058823,
  J: 0.0,
  St: 3.9411764705882355,
  Nt: 3625.0588235294117,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 3,
  TimeNow: 198.64705882352942,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 0,
  TimeNow: 217.26315789473685,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 22,
  TimeNow: 226.94736842105263,
  S: 0.3684210526315789,
  N: 98.63157894736842,
  J: 0.0,
  St: 15.947368421052632,
  Nt: 3613.0526315789475,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 20,
  TimeNow: 222.8148148148148,
  S: 0.037037037037037035,
  N: 98.81481481481481,
  J: 0.14814814814814814,
  St: 4.037037037037037,
  Nt: 3619.1111111111113,
  Jt: 5.851851851851852,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 19,
  TimeNow: 220.39285714285714,
  S: 0.03571428571428571,
  N: 98.82142857142857,
  J: 0.14285714285714285,
  St: 2.107142857142857,
  Nt: 3621.25,
  Jt: 5.642857142857143,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 23,
  TimeNow: 224.16666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 1.3888888888888888,
  Nt: 3627.6111111111113,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 17,
  TimeNow: 237.52777777777777,
  S: 3.9444444444444446,
  N: 93.25,
  J: 1.8055555555555556,
  St: 151.19444444444446,
  Nt: 3401.6111111111113,
  Jt: 76.19444444444444,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 2,
  TimeNow: 211.16666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 1,
  TimeNow: 218.8125,
  S: 0.125,
  N: 98.875,
  J: 0.0,
  St: 5.3125,
  Nt: 3623.6875,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 16,
  TimeNow: 234.1818181818182,
  S: 5.7272727272727275,
  N: 92.72727272727273,
  J: 0.45454545454545453,
  St: 222.8181818181818,
  Nt: 3387.6363636363635,
  Jt: 18.545454545454547,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 15,
  TimeNow: 222.72727272727272,
  S: 4.818181818181818,
  N: 94.18181818181819,
  J: 0.0,
  St: 182.27272727272728,
  Nt: 3446.7272727272725,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 4,
  TimeNow: 197.88888888888889,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'Q5nsyZclh4X6utoxdCBr',
  Hour: 11,
  TimeNow: 206.6153846153846,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3629.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 21,
  TimeNow: 159.69565217391303,
  S: 0.9130434782608695,
  N: 98.08695652173913,
  J: 0.0,
  St: 39.47826086956522,
  Nt: 3552.521739130435,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 18,
  TimeNow: 189.54166666666666,
  S: 6.0,
  N: 89.91666666666667,
  J: 2.8333333333333335,
  St: 231.83333333333334,
  Nt: 3250.4583333333335,
  Jt: 109.70833333333333,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 14,
  TimeNow: 768.5,
  S: 22.75,
  N: 6.083333333333333,
  J: 70.0,
  St: 834.4166666666666,
  Nt: 221.5,
  Jt: 2536.0833333333335,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 0,
  TimeNow: 145.57894736842104,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3592.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 22,
  TimeNow: 156.26315789473685,
  S: 0.10526315789473684,
  N: 98.89473684210526,
  J: 0.0,
  St: 5.368421052631579,
  Nt: 3586.6315789473683,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 20,
  TimeNow: 169.40740740740742,
  S: 4.296296296296297,
  N: 93.66666666666667,
  J: 0.8888888888888888,
  St: 163.40740740740742,
  Nt: 3392.1481481481483,
  Jt: 36.44444444444444,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 19,
  TimeNow: 271.0357142857143,
  S: 8.714285714285714,
  N: 79.07142857142857,
  J: 10.785714285714286,
  St: 329.5,
  Nt: 2857.714285714286,
  Jt: 404.7857142857143,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 23,
  TimeNow: 150.44444444444446,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3592.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 17,
  TimeNow: 675.0555555555555,
  S: 10.722222222222221,
  N: 46.611111111111114,
  J: 41.30555555555556,
  St: 399.69444444444446,
  Nt: 1688.9444444444443,
  Jt: 1503.361111111111,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 2,
  TimeNow: 141.66666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3592.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 1,
  TimeNow: 143.41176470588235,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3592.0,
  Jt: 0.0,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 16,
  TimeNow: 760.9545454545455,
  S: 18.272727272727273,
  N: 30.136363636363637,
  J: 50.04545454545455,
  St: 671.7727272727273,
  Nt: 1097.1363636363637,
  Jt: 1823.090909090909,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 15,
  TimeNow: 625.0,
  S: 30.727272727272727,
  N: 21.636363636363637,
  J: 46.18181818181818,
  St: 1120.909090909091,
  Nt: 791.3636363636364,
  Jt: 1679.7272727272727,
}, {
  Id: 'QTnF9muZIf3AKWIzWJqq',
  Hour: 13,
  TimeNow: 825.6666666666666,
  S: 36.0,
  N: 21.666666666666668,
  J: 41.166666666666664,
  St: 1306.0,
  Nt: 790.8333333333334,
  Jt: 1495.1666666666667,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 21,
  TimeNow: 176.08695652173913,
  S: 0.17391304347826086,
  N: 98.47826086956522,
  J: 0.34782608695652173,
  St: 7.521739130434782,
  Nt: 3663.2608695652175,
  Jt: 14.26086956521739,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 18,
  TimeNow: 347.0,
  S: 7.333333333333333,
  N: 52.583333333333336,
  J: 38.5,
  St: 289.3333333333333,
  Nt: 1954.9166666666667,
  Jt: 1440.5,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 14,
  TimeNow: 268.6666666666667,
  S: 13.25,
  N: 67.58333333333333,
  J: 17.916666666666668,
  St: 506.0833333333333,
  Nt: 2506.3333333333335,
  Jt: 672.5833333333334,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 0,
  TimeNow: 166.68421052631578,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 22,
  TimeNow: 172.10526315789474,
  S: 0.10526315789473684,
  N: 98.89473684210526,
  J: 0.0,
  St: 5.157894736842105,
  Nt: 3679.842105263158,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 20,
  TimeNow: 187.65384615384616,
  S: 1.5,
  N: 96.42307692307692,
  J: 1.0384615384615385,
  St: 60.11538461538461,
  Nt: 3585.3846153846152,
  Jt: 39.5,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 19,
  TimeNow: 239.07142857142858,
  S: 11.142857142857142,
  N: 71.32142857142857,
  J: 16.285714285714285,
  St: 424.60714285714283,
  Nt: 2644.535714285714,
  Jt: 615.9642857142857,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 23,
  TimeNow: 170.72222222222223,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 17,
  TimeNow: 554.75,
  S: 4.333333333333333,
  N: 45.666666666666664,
  J: 48.583333333333336,
  St: 174.16666666666666,
  Nt: 1697.3333333333333,
  Jt: 1813.5833333333333,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 2,
  TimeNow: 162.66666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3685.0,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 1,
  TimeNow: 165.35294117647058,
  S: 0.23529411764705882,
  N: 98.76470588235294,
  J: 0.0,
  St: 9.352941176470589,
  Nt: 3675.705882352941,
  Jt: 0.0,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 16,
  TimeNow: 546.9090909090909,
  S: 3.272727272727273,
  N: 46.09090909090909,
  J: 49.31818181818182,
  St: 131.54545454545453,
  Nt: 1716.3181818181818,
  Jt: 1836.9545454545455,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 15,
  TimeNow: 394.90909090909093,
  S: 4.636363636363637,
  N: 50.81818181818182,
  J: 42.90909090909091,
  St: 188.0909090909091,
  Nt: 1890.2727272727273,
  Jt: 1605.909090909091,
}, {
  Id: 'Qz3oMEwGZDIqB05vTeD5',
  Hour: 13,
  TimeNow: 189.0,
  S: 13.166666666666666,
  N: 85.83333333333333,
  J: 0.0,
  St: 502.5,
  Nt: 3182.5,
  Jt: 0.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 21,
  TimeNow: 194.6086956521739,
  S: 5.0,
  N: 87.91304347826087,
  J: 5.260869565217392,
  St: 104.21739130434783,
  Nt: 1591.8260869565217,
  Jt: 106.95652173913044,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 18,
  TimeNow: 176.48,
  S: 2.8,
  N: 91.36,
  J: 4.56,
  St: 59.96,
  Nt: 1649.2,
  Jt: 93.84,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 12,
  TimeNow: 168.94117647058823,
  S: 4.0588235294117645,
  N: 91.0,
  J: 3.588235294117647,
  St: 85.94117647058823,
  Nt: 1644.7058823529412,
  Jt: 72.3529411764706,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 14,
  TimeNow: 160.66666666666666,
  S: 3.1666666666666665,
  N: 92.0,
  J: 3.6666666666666665,
  St: 66.0,
  Nt: 1660.5,
  Jt: 76.5,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 13,
  TimeNow: 166.23529411764707,
  S: 3.411764705882353,
  N: 90.88235294117646,
  J: 4.352941176470588,
  St: 70.88235294117646,
  Nt: 1643.3529411764705,
  Jt: 88.76470588235294,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 3,
  TimeNow: 131.8235294117647,
  S: 6.352941176470588,
  N: 92.6470588235294,
  J: 0.0,
  St: 121.23529411764706,
  Nt: 1681.764705882353,
  Jt: 0.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 0,
  TimeNow: 151.26315789473685,
  S: 6.052631578947368,
  N: 92.21052631578948,
  J: 0.7368421052631579,
  St: 123.3157894736842,
  Nt: 1663.421052631579,
  Jt: 16.263157894736842,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 22,
  TimeNow: 181.42105263157896,
  S: 12.473684210526315,
  N: 81.57894736842105,
  J: 4.421052631578948,
  St: 232.68421052631578,
  Nt: 1480.157894736842,
  Jt: 90.15789473684211,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 20,
  TimeNow: 193.07407407407408,
  S: 5.703703703703703,
  N: 87.70370370370371,
  J: 5.111111111111111,
  St: 112.92592592592592,
  Nt: 1586.7407407407406,
  Jt: 103.33333333333333,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 19,
  TimeNow: 176.10714285714286,
  S: 2.857142857142857,
  N: 90.78571428571429,
  J: 4.642857142857143,
  St: 66.10714285714286,
  Nt: 1642.1785714285713,
  Jt: 94.71428571428571,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 23,
  TimeNow: 176.0,
  S: 7.5,
  N: 87.61111111111111,
  J: 3.611111111111111,
  St: 145.44444444444446,
  Nt: 1583.888888888889,
  Jt: 73.66666666666667,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 17,
  TimeNow: 176.25,
  S: 2.8333333333333335,
  N: 90.97222222222223,
  J: 5.0,
  St: 57.583333333333336,
  Nt: 1643.4166666666667,
  Jt: 102.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 2,
  TimeNow: 148.16666666666666,
  S: 7.666666666666667,
  N: 91.33333333333333,
  J: 0.0,
  St: 147.16666666666666,
  Nt: 1655.8333333333333,
  Jt: 0.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 1,
  TimeNow: 148.64705882352942,
  S: 9.705882352941176,
  N: 89.29411764705883,
  J: 0.0,
  St: 188.7058823529412,
  Nt: 1614.2941176470588,
  Jt: 0.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 16,
  TimeNow: 183.5,
  S: 4.2727272727272725,
  N: 90.0,
  J: 4.545454545454546,
  St: 83.86363636363636,
  Nt: 1626.409090909091,
  Jt: 92.72727272727273,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 15,
  TimeNow: 159.63636363636363,
  S: 2.3636363636363638,
  N: 91.63636363636364,
  J: 4.636363636363637,
  St: 52.36363636363637,
  Nt: 1654.6363636363637,
  Jt: 96.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 4,
  TimeNow: 131.77777777777777,
  S: 6.333333333333333,
  N: 92.66666666666667,
  J: 0.0,
  St: 130.0,
  Nt: 1673.0,
  Jt: 0.0,
}, {
  Id: 'Spo0PQkpY5nEurTi1JZV',
  Hour: 11,
  TimeNow: 332.0,
  S: 2.6923076923076925,
  N: 87.61538461538461,
  J: 8.538461538461538,
  St: 58.84615384615385,
  Nt: 1585.6153846153845,
  Jt: 158.53846153846155,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 21,
  TimeNow: 258.1304347826087,
  S: 0.391304347826087,
  N: 98.6086956521739,
  J: 0.0,
  St: 32.30434782608695,
  Nt: 5893.652173913043,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 18,
  TimeNow: 293.84,
  S: 4.48,
  N: 92.72,
  J: 1.56,
  St: 288.68,
  Nt: 5528.56,
  Jt: 108.28,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 14,
  TimeNow: 907.9166666666666,
  S: 24.666666666666668,
  N: 30.083333333333332,
  J: 43.75,
  St: 1493.0833333333333,
  Nt: 1810.8333333333333,
  Jt: 2622.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 0,
  TimeNow: 225.31578947368422,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5926.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 22,
  TimeNow: 255.1578947368421,
  S: 0.5789473684210527,
  N: 98.42105263157895,
  J: 0.0,
  St: 37.68421052631579,
  Nt: 5888.315789473684,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 20,
  TimeNow: 271.037037037037,
  S: 2.5555555555555554,
  N: 95.81481481481481,
  J: 0.48148148148148145,
  St: 167.37037037037038,
  Nt: 5723.518518518518,
  Jt: 34.18518518518518,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 19,
  TimeNow: 372.75,
  S: 5.178571428571429,
  N: 87.17857142857143,
  J: 6.464285714285714,
  St: 329.64285714285717,
  Nt: 5189.035714285715,
  Jt: 407.0357142857143,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 23,
  TimeNow: 239.83333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 1.3333333333333333,
  Nt: 5924.666666666667,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 17,
  TimeNow: 746.0,
  S: 6.75,
  N: 67.08333333333333,
  J: 24.88888888888889,
  St: 420.1111111111111,
  Nt: 3999.9166666666665,
  Jt: 1505.75,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 2,
  TimeNow: 216.83333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5926.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 1,
  TimeNow: 220.1764705882353,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5926.0,
  Jt: 0.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 16,
  TimeNow: 840.1818181818181,
  S: 10.772727272727273,
  N: 57.36363636363637,
  J: 30.272727272727273,
  St: 671.0454545454545,
  Nt: 3428.8636363636365,
  Jt: 1825.6363636363637,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 15,
  TimeNow: 731.2727272727273,
  S: 18.90909090909091,
  N: 51.45454545454545,
  J: 28.181818181818183,
  St: 1141.8181818181818,
  Nt: 3078.7272727272725,
  Jt: 1705.0,
}, {
  Id: 'SvsubPFf0KJzL2nE7zav',
  Hour: 13,
  TimeNow: 926.6666666666666,
  S: 32.0,
  N: 39.833333333333336,
  J: 26.5,
  St: 1934.5,
  Nt: 2393.8333333333335,
  Jt: 1597.3333333333333,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 21,
  TimeNow: 52.21739130434783,
  S: 41.47826086956522,
  N: 50.47826086956522,
  J: 7.043478260869565,
  St: 156.2608695652174,
  Nt: 188.3913043478261,
  Jt: 26.347826086956523,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 18,
  TimeNow: 76.6,
  S: 54.04,
  N: 0.12,
  J: 44.84,
  St: 202.72,
  Nt: 0.52,
  Jt: 167.76,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 12,
  TimeNow: 133.41176470588235,
  S: 18.705882352941178,
  N: 0.17647058823529413,
  J: 80.11764705882354,
  St: 71.23529411764706,
  Nt: 0.7647058823529411,
  Jt: 299.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 14,
  TimeNow: 137.5,
  S: 11.75,
  N: 0.0,
  J: 87.25,
  St: 45.0,
  Nt: 0.0,
  Jt: 326.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 13,
  TimeNow: 142.94117647058823,
  S: 12.352941176470589,
  N: 0.0,
  J: 86.6470588235294,
  St: 47.88235294117647,
  Nt: 0.0,
  Jt: 323.11764705882354,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 3,
  TimeNow: 37.529411764705884,
  S: 12.705882352941176,
  N: 86.29411764705883,
  J: 0.0,
  St: 48.94117647058823,
  Nt: 322.05882352941177,
  Jt: 0.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 0,
  TimeNow: 35.10526315789474,
  S: 0.3157894736842105,
  N: 98.52631578947368,
  J: 0.15789473684210525,
  St: 1.368421052631579,
  Nt: 368.94736842105266,
  Jt: 0.6842105263157895,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 22,
  TimeNow: 37.31578947368421,
  S: 2.6842105263157894,
  N: 96.26315789473684,
  J: 0.0,
  St: 10.842105263157896,
  Nt: 360.1578947368421,
  Jt: 0.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 20,
  TimeNow: 88.33333333333333,
  S: 43.629629629629626,
  N: 0.8148148148148148,
  J: 54.51851851851852,
  St: 163.59259259259258,
  Nt: 3.037037037037037,
  Jt: 204.37037037037038,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 19,
  TimeNow: 165.25,
  S: 4.464285714285714,
  N: 0.0,
  J: 94.53571428571429,
  St: 17.964285714285715,
  Nt: 0.0,
  Jt: 353.0357142857143,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 23,
  TimeNow: 37.27777777777778,
  S: 0.6666666666666666,
  N: 98.33333333333333,
  J: 0.0,
  St: 2.888888888888889,
  Nt: 368.1111111111111,
  Jt: 0.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 17,
  TimeNow: 141.42857142857142,
  S: 24.685714285714287,
  N: 0.42857142857142855,
  J: 73.88571428571429,
  St: 93.25714285714285,
  Nt: 1.8571428571428572,
  Jt: 275.8857142857143,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 2,
  TimeNow: 36.333333333333336,
  S: 2.5,
  N: 96.5,
  J: 0.0,
  St: 10.833333333333334,
  Nt: 360.1666666666667,
  Jt: 0.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 1,
  TimeNow: 33.94117647058823,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 371.0,
  Jt: 0.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 16,
  TimeNow: 171.4090909090909,
  S: 14.863636363636363,
  N: 0.0,
  J: 84.13636363636364,
  St: 57.13636363636363,
  Nt: 0.0,
  Jt: 313.8636363636364,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 15,
  TimeNow: 93.0909090909091,
  S: 33.09090909090909,
  N: 5.181818181818182,
  J: 60.72727272727273,
  St: 124.54545454545455,
  Nt: 19.545454545454547,
  Jt: 226.9090909090909,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 4,
  TimeNow: 35.0,
  S: 1.6666666666666667,
  N: 97.33333333333333,
  J: 0.0,
  St: 6.444444444444445,
  Nt: 364.55555555555554,
  Jt: 0.0,
}, {
  Id: 'U8EjK5bVsRkjAZ9HEc0G',
  Hour: 11,
  TimeNow: 154.76923076923077,
  S: 2.5384615384615383,
  N: 0.0,
  J: 96.46153846153847,
  St: 11.0,
  Nt: 0.0,
  Jt: 360.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 21,
  TimeNow: 515.4782608695652,
  S: 16.391304347826086,
  N: 80.26086956521739,
  J: 2.217391304347826,
  St: 1259.2608695652175,
  Nt: 6050.652173913043,
  Jt: 182.08695652173913,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 18,
  TimeNow: 559.24,
  S: 22.08,
  N: 68.64,
  J: 7.72,
  St: 1687.0,
  Nt: 5185.6,
  Jt: 619.4,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 14,
  TimeNow: 528.1666666666666,
  S: 12.916666666666666,
  N: 76.41666666666667,
  J: 9.25,
  St: 1004.25,
  Nt: 5756.25,
  Jt: 731.5,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 0,
  TimeNow: 408.2105263157895,
  S: 0.10526315789473684,
  N: 98.89473684210526,
  J: 0.0,
  St: 23.894736842105264,
  Nt: 7468.105263157895,
  Jt: 0.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 22,
  TimeNow: 482.4736842105263,
  S: 11.368421052631579,
  N: 87.63157894736842,
  J: 0.0,
  St: 885.1578947368421,
  Nt: 6606.8421052631575,
  Jt: 0.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 20,
  TimeNow: 555.1851851851852,
  S: 28.22222222222222,
  N: 63.77777777777778,
  J: 6.481481481481482,
  St: 2151.703703703704,
  Nt: 4814.814814814815,
  Jt: 525.4814814814815,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 19,
  TimeNow: 582.6785714285714,
  S: 26.071428571428573,
  N: 61.214285714285715,
  J: 11.071428571428571,
  St: 1988.0357142857142,
  Nt: 4633.071428571428,
  Jt: 870.8928571428571,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 23,
  TimeNow: 440.44444444444446,
  S: 2.388888888888889,
  N: 96.61111111111111,
  J: 0.0,
  St: 204.77777777777777,
  Nt: 7287.222222222223,
  Jt: 0.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 17,
  TimeNow: 605.9444444444445,
  S: 19.444444444444443,
  N: 68.61111111111111,
  J: 10.444444444444445,
  St: 1501.611111111111,
  Nt: 5171.111111111111,
  Jt: 819.2777777777778,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 2,
  TimeNow: 362.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 7492.0,
  Jt: 0.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 1,
  TimeNow: 379.47058823529414,
  S: 0.058823529411764705,
  N: 98.94117647058823,
  J: 0.0,
  St: 6.882352941176471,
  Nt: 7485.117647058823,
  Jt: 0.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 16,
  TimeNow: 603.1363636363636,
  S: 10.681818181818182,
  N: 77.31818181818181,
  J: 10.545454545454545,
  St: 843.4090909090909,
  Nt: 5826.545454545455,
  Jt: 822.0454545454545,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 15,
  TimeNow: 563.6363636363636,
  S: 8.636363636363637,
  N: 79.27272727272727,
  J: 10.727272727272727,
  St: 679.6363636363636,
  Nt: 5978.363636363636,
  Jt: 834.0,
}, {
  Id: 'VHifwHAt6iuP9XKJQdK6',
  Hour: 13,
  TimeNow: 524.6,
  S: 14.4,
  N: 75.2,
  J: 8.8,
  St: 1120.6,
  Nt: 5672.4,
  Jt: 699.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 21,
  TimeNow: 315.0869565217391,
  S: 41.130434782608695,
  N: 50.608695652173914,
  J: 7.043478260869565,
  St: 2016.0869565217392,
  Nt: 2466.608695652174,
  Jt: 349.30434782608694,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 18,
  TimeNow: 328.5416666666667,
  S: 44.583333333333336,
  N: 46.5,
  J: 7.583333333333333,
  St: 2183.2083333333335,
  Nt: 2270.75,
  Jt: 378.0416666666667,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 12,
  TimeNow: 715.6470588235294,
  S: 21.41176470588235,
  N: 16.647058823529413,
  J: 60.294117647058826,
  St: 1057.1764705882354,
  Nt: 828.7647058823529,
  Jt: 2951.0588235294117,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 14,
  TimeNow: 882.0833333333334,
  S: 17.666666666666668,
  N: 7.0,
  J: 73.66666666666667,
  St: 875.8333333333334,
  Nt: 365.0,
  Jt: 3596.1666666666665,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 13,
  TimeNow: 757.7058823529412,
  S: 20.176470588235293,
  N: 7.529411764705882,
  J: 70.94117647058823,
  St: 997.1176470588235,
  Nt: 380.5882352941176,
  Jt: 3459.294117647059,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 3,
  TimeNow: 183.7058823529412,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 4832.0,
  Jt: 0.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 0,
  TimeNow: 193.68421052631578,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 4832.0,
  Jt: 0.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 22,
  TimeNow: 278.94736842105266,
  S: 38.578947368421055,
  N: 58.0,
  J: 2.4210526315789473,
  St: 1883.5263157894738,
  Nt: 2827.157894736842,
  Jt: 121.3157894736842,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 20,
  TimeNow: 274.22222222222223,
  S: 23.62962962962963,
  N: 71.44444444444444,
  J: 3.7777777777777777,
  St: 1161.888888888889,
  Nt: 3481.703703703704,
  Jt: 188.40740740740742,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 19,
  TimeNow: 304.10714285714283,
  S: 43.285714285714285,
  N: 48.42857142857143,
  J: 7.214285714285714,
  St: 2106.1785714285716,
  Nt: 2367.5,
  Jt: 358.32142857142856,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 23,
  TimeNow: 208.72222222222223,
  S: 0.6666666666666666,
  N: 98.33333333333333,
  J: 0.0,
  St: 34.888888888888886,
  Nt: 4797.111111111111,
  Jt: 0.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 17,
  TimeNow: 385.19444444444446,
  S: 37.138888888888886,
  N: 40.333333333333336,
  J: 21.13888888888889,
  St: 1813.6666666666667,
  Nt: 1970.3333333333333,
  Jt: 1048.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 2,
  TimeNow: 188.33333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 4832.0,
  Jt: 0.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 1,
  TimeNow: 189.23529411764707,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 4832.0,
  Jt: 0.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 16,
  TimeNow: 472.45454545454544,
  S: 48.0,
  N: 23.09090909090909,
  J: 27.181818181818183,
  St: 2348.818181818182,
  Nt: 1145.6363636363637,
  Jt: 1342.090909090909,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 15,
  TimeNow: 570.4545454545455,
  S: 29.818181818181817,
  N: 16.818181818181817,
  J: 51.72727272727273,
  St: 1473.1818181818182,
  Nt: 832.3636363636364,
  Jt: 2531.4545454545455,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 4,
  TimeNow: 185.66666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 4832.0,
  Jt: 0.0,
}, {
  Id: 'ZMDmlhS8v6rgDCJZPWo0',
  Hour: 11,
  TimeNow: 723.7692307692307,
  S: 22.076923076923077,
  N: 11.307692307692308,
  J: 65.07692307692308,
  St: 1099.6153846153845,
  Nt: 568.6923076923077,
  Jt: 3167.923076923077,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 21,
  TimeNow: 246.2173913043478,
  S: 10.652173913043478,
  N: 88.34782608695652,
  J: 0.0,
  St: 247.82608695652175,
  Nt: 1990.2173913043478,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 18,
  TimeNow: 268.25,
  S: 11.958333333333334,
  N: 83.58333333333333,
  J: 2.875,
  St: 280.4583333333333,
  Nt: 1884.9166666666667,
  Jt: 72.70833333333333,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 12,
  TimeNow: 299.29411764705884,
  S: 14.117647058823529,
  N: 76.3529411764706,
  J: 8.176470588235293,
  St: 324.88235294117646,
  Nt: 1722.5294117647059,
  Jt: 191.05882352941177,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 14,
  TimeNow: 244.08333333333334,
  S: 10.416666666666666,
  N: 87.83333333333333,
  J: 0.5,
  St: 243.75,
  Nt: 1978.75,
  Jt: 15.666666666666666,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 13,
  TimeNow: 235.7058823529412,
  S: 6.0,
  N: 92.70588235294117,
  J: 0.17647058823529413,
  St: 142.94117647058823,
  Nt: 2088.5882352941176,
  Jt: 6.647058823529412,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 3,
  TimeNow: 236.47058823529412,
  S: 1.8235294117647058,
  N: 97.17647058823529,
  J: 0.0,
  St: 53.35294117647059,
  Nt: 2184.6470588235293,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 0,
  TimeNow: 245.5,
  S: 19.444444444444443,
  N: 79.27777777777777,
  J: 0.0,
  St: 449.8333333333333,
  Nt: 1788.1666666666667,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 22,
  TimeNow: 245.0,
  S: 10.368421052631579,
  N: 88.63157894736842,
  J: 0.0,
  St: 236.0,
  Nt: 2002.0,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 20,
  TimeNow: 249.25925925925927,
  S: 15.962962962962964,
  N: 82.5925925925926,
  J: 0.4074074074074074,
  St: 369.4074074074074,
  Nt: 1858.3333333333333,
  Jt: 10.074074074074074,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 19,
  TimeNow: 247.22222222222223,
  S: 11.148148148148149,
  N: 86.88888888888889,
  J: 0.7777777777777778,
  St: 261.4074074074074,
  Nt: 1954.5925925925926,
  Jt: 22.11111111111111,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 23,
  TimeNow: 235.44444444444446,
  S: 9.666666666666666,
  N: 89.33333333333333,
  J: 0.0,
  St: 228.44444444444446,
  Nt: 2009.5555555555557,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 17,
  TimeNow: 266.94444444444446,
  S: 19.444444444444443,
  N: 76.16666666666667,
  J: 2.888888888888889,
  St: 446.75,
  Nt: 1716.75,
  Jt: 74.47222222222223,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 2,
  TimeNow: 226.83333333333334,
  S: 1.8333333333333333,
  N: 97.16666666666667,
  J: 0.0,
  St: 53.666666666666664,
  Nt: 2184.3333333333335,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 1,
  TimeNow: 234.76470588235293,
  S: 4.352941176470588,
  N: 94.6470588235294,
  J: 0.0,
  St: 106.11764705882354,
  Nt: 2131.8823529411766,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 16,
  TimeNow: 257.0,
  S: 17.59090909090909,
  N: 80.18181818181819,
  J: 0.8181818181818182,
  St: 405.22727272727275,
  Nt: 1806.2727272727273,
  Jt: 27.136363636363637,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 15,
  TimeNow: 233.1818181818182,
  S: 7.818181818181818,
  N: 91.0909090909091,
  J: 0.0,
  St: 186.0909090909091,
  Nt: 2050.090909090909,
  Jt: 2.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 4,
  TimeNow: 233.0,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 34.0,
  Nt: 2204.0,
  Jt: 0.0,
}, {
  Id: 'ZTXeIZP0br2MbYiKaX1G',
  Hour: 11,
  TimeNow: 258.61538461538464,
  S: 14.23076923076923,
  N: 81.3076923076923,
  J: 3.230769230769231,
  St: 329.15384615384613,
  Nt: 1828.076923076923,
  Jt: 80.61538461538461,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 21,
  TimeNow: 291.9130434782609,
  S: 21.391304347826086,
  N: 72.52173913043478,
  J: 4.782608695652174,
  St: 546.1304347826087,
  Nt: 1820.391304347826,
  Jt: 126.3913043478261,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 18,
  TimeNow: 478.4,
  S: 22.8,
  N: 52.8,
  J: 22.72,
  St: 583.24,
  Nt: 1329.92,
  Jt: 580.24,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 12,
  TimeNow: 233.11764705882354,
  S: 9.882352941176471,
  N: 86.6470588235294,
  J: 2.3529411764705883,
  St: 255.05882352941177,
  Nt: 2173.6470588235293,
  Jt: 63.411764705882355,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 14,
  TimeNow: 255.5,
  S: 21.666666666666668,
  N: 75.0,
  J: 2.25,
  St: 553.8333333333334,
  Nt: 1879.25,
  Jt: 59.666666666666664,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 13,
  TimeNow: 275.7647058823529,
  S: 15.117647058823529,
  N: 78.76470588235294,
  J: 4.647058823529412,
  St: 391.3529411764706,
  Nt: 1977.7058823529412,
  Jt: 123.82352941176471,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 3,
  TimeNow: 163.0,
  S: 0.9411764705882353,
  N: 98.0,
  J: 0.058823529411764705,
  St: 27.058823529411764,
  Nt: 2462.8823529411766,
  Jt: 2.0588235294117645,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 0,
  TimeNow: 189.47058823529412,
  S: 0.5882352941176471,
  N: 97.47058823529412,
  J: 0.9411764705882353,
  St: 18.647058823529413,
  Nt: 2444.294117647059,
  Jt: 29.058823529411764,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 22,
  TimeNow: 229.94736842105263,
  S: 9.631578947368421,
  N: 88.84210526315789,
  J: 0.47368421052631576,
  St: 250.8421052631579,
  Nt: 2228.0,
  Jt: 13.31578947368421,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 20,
  TimeNow: 285.48148148148147,
  S: 24.296296296296298,
  N: 69.0,
  J: 5.333333333333333,
  St: 618.1111111111111,
  Nt: 1734.4814814814815,
  Jt: 140.44444444444446,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 19,
  TimeNow: 275.14285714285717,
  S: 12.535714285714286,
  N: 82.39285714285714,
  J: 3.75,
  St: 324.89285714285717,
  Nt: 2069.4285714285716,
  Jt: 98.03571428571429,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 23,
  TimeNow: 206.94444444444446,
  S: 6.277777777777778,
  N: 92.38888888888889,
  J: 0.2222222222222222,
  St: 167.38888888888889,
  Nt: 2318.6666666666665,
  Jt: 5.944444444444445,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 17,
  TimeNow: 1001.5555555555555,
  S: 11.944444444444445,
  N: 11.972222222222221,
  J: 74.5,
  St: 310.69444444444446,
  Nt: 310.52777777777777,
  Jt: 1871.4166666666667,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 2,
  TimeNow: 175.0,
  S: 2.1666666666666665,
  N: 96.0,
  J: 0.6666666666666666,
  St: 60.333333333333336,
  Nt: 2408.3333333333335,
  Jt: 23.333333333333332,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 1,
  TimeNow: 180.76470588235293,
  S: 0.8235294117647058,
  N: 97.76470588235294,
  J: 0.4117647058823529,
  St: 23.352941176470587,
  Nt: 2454.235294117647,
  Jt: 14.411764705882353,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 16,
  TimeNow: 723.7727272727273,
  S: 18.136363636363637,
  N: 26.5,
  J: 53.81818181818182,
  St: 466.3181818181818,
  Nt: 669.9090909090909,
  Jt: 1356.5454545454545,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 15,
  TimeNow: 433.0,
  S: 36.36363636363637,
  N: 29.272727272727273,
  J: 32.72727272727273,
  St: 918.9090909090909,
  Nt: 741.4545454545455,
  Jt: 832.7272727272727,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 4,
  TimeNow: 164.0,
  S: 0.3333333333333333,
  N: 97.88888888888889,
  J: 0.7777777777777778,
  St: 9.444444444444445,
  Nt: 2455.3333333333335,
  Jt: 27.22222222222222,
}, {
  Id: 'bkIY4932t5SVR7hf8fRX',
  Hour: 11,
  TimeNow: 229.3846153846154,
  S: 7.923076923076923,
  N: 89.38461538461539,
  J: 1.6923076923076923,
  St: 211.30769230769232,
  Nt: 2238.076923076923,
  Jt: 42.61538461538461,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 21,
  TimeNow: 699.1304347826087,
  S: 22.391304347826086,
  N: 75.21739130434783,
  J: 1.3043478260869565,
  St: 3002.0434782608695,
  Nt: 9970.521739130434,
  Jt: 187.43478260869566,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 18,
  TimeNow: 715.16,
  S: 26.48,
  N: 71.32,
  J: 1.04,
  St: 3539.2,
  Nt: 9465.24,
  Jt: 155.56,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 12,
  TimeNow: 1684.9411764705883,
  S: 19.176470588235293,
  N: 34.8235294117647,
  J: 44.411764705882355,
  St: 2590.470588235294,
  Nt: 4656.176470588235,
  Jt: 5913.35294117647,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 14,
  TimeNow: 1813.5,
  S: 18.416666666666668,
  N: 22.166666666666668,
  J: 57.916666666666664,
  St: 2497.5833333333335,
  Nt: 2967.3333333333335,
  Jt: 7695.083333333333,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 13,
  TimeNow: 1687.0,
  S: 14.941176470588236,
  N: 30.941176470588236,
  J: 52.8235294117647,
  St: 2035.6470588235295,
  Nt: 4120.64705882353,
  Jt: 7003.705882352941,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 3,
  TimeNow: 486.11764705882354,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 13160.0,
  Jt: 0.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 0,
  TimeNow: 506.7894736842105,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 13160.0,
  Jt: 0.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 22,
  TimeNow: 640.9473684210526,
  S: 22.0,
  N: 76.63157894736842,
  J: 0.3157894736842105,
  St: 2947.2105263157896,
  Nt: 10167.842105263158,
  Jt: 44.94736842105263,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 20,
  TimeNow: 652.7777777777778,
  S: 11.296296296296296,
  N: 87.18518518518519,
  J: 0.4444444444444444,
  St: 1552.7037037037037,
  Nt: 11542.74074074074,
  Jt: 64.55555555555556,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 19,
  TimeNow: 706.8214285714286,
  S: 23.785714285714285,
  N: 72.32142857142857,
  J: 2.5714285714285716,
  St: 3209.035714285714,
  Nt: 9584.07142857143,
  Jt: 366.89285714285717,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 23,
  TimeNow: 543.8333333333334,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 139.44444444444446,
  Nt: 13020.555555555555,
  Jt: 0.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 17,
  TimeNow: 838.3611111111111,
  S: 29.52777777777778,
  N: 60.361111111111114,
  J: 8.666666666666666,
  St: 3946.75,
  Nt: 8011.027777777777,
  Jt: 1202.2222222222222,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 2,
  TimeNow: 491.6666666666667,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 13160.0,
  Jt: 0.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 1,
  TimeNow: 489.11764705882354,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 13160.0,
  Jt: 0.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 16,
  TimeNow: 1102.6363636363637,
  S: 34.77272727272727,
  N: 39.22727272727273,
  J: 24.454545454545453,
  St: 4647.090909090909,
  Nt: 5233.545454545455,
  Jt: 3279.3636363636365,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 15,
  TimeNow: 1249.5454545454545,
  S: 23.09090909090909,
  N: 34.45454545454545,
  J: 41.0,
  St: 3102.909090909091,
  Nt: 4581.727272727273,
  Jt: 5475.363636363636,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 4,
  TimeNow: 486.44444444444446,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 13160.0,
  Jt: 0.0,
}, {
  Id: 'cRyuvsyxZFjaFD1hqjfl',
  Hour: 11,
  TimeNow: 1525.5384615384614,
  S: 16.307692307692307,
  N: 45.46153846153846,
  J: 36.84615384615385,
  St: 2207.923076923077,
  Nt: 6039.0,
  Jt: 4913.076923076923,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 21,
  TimeNow: 766.304347826087,
  S: 12.826086956521738,
  N: 75.43478260869566,
  J: 10.08695652173913,
  St: 1178.5217391304348,
  Nt: 6659.217391304348,
  Jt: 931.2608695652174,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 18,
  TimeNow: 758.0,
  S: 16.04,
  N: 68.64,
  J: 13.84,
  St: 1446.2,
  Nt: 6068.48,
  Jt: 1254.32,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 12,
  TimeNow: 1160.8823529411766,
  S: 30.294117647058822,
  N: 50.64705882352941,
  J: 17.470588235294116,
  St: 2695.0588235294117,
  Nt: 4479.823529411765,
  Jt: 1594.1176470588234,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 14,
  TimeNow: 1054.1666666666667,
  S: 22.25,
  N: 58.75,
  J: 17.333333333333332,
  St: 2012.0833333333333,
  Nt: 5198.916666666667,
  Jt: 1558.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 13,
  TimeNow: 1136.2941176470588,
  S: 35.76470588235294,
  N: 38.294117647058826,
  J: 24.294117647058822,
  St: 3187.529411764706,
  Nt: 3412.294117647059,
  Jt: 2169.176470588235,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 3,
  TimeNow: 458.47058823529414,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 2.6470588235294117,
  Nt: 8766.35294117647,
  Jt: 0.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 0,
  TimeNow: 507.3157894736842,
  S: 0.47368421052631576,
  N: 98.52631578947368,
  J: 0.0,
  St: 77.63157894736842,
  Nt: 8691.368421052632,
  Jt: 0.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 22,
  TimeNow: 629.8947368421053,
  S: 7.2105263157894735,
  N: 87.63157894736842,
  J: 3.4210526315789473,
  St: 678.8421052631579,
  Nt: 7734.1578947368425,
  Jt: 356.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 20,
  TimeNow: 785.4444444444445,
  S: 16.333333333333332,
  N: 70.85185185185185,
  J: 11.185185185185185,
  St: 1483.962962962963,
  Nt: 6258.518518518518,
  Jt: 1026.5185185185185,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 19,
  TimeNow: 712.6428571428571,
  S: 14.928571428571429,
  N: 75.89285714285714,
  J: 7.464285714285714,
  St: 1357.392857142857,
  Nt: 6708.464285714285,
  Jt: 703.1428571428571,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 23,
  TimeNow: 550.6111111111111,
  S: 1.2222222222222223,
  N: 96.33333333333333,
  J: 0.9444444444444444,
  St: 153.22222222222223,
  Nt: 8493.166666666666,
  Jt: 122.61111111111111,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 17,
  TimeNow: 1189.638888888889,
  S: 25.36111111111111,
  N: 39.833333333333336,
  J: 33.19444444444444,
  St: 2273.6944444444443,
  Nt: 3542.1111111111113,
  Jt: 2953.1944444444443,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 2,
  TimeNow: 467.6666666666667,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 8769.0,
  Jt: 0.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 1,
  TimeNow: 478.5882352941176,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 3.2941176470588234,
  Nt: 8765.70588235294,
  Jt: 0.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 16,
  TimeNow: 975.9090909090909,
  S: 29.09090909090909,
  N: 47.18181818181818,
  J: 22.363636363636363,
  St: 2580.2727272727275,
  Nt: 4180.454545454545,
  Jt: 2008.2727272727273,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 15,
  TimeNow: 1006.5454545454545,
  S: 20.90909090909091,
  N: 60.81818181818182,
  J: 16.363636363636363,
  St: 1898.1818181818182,
  Nt: 5390.545454545455,
  Jt: 1480.2727272727273,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 4,
  TimeNow: 477.77777777777777,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 1.5555555555555556,
  Nt: 8767.444444444445,
  Jt: 0.0,
}, {
  Id: 'fdNNCQ6kPiglUszPfuR2',
  Hour: 11,
  TimeNow: 932.2307692307693,
  S: 20.153846153846153,
  N: 64.61538461538461,
  J: 13.615384615384615,
  St: 1811.7692307692307,
  Nt: 5717.7692307692305,
  Jt: 1239.4615384615386,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 21,
  TimeNow: 215.0,
  S: 5.3478260869565215,
  N: 93.56521739130434,
  J: 0.043478260869565216,
  St: 126.91304347826087,
  Nt: 1944.304347826087,
  Jt: 1.7826086956521738,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 18,
  TimeNow: 280.48,
  S: 12.08,
  N: 81.56,
  J: 5.08,
  St: 259.72,
  Nt: 1701.88,
  Jt: 111.4,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 12,
  TimeNow: 206.8235294117647,
  S: 7.176470588235294,
  N: 91.47058823529412,
  J: 0.29411764705882354,
  St: 155.41176470588235,
  Nt: 1910.3529411764705,
  Jt: 7.294117647058823,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 14,
  TimeNow: 203.58333333333334,
  S: 5.5,
  N: 93.41666666666667,
  J: 0.0,
  St: 120.25,
  Nt: 1952.8333333333333,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 13,
  TimeNow: 202.64705882352942,
  S: 5.176470588235294,
  N: 93.29411764705883,
  J: 0.23529411764705882,
  St: 117.47058823529412,
  Nt: 1949.6470588235295,
  Jt: 5.882352941176471,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 3,
  TimeNow: 210.94117647058823,
  S: 0.6470588235294118,
  N: 98.3529411764706,
  J: 0.0,
  St: 16.823529411764707,
  Nt: 2057.176470588235,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 0,
  TimeNow: 229.0,
  S: 19.42105263157895,
  N: 79.57894736842105,
  J: 0.0,
  St: 405.4736842105263,
  Nt: 1668.0526315789473,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 22,
  TimeNow: 204.1578947368421,
  S: 1.105263157894737,
  N: 97.36842105263158,
  J: 0.0,
  St: 37.21052631578947,
  Nt: 2036.1052631578948,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 20,
  TimeNow: 235.55555555555554,
  S: 7.703703703703703,
  N: 88.4074074074074,
  J: 2.6296296296296298,
  St: 173.37037037037038,
  Nt: 1842.5185185185185,
  Jt: 57.111111111111114,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 19,
  TimeNow: 236.46428571428572,
  S: 10.107142857142858,
  N: 85.57142857142857,
  J: 3.107142857142857,
  St: 218.75,
  Nt: 1787.357142857143,
  Jt: 66.89285714285714,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 23,
  TimeNow: 210.38888888888889,
  S: 2.611111111111111,
  N: 96.27777777777777,
  J: 0.0,
  St: 59.27777777777778,
  Nt: 2013.888888888889,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 17,
  TimeNow: 339.6388888888889,
  S: 10.88888888888889,
  N: 73.58333333333333,
  J: 14.027777777777779,
  St: 234.41666666666666,
  Nt: 1537.8333333333333,
  Jt: 300.75,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 2,
  TimeNow: 202.66666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 2074.0,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 1,
  TimeNow: 223.58823529411765,
  S: 14.588235294117647,
  N: 84.41176470588235,
  J: 0.0,
  St: 305.05882352941177,
  Nt: 1768.5294117647059,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 16,
  TimeNow: 290.3181818181818,
  S: 10.0,
  N: 80.5909090909091,
  J: 8.136363636363637,
  St: 217.3181818181818,
  Nt: 1678.2272727272727,
  Jt: 177.45454545454547,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 15,
  TimeNow: 206.8181818181818,
  S: 8.727272727272727,
  N: 90.0,
  J: 0.0,
  St: 190.36363636363637,
  Nt: 1882.6363636363637,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 4,
  TimeNow: 202.88888888888889,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 26.0,
  Nt: 2048.0,
  Jt: 0.0,
}, {
  Id: 'hZrY5SFfLR6WbPUf2bMJ',
  Hour: 11,
  TimeNow: 200.3846153846154,
  S: 2.6153846153846154,
  N: 96.38461538461539,
  J: 0.0,
  St: 66.3076923076923,
  Nt: 2007.076923076923,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 21,
  TimeNow: 154.95652173913044,
  S: 35.26086956521739,
  N: 54.52173913043478,
  J: 9.130434782608695,
  St: 697.4347826086956,
  Nt: 1069.9565217391305,
  Jt: 181.82608695652175,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 18,
  TimeNow: 213.8,
  S: 48.92,
  N: 18.64,
  J: 31.04,
  St: 963.8,
  Nt: 372.2,
  Jt: 613.24,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 14,
  TimeNow: 193.25,
  S: 33.75,
  N: 27.75,
  J: 37.0,
  St: 669.1666666666666,
  Nt: 551.0833333333334,
  Jt: 729.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 0,
  TimeNow: 97.89473684210526,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1949.0,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 22,
  TimeNow: 129.10526315789474,
  S: 17.42105263157895,
  N: 81.57894736842105,
  J: 0.0,
  St: 349.2105263157895,
  Nt: 1599.7894736842106,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 20,
  TimeNow: 190.77777777777777,
  S: 58.148148148148145,
  N: 14.592592592592593,
  J: 25.666666666666668,
  St: 1145.4814814814815,
  Nt: 293.48148148148147,
  Jt: 510.14814814814815,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 19,
  TimeNow: 199.64285714285714,
  S: 51.214285714285715,
  N: 11.571428571428571,
  J: 35.892857142857146,
  St: 1007.5357142857143,
  Nt: 233.07142857142858,
  Jt: 708.4642857142857,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 23,
  TimeNow: 109.61111111111111,
  S: 2.4444444444444446,
  N: 96.33333333333333,
  J: 0.0,
  St: 54.888888888888886,
  Nt: 1894.111111111111,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 17,
  TimeNow: 260.51428571428573,
  S: 41.457142857142856,
  N: 16.885714285714286,
  J: 40.2,
  St: 818.4857142857143,
  Nt: 338.2857142857143,
  Jt: 792.4,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 2,
  TimeNow: 87.83333333333333,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1949.0,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 1,
  TimeNow: 92.41176470588235,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1949.0,
  Jt: 0.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 16,
  TimeNow: 266.3181818181818,
  S: 28.954545454545453,
  N: 28.045454545454547,
  J: 41.63636363636363,
  St: 575.1818181818181,
  Nt: 555.4090909090909,
  Jt: 819.0454545454545,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 15,
  TimeNow: 239.27272727272728,
  S: 22.0,
  N: 34.63636363636363,
  J: 42.27272727272727,
  St: 436.09090909090907,
  Nt: 682.2727272727273,
  Jt: 831.0,
}, {
  Id: 'jIIiFSIkF4FGpc3d8FGX',
  Hour: 13,
  TimeNow: 196.0,
  S: 44.4,
  N: 18.8,
  J: 35.4,
  St: 878.4,
  Nt: 374.2,
  Jt: 696.8,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 21,
  TimeNow: 415.82608695652175,
  S: 62.04347826086956,
  N: 9.608695652173912,
  J: 26.956521739130434,
  St: 3187.3478260869565,
  Nt: 516.8260869565217,
  Jt: 1394.8260869565217,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 18,
  TimeNow: 580.44,
  S: 23.0,
  N: 0.56,
  J: 75.36,
  St: 1199.6,
  Nt: 30.96,
  Jt: 3868.44,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 12,
  TimeNow: 388.6470588235294,
  S: 43.35294117647059,
  N: 33.23529411764706,
  J: 22.11764705882353,
  St: 2233.0588235294117,
  Nt: 1719.9411764705883,
  Jt: 1146.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 14,
  TimeNow: 1031.0,
  S: 9.25,
  N: 0.16666666666666666,
  J: 89.5,
  St: 487.6666666666667,
  Nt: 11.333333333333334,
  Jt: 4600.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 13,
  TimeNow: 313.05882352941177,
  S: 36.35294117647059,
  N: 55.529411764705884,
  J: 7.0588235294117645,
  St: 1880.0,
  Nt: 2855.8823529411766,
  Jt: 363.11764705882354,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 3,
  TimeNow: 187.76470588235293,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5099.0,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 0,
  TimeNow: 209.83333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5099.0,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 22,
  TimeNow: 354.2631578947368,
  S: 63.10526315789474,
  N: 21.42105263157895,
  J: 14.210526315789474,
  St: 3240.842105263158,
  Nt: 1120.578947368421,
  Jt: 737.578947368421,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 20,
  TimeNow: 375.22222222222223,
  S: 77.33333333333333,
  N: 14.592592592592593,
  J: 6.851851851851852,
  St: 3967.925925925926,
  Nt: 770.7777777777778,
  Jt: 360.2962962962963,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 19,
  TimeNow: 495.75,
  S: 42.892857142857146,
  N: 0.75,
  J: 55.25,
  St: 2210.3214285714284,
  Nt: 41.464285714285715,
  Jt: 2847.214285714286,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 23,
  TimeNow: 255.11111111111111,
  S: 30.0,
  N: 68.72222222222223,
  J: 0.2222222222222222,
  St: 1547.388888888889,
  Nt: 3538.277777777778,
  Jt: 13.333333333333334,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 17,
  TimeNow: 749.25,
  S: 16.583333333333332,
  N: 0.0,
  J: 82.41666666666667,
  St: 862.4166666666666,
  Nt: 0.0,
  Jt: 4236.583333333333,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 2,
  TimeNow: 196.33333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5099.0,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 1,
  TimeNow: 194.1764705882353,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5099.0,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 16,
  TimeNow: 1010.1818181818181,
  S: 3.4545454545454546,
  N: 0.0,
  J: 95.54545454545455,
  St: 180.0909090909091,
  Nt: 0.0,
  Jt: 4918.909090909091,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 15,
  TimeNow: 839.9090909090909,
  S: 9.909090909090908,
  N: 0.0,
  J: 89.0909090909091,
  St: 524.7272727272727,
  Nt: 0.0,
  Jt: 4574.272727272727,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 4,
  TimeNow: 188.66666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5099.0,
  Jt: 0.0,
}, {
  Id: 'jovVzfQYAGFJa3xfBccZ',
  Hour: 11,
  TimeNow: 436.61538461538464,
  S: 49.23076923076923,
  N: 19.923076923076923,
  J: 29.307692307692307,
  St: 2542.230769230769,
  Nt: 1039.2307692307693,
  Jt: 1517.5384615384614,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 21,
  TimeNow: 242.2608695652174,
  S: 13.73913043478261,
  N: 85.17391304347827,
  J: 0.0,
  St: 367.2173913043478,
  Nt: 2208.304347826087,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 18,
  TimeNow: 234.54166666666666,
  S: 16.041666666666668,
  N: 79.375,
  J: 3.2916666666666665,
  St: 423.8333333333333,
  Nt: 2059.75,
  Jt: 91.41666666666667,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 12,
  TimeNow: 353.5882352941176,
  S: 24.0,
  N: 50.0,
  J: 24.352941176470587,
  St: 632.5882352941177,
  Nt: 1302.5882352941176,
  Jt: 639.3529411764706,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 14,
  TimeNow: 307.4166666666667,
  S: 29.666666666666668,
  N: 55.25,
  J: 13.75,
  St: 778.0,
  Nt: 1431.75,
  Jt: 365.4166666666667,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 13,
  TimeNow: 291.8235294117647,
  S: 19.41176470588235,
  N: 58.0,
  J: 21.294117647058822,
  St: 511.11764705882354,
  Nt: 1504.9411764705883,
  Jt: 559.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 3,
  TimeNow: 222.23529411764707,
  S: 5.470588235294118,
  N: 93.52941176470588,
  J: 0.0,
  St: 150.76470588235293,
  Nt: 2424.235294117647,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 0,
  TimeNow: 240.47368421052633,
  S: 18.157894736842106,
  N: 80.84210526315789,
  J: 0.0,
  St: 473.57894736842104,
  Nt: 2101.0,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 22,
  TimeNow: 241.8421052631579,
  S: 9.842105263157896,
  N: 89.15789473684211,
  J: 0.0,
  St: 266.2631578947368,
  Nt: 2309.157894736842,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 20,
  TimeNow: 244.66666666666666,
  S: 23.62962962962963,
  N: 73.18518518518519,
  J: 2.037037037037037,
  St: 622.5185185185185,
  Nt: 1897.888888888889,
  Jt: 55.22222222222222,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 19,
  TimeNow: 255.71428571428572,
  S: 16.071428571428573,
  N: 71.75,
  J: 10.75,
  St: 426.60714285714283,
  Nt: 1861.7142857142858,
  Jt: 286.75,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 23,
  TimeNow: 232.88888888888889,
  S: 8.88888888888889,
  N: 90.11111111111111,
  J: 0.0,
  St: 240.16666666666666,
  Nt: 2334.9444444444443,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 17,
  TimeNow: 421.97222222222223,
  S: 29.27777777777778,
  N: 51.833333333333336,
  J: 17.25,
  St: 767.75,
  Nt: 1348.9166666666667,
  Jt: 458.22222222222223,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 2,
  TimeNow: 218.0,
  S: 1.1666666666666667,
  N: 97.83333333333333,
  J: 0.0,
  St: 36.333333333333336,
  Nt: 2538.6666666666665,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 1,
  TimeNow: 226.8235294117647,
  S: 3.5294117647058822,
  N: 95.47058823529412,
  J: 0.0,
  St: 95.23529411764706,
  Nt: 2479.705882352941,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 16,
  TimeNow: 378.54545454545456,
  S: 31.5,
  N: 45.72727272727273,
  J: 21.5,
  St: 823.5909090909091,
  Nt: 1187.5,
  Jt: 563.5909090909091,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 15,
  TimeNow: 272.8181818181818,
  S: 25.0,
  N: 67.45454545454545,
  J: 6.090909090909091,
  St: 658.6363636363636,
  Nt: 1749.2727272727273,
  Jt: 167.36363636363637,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 4,
  TimeNow: 224.0,
  S: 1.6666666666666667,
  N: 97.33333333333333,
  J: 0.0,
  St: 51.333333333333336,
  Nt: 2523.6666666666665,
  Jt: 0.0,
}, {
  Id: 'kuGkReF6XUPgehdO2Obd',
  Hour: 11,
  TimeNow: 324.84615384615387,
  S: 29.0,
  N: 54.53846153846154,
  J: 15.0,
  St: 762.1538461538462,
  Nt: 1418.6153846153845,
  Jt: 393.7692307692308,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 21,
  TimeNow: 524.1739130434783,
  S: 40.30434782608695,
  N: 36.08695652173913,
  J: 22.347826086956523,
  St: 2414.6521739130435,
  Nt: 2167.1304347826085,
  Jt: 1342.2173913043478,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 18,
  TimeNow: 464.4583333333333,
  S: 43.958333333333336,
  N: 43.375,
  J: 11.416666666666666,
  St: 2628.5,
  Nt: 2601.5833333333335,
  Jt: 693.9166666666666,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 12,
  TimeNow: 351.8235294117647,
  S: 23.529411764705884,
  N: 75.47058823529412,
  J: 0.0,
  St: 1424.0588235294117,
  Nt: 4499.941176470588,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 14,
  TimeNow: 350.0833333333333,
  S: 13.916666666666666,
  N: 85.08333333333333,
  J: 0.0,
  St: 850.0,
  Nt: 5072.416666666667,
  Jt: 1.5833333333333333,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 13,
  TimeNow: 398.05882352941177,
  S: 38.529411764705884,
  N: 56.529411764705884,
  J: 3.8823529411764706,
  St: 2314.3529411764707,
  Nt: 3373.764705882353,
  Jt: 235.88235294117646,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 3,
  TimeNow: 289.70588235294116,
  S: 1.8235294117647058,
  N: 97.17647058823529,
  J: 0.0,
  St: 114.52941176470588,
  Nt: 5809.470588235294,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 0,
  TimeNow: 301.2631578947368,
  S: 3.9473684210526314,
  N: 95.05263157894737,
  J: 0.0,
  St: 249.0,
  Nt: 5675.0,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 22,
  TimeNow: 367.05263157894734,
  S: 27.263157894736842,
  N: 67.10526315789474,
  J: 4.526315789473684,
  St: 1642.3684210526317,
  Nt: 4005.315789473684,
  Jt: 276.3157894736842,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 20,
  TimeNow: 543.7037037037037,
  S: 48.2962962962963,
  N: 23.296296296296298,
  J: 26.814814814814813,
  St: 2903.4444444444443,
  Nt: 1407.5925925925926,
  Jt: 1612.962962962963,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 19,
  TimeNow: 442.0357142857143,
  S: 51.714285714285715,
  N: 43.75,
  J: 3.357142857142857,
  St: 3092.6785714285716,
  Nt: 2621.785714285714,
  Jt: 209.53571428571428,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 23,
  TimeNow: 320.44444444444446,
  S: 6.611111111111111,
  N: 92.38888888888889,
  J: 0.0,
  St: 409.1111111111111,
  Nt: 5514.888888888889,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 17,
  TimeNow: 568.6944444444445,
  S: 37.083333333333336,
  N: 31.055555555555557,
  J: 30.36111111111111,
  St: 2225.5555555555557,
  Nt: 1875.4166666666667,
  Jt: 1823.0277777777778,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 2,
  TimeNow: 311.6666666666667,
  S: 10.666666666666666,
  N: 88.33333333333333,
  J: 0.0,
  St: 646.1666666666666,
  Nt: 5277.833333333333,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 1,
  TimeNow: 281.1764705882353,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5924.0,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 16,
  TimeNow: 594.1363636363636,
  S: 32.86363636363637,
  N: 24.454545454545453,
  J: 40.95454545454545,
  St: 1981.9545454545455,
  Nt: 1487.1818181818182,
  Jt: 2454.8636363636365,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 15,
  TimeNow: 463.6,
  S: 40.5,
  N: 46.1,
  J: 12.0,
  St: 2434.2,
  Nt: 2768.7,
  Jt: 721.1,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 4,
  TimeNow: 285.1111111111111,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 5924.0,
  Jt: 0.0,
}, {
  Id: 'lD4aGchJfjRI2LTzcXE0',
  Hour: 11,
  TimeNow: 336.9230769230769,
  S: 16.0,
  N: 82.84615384615384,
  J: 0.0,
  St: 989.7692307692307,
  Nt: 4934.2307692307695,
  Jt: 0.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 21,
  TimeNow: 288.5652173913044,
  S: 29.47826086956522,
  N: 64.47826086956522,
  J: 4.826086956521739,
  St: 1101.391304347826,
  Nt: 2377.782608695652,
  Jt: 181.82608695652175,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 18,
  TimeNow: 326.875,
  S: 38.0,
  N: 44.041666666666664,
  J: 16.541666666666668,
  St: 1407.125,
  Nt: 1630.25,
  Jt: 623.625,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 14,
  TimeNow: 318.25,
  S: 25.833333333333332,
  N: 53.083333333333336,
  J: 19.5,
  St: 966.25,
  Nt: 1964.0,
  Jt: 730.75,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 0,
  TimeNow: 205.6315789473684,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.5789473684210527,
  Nt: 3660.4210526315787,
  Jt: 0.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 22,
  TimeNow: 259.57894736842104,
  S: 19.157894736842106,
  N: 79.78947368421052,
  J: 0.0,
  St: 719.7894736842105,
  Nt: 2941.2105263157896,
  Jt: 0.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 20,
  TimeNow: 324.1111111111111,
  S: 48.148148148148145,
  N: 36.51851851851852,
  J: 13.925925925925926,
  St: 1780.2592592592594,
  Nt: 1355.7037037037037,
  Jt: 525.0370370370371,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 19,
  TimeNow: 347.2142857142857,
  S: 47.07142857142857,
  N: 28.214285714285715,
  J: 23.464285714285715,
  St: 1737.3214285714287,
  Nt: 1054.0714285714287,
  Jt: 869.6071428571429,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 23,
  TimeNow: 227.55555555555554,
  S: 2.8333333333333335,
  N: 96.16666666666667,
  J: 0.0,
  St: 116.11111111111111,
  Nt: 3544.8888888888887,
  Jt: 0.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 17,
  TimeNow: 380.25,
  S: 34.55555555555556,
  N: 42.19444444444444,
  J: 22.02777777777778,
  St: 1282.9722222222222,
  Nt: 1559.5833333333333,
  Jt: 818.4444444444445,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 2,
  TimeNow: 184.66666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 3661.0,
  Jt: 0.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 1,
  TimeNow: 191.94117647058823,
  S: 0.17647058823529413,
  N: 98.82352941176471,
  J: 0.0,
  St: 6.882352941176471,
  Nt: 3654.1176470588234,
  Jt: 0.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 16,
  TimeNow: 381.1818181818182,
  S: 20.772727272727273,
  N: 55.77272727272727,
  J: 22.09090909090909,
  St: 779.9545454545455,
  Nt: 2060.0,
  Jt: 821.0454545454545,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 15,
  TimeNow: 344.8181818181818,
  S: 17.363636363636363,
  N: 58.81818181818182,
  J: 22.454545454545453,
  St: 659.1818181818181,
  Nt: 2168.818181818182,
  Jt: 833.0,
}, {
  Id: 'lF2ALyrRClB2hYH5aUKY',
  Hour: 13,
  TimeNow: 309.2,
  S: 28.0,
  N: 51.6,
  J: 18.6,
  St: 1049.2,
  Nt: 1913.6,
  Jt: 698.2,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 21,
  TimeNow: 567.7826086956521,
  S: 3.0869565217391304,
  N: 95.91304347826087,
  J: 0.0,
  St: 380.95652173913044,
  Nt: 10285.173913043478,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 18,
  TimeNow: 882.1666666666666,
  S: 9.083333333333334,
  N: 78.875,
  J: 10.5,
  St: 1021.625,
  Nt: 8471.708333333334,
  Jt: 1172.6666666666667,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 14,
  TimeNow: 1023.5833333333334,
  S: 4.083333333333333,
  N: 82.58333333333333,
  J: 11.916666666666666,
  St: 489.5,
  Nt: 8856.333333333334,
  Jt: 1320.4166666666667,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 0,
  TimeNow: 530.4736842105264,
  S: 0.05263157894736842,
  N: 98.94736842105263,
  J: 0.0,
  St: 27.894736842105264,
  Nt: 10638.105263157895,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 22,
  TimeNow: 567.9473684210526,
  S: 0.7368421052631579,
  N: 98.26315789473684,
  J: 0.0,
  St: 109.10526315789474,
  Nt: 10556.947368421053,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 20,
  TimeNow: 689.7037037037037,
  S: 7.074074074074074,
  N: 84.51851851851852,
  J: 6.925925925925926,
  St: 814.9629629629629,
  Nt: 9076.25925925926,
  Jt: 774.8518518518518,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 19,
  TimeNow: 751.1785714285714,
  S: 7.285714285714286,
  N: 82.21428571428571,
  J: 9.0,
  St: 830.7857142857143,
  Nt: 8824.75,
  Jt: 1010.4642857142857,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 23,
  TimeNow: 543.0555555555555,
  S: 0.2222222222222222,
  N: 98.77777777777777,
  J: 0.0,
  St: 30.11111111111111,
  Nt: 10635.888888888889,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 17,
  TimeNow: 1052.138888888889,
  S: 9.86111111111111,
  N: 74.27777777777777,
  J: 14.277777777777779,
  St: 1114.861111111111,
  Nt: 7972.166666666667,
  Jt: 1578.9722222222222,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 2,
  TimeNow: 517.3333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 10666.0,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 1,
  TimeNow: 521.4705882352941,
  S: 0.23529411764705882,
  N: 98.76470588235294,
  J: 0.0,
  St: 53.11764705882353,
  Nt: 10612.882352941177,
  Jt: 0.0,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 16,
  TimeNow: 1019.2727272727273,
  S: 4.636363636363637,
  N: 77.5909090909091,
  J: 16.318181818181817,
  St: 548.5,
  Nt: 8319.545454545454,
  Jt: 1798.0454545454545,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 15,
  TimeNow: 1008.0,
  S: 4.363636363636363,
  N: 83.0,
  J: 11.181818181818182,
  St: 527.7272727272727,
  Nt: 8887.727272727272,
  Jt: 1250.7272727272727,
}, {
  Id: 'm7jwb0WGHCZ86rI2Mlgs',
  Hour: 13,
  TimeNow: 1031.75,
  S: 1.25,
  N: 85.75,
  J: 11.75,
  St: 185.0,
  Nt: 9187.25,
  Jt: 1294.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 21,
  TimeNow: 421.8695652173913,
  S: 7.913043478260869,
  N: 88.73913043478261,
  J: 2.0869565217391304,
  St: 406.9130434782609,
  Nt: 4338.04347826087,
  Jt: 112.91304347826087,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 18,
  TimeNow: 423.8,
  S: 9.6,
  N: 87.48,
  J: 1.44,
  St: 494.44,
  Nt: 4281.72,
  Jt: 81.72,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 12,
  TimeNow: 456.94117647058823,
  S: 19.41176470588235,
  N: 69.70588235294117,
  J: 9.470588235294118,
  St: 961.4705882352941,
  Nt: 3410.0588235294117,
  Jt: 486.29411764705884,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 14,
  TimeNow: 427.5833333333333,
  S: 14.5,
  N: 79.75,
  J: 4.25,
  St: 725.5833333333334,
  Nt: 3900.1666666666665,
  Jt: 231.91666666666666,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 13,
  TimeNow: 444.94117647058823,
  S: 24.705882352941178,
  N: 68.3529411764706,
  J: 5.294117647058823,
  St: 1229.5882352941176,
  Nt: 3349.3529411764707,
  Jt: 279.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 3,
  TimeNow: 386.7647058823529,
  S: 5.882352941176471,
  N: 93.0,
  J: 0.0,
  St: 322.4117647058824,
  Nt: 4534.588235294118,
  Jt: 0.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 0,
  TimeNow: 421.63157894736844,
  S: 14.263157894736842,
  N: 84.73684210526316,
  J: 0.0,
  St: 724.421052631579,
  Nt: 4132.526315789473,
  Jt: 0.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 22,
  TimeNow: 405.8421052631579,
  S: 6.7368421052631575,
  N: 90.05263157894737,
  J: 1.894736842105263,
  St: 354.4736842105263,
  Nt: 4401.789473684211,
  Jt: 100.89473684210526,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 20,
  TimeNow: 413.81481481481484,
  S: 17.333333333333332,
  N: 80.44444444444444,
  J: 0.8518518518518519,
  St: 869.0,
  Nt: 3936.5555555555557,
  Jt: 53.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 19,
  TimeNow: 412.7857142857143,
  S: 19.214285714285715,
  N: 79.21428571428571,
  J: 0.35714285714285715,
  St: 956.1428571428571,
  Nt: 3877.035714285714,
  Jt: 25.035714285714285,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 23,
  TimeNow: 394.77777777777777,
  S: 7.722222222222222,
  N: 91.27777777777777,
  J: 0.0,
  St: 409.05555555555554,
  Nt: 4447.944444444444,
  Jt: 0.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 17,
  TimeNow: 449.52777777777777,
  S: 19.75,
  N: 76.19444444444444,
  J: 2.5833333333333335,
  St: 988.9722222222222,
  Nt: 3721.6666666666665,
  Jt: 146.91666666666666,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 2,
  TimeNow: 410.1666666666667,
  S: 12.666666666666666,
  N: 86.33333333333333,
  J: 0.0,
  St: 636.3333333333334,
  Nt: 4220.666666666667,
  Jt: 0.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 1,
  TimeNow: 426.3529411764706,
  S: 17.88235294117647,
  N: 80.3529411764706,
  J: 0.4117647058823529,
  St: 895.4117647058823,
  Nt: 3934.3529411764707,
  Jt: 27.235294117647058,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 16,
  TimeNow: 438.40909090909093,
  S: 14.818181818181818,
  N: 81.22727272727273,
  J: 2.5454545454545454,
  St: 748.0909090909091,
  Nt: 3971.0,
  Jt: 138.9090909090909,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 15,
  TimeNow: 442.72727272727275,
  S: 19.818181818181817,
  N: 72.0909090909091,
  J: 6.7272727272727275,
  St: 993.7272727272727,
  Nt: 3520.181818181818,
  Jt: 343.45454545454544,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 4,
  TimeNow: 379.22222222222223,
  S: 5.222222222222222,
  N: 93.77777777777777,
  J: 0.0,
  St: 293.6666666666667,
  Nt: 4563.333333333333,
  Jt: 0.0,
}, {
  Id: 'qJjMaaYZxNoHTnmI8FGY',
  Hour: 11,
  TimeNow: 480.84615384615387,
  S: 22.076923076923077,
  N: 71.15384615384616,
  J: 5.384615384615385,
  St: 1095.6923076923076,
  Nt: 3476.076923076923,
  Jt: 286.2307692307692,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 21,
  TimeNow: 133.34782608695653,
  S: 2.3043478260869565,
  N: 96.21739130434783,
  J: 0.0,
  St: 39.21739130434783,
  Nt: 1213.7826086956522,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 18,
  TimeNow: 173.48,
  S: 10.2,
  N: 83.12,
  J: 5.08,
  St: 135.36,
  Nt: 1046.32,
  Jt: 71.32,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 12,
  TimeNow: 142.76470588235293,
  S: 9.882352941176471,
  N: 88.11764705882354,
  J: 0.7647058823529411,
  St: 131.58823529411765,
  Nt: 1109.1764705882354,
  Jt: 12.235294117647058,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 14,
  TimeNow: 135.83333333333334,
  S: 2.5833333333333335,
  N: 96.0,
  J: 0.25,
  St: 40.583333333333336,
  Nt: 1207.4166666666667,
  Jt: 5.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 13,
  TimeNow: 135.76470588235293,
  S: 2.1176470588235294,
  N: 96.6470588235294,
  J: 0.11764705882352941,
  St: 34.64705882352941,
  Nt: 1216.4705882352941,
  Jt: 1.8823529411764706,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 3,
  TimeNow: 129.05882352941177,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1253.0,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 0,
  TimeNow: 128.1578947368421,
  S: 0.21052631578947367,
  N: 98.78947368421052,
  J: 0.0,
  St: 4.421052631578948,
  Nt: 1248.578947368421,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 22,
  TimeNow: 131.05263157894737,
  S: 0.42105263157894735,
  N: 98.57894736842105,
  J: 0.0,
  St: 9.0,
  Nt: 1244.0,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 20,
  TimeNow: 138.59259259259258,
  S: 7.111111111111111,
  N: 91.14814814814815,
  J: 0.5925925925925926,
  St: 97.4074074074074,
  Nt: 1146.6296296296296,
  Jt: 8.962962962962964,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 19,
  TimeNow: 141.03571428571428,
  S: 4.75,
  N: 92.85714285714286,
  J: 1.0714285714285714,
  St: 67.60714285714286,
  Nt: 1169.9642857142858,
  Jt: 15.428571428571429,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 23,
  TimeNow: 126.22222222222223,
  S: 1.0555555555555556,
  N: 97.94444444444444,
  J: 0.0,
  St: 16.5,
  Nt: 1236.5,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 17,
  TimeNow: 157.30555555555554,
  S: 12.13888888888889,
  N: 83.83333333333333,
  J: 2.638888888888889,
  St: 158.97222222222223,
  Nt: 1057.1666666666667,
  Jt: 36.861111111111114,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 2,
  TimeNow: 128.33333333333334,
  S: 1.5,
  N: 97.5,
  J: 0.0,
  St: 19.833333333333332,
  Nt: 1233.1666666666667,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 1,
  TimeNow: 127.75,
  S: 2.75,
  N: 96.25,
  J: 0.0,
  St: 37.4375,
  Nt: 1215.5625,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 16,
  TimeNow: 150.36363636363637,
  S: 15.818181818181818,
  N: 82.63636363636364,
  J: 0.45454545454545453,
  St: 203.9090909090909,
  Nt: 1042.1818181818182,
  Jt: 6.909090909090909,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 15,
  TimeNow: 135.0909090909091,
  S: 3.4545454545454546,
  N: 95.36363636363636,
  J: 0.09090909090909091,
  St: 50.36363636363637,
  Nt: 1200.6363636363637,
  Jt: 2.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 4,
  TimeNow: 126.11111111111111,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 1253.0,
  Jt: 0.0,
}, {
  Id: 'qpGOFcJPWgOaCilvlspC',
  Hour: 11,
  TimeNow: 141.53846153846155,
  S: 11.23076923076923,
  N: 86.15384615384616,
  J: 0.9230769230769231,
  St: 148.84615384615384,
  Nt: 1087.2307692307693,
  Jt: 16.923076923076923,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 21,
  TimeNow: 308.9130434782609,
  S: 4.869565217391305,
  N: 92.0,
  J: 1.826086956521739,
  St: 170.56521739130434,
  Nt: 2911.782608695652,
  Jt: 65.65217391304348,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 18,
  TimeNow: 298.8,
  S: 3.96,
  N: 93.16,
  J: 1.6,
  St: 141.28,
  Nt: 2948.32,
  Jt: 58.4,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 12,
  TimeNow: 289.6470588235294,
  S: 3.2941176470588234,
  N: 94.52941176470588,
  J: 1.0,
  St: 120.0,
  Nt: 2993.176470588235,
  Jt: 34.8235294117647,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 14,
  TimeNow: 287.8333333333333,
  S: 3.25,
  N: 94.91666666666667,
  J: 0.75,
  St: 119.0,
  Nt: 3003.6666666666665,
  Jt: 25.333333333333332,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 13,
  TimeNow: 306.7647058823529,
  S: 3.411764705882353,
  N: 93.6470588235294,
  J: 1.6470588235294117,
  St: 123.82352941176471,
  Nt: 2967.0,
  Jt: 57.1764705882353,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 3,
  TimeNow: 232.35294117647058,
  S: 1.8823529411764706,
  N: 97.11764705882354,
  J: 0.0,
  St: 68.70588235294117,
  Nt: 3079.294117647059,
  Jt: 0.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 0,
  TimeNow: 277.2631578947368,
  S: 1.631578947368421,
  N: 96.05263157894737,
  J: 1.105263157894737,
  St: 62.526315789473685,
  Nt: 3041.9473684210525,
  Jt: 43.526315789473685,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 22,
  TimeNow: 290.2631578947368,
  S: 4.7894736842105265,
  N: 93.63157894736842,
  J: 0.42105263157894735,
  St: 165.05263157894737,
  Nt: 2966.9473684210525,
  Jt: 16.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 20,
  TimeNow: 336.8888888888889,
  S: 6.444444444444445,
  N: 89.74074074074075,
  J: 2.4444444444444446,
  St: 219.59259259259258,
  Nt: 2840.5185185185187,
  Jt: 87.88888888888889,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 19,
  TimeNow: 309.67857142857144,
  S: 4.535714285714286,
  N: 92.82142857142857,
  J: 1.4285714285714286,
  St: 155.32142857142858,
  Nt: 2940.6785714285716,
  Jt: 52.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 23,
  TimeNow: 280.72222222222223,
  S: 1.8888888888888888,
  N: 96.16666666666667,
  J: 0.8888888888888888,
  St: 68.11111111111111,
  Nt: 3047.0,
  Jt: 32.888888888888886,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 17,
  TimeNow: 300.55555555555554,
  S: 5.833333333333333,
  N: 91.30555555555556,
  J: 1.6111111111111112,
  St: 202.66666666666666,
  Nt: 2887.1666666666665,
  Jt: 58.166666666666664,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 2,
  TimeNow: 241.33333333333334,
  S: 1.1666666666666667,
  N: 97.83333333333333,
  J: 0.0,
  St: 51.166666666666664,
  Nt: 3096.8333333333335,
  Jt: 0.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 1,
  TimeNow: 259.2352941176471,
  S: 2.176470588235294,
  N: 96.82352941176471,
  J: 0.0,
  St: 79.11764705882354,
  Nt: 3068.8823529411766,
  Jt: 0.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 16,
  TimeNow: 287.72727272727275,
  S: 2.6818181818181817,
  N: 94.5909090909091,
  J: 1.4545454545454546,
  St: 95.13636363636364,
  Nt: 2999.590909090909,
  Jt: 53.27272727272727,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 15,
  TimeNow: 285.3636363636364,
  S: 1.2727272727272727,
  N: 95.72727272727273,
  J: 1.8181818181818181,
  St: 54.18181818181818,
  Nt: 3028.3636363636365,
  Jt: 65.45454545454545,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 4,
  TimeNow: 229.33333333333334,
  S: 0.2222222222222222,
  N: 98.77777777777777,
  J: 0.0,
  St: 22.555555555555557,
  Nt: 3125.4444444444443,
  Jt: 0.0,
}, {
  Id: 'qrfVyESuKWArso9aUxGe',
  Hour: 11,
  TimeNow: 301.0,
  S: 5.076923076923077,
  N: 92.76923076923077,
  J: 0.9230769230769231,
  St: 171.3846153846154,
  Nt: 2937.230769230769,
  Jt: 39.38461538461539,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 21,
  TimeNow: 354.95652173913044,
  S: 0.391304347826087,
  N: 98.6086956521739,
  J: 0.0,
  St: 39.30434782608695,
  Nt: 9258.695652173914,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 18,
  TimeNow: 363.68,
  S: 1.68,
  N: 97.32,
  J: 0.0,
  St: 172.96,
  Nt: 9125.04,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 12,
  TimeNow: 745.8823529411765,
  S: 10.058823529411764,
  N: 71.17647058823529,
  J: 17.235294117647058,
  St: 993.1764705882352,
  Nt: 6684.235294117647,
  Jt: 1653.8235294117646,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 14,
  TimeNow: 698.5833333333334,
  S: 9.916666666666666,
  N: 67.5,
  J: 21.583333333333332,
  St: 979.5,
  Nt: 6307.833333333333,
  Jt: 2029.5,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 13,
  TimeNow: 665.0588235294117,
  S: 8.411764705882353,
  N: 75.6470588235294,
  J: 14.705882352941176,
  St: 828.5882352941177,
  Nt: 7079.470588235294,
  Jt: 1396.5882352941176,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 3,
  TimeNow: 328.4117647058824,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 0,
  TimeNow: 340.2105263157895,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 22,
  TimeNow: 354.7368421052632,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 20,
  TimeNow: 353.25925925925924,
  S: 0.2222222222222222,
  N: 98.77777777777777,
  J: 0.0,
  St: 21.0,
  Nt: 9277.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 19,
  TimeNow: 387.32142857142856,
  S: 4.142857142857143,
  N: 92.5,
  J: 2.357142857142857,
  St: 414.39285714285717,
  Nt: 8660.857142857143,
  Jt: 222.75,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 23,
  TimeNow: 345.05555555555554,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 17,
  TimeNow: 630.5277777777778,
  S: 5.194444444444445,
  N: 79.72222222222223,
  J: 13.916666666666666,
  St: 519.0277777777778,
  Nt: 7460.805555555556,
  Jt: 1318.1666666666667,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 2,
  TimeNow: 336.0,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 1,
  TimeNow: 335.9375,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 16,
  TimeNow: 667.2272727272727,
  S: 9.0,
  N: 72.31818181818181,
  J: 17.318181818181817,
  St: 873.4545454545455,
  Nt: 6786.454545454545,
  Jt: 1638.090909090909,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 15,
  TimeNow: 595.9090909090909,
  S: 11.727272727272727,
  N: 72.18181818181819,
  J: 14.818181818181818,
  St: 1155.4545454545455,
  Nt: 6733.727272727273,
  Jt: 1408.8181818181818,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 4,
  TimeNow: 327.8888888888889,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 9298.0,
  Jt: 0.0,
}, {
  Id: 'qwET8nsY5FqeF3tcphSJ',
  Hour: 11,
  TimeNow: 809.7692307692307,
  S: 7.153846153846154,
  N: 69.46153846153847,
  J: 21.384615384615383,
  St: 728.3076923076923,
  Nt: 6562.538461538462,
  Jt: 2050.6153846153848,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 21,
  TimeNow: 325.0,
  S: 0.8260869565217391,
  N: 98.17391304347827,
  J: 0.0,
  St: 11.565217391304348,
  Nt: 1212.4347826086957,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 18,
  TimeNow: 325.0,
  S: 0.68,
  N: 98.04,
  J: 0.28,
  St: 9.52,
  Nt: 1210.56,
  Jt: 3.92,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 12,
  TimeNow: 325.0,
  S: 0.7647058823529411,
  N: 98.05882352941177,
  J: 0.17647058823529413,
  St: 10.705882352941176,
  Nt: 1210.8235294117646,
  Jt: 2.4705882352941178,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 14,
  TimeNow: 325.0,
  S: 0.6666666666666666,
  N: 98.0,
  J: 0.3333333333333333,
  St: 9.333333333333334,
  Nt: 1210.0,
  Jt: 4.666666666666667,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 13,
  TimeNow: 325.0,
  S: 0.9411764705882353,
  N: 98.0,
  J: 0.058823529411764705,
  St: 13.176470588235293,
  Nt: 1210.0,
  Jt: 0.8235294117647058,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 3,
  TimeNow: 325.0,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 14.0,
  Nt: 1210.0,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 0,
  TimeNow: 325.0,
  S: 0.10526315789473684,
  N: 98.84210526315789,
  J: 0.05263157894736842,
  St: 1.4736842105263157,
  Nt: 1221.7894736842106,
  Jt: 0.7368421052631579,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 22,
  TimeNow: 325.0,
  S: 0.3684210526315789,
  N: 98.63157894736842,
  J: 0.0,
  St: 5.157894736842105,
  Nt: 1218.842105263158,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 20,
  TimeNow: 325.0,
  S: 0.5555555555555556,
  N: 98.0,
  J: 0.4444444444444444,
  St: 7.777777777777778,
  Nt: 1210.0,
  Jt: 6.222222222222222,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 19,
  TimeNow: 325.0,
  S: 0.7142857142857143,
  N: 98.0,
  J: 0.2857142857142857,
  St: 10.0,
  Nt: 1210.0,
  Jt: 4.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 23,
  TimeNow: 325.0,
  S: 0.2222222222222222,
  N: 98.77777777777777,
  J: 0.0,
  St: 3.111111111111111,
  Nt: 1220.888888888889,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 17,
  TimeNow: 325.0,
  S: 0.5277777777777778,
  N: 98.13888888888889,
  J: 0.3333333333333333,
  St: 7.388888888888889,
  Nt: 1211.9444444444443,
  Jt: 4.666666666666667,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 2,
  TimeNow: 325.0,
  S: 0.8333333333333334,
  N: 98.16666666666667,
  J: 0.0,
  St: 11.666666666666666,
  Nt: 1212.3333333333333,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 1,
  TimeNow: 325.0,
  S: 0.058823529411764705,
  N: 98.94117647058823,
  J: 0.0,
  St: 0.8235294117647058,
  Nt: 1223.1764705882354,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 16,
  TimeNow: 325.0,
  S: 0.8636363636363636,
  N: 98.0,
  J: 0.13636363636363635,
  St: 12.090909090909092,
  Nt: 1210.0,
  Jt: 1.9090909090909092,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 15,
  TimeNow: 325.0,
  S: 0.8181818181818182,
  N: 98.0909090909091,
  J: 0.09090909090909091,
  St: 11.454545454545455,
  Nt: 1211.2727272727273,
  Jt: 1.2727272727272727,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 4,
  TimeNow: 325.0,
  S: 0.2222222222222222,
  N: 98.77777777777777,
  J: 0.0,
  St: 3.111111111111111,
  Nt: 1220.888888888889,
  Jt: 0.0,
}, {
  Id: 'sCRXgLM8YvJVvw0h8R2d',
  Hour: 11,
  TimeNow: 325.0,
  S: 0.8461538461538461,
  N: 98.0,
  J: 0.15384615384615385,
  St: 11.846153846153847,
  Nt: 1210.0,
  Jt: 2.1538461538461537,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 21,
  TimeNow: 436.2173913043478,
  S: 19.26086956521739,
  N: 76.73913043478261,
  J: 2.869565217391304,
  St: 1226.608695652174,
  Nt: 4766.304347826087,
  Jt: 182.08695652173913,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 18,
  TimeNow: 484.0,
  S: 25.88,
  N: 63.16,
  J: 9.36,
  St: 1629.4,
  Nt: 3927.2,
  Jt: 618.4,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 14,
  TimeNow: 453.6666666666667,
  S: 15.583333333333334,
  N: 71.66666666666667,
  J: 11.5,
  St: 988.25,
  Nt: 4456.0,
  Jt: 730.75,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 0,
  TimeNow: 339.3157894736842,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 3.736842105263158,
  Nt: 6171.263157894737,
  Jt: 0.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 22,
  TimeNow: 406.5263157894737,
  S: 13.421052631578947,
  N: 85.57894736842105,
  J: 0.0,
  St: 857.4736842105264,
  Nt: 5317.526315789473,
  Jt: 0.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 20,
  TimeNow: 479.22222222222223,
  S: 33.25925925925926,
  N: 57.111111111111114,
  J: 8.185185185185185,
  St: 2088.925925925926,
  Nt: 3560.703703703704,
  Jt: 525.3703703703703,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 19,
  TimeNow: 509.57142857142856,
  S: 31.178571428571427,
  N: 53.75,
  J: 13.607142857142858,
  St: 1951.392857142857,
  Nt: 3353.714285714286,
  Jt: 869.8928571428571,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 23,
  TimeNow: 368.1111111111111,
  S: 2.388888888888889,
  N: 96.61111111111111,
  J: 0.0,
  St: 171.44444444444446,
  Nt: 6003.555555555556,
  Jt: 0.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 17,
  TimeNow: 532.3333333333334,
  S: 23.02777777777778,
  N: 62.72222222222222,
  J: 12.805555555555555,
  St: 1453.5,
  Nt: 3903.222222222222,
  Jt: 818.2777777777778,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 2,
  TimeNow: 300.6666666666667,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 6175.0,
  Jt: 0.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 1,
  TimeNow: 314.8235294117647,
  S: 0.058823529411764705,
  N: 98.94117647058823,
  J: 0.0,
  St: 6.882352941176471,
  Nt: 6168.117647058823,
  Jt: 0.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 16,
  TimeNow: 530.7727272727273,
  S: 12.363636363636363,
  N: 73.36363636363636,
  J: 12.909090909090908,
  St: 792.9545454545455,
  Nt: 4561.0,
  Jt: 821.0454545454545,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 15,
  TimeNow: 488.90909090909093,
  S: 10.181818181818182,
  N: 75.0909090909091,
  J: 13.090909090909092,
  St: 669.0,
  Nt: 4673.0,
  Jt: 833.0,
}, {
  Id: 'u5qDYGjH2IVqp1qUHfRb',
  Hour: 13,
  TimeNow: 446.4,
  S: 17.2,
  N: 70.6,
  J: 10.8,
  St: 1076.8,
  Nt: 4399.6,
  Jt: 698.6,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 21,
  TimeNow: 101.30434782608695,
  S: 48.47826086956522,
  N: 46.56521739130435,
  J: 3.9130434782608696,
  St: 320.60869565217394,
  Nt: 309.04347826086956,
  Jt: 26.347826086956523,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 18,
  TimeNow: 126.8,
  S: 64.32,
  N: 9.6,
  J: 25.08,
  St: 425.56,
  Nt: 64.24,
  Jt: 166.32,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 12,
  TimeNow: 342.2352941176471,
  S: 29.529411764705884,
  N: 0.35294117647058826,
  J: 69.11764705882354,
  St: 196.2941176470588,
  Nt: 2.3529411764705883,
  Jt: 457.3529411764706,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 14,
  TimeNow: 314.75,
  S: 47.416666666666664,
  N: 0.0,
  J: 51.583333333333336,
  St: 315.9166666666667,
  Nt: 0.0,
  Jt: 340.0833333333333,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 13,
  TimeNow: 319.11764705882354,
  S: 42.470588235294116,
  N: 1.7058823529411764,
  J: 54.705882352941174,
  St: 283.11764705882354,
  Nt: 12.294117647058824,
  Jt: 360.5882352941176,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 3,
  TimeNow: 86.29411764705883,
  S: 45.1764705882353,
  N: 53.8235294117647,
  J: 0.0,
  St: 299.8235294117647,
  Nt: 356.1764705882353,
  Jt: 0.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 0,
  TimeNow: 87.3157894736842,
  S: 30.526315789473685,
  N: 68.36842105263158,
  J: 0.10526315789473684,
  St: 203.52631578947367,
  Nt: 451.7894736842105,
  Jt: 0.7368421052631579,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 22,
  TimeNow: 86.63157894736842,
  S: 12.31578947368421,
  N: 86.6842105263158,
  J: 0.0,
  St: 83.05263157894737,
  Nt: 572.9473684210526,
  Jt: 0.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 20,
  TimeNow: 128.11111111111111,
  S: 65.48148148148148,
  N: 1.8888888888888888,
  J: 31.51851851851852,
  St: 432.6666666666667,
  Nt: 12.62962962962963,
  Jt: 210.88888888888889,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 19,
  TimeNow: 214.57142857142858,
  S: 43.892857142857146,
  N: 1.3928571428571428,
  J: 53.67857142857143,
  St: 293.5357142857143,
  Nt: 9.321428571428571,
  Jt: 353.14285714285717,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 23,
  TimeNow: 86.33333333333333,
  S: 21.22222222222222,
  N: 77.77777777777777,
  J: 0.0,
  St: 142.33333333333334,
  Nt: 513.6666666666666,
  Jt: 0.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 17,
  TimeNow: 305.1388888888889,
  S: 41.27777777777778,
  N: 9.083333333333334,
  J: 48.583333333333336,
  St: 274.6111111111111,
  Nt: 60.638888888888886,
  Jt: 320.8611111111111,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 2,
  TimeNow: 79.16666666666667,
  S: 15.0,
  N: 84.0,
  J: 0.0,
  St: 100.0,
  Nt: 556.0,
  Jt: 0.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 1,
  TimeNow: 80.0,
  S: 18.176470588235293,
  N: 80.82352941176471,
  J: 0.0,
  St: 121.23529411764706,
  Nt: 534.7647058823529,
  Jt: 0.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 16,
  TimeNow: 370.8181818181818,
  S: 36.81818181818182,
  N: 0.4090909090909091,
  J: 61.77272727272727,
  St: 245.13636363636363,
  Nt: 2.772727272727273,
  Jt: 408.09090909090907,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 15,
  TimeNow: 272.45454545454544,
  S: 58.63636363636363,
  N: 3.6363636363636362,
  J: 36.63636363636363,
  St: 388.90909090909093,
  Nt: 24.363636363636363,
  Jt: 242.72727272727272,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 4,
  TimeNow: 83.77777777777777,
  S: 31.88888888888889,
  N: 67.11111111111111,
  J: 0.0,
  St: 212.33333333333334,
  Nt: 443.6666666666667,
  Jt: 0.0,
}, {
  Id: 'vLYYPYJhRtmdqMvfqqdl',
  Hour: 11,
  TimeNow: 350.84615384615387,
  S: 42.30769230769231,
  N: 0.0,
  J: 56.69230769230769,
  St: 282.7692307692308,
  Nt: 0.0,
  Jt: 373.2307692307692,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 21,
  TimeNow: 289.3478260869565,
  S: 1.6956521739130435,
  N: 97.30434782608695,
  J: 0.0,
  St: 106.43478260869566,
  Nt: 5395.565217391304,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 18,
  TimeNow: 994.96,
  S: 6.12,
  N: 36.48,
  J: 55.92,
  St: 360.76,
  Nt: 2031.96,
  Jt: 3109.28,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 12,
  TimeNow: 448.70588235294116,
  S: 25.058823529411764,
  N: 53.411764705882355,
  J: 20.176470588235293,
  St: 1400.2941176470588,
  Nt: 2969.470588235294,
  Jt: 1132.235294117647,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 14,
  TimeNow: 608.5833333333334,
  S: 34.5,
  N: 31.416666666666668,
  J: 32.583333333333336,
  St: 1924.75,
  Nt: 1753.4166666666667,
  Jt: 1823.8333333333333,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 13,
  TimeNow: 606.8823529411765,
  S: 17.941176470588236,
  N: 41.11764705882353,
  J: 39.470588235294116,
  St: 1013.5294117647059,
  Nt: 2290.294117647059,
  Jt: 2198.176470588235,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 3,
  TimeNow: 338.5882352941176,
  S: 5.764705882352941,
  N: 88.23529411764706,
  J: 4.705882352941177,
  St: 346.47058823529414,
  Nt: 4879.764705882353,
  Jt: 275.7647058823529,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 0,
  TimeNow: 264.3157894736842,
  S: 0.8421052631578947,
  N: 98.15789473684211,
  J: 0.0,
  St: 52.68421052631579,
  Nt: 5449.315789473684,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 22,
  TimeNow: 279.2631578947368,
  S: 1.0,
  N: 98.0,
  J: 0.0,
  St: 61.526315789473685,
  Nt: 5440.473684210527,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 20,
  TimeNow: 333.7037037037037,
  S: 8.518518518518519,
  N: 87.5925925925926,
  J: 2.814814814814815,
  St: 491.037037037037,
  Nt: 4852.037037037037,
  Jt: 158.92592592592592,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 19,
  TimeNow: 477.0740740740741,
  S: 8.925925925925926,
  N: 70.51851851851852,
  J: 19.444444444444443,
  St: 504.48148148148147,
  Nt: 3920.037037037037,
  Jt: 1077.4814814814815,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 23,
  TimeNow: 264.94444444444446,
  S: 3.0555555555555554,
  N: 92.38888888888889,
  J: 3.4444444444444446,
  St: 173.83333333333334,
  Nt: 5133.055555555556,
  Jt: 195.11111111111111,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 17,
  TimeNow: 1572.4722222222222,
  S: 4.916666666666667,
  N: 21.555555555555557,
  J: 72.0,
  St: 301.77777777777777,
  Nt: 1208.0,
  Jt: 3992.222222222222,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 2,
  TimeNow: 310.0,
  S: 12.666666666666666,
  N: 82.5,
  J: 3.3333333333333335,
  St: 732.3333333333334,
  Nt: 4563.0,
  Jt: 206.66666666666666,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 1,
  TimeNow: 264.2352941176471,
  S: 2.9411764705882355,
  N: 96.05882352941177,
  J: 0.0,
  St: 168.76470588235293,
  Nt: 5333.235294117647,
  Jt: 0.0,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 16,
  TimeNow: 1696.5454545454545,
  S: 3.8181818181818183,
  N: 22.181818181818183,
  J: 72.22727272727273,
  St: 244.1818181818182,
  Nt: 1246.5,
  Jt: 4011.318181818182,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 15,
  TimeNow: 943.6363636363636,
  S: 12.909090909090908,
  N: 26.818181818181817,
  J: 58.72727272727273,
  St: 741.1818181818181,
  Nt: 1499.909090909091,
  Jt: 3260.909090909091,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 4,
  TimeNow: 325.8888888888889,
  S: 5.666666666666667,
  N: 90.22222222222223,
  J: 2.111111111111111,
  St: 352.77777777777777,
  Nt: 4991.444444444444,
  Jt: 157.77777777777777,
}, {
  Id: 'yaXl7CiWAjijl2XPONca',
  Hour: 11,
  TimeNow: 278.7692307692308,
  S: 2.6923076923076925,
  N: 96.3076923076923,
  J: 0.0,
  St: 162.23076923076923,
  Nt: 5339.7692307692305,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 21,
  TimeNow: 733.304347826087,
  S: 0.34782608695652173,
  N: 98.65217391304348,
  J: 0.0,
  St: 126.65217391304348,
  Nt: 18259.347826086956,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 18,
  TimeNow: 785.72,
  S: 3.28,
  N: 94.32,
  J: 1.28,
  St: 665.08,
  Nt: 17458.0,
  Jt: 262.92,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 12,
  TimeNow: 702.0588235294117,
  S: 1.0588235294117647,
  N: 97.94117647058823,
  J: 0.0,
  St: 225.41176470588235,
  Nt: 18160.58823529412,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 14,
  TimeNow: 711.3333333333334,
  S: 1.25,
  N: 97.75,
  J: 0.0,
  St: 266.0833333333333,
  Nt: 18119.916666666668,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 13,
  TimeNow: 725.8823529411765,
  S: 4.764705882352941,
  N: 94.23529411764706,
  J: 0.0,
  St: 972.0,
  Nt: 17414.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 3,
  TimeNow: 687.9411764705883,
  S: 0.058823529411764705,
  N: 98.94117647058823,
  J: 0.0,
  St: 19.0,
  Nt: 18367.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 0,
  TimeNow: 700.5555555555555,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 7.611111111111111,
  Nt: 18378.38888888889,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 22,
  TimeNow: 717.8421052631579,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 20,
  TimeNow: 738.5555555555555,
  S: 0.7407407407407407,
  N: 97.96296296296296,
  J: 0.25925925925925924,
  St: 248.1851851851852,
  Nt: 18084.62962962963,
  Jt: 53.18518518518518,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 19,
  TimeNow: 737.8214285714286,
  S: 0.7142857142857143,
  N: 98.0,
  J: 0.25,
  St: 190.53571428571428,
  Nt: 18144.178571428572,
  Jt: 51.285714285714285,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 23,
  TimeNow: 712.6111111111111,
  S: 0.16666666666666666,
  N: 98.83333333333333,
  J: 0.0,
  St: 32.833333333333336,
  Nt: 18353.166666666668,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 17,
  TimeNow: 978.8611111111111,
  S: 9.666666666666666,
  N: 79.22222222222223,
  J: 9.583333333333334,
  St: 1869.4444444444443,
  Nt: 14649.111111111111,
  Jt: 1867.4444444444443,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 2,
  TimeNow: 709.6666666666666,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 1,
  TimeNow: 701.8823529411765,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 16,
  TimeNow: 985.6363636363636,
  S: 8.454545454545455,
  N: 81.77272727272727,
  J: 8.045454545454545,
  St: 1644.7272727272727,
  Nt: 15120.863636363636,
  Jt: 1620.409090909091,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 15,
  TimeNow: 897.9090909090909,
  S: 4.818181818181818,
  N: 87.0909090909091,
  J: 6.545454545454546,
  St: 977.0909090909091,
  Nt: 16109.636363636364,
  Jt: 1299.2727272727273,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 4,
  TimeNow: 672.3333333333334,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}, {
  Id: 'zo7i6MU1XWSiVHPOcuho',
  Hour: 11,
  TimeNow: 693.7692307692307,
  S: 0.0,
  N: 99.0,
  J: 0.0,
  St: 0.0,
  Nt: 18386.0,
  Jt: 0.0,
}]

const groupByCategory = arr.reduce((group, product) => {
  const { Id } = product
  group[Id] = group[Id] ?? []
  group[Id].push(product)
  return group
}, {})
export default groupByCategory
