const arr = [{
  '24fBwLf4cYSZpeKnJh7z': {
    s: 7.9226190476190474,
    n: 90.92857142857143,
    J: 0.13095238095238096,
    St: 141.50595238095238,
    Nt: 1517.1339285714287,
    Jt: 2.3601190476190474,
  },
}, {
  A6noUNQaeqBvu9nw2W9l: {
    s: 9.193452380952381,
    n: 81.69345238095238,
    J: 7.863095238095238,
    St: 228.3125,
    Nt: 1970.6934523809523,
    Jt: 194.42857142857142,
  },
}, {
  AK045s1PEwVSnG7u00jZ: {
    s: 17.8125,
    n: 63.520833333333336,
    J: 17.37797619047619,
    St: 799.2053571428571,
    Nt: 2806.6309523809523,
    Jt: 772.3958333333334,
  },
}, {
  D4QgJsuHSWponcYfbvR0: {
    s: 23.958333333333332,
    n: 38.586309523809526,
    J: 36.232142857142854,
    St: 1356.8511904761904,
    Nt: 2182.9910714285716,
    Jt: 2046.6041666666667,
  },
}, {
  EpkvexvRz5okcvZNB9Uz: {
    s: 0.0,
    n: 99.0,
    J: 0.0,
    St: 0.0,
    Nt: 337.0,
    Jt: 0.0,
  },
}, {
  FYCoCtE3WUyaAPEE64Ng: {
    s: 8.217910447761193,
    n: 86.8179104477612,
    J: 3.7761194029850746,
    St: 1073.4567164179105,
    Nt: 10940.507462686568,
    Jt: 495.03582089552236,
  },
}, {
  FzRd6op64jHeWdsfQBoL: {
    s: 8.633928571428571,
    n: 82.43154761904762,
    J: 7.598214285714286,
    St: 2241.535714285714,
    Nt: 19887.886904761905,
    Jt: 1948.3095238095239,
  },
}, {
  GAbS3czb5upPGpUZCaYv: {
    s: 14.354166666666666,
    n: 74.74404761904762,
    J: 9.633928571428571,
    St: 273.07738095238096,
    Nt: 1399.2470238095239,
    Jt: 185.05357142857142,
  },
}, {
  GnSJS1Tf4aZHLFVixC1c: {
    s: 11.68452380952381,
    n: 84.93452380952381,
    J: 2.1458333333333335,
    St: 600.7083333333334,
    Nt: 4188.357142857143,
    Jt: 115.92261904761905,
  },
}, {
  GuUxAcTrgDuZq36ona8p: {
    s: 4.943283582089553,
    n: 91.28955223880597,
    J: 2.370149253731343,
    St: 512.4149253731343,
    Nt: 8529.402985074626,
    Jt: 258.1820895522388,
  },
}, {
  JJ0MT1LnOLOzJAa1uWEv: {
    s: 7.374251497005988,
    n: 85.99700598802396,
    J: 5.2604790419161676,
    St: 2265.131736526946,
    Nt: 24529.434131736525,
    Jt: 1605.4580838323354,
  },
}, {
  JZiIa8J7JXL9yIWQtDKC: {
    s: 13.631736526946108,
    n: 71.15868263473054,
    J: 13.79940119760479,
    St: 3139.4790419161677,
    Nt: 15980.062874251496,
    Jt: 3161.22754491018,
  },
}, {
  KK6CpiSlPPG6ChUKmN5g: {
    s: 13.092814371257486,
    n: 67.9940119760479,
    J: 17.526946107784433,
    St: 3082.9011976047905,
    Nt: 15579.934131736527,
    Jt: 4071.440119760479,
  },
}, {
  KTabvSUbDkLllhZ9M0fz: {
    s: 0.5208333333333334,
    n: 98.46428571428571,
    J: 0.011904761904761904,
    St: 10.452380952380953,
    Nt: 1919.2886904761904,
    Jt: 0.25892857142857145,
  },
}, {
  Lh8UanQXJltQacha7VGq: {
    s: 4.719402985074627,
    n: 93.20597014925373,
    J: 0.9283582089552239,
    St: 53.474626865671645,
    Nt: 997.9104477611941,
    Jt: 10.614925373134328,
  },
}, {
  MvAnpzEUy1wRjUknb2Wq: {
    s: 9.555970149253731,
    n: 62.46641791044776,
    J: 26.865671641791046,
    St: 172.51492537313433,
    Nt: 1108.7798507462687,
    Jt: 479.70522388059703,
  },
}, {
  NkQgYfbxHPHM9xFmIzWK: {
    s: 18.277611940298506,
    n: 67.85671641791045,
    J: 12.480597014925372,
    St: 275.844776119403,
    Nt: 1007.8328358208955,
    Jt: 189.4,
  },
}, {
  NlrvbOqaEZ95gJLZQJHK: {
    s: 3.619047619047619,
    n: 88.17261904761905,
    J: 6.875,
    St: 175.52083333333334,
    Nt: 3834.315476190476,
    Jt: 321.1636904761905,
  },
}, {
  PElsdRZHXvD54ZwV4qsi: {
    s: 18.81547619047619,
    n: 51.19345238095238,
    J: 28.74702380952381,
    St: 1414.3541666666667,
    Nt: 3827.279761904762,
    Jt: 2142.3660714285716,
  },
}, {
  Q5nsyZclh4X6utoxdCBr: {
    s: 1.2604790419161678,
    n: 97.40718562874251,
    J: 0.3203592814371258,
    St: 49.553892215568865,
    Nt: 3566.0928143712576,
    Jt: 13.353293413173652,
  },
}, {
  QTnF9muZIf3AKWIzWJqq: {
    s: 7.992537313432836,
    n: 73.72388059701493,
    J: 17.078358208955223,
    St: 296.8619402985075,
    Nt: 2671.776119402985,
    Jt: 623.3619402985074,
  },
}, {
  Qz3oMEwGZDIqB05vTeD5: {
    s: 3.947565543071161,
    n: 76.3632958801498,
    J: 18.48689138576779,
    St: 154.10861423220973,
    Nt: 2839.423220973783,
    Jt: 691.4307116104869,
  },
}, {
  Spo0PQkpY5nEurTi1JZV: {
    s: 5.071428571428571,
    n: 89.78571428571429,
    J: 3.830357142857143,
    St: 101.83630952380952,
    Nt: 1623.5386904761904,
    Jt: 77.625,
  },
}, {
  SvsubPFf0KJzL2nE7zav: {
    s: 5.657992565055762,
    n: 82.77695167286245,
    J: 10.368029739776953,
    St: 351.003717472119,
    Nt: 4945.728624535316,
    Jt: 629.0,
  },
}, {
  U8EjK5bVsRkjAZ9HEc0G: {
    s: 18.44776119402985,
    n: 33.841791044776116,
    J: 46.7044776119403,
    St: 69.91044776119404,
    Nt: 126.63880597014925,
    Jt: 174.45074626865673,
  },
}, {
  VHifwHAt6iuP9XKJQdK6: {
    s: 14.701492537313433,
    n: 77.9589552238806,
    J: 6.007462686567164,
    St: 1133.2723880597016,
    Nt: 5883.832089552238,
    Jt: 474.8955223880597,
  },
}, {
  ZMDmlhS8v6rgDCJZPWo0: {
    s: 25.486567164179103,
    n: 53.6,
    J: 19.650746268656718,
    St: 1249.0298507462687,
    Nt: 2620.268656716418,
    Jt: 964.0149253731344,
  },
}, {
  ZTXeIZP0br2MbYiKaX1G: {
    s: 11.816816816816816,
    n: 85.74174174174175,
    J: 1.2402402402402402,
    St: 275.3663663663664,
    Nt: 1931.1501501501502,
    Jt: 31.564564564564563,
  },
}, {
  bkIY4932t5SVR7hf8fRX: {
    s: 13.2874251497006,
    n: 69.3443113772455,
    J: 16.0748502994012,
    St: 342.10179640718565,
    Nt: 1742.3473053892214,
    Jt: 408.0748502994012,
  },
}, {
  cRyuvsyxZFjaFD1hqjfl: {
    s: 16.901785714285715,
    n: 69.14880952380952,
    J: 12.720238095238095,
    St: 2272.5565476190477,
    Nt: 9183.497023809523,
    Jt: 1703.9464285714287,
  },
}, {
  fdNNCQ6kPiglUszPfuR2: {
    s: 15.351190476190476,
    n: 70.86011904761905,
    J: 12.300595238095237,
    St: 1386.2559523809523,
    Nt: 6267.514880952381,
    Jt: 1115.2291666666667,
  },
}, {
  hZrY5SFfLR6WbPUf2bMJ: {
    s: 7.854166666666667,
    n: 88.0327380952381,
    J: 2.9136904761904763,
    St: 171.60714285714286,
    Nt: 1838.5119047619048,
    Jt: 63.083333333333336,
  },
}, {
  jIIiFSIkF4FGpc3d8FGX: {
    s: 31.348314606741575,
    n: 44.554307116104866,
    J: 22.820224719101123,
    St: 619.5430711610487,
    Nt: 879.2509363295881,
    Jt: 450.37453183520597,
  },
}, {
  jovVzfQYAGFJa3xfBccZ: {
    s: 29.605970149253732,
    n: 31.93134328358209,
    J: 37.343283582089555,
    St: 1526.4328358208954,
    Nt: 1649.7940298507463,
    Jt: 1922.7731343283583,
  },
}, {
  kuGkReF6XUPgehdO2Obd: {
    s: 18.355223880597016,
    n: 72.2358208955224,
    J: 8.155223880597015,
    St: 484.4865671641791,
    Nt: 1874.6537313432837,
    Jt: 215.89850746268658,
  },
}, {
  lD4aGchJfjRI2LTzcXE0: {
    s: 28.24550898203593,
    n: 58.93413173652694,
    J: 11.592814371257486,
    St: 1698.8562874251497,
    Nt: 3527.3353293413174,
    Jt: 697.8083832335329,
  },
}, {
  lF2ALyrRClB2hYH5aUKY: {
    s: 26.09737827715356,
    n: 59.947565543071164,
    J: 12.711610486891386,
    St: 970.5880149812734,
    Nt: 2216.164794007491,
    Jt: 474.24719101123594,
  },
}, {
  m7jwb0WGHCZ86rI2Mlgs: {
    s: 4.7593984962406015,
    n: 86.87593984962406,
    J: 7.056390977443609,
    St: 553.4436090225564,
    Nt: 9328.635338345865,
    Jt: 783.9736842105264,
  },
}, {
  qJjMaaYZxNoHTnmI8FGY: {
    s: 14.758928571428571,
    n: 81.67559523809524,
    J: 2.2470238095238093,
    St: 744.5595238095239,
    Nt: 3991.690476190476,
    Jt: 121.39583333333333,
  },
}, {
  qpGOFcJPWgOaCilvlspC: {
    s: 5.7343283582089555,
    n: 92.12537313432836,
    J: 0.9223880597014925,
    St: 77.88059701492537,
    Nt: 1161.7522388059701,
    Jt: 13.367164179104478,
  },
}, {
  qrfVyESuKWArso9aUxGe: {
    s: 3.7142857142857144,
    n: 93.86904761904762,
    J: 1.2172619047619047,
    St: 131.22619047619048,
    Nt: 2972.464285714286,
    Jt: 44.30952380952381,
  },
}, {
  qwET8nsY5FqeF3tcphSJ: {
    s: 3.62089552238806,
    n: 88.71044776119403,
    J: 6.540298507462687,
    St: 358.8089552238806,
    Nt: 8322.397014925373,
    Jt: 621.179104477612,
  },
}, {
  sCRXgLM8YvJVvw0h8R2d: {
    s: 0.6071428571428571,
    n: 98.23214285714286,
    J: 0.16071428571428573,
    St: 8.5,
    Nt: 1213.25,
    Jt: 2.25,
  },
}, {
  u5qDYGjH2IVqp1qUHfRb: {
    s: 17.33582089552239,
    n: 73.97014925373135,
    J: 7.399253731343284,
    St: 1096.7388059701493,
    Nt: 4603.873134328358,
    Jt: 474.3880597014925,
  },
}, {
  vLYYPYJhRtmdqMvfqqdl: {
    s: 40.63690476190476,
    n: 28.4375,
    J: 29.895833333333332,
    St: 270.1309523809524,
    Nt: 188.29761904761904,
    Jt: 197.61011904761904,
  },
}, {
  yaXl7CiWAjijl2XPONca: {
    s: 7.794029850746269,
    n: 65.80298507462686,
    J: 25.11044776119403,
    St: 449.56119402985075,
    Nt: 3654.4776119402986,
    Jt: 1397.9611940298507,
  },
}, {
  zo7i6MU1XWSiVHPOcuho: {
    s: 2.4925373134328357,
    n: 94.45970149253732,
    J: 1.9104477611940298,
    St: 508.68059701492535,
    Nt: 17499.367164179104,
    Jt: 377.9522388059701,
  },
}]
arr.sort(function (a, b) {
  var x = Object.keys(a)[0]; var y = Object.keys(b)[0]
  return ((x < y) ? -1 : ((x > y) ? 1 : 0))
})
export default arr
